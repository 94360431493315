import i18n from 'i18next';
import _ from 'lodash';
import { Container, Graphics, ITextStyle, Rectangle, Sprite, Text, Texture, filters, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureState, IBonus } from '../../global.d';
import { setBetAmount, setBonuses, setSlotConfig } from '../../gql/cache';
import type { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { getBonusIdByFeature, normalizeCoins, showCurrency } from '../../utils';
import { titleBaseTextStyle, totalCostTextStyle, totalCostValueTextStyle } from '../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { PopupTypes, eventManager } from '../config';
import type { BuyFeatureConfirmProps, LineSet } from '../d';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BuyFeaturePopupConfirm extends Popup {
  private popupBg: Sprite = new Sprite(Texture.WHITE);

  private coinAmount: number;

  private featureState: FeatureState | undefined;

  private promptMessage: TextField;

  private titleText: Sprite;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private confirmBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private promptTextBackplate: Sprite;

  private betAmountBackplate: Sprite;

  private disableBtnFilter = new filters.ColorMatrixFilter();

  private balance: number;

  private coinMultiplier: number;

  private betSettings: IBetSettings;

  private betAmount: number;

  private currency = 'FUN';

  constructor() {
    super();
    this.x = 0;
    this.y = 0;
    this.visible = false;
    this.coinAmount = 1;
    this.balance = 0;

    this.coinMultiplier = (setSlotConfig().lineSets[0] as LineSet).coinAmountMultiplier;
    this.betSettings = getBetsSetting();
    this.betAmount = this.getBetAmount(setBetAmount());

    this.disableBtnFilter.blackAndWhite(true);

    this.initPopupBg();
    this.promptMessage = this.initPromptText();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.confirmBtn = this.initConfirmBtn();
    this.promptTextBackplate = this.initPromptTextBackplate();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.titleText = this.initTitle();

    this.init();
    this.addEvents();
  }

  private addEvents() {
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      //this.handleDisable();
    });

    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
    });
  }

  public override show(props: BuyFeatureConfirmProps): void {
    super.show();
    AudioApi.play({ type: ISongs.SFX_Popup_open_or_info_page_slide });
    const { featureState, coinAmount } = props;
    this.popupBg.texture = utils.TextureCache['menu_platebg.png'] || Texture.EMPTY;

    if (featureState === FeatureState.ONE_BONUS_LINE) {
      this.promptMessage.setText(i18n.t<string>('buyFeatureConfirmTitleOneRow'));
    } else if (featureState === FeatureState.ALL_BONUS_LINES) {
      this.promptMessage.setText(i18n.t<string>('buyFeatureConfirmTitleAllRows'));
    } else if (featureState === FeatureState.BONUS_GAME) {
      this.promptMessage.setText(i18n.t<string>('buyFeatureConfirmTitleBonus'));
    }
    this.featureState = featureState;
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text.text = this.getTotalCost(this.featureState);
    this.confirmBtn.interactive = true;
    this.cancelBtn.interactive = true;

    this.closeAllAnimationsInSlot();
    this.visible = true;

    if (this.balance < this.getFeaturePrice(featureState)) {
      this.confirmBtn.disable();
    }
  }

  private getFeaturePrice(featureState: FeatureState): number {
    return normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState));
  }

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getTotalCost = (featureState: FeatureState): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private getCoinAmount = (featureState: FeatureState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    // TODO: add bonus type to server response
    return bonus.coinAmount;
  };

  private closeAllAnimationsInSlot(): void {
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private init(): void {
    // TODO: adjust to game scaling
    const size = 6000;
    const darkOverlay = new Graphics();
    darkOverlay.beginFill(0x000000, 0.8); // Dark color with 50% transparency
    darkOverlay.drawRect(-size / 2, -size / 2, size, size);
    darkOverlay.endFill();

    this.addChild(darkOverlay);

    this.addChild(this.popupBg);
    this.popupBg.addChild(this.titleText);
    //this.addChild(this.promptTextBackplate);
    this.addChild(this.promptMessage.getText());
    this.addChild(this.totalCostText.getText());

    const totalCostContainer = new Container();
    totalCostContainer.y = -30;
    totalCostContainer.addChild(this.betAmountBackplate, this.totalCostValue.getText());
    this.addChild(totalCostContainer);
    this.addChild(this.confirmBtn);
    this.addChild(this.cancelBtn);
  }

  private initPromptTextBackplate(): Sprite {
    const texture = utils.TextureCache['Text Rectangle_Largest.png'];
    const input = new Sprite(texture);
    input.name = 'promptTextBackplate';
    input.y = 150;
    input.x = 0;
    input.anchor.set(0.5);

    return input;
  }

  private initBetAmountBackplate(): Sprite {
    const texture = utils.TextureCache['menu_field.png'];
    const input = new Sprite(texture);
    input.name = 'promptTextBackplate';
    input.y = 50;
    // input.x = 0;
    input.anchor.set(0.5);

    return input;
  }

  private initPopupBg(): void {
    this.popupBg.name = 'this.popupBg';
    this.popupBg.x = 0;
    this.popupBg.y = 0;
    this.popupBg.anchor.set(0.5);
  }

  private initTitle(): Sprite {
    const title = new Text(i18n.t<string>('buyFeatureTitle'), titleBaseTextStyle);

    title.y = -290;
    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initPromptText(): TextField {
    const title = new TextField(
      i18n.t<string>('buyFeatureConfirmTitleOneRow'),
      600,
      200,
      titleBaseTextStyle as Partial<ITextStyle>,
    );
    title.text.y = -60;
    title.text.x = 0;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initTotalCostText(): TextField {
    const totalCostText = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      400,
      100,
      totalCostTextStyle as Partial<ITextStyle>,
    );
    totalCostText.text.name = 'totalCostText';
    totalCostText.text.y = 50;
    totalCostText.text.x = 0;
    totalCostText.text.anchor.set(0.5);

    return totalCostText;
  }

  private initTotalCostValue(): TextField {
    const betValue = new TextField('0', 700, 200, totalCostValueTextStyle as Partial<ITextStyle>);
    betValue.text.name = 'totalCostValue';
    betValue.text.y = 170;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['x_normal.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['x_hover.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['x_press.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['x_normal.png'] || Texture.EMPTY,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.name = 'cancelBtn';
    cancelBtn.x = -300;
    cancelBtn.y = 350;
    cancelBtn.anchor.set(0.5, 0.5);

    const width = 186;
    const height = 134;
    const hitArea = new Rectangle(-width / 2, -height / 2, width, height);
    cancelBtn.hitArea = hitArea;

    return cancelBtn;
  }

  private initConfirmBtn(): SpriteButton {
    const confirmBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['ok_normal.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['ok_hover.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['ok_press.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['ok_normal.png'] || Texture.EMPTY,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioApi.play({ type: ISongs.SFX_Custom_click });
        this.startBuyFeature();
      },
      onTouchStart: () => {
        AudioApi.play({ type: ISongs.SFX_Custom_click });
        this.startBuyFeature();
      },
    });

    confirmBtn.name = 'confirmBtn';
    confirmBtn.anchor.set(0.5, 0.5);
    confirmBtn.x = 300;
    confirmBtn.y = 350;

    const width = 186;
    const height = 134;
    const hitArea = new Rectangle(-width / 2, -height / 2, width, height);
    confirmBtn.hitArea = hitArea;

    // const graphics = new Graphics();

    // // Set the fill color and draw a rectangle
    // graphics.beginFill(0xff0000); // Red color
    // graphics.drawRect(-width / 2 + 225, -height / 2 + 370, width, height);
    // graphics.endFill();
    // graphics.alpha = 0.5;

    // confirmBtn.addChild(graphics);
    // // @ts-ignore
    // window.testHitArea = graphics;

    return confirmBtn;
  }

  private startBuyFeature(): void {
    if (!this.featureState) {
      return;
    }
    this.confirmBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.ENABLE_PAYTABLE);
    PopupController.the.closeCurrentPopup();
  }

  public handleClose(_closeBuyFeature?: boolean): void {
    this.confirmBtn.interactive = false;
    this.cancelBtn.interactive = false;
    PopupController.the.openPopup(PopupTypes.BUY_FEATURE);
  }

  protected override resize(_width: number, _height: number): void {
    const isLandscape = _width >= _height;

    if (isLandscape) {
      this.scale.set(1);
    } else {
      this.scale.set(0.75);
    }
  }
}

export default BuyFeaturePopupConfirm;

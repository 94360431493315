import { gsap } from 'gsap';
import { Container, Loader, Sprite, Texture, filters } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { easeInOutSine } from '../../utils';
import { TweenProperties } from '../animations/d';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

export const gateOpen: { [key: string]: boolean } = {
  upper: false,
  lower: false,
};

interface gateType extends Container {
  currentState: string;
}

export class Gates extends ViewContainer {
  private gates: { [key: string]: Sprite } = {};
  isPortrait: boolean;

  private colorMatrix = new filters.ColorMatrixFilter();

  upperGate: gateType;
  lowerGate: gateType;

  private spriteAnimations: SpriteAnimation[] = [];

  constructor() {
    super();
    this.isPortrait = window.innerWidth < window.innerHeight;
    const slotSheet = Loader.shared.resources['gates']!.spritesheet!;
    const textureUpperGate = slotSheet.animations['grid_top_row'] as Texture[];
    this.upperGate = new Container() as gateType;
    this.lowerGate = new Container() as gateType;
    const upperGateBase = new SpriteAnimation({ isLoop: true }, textureUpperGate);
    upperGateBase.spriteAnimation.anchor.set(0.5, 0.5);
    upperGateBase.start();
    upperGateBase.spriteAnimation.animationSpeed = 0.2;
    this.upperGate.addChild(upperGateBase.spriteAnimation);
    this.spriteAnimations.push(upperGateBase);
    const textureLowerGate = slotSheet.animations['grid_bottom_row'] as Texture[];
    const lowerGateBase = new SpriteAnimation({ isLoop: true }, textureLowerGate);
    lowerGateBase.spriteAnimation.anchor.set(0.5, 0.5);
    lowerGateBase.start();
    lowerGateBase.spriteAnimation.animationSpeed = 0.2;
    this.spriteAnimations.push(lowerGateBase);

    const upperActiveState = new Sprite(Texture.from('grid_top_active.png'));
    upperActiveState.anchor.set(0.5, 0.5);
    upperActiveState.position.set(-282, -444);
    upperActiveState.name = 'upperActiveState';
    upperActiveState.visible = false;
    this.upperGate.addChild(upperActiveState);
    Object.assign(this.upperGate, { currentState: 'close' });

    const lowerActiveState = new Sprite(Texture.from('grid_bottom_active.png'));
    lowerActiveState.anchor.set(0.5, 0.5);
    lowerActiveState.position.set(-294, 343);
    lowerActiveState.name = 'lowerActiveState';
    lowerActiveState.visible = false;

    const lowerGateShadow = new Sprite(Texture.from('grid_bottom_row_shadow.png'));
    lowerGateShadow.anchor.set(0.5);
    Object.assign(this.lowerGate, { currentState: 'close' });

    this.lowerGate.addChild(lowerGateShadow, lowerGateBase.spriteAnimation, lowerActiveState);

    this.colorMatrix.blackAndWhite(false);
    this.colorMatrix.brightness(1, false);
    this.upperGate.filters = [this.colorMatrix];

    this.addChild(this.upperGate, this.lowerGate);

    this.addEvents();
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      // case GameMode.BASE_GAME:
      //   this.setBonusLevel(0);
      //   break;
      case GameMode.ONE_BONUS_LINE:
        if (!gateOpen['upper']) {
          this.toggleUpperGate();
        }

        if (gateOpen['lower']) {
          this.toggleLowerGate();
        }

        break;
      case GameMode.ALL_BONUS_LINES:
        if (!gateOpen['upper']) {
          this.toggleUpperGate();
        }

        if (!gateOpen['lower']) {
          this.toggleLowerGate();
        }
        break;
      case GameMode.BONUS_GAME:
        // close all gates
        if (gateOpen['upper']) {
          this.toggleUpperGate();
        }

        if (gateOpen['lower']) {
          this.toggleLowerGate();
        }

        this.spriteAnimations.forEach((a) => {
          a.spriteAnimation.stop();
        });

        break;
      default:
        this.spriteAnimations.forEach((a) => {
          a.spriteAnimation.play();
        });

        break;
    }
  }

  private addEvents() {
    eventManager.on(EventTypes.END_BONUS_SPINS, () => {
      // close any open gates
      if (gateOpen['upper']) {
        this.toggleUpperGate();
      }

      if (gateOpen['lower']) {
        this.toggleLowerGate();
      }
    });
  }

  // TODO: make gate methods private
  public toggleUpperGate() {
    this.toggleGate('upper');
  }

  public toggleLowerGate() {
    this.toggleGate('lower');
  }

  private toggleGate(gateKey: string) {
    const doorDirection = {
      open: { upper: -1, lower: 1 },
      close: { upper: 1, lower: -1 },
    };

    const action = gateOpen[gateKey] ? 'close' : 'open';

    let currentDoor = this.upperGate;

    let currentDirection = doorDirection[action].upper;
    if (gateKey === 'lower') {
      currentDoor = this.lowerGate;
      currentDirection = doorDirection[action].lower;
    }

    currentDoor.currentState = action;

    const activeState = currentDoor.children.find((el) => el.name === gateKey + 'ActiveState');
    if (activeState) {
      activeState.visible = action === 'open';
    }
    action === 'open'
      ? AudioApi.play({ type: ISongs.SFX_Open_Extra_Row })
      : AudioApi.play({ type: ISongs.SFX_Close_Extra_Row });
    if (this.isPortrait) {
      eventManager.on(EventTypes.END_TOGGLE_GATE, () => {
        gateOpen['lower'] && eventManager.emit(EventTypes.OPEN_LOWER_GATE);
      });
      eventManager.on(EventTypes.START_TOGGLE_GATE, () => {
        gateOpen['lower'] && eventManager.emit(EventTypes.CLOSE_LOWER_GATE);
      });
    }
    const duration = 850;
    const tween = new Tween({
      object: currentDoor,
      property: TweenProperties.Y,
      propertyBeginValue: currentDoor.y,
      target: currentDoor.y + currentDirection * 195,
      duration: duration,
      easing: easeInOutSine,
    });

    if (gateKey === 'upper') {
      const brightnessFactor = 0.4;
      let initialBrightness = 1;
      let targetBrightness = brightnessFactor;
      if (gateOpen[gateKey]) {
        initialBrightness = brightnessFactor;
        targetBrightness = 1;
      }

      const anim = gsap.to(this.colorMatrix, {
        duration: duration / 1000, // Duration in seconds
        brightness: targetBrightness, // Target brightness value
        ease: 'none',
        onUpdate: () => {
          // Update the brightness directly
          gsap.getProperty(this.colorMatrix, 'brightness');
          const progress = anim.progress();
          // console.log(brightness, progress, anim.progress());
          const currentBrightness = initialBrightness - (initialBrightness - targetBrightness) * progress;
          // console.log(brightness, anim.progress(), currentBrightness);
          this.colorMatrix.brightness(currentBrightness, false);
        },
      });
    }

    tween.addOnComplete(() => {
      if (gateKey === 'lower') {
        const prizeCounter = this.parent.children.find((element) => element.name === 'WinCountUpMessage');
        if (prizeCounter) {
          prizeCounter.y = this.lowerGate.y;
        }
      }
    });

    tween.addOnComplete(() => eventManager.emit(EventTypes.END_TOGGLE_GATE, this));
    tween.addOnStart(() => eventManager.emit(EventTypes.START_TOGGLE_GATE, this));
    tween.start();

    gateOpen[gateKey] = !gateOpen[gateKey];
  }

  protected override resize(_width: number, _height: number): void {
    this.isPortrait = _width < _height;
    _width <= _height && gateOpen['lower']
      ? eventManager.emit(EventTypes.OPEN_LOWER_GATE)
      : eventManager.emit(EventTypes.CLOSE_LOWER_GATE);
  }
}
export default Gates;

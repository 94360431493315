import gsap from 'gsap';
import { Loader, Sprite, Texture, utils } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import SpriteAnimation from '../../animations/sprite';
import { ViewContainer } from '../../components/ViewContainer';
import { REELS_AMOUNT, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private background: Sprite;
  private reelBackGrounds: SpriteAnimation[];

  constructor() {
    super();
    this.background = this.initBackground();
    this.background.anchor.set(0.5);

    this.reelBackGrounds = this.initReelBackground();
    this.addChild(this.background);
    for (const animation of this.reelBackGrounds) {
      this.addChild(animation.spriteAnimation);
    }
    this.addEvents();
  }

  addEvents() {
    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => {
      this.showReelBackground();
    });

    eventManager.on(EventTypes.BASE_GAME_IDLE, () => {
      this.hideReelBackground();
    });

    eventManager.on(EventTypes.START_BIG_WIN_PRESENTATION, () => {
      this.hideReelBackground();
    });

    eventManager.on(EventTypes.START_MULTIPLIER_ANIMATION, () => {
      this.hideReelBackground();
    });
  }

  initBackground(): Sprite {
    const texture = utils.TextureCache['grid_bg.png'];
    const sprite = new Sprite(texture);
    sprite.x = -10;
    sprite.y = 0;
    return sprite;
  }

  initReelBackground(): SpriteAnimation[] {
    const distance = 193;
    const startX = -distance * 2 - 15;

    const animations: SpriteAnimation[] = [];
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reelTextures = this.initReelBgIntro();
      const reelBgAnimation = new SpriteAnimation({ isLoop: true }, reelTextures);
      reelBgAnimation.spriteAnimation.anchor.set(0.5);
      reelBgAnimation.spriteAnimation.stop();

      reelBgAnimation.spriteAnimation.animationSpeed = 0.3 + Math.random() * 0.1;
      reelBgAnimation.spriteAnimation.name = 'reel-bg';
      reelBgAnimation.spriteAnimation.visible = false;
      reelBgAnimation.spriteAnimation.x = startX + distance * i;
      reelBgAnimation.spriteAnimation.scale.set(0.85, 1);
      animations.push(reelBgAnimation);
    }

    // // @ts-ignore
    // window.animations = animations;
    return animations;
  }

  initReelBgIntro(): Texture[] {
    const sheet = Loader.shared.resources['game-elements']!.spritesheet!;
    const reelTextures = sheet.animations['grid_column'] as Texture[];

    return [reelTextures[0] as Texture, reelTextures[1] as Texture, reelTextures[2] as Texture];
  }

  initReelBgLoop(): Texture[] {
    const sheet = Loader.shared.resources['game-elements']!.spritesheet!;
    const reelTextures = sheet.animations['grid_column'] as Texture[];

    return [
      reelTextures[3] as Texture,
      reelTextures[4] as Texture,
      reelTextures[5] as Texture,
      reelTextures[6] as Texture,
    ];
  }

  showReelBackground() {
    for (const animation of this.reelBackGrounds) {
      const delay = 0.15;
      gsap.delayedCall(delay, () => {
        animation.spriteAnimation.textures = this.initReelBgIntro();
        animation.spriteAnimation.visible = true;
        animation.spriteAnimation.loop = false;
        animation.spriteAnimation.gotoAndPlay(0);

        animation.addOnComplete(() => {
          animation.spriteAnimation.textures = this.initReelBgLoop();
          animation.spriteAnimation.gotoAndPlay(0);
          animation.spriteAnimation.loop = true;
          animation.cleanUpOnComplete();
        });
      });
    }
  }

  hideReelBackground() {
    for (const animation of this.reelBackGrounds) {
      animation.spriteAnimation.visible = false;
      animation.spriteAnimation.loop = false;
      animation.spriteAnimation.stop();
    }
  }
}

export default ReelsBackgroundContainer;

import type React from 'react';
import { useTranslation } from 'react-i18next';

import { gameRulesPatterns1, gameRulesPatterns2 } from '../../config/gameRules';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['payLines']}>
      <h1 className={styles['title']}>{t('infoPayLinesTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {gameRulesPatterns1.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
      <div className={styles['img']}>
        <h1 className={styles['subtitle']}>{t('infoGameRulesPatternsSubtitle')}</h1>
        <img draggable="false" alt="sc" src={Resources.getSource('blockPatterns')} style={{ width: '30%' }} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {gameRulesPatterns2.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PayLines;

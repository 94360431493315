export enum ResourceTypes {
  blockPatterns = 'blockPatterns',
  buyFeature01MaskForCta = 'buyFeature01MaskForCta',
  buybonus = 'buybonus',
  backdrop = 'backdrop',
  bg = 'bg',
  bonusTutorial01 = 'bonusTutorial01',
  bonusTutorial02 = 'bonusTutorial02',
  bonusTutorial03 = 'bonusTutorial03',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  introReel4 = 'introReel4',
  introReel5 = 'introReel5',
  coins = 'coins',
  a = 'a',
  c = 'c',
  d = 'd',
  e = 'e',
  f = 'f',
  j = 'j',
  k = 'k',
  q = 'q',
  sc1 = 'sc1',
  sc2 = 'sc2',
  sc3 = 'sc3',
  sc4 = 'sc4',
  sc5 = 'sc5',
  sc6 = 'sc6',
  t = 't',
  wL = 'wL',
  coin = 'coin',
  empty01 = 'empty01',
  empty02 = 'empty02',
  empty03 = 'empty03',
  empty04 = 'empty04',
  empty05 = 'empty05',
  empty06 = 'empty06',
  logo = 'logo',
}

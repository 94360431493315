import { gsap } from 'gsap';
import { Spine } from 'pixi-spine';
import { Container, DisplayObject, Loader, Point, Sprite, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS, SlotId } from '../../config';
import { Cascade, EventTypes, GameMode } from '../../global.d';
import { setIsTurboSpin } from '../../gql/cache';
import { easeOutSine, getFromMappedSymbol } from '../../utils';
import type Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import { ParticlesAnimation } from '../animations/particles';
import Tween from '../animations/tween';
import type ComboCollectorContainer from '../comboCollector/comboCollectorContainer';
import { ViewContainer } from '../components/ViewContainer';
import { REELS_AMOUNT, REEL_WIDTH, SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, eventManager } from '../config';
import type { Icon } from '../d';
import type RandomEffectsContainer from '../effects/randomEffects';
import { mainGameRandomSteamForegroundEffects } from '../effects/steamRandomEffects';
import type GameView from '../gameView/gameView';
import type ReelsForegroundContainer from '../reels/foregraound/reelsForeground';
import type Reel from '../reels/reel';
import type ReelsContainer from '../reels/reelsContainer';
import Slot from '../reels/slot';
import type WildCollectorContainer from '../wildCollector/wildCollectorContainer';
import type WildSlotContainer from '../wildCollector/wildSlotContainer';

import { comboPatterns, getPattern, patternsMap } from './patternBuilder';

class WinSlotsContainer extends ViewContainer {
  public animation: Animation | null = null;

  private explosionDelay = 0.3;
  private winFramesContainer = new Container();

  private symbolAnimationsContainer = new Container();
  private comboCollector: ComboCollectorContainer;
  extraWildPositions: number[];
  isMSBonus: boolean;
  foregroundSteamEffects: RandomEffectsContainer;

  constructor(comboCollector: ComboCollectorContainer) {
    super();
    this.foregroundSteamEffects = mainGameRandomSteamForegroundEffects();
    this.foregroundSteamEffects.position.set(1500, 700);
    this.foregroundSteamEffects.randomEffectsPlay();
    this.comboCollector = comboCollector;
    this.addChild(this.winFramesContainer);
    this.addChild(this.symbolAnimationsContainer);
    this.addChild(this.foregroundSteamEffects);
    this.extraWildPositions = [];
    this.isMSBonus = true;
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.showWin.bind(this));
  }

  private showWin(spinResult: Icon[], cascade: Cascade, id: number): void {
    eventManager.emit(EventTypes.PAUSE_SLOT_BLICK);
    if (cascade.extraWildPositions) {
      this.extraWildPositions = cascade.extraWildPositions;
    } else {
      this.extraWildPositions = [];
    }

    const currentSpinResult = [...spinResult];

    const winChain = gsap.timeline();
    //
    const symbolsCount: { [key: number]: number } = {};
    for (const winPosition of cascade.winPositions) {
      for (const index of winPosition) {
        if (!symbolsCount[index]) symbolsCount[index] = 0;
        symbolsCount[index]++;
      }
    }

    const multiWilds = [];
    for (const slotIndex in symbolsCount) {
      if (symbolsCount[+slotIndex]! > 1) {
        if (currentSpinResult[slotIndex]!.id === 'WL') {
          multiWilds.push(slotIndex);
        } else {
          throw new Error('only Wild symbol can be part of 2 or more patters at a time');
        }
      }
    }
    const lastPatternPerWild: { [key: number]: number } = {};
    for (const wildIndex of multiWilds) {
      for (const winPosition of cascade.winPositions) {
        for (const index of winPosition) {
          if (index === +wildIndex) {
            const patternIndex = cascade.winPositions.indexOf(winPosition);
            lastPatternPerWild[+wildIndex] = patternIndex;
          }
        }
      }
    }

    let isLastBonus = false;
    for (let index = 0; index < cascade.winPositions.length; index++) {
      const pattern = cascade.winPositions[index] as number[];
      //   const timeScale = setIsTurboSpin() ? 1.5 : 1;
      //   const winAmimation = Tween.createDelayAnimation(1500 / timeScale);
      //   const isLastPattern = index === cascade.winPositions.length - 1;
      const patternTypes = pattern.map((p) => currentSpinResult[p]?.id) as SlotId[];
      const bonusScattersCount = patternTypes.filter((v) => {
        if (this.isMSBonus) {
          return v === 'SC1' || v === 'MS1';
        }

        return v === 'SC1';
      }).length;

      const isBonus = bonusScattersCount >= 3 && pattern.length === bonusScattersCount;
      isLastBonus = isBonus;

      const multiplierScattersCount = patternTypes.filter((v) => {
        return v === 'SC5';
      }).length;

      const isMultiplier = multiplierScattersCount >= 3 && pattern.length === multiplierScattersCount;

      const IWScattersCount = patternTypes.filter((v) => {
        return v === 'SC3' || v === 'SC4';
      }).length;

      const isInstantWin =
        IWScattersCount === 2 && pattern.length === IWScattersCount && patternTypes[0] !== patternTypes[1];

      const bombScattersCount = patternTypes.filter((v) => {
        return v === 'SC2';
      }).length;

      const isBomb = bombScattersCount >= 3 && pattern.length + bombScattersCount >= 15;

      const tween = this.createCascadeAnimation(currentSpinResult, pattern, id, lastPatternPerWild, index, {
        isBomb,
        isInstantWin,
        isMultiplier,
        isBonus,
      });

      tween.play();
      tween.call(() => {
        const slotIndex = pattern.filter((p) => currentSpinResult[p]?.id != 'WL')[0] as number;
        if (slotIndex >= 0) {
          const type = currentSpinResult[slotIndex]?.id;
          const count = pattern.length;

          if (isBonus) {
            eventManager.emit(EventTypes.COLLECT_BONUS);
          } else if (isMultiplier) {
            eventManager.emit(EventTypes.COLLECT_MULTIPLIER);
          } else if (isInstantWin) {
            eventManager.emit(EventTypes.COLLECT_INSTANT_WIN);
          } else if (isBomb) {
            return;
          } else {
            eventManager.emit(EventTypes.COLLECT_PATTERN, { type, count });
          }
        }
      });

      winChain.add(tween, '+=0.001');
    }

    winChain.call(() => {
      this.showExtraWild();
    });

    // wait for extra wild animations
    const extraWildsPerCascade = this.extraWildPositions.filter((v) => v >= 0).length;
    const hasExtraWilds = !!extraWildsPerCascade;
    winChain.add(gsap.to({}, { duration: (2 + this.explosionDelay) * +hasExtraWilds }));

    winChain.call(() => {
      eventManager.emit(EventTypes.NEXT_CASCADE, id + 1, isLastBonus);
    });

    winChain.play();
  }

  private scatterMatchAnimation(id: number, srcName: string) {
    const timeScale = setIsTurboSpin() ? 1.5 : 1;
    const winSymbol = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, srcName as SlotId)));
    winSymbol.anchor.set(0.5, 0.5);
    winSymbol.visible = false;

    const winSymbolBorder = new Sprite(Texture.from('hightlight.png'));
    winSymbolBorder.anchor.set(0.5, 0.5);
    winSymbolBorder.alpha = 0;
    winSymbolBorder.visible = false;
    const delay = Tween.createDelayAnimation((50 * (id % 5)) / timeScale);
    delay.addOnComplete(() => {
      new Tween({
        object: winSymbolBorder,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 0,
        target: 1,
        duration: 250,
        easing: easeOutSine,
      }).start();
    });
    delay.start();
    const onStart = () => {
      const x = REEL_WIDTH / 2 + REEL_WIDTH * (id % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
      winSymbol.visible = true;
      winSymbolBorder.visible = true;

      winSymbol.position.set(x, y);
      winSymbolBorder.position.set(x, y);
      AudioApi.play({ type: ISongs.SFX_Block_Combine_In_Pattern, stopPrev: true });
      srcName === 'SC1' && AudioApi.play({ type: ISongs.SFX_Match_Bonus, stopPrev: true });
      srcName === 'SC2' && AudioApi.play({ type: ISongs.SFX_Bomb_Alarm, stopPrev: true });
      srcName === 'SC5' && AudioApi.play({ type: ISongs.SFX_Popup_open_or_info_page_slide, stopPrev: true });
    };
    const onComplete = () => {
      winSymbol.destroy();
      winSymbolBorder.destroy();
    };

    const tween = gsap.to(
      {},
      {
        // alpha: 1,
        duration: 1.2 / timeScale,
        onStart,
        onComplete,
      },
    );

    this.addChild(winSymbol, winSymbolBorder);
    return tween;
  }

  private createSlotLightAnimation(id: number, srcName: string) {
    const tintPerType: { [key: string]: number } = {
      E: 0x072332,
      F: 0x783700,
      D: 0x293c2e,
      C: 0x4d3e76,
    };
    // const timeScale = setIsTurboSpin() ? 1.5 : 1;
    // const tween = Tween.createDelayAnimation(1200 / timeScale);
    const light = new Sprite(Texture.WHITE);
    light.width = REEL_WIDTH;
    light.height = SLOT_HEIGHT;
    light.anchor.set(0.5, 0.5);
    const x = REEL_WIDTH / 2 + REEL_WIDTH * (id % REELS_AMOUNT);
    const y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
    light.position.set(x, y);
    light.alpha = 0;
    light.tint = tintPerType[srcName] || 0x45423f;
    const texture = utils.TextureCache['pattern_smokes0.png'] as Texture;
    const texture1 = utils.TextureCache['pattern_smokes1.png'] as Texture;
    const texture2 = utils.TextureCache['pattern_smokes2.png'] as Texture;
    const explodeParticle = new ParticlesAnimation('lighting', [texture, texture1, texture2]);
    explodeParticle.position.set(x, y);

    // const delay = Tween.createDelayAnimation((50 * (id % 5)) / timeScale);
    // delay.start();

    // tween.addOnStart(() => {
    //   //   winSymbolBorder.position.set(x, y);
    // });
    const lightAnimation = gsap.timeline();
    lightAnimation.addLabel('show', 0);
    lightAnimation.addLabel('hide', 0.25);
    lightAnimation.call(
      () => {
        explodeParticle.emitter.autoUpdate = true;
      },
      undefined,
      'show',
    );
    lightAnimation.to(
      light,
      {
        alpha: 1,
        duration: 0.25,
        ease: 'none',
      },

      'show',
    );

    lightAnimation.to(
      light.scale,
      {
        x: '*=2',
        y: '*=2',
        duration: 0.25,
        ease: 'power1.out',
      },
      'hide',
    );

    lightAnimation.to(
      light,
      {
        alpha: 0,
        duration: 0.25,
        ease: 'power1.out',
        onComplete: () => {
          explodeParticle.emitter.autoUpdate = false;
          explodeParticle.destroy();
          light.destroy();
        },
      },
      'hide',
    );

    this.addChild(light, explodeParticle);
    return lightAnimation;
  }

  matchAnimation(rawPattern: number[], slotId: SlotId) {
    let patterns = [rawPattern];
    const comboPatternData = comboPatterns.find(
      (comboPattern) => JSON.stringify(comboPattern.ids.sort()) === JSON.stringify(rawPattern.sort()),
    );
    if (comboPatternData) {
      patterns = comboPatternData.subPatterns;
    }

    const timeline = gsap.timeline({ smoothChildTiming: true, autoRemoveChildren: true });
    for (const pattern of patterns) {
      //find the wanted data by matching the pattern ids with the map ids()
      const currentPatternData = patternsMap.find(
        (obj) =>
          JSON.stringify(
            Object.keys(obj)
              .map((v) => +v)
              .sort(),
          ) === JSON.stringify(pattern.sort()),
      ) as { [key: number]: string };

      if (!currentPatternData) {
        console.error('Unexpected pattern: ' + JSON.stringify(pattern));
        return gsap.to(
          {},
          {
            duration: 0.25,
          },
        );
      }

      const currentPattern: Container = getPattern(currentPatternData, slotId);
      currentPattern.alpha = 0;

      const centerElement = currentPattern.children.find((el) => {
        const child = el as Container;
        return child.children[0]?.name === 'center';
      }) as DisplayObject;

      const time = 0.5;
      const showPattern = gsap.to(currentPattern, {
        alpha: 1,
        duration: 0.09,
        ease: 'back.out(0.5)',
        onStart: () => {
          AudioApi.play({ type: ISongs.SFX_Block_Combine_In_Pattern, stopPrev: true });
        },
        onComplete: () => {
          gsap.delayedCall(time / 2, () => {
            currentPattern.destroy();
          });
        },
      });

      const particle = utils.TextureCache[MAPPED_SYMBOLS[slotId].default] as Texture;
      const burstAnimation = new ParticlesAnimation('trail', [particle]);
      this.addChild(burstAnimation);

      const flyToCollector = this.flyToCollector(centerElement, slotId);

      timeline.add(showPattern, 0);
      timeline.add(flyToCollector, time);
      this.addChild(currentPattern);
    }

    return timeline;
  }

  private showExtraWild() {
    for (const slotIndex of this.extraWildPositions) {
      if (slotIndex >= 0) {
        const reelIndex = slotIndex % REELS_AMOUNT;
        const lineIndex = Math.floor(slotIndex / REELS_AMOUNT);
        const reverseCountIndex = SLOTS_PER_REEL_AMOUNT - 1 - lineIndex;
        const newWild = new Slot(reverseCountIndex, SlotId.WL, this.isMSBonus);
        const originPosition = { x: newWild.x, y: newWild.y } as Point;
        const targetPosition = { x: reelIndex * REEL_WIDTH - 50, y: newWild.y } as Point;

        gsap.delayedCall(this.explosionDelay, () => {
          eventManager.emit(EventTypes.STEAMPUNK_ANTICIPATION_START, { x: reelIndex * REEL_WIDTH - 50, y: newWild.y });
        });

        const wildSlotId = { 0: 'c', 1: 'd', 2: 'e', 3: 'f' }[this.extraWildPositions.indexOf(slotIndex)] as string;
        const gameView = this.parent?.parent as GameView;
        const mainContainer = gameView.children[0]! as Sprite;
        const wildCollectorContainer = mainContainer.children.find(
          (child) => child.name === 'WildCollectorContainer',
        ) as WildCollectorContainer;
        const currentWildSlotContainer = wildCollectorContainer.slots[wildSlotId] as WildSlotContainer;

        const globalPosition = currentWildSlotContainer.children
          .find((child) => child.name === 'symbol')!
          .getGlobalPosition() as Point;
        const targetLocalPosition = this.toLocal(globalPosition);
        newWild.x = targetLocalPosition.x;
        newWild.y = targetLocalPosition.y;
        const onCompleteCall = () => {
          newWild.x = originPosition.x;
          newWild.y = originPosition.y;
          const reels = ((this.parent.parent as GameView).reelsContainer as ReelsContainer).reels as Reel[];
          const index = reels[reelIndex]!.slots.findIndex(
            (slot) => slot.id === 4 - Math.floor(slotIndex / REELS_AMOUNT),
          );
          reels[reelIndex]!.slots.splice(index, 1, newWild);
          reels[reelIndex]?.container.addChild(newWild);
        };
        this.flyTo(newWild, targetPosition, SlotId.WL, onCompleteCall);
      }
    }
  }

  private flyToCollector(element: DisplayObject, slotId: SlotId) {
    const gameView = this.parent?.parent as GameView;
    const comboCollector = gameView.comboCollectorContainer as ComboCollectorContainer;
    const targetGlobalPosition = comboCollector?.predictGlobalPosition(slotId, true);
    const targetLocalPosition = this.toLocal(targetGlobalPosition);
    const onCompleteCall = () => {
      element.destroy();
    };

    const flyToCollector = this.flyTo(element, targetLocalPosition, slotId, onCompleteCall);

    return flyToCollector;
  }

  private flyTo(element: DisplayObject, position: Point, slotId: SlotId, onCompleteCall: () => void) {
    const particle = utils.TextureCache[MAPPED_SYMBOLS[slotId].default] as Texture;
    const burstAnimation = new ParticlesAnimation('trail', [particle]);
    this.addChild(burstAnimation);

    const flyDuration = 0.25;
    const animation = gsap.to(element, {
      x: position.x + 50,
      y: position.y,
      duration: flyDuration,
      ease: 'power1.inOut',
      onUpdate: () => {
        burstAnimation.emitter.updateOwnerPos(element.x - 20, element.y);
      },
      onStart: () => {
        burstAnimation.emitter.autoUpdate = true;
        gsap.to(element.scale, {
          x: 1.1,
          y: 1.1,
          duration: flyDuration,
        });
        AudioApi.play({ type: ISongs.SFX_Symbols_Fly, stopPrev: true });
      },
      onComplete: () => {
        onCompleteCall();
        AudioApi.play({ type: ISongs.SFX_Block_Land_In_Collector });
        burstAnimation.emitter.autoUpdate = false;
        burstAnimation.destroy();
      },
    });

    return animation;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.isMSBonus = true;
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.foregroundSteamEffects.randomEffectsPlay();
        break;
      case GameMode.BONUS_GAME:
        this.foregroundSteamEffects.randomEffectsStop();
        break;
      case GameMode.ALL_BONUS_LINES:
        this.isMSBonus = false;
        break;
    }
  }

  private findMidpoint(points: { x: number; y: number }[]) {
    if (points.length === 0) return null;

    let sumX = 0;
    let sumY = 0;

    for (const point of points) {
      sumX += point.x;
      sumY += point.y;
    }

    const midX = sumX / points.length;
    const midY = sumY / points.length;

    return { x: midX, y: midY };
  }

  //   private bonusSymbolsAnimation(id: number, srcName: string){

  //   }

  private createCascadeAnimation(
    spinResult: Icon[],
    pattern: number[],
    _id: number,
    lastPatternPerWild: { [key: number]: number },
    patternIndex: number,
    type: { isBomb: boolean; isInstantWin: boolean; isMultiplier: boolean; isBonus: boolean },
  ): gsap.core.Timeline {
    const matchTimeline = gsap.timeline({
      paused: true,
    });

    let matchSymbolsPattern = pattern;
    if (type.isBomb) {
      matchSymbolsPattern = pattern.filter((winId) => (spinResult[winId as number] as Icon).id === 'SC2');
    }

    matchTimeline.addLabel('light', 0);
    matchTimeline.addLabel('pattern', 0.25);
    matchTimeline.addLabel('bomb', 1.25);
    let visibilityLabel = 'pattern';
    if (type.isBomb) {
      visibilityLabel = 'bomb';
    }

    if (!type.isBonus) {
      matchTimeline.call(
        () => {
          for (const wildIndex in lastPatternPerWild) {
            if (patternIndex !== lastPatternPerWild[wildIndex]) {
              matchSymbolsPattern = matchSymbolsPattern.filter((symbolIndex) => symbolIndex !== +wildIndex);
            }
          }

          eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, matchSymbolsPattern, false);
        },
        undefined,
        visibilityLabel,
      );
    } else {
      // is Bonus
      eventManager.emit('bonusSymbolAnimation');
      //   matchSymbolsPattern.forEach((winId) => {
      //   });
    }

    const first = spinResult[pattern[0] as number];
    let id = '';
    if (first) {
      id = first.id;
    }

    const isScatter = type.isBomb || type.isBonus || type.isInstantWin || type.isMultiplier || id.includes('SC');
    if (isScatter) {
      matchSymbolsPattern.forEach((winId) => {
        matchTimeline.add(this.scatterMatchAnimation(winId, (spinResult[winId as number] as Icon).id), 'light');
      });
      if (type.isBomb) {
        pattern.forEach((winId) => {
          if ((spinResult[winId as number] as Icon).id === 'SC2') {
            matchTimeline.add(this.scatterMatchAnimation(winId, (spinResult[winId as number] as Icon).id), 'light');
          }
        });
        matchSymbolsPattern = pattern.filter((winId) => (spinResult[winId as number] as Icon).id === 'SC2');
      } else {
        pattern.forEach((winId) => {
          matchTimeline.add(this.scatterMatchAnimation(winId, (spinResult[winId as number] as Icon).id), 'light');
        });
      }

      const slotsData: { slot: Slot; slotId: SlotId; winId: number }[] = matchSymbolsPattern.map((winId) => {
        const x = REEL_WIDTH * (winId % REELS_AMOUNT) + REEL_WIDTH / 2;
        const y = SLOT_HEIGHT * Math.floor(winId / REELS_AMOUNT) + SLOT_HEIGHT / 2;
        const slotId = (spinResult[winId as number] as Icon).id;
        const slot = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId as SlotId))) as Slot;
        slot.position.set(x, y);
        return { slot, slotId, winId };
      });

      const positions = slotsData.map((data) => {
        return { x: data.slot.x, y: data.slot.y };
      });

      const midPoint = this.findMidpoint(positions) as Point;

      for (const slotData of slotsData) {
        const flyAnimation = this.flyTo(slotData.slot, midPoint, slotData.slotId, () => {
          if (slotData.slotId === 'SC3' || slotData.slotId === 'SC4') {
            AudioApi.play({ type: ISongs.SFX_Coins_combine, stopPrev: true });
          }

          slotData.slot.destroy;
        });
        matchTimeline.add(flyAnimation, 'flyToMid');
      }

      const explodeEffect = new Spine(Loader.shared.resources['steampunk_bomb_explosion']!.spineData!);

      matchTimeline.call(
        () => {
          explodeEffect.scale.set(0.3);
          explodeEffect.state.timeScale = 3;
          explodeEffect.x = midPoint.x;
          explodeEffect.y = midPoint.y;

          explodeEffect.state.setAnimation(0, 'animation', false);
          const foreGround = this.parent.children.find(
            (el) => el.name === 'ReelsForegroundContainer',
          ) as ReelsForegroundContainer;
          foreGround.addChild(explodeEffect);
          //   this.addChild(explodeEffect);
        },
        undefined,
        'flyToMid+=0.15',
      );

      if (type.isBomb) {
        matchTimeline.call(
          () => {
            eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, pattern, false);
          },
          undefined,
          'bomb+=0.7',
        );
        const bombEffect = new Spine(Loader.shared.resources['steampunk_bomb_explosion']!.spineData!);
        bombEffect.scale.set(3);
        bombEffect.x = 500;
        bombEffect.y = 450;
        const spineTime = bombEffect.skeleton.data.findAnimation('animation')!.duration / bombEffect.state.timeScale;
        const delay = gsap.to({}, { duration: spineTime - 0.3 });
        matchTimeline.add(delay);
        matchTimeline.call(
          () => {
            bombEffect.state.setAnimation(0, 'animation', false);
            const foreGround = this.parent.children.find(
              (el) => el.name === 'ReelsForegroundContainer',
            ) as ReelsForegroundContainer;
            foreGround.addChild(bombEffect);

            AudioApi.play({ type: ISongs.SFX_Synthetic_thunder_short, stopImmediately: [ISongs.SFX_Bomb_Alarm] });
          },
          undefined,
          'bomb+=0.3',
        );
      }
      if (type.isInstantWin) {
        const coin: Sprite = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, 'coin' as SlotId)));
        coin.anchor.set(0.5, 0.5);
        coin.position.set(midPoint.x, midPoint.y);
        matchTimeline.add(this.flyToCollector(coin, 'coin' as SlotId));
      }
      if (type.isMultiplier) {
        const multiplier: Sprite = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, 'SC3' as SlotId)));
        multiplier.anchor.set(0.5, 0.5);
        multiplier.position.set(midPoint.x, midPoint.y);

        const targetGlobalPosition = this.comboCollector.multiplierContainer.getGlobalPosition();
        const targetLocalPosition = this.toLocal(targetGlobalPosition);
        const onCompleteCall = () => {
          multiplier.destroy();
        };

        const flyToMultiplier = this.flyTo(multiplier, targetLocalPosition, 'SC3' as SlotId, onCompleteCall);
        matchTimeline.add(flyToMultiplier);
      }
    } else {
      pattern.forEach((winId) => {
        matchTimeline.add(this.createSlotLightAnimation(winId, (spinResult[winId as number] as Icon).id), 'light');
      });
      let slotId = (spinResult[pattern[0] as number] as Icon).id;
      for (const index in pattern) {
        if (slotId !== 'WL') {
          break;
        }

        slotId = (spinResult[pattern[index] as number] as Icon).id;
      }

      matchTimeline.add(this.matchAnimation(pattern, slotId), 'pattern');
    }

    return matchTimeline;
  }
}

export default WinSlotsContainer;

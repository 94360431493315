import { gql } from '@apollo/client';

export const stressfulGql = gql`
  query Stressful {
    stressful @client
  }
`;

export const configGql = gql`
  query Config {
    isAuthorized @client
    isMobile @client
    isTurboSpin @client
    isSoundOn @client
    isSpinInProgress @client
    isSlotBusy @client
    isSlotStopped @client
    isOpenBetSettingsPopup @client
    isOpenAutoplayPopup @client
    isOpenInfoPopup @client
    isOpenHistoryPopup @client
  }
`;

export const isAuthenticatedGql = gql`
  query IsAuthenticated {
    isAuthenticated
  }
`;

export const getUserGql = gql`
  query GetUser {
    user {
      id
      clientId
      balance {
        amount
        currency
      }
    }
  }
`;

export const getInfoGql = gql`
  query GetInfo {
    getInfo {
      ip
      country
      city
    }
  }
`;

export const getProgressGql = gql`
  query GetProgress {
    progress @client
  }
`;

export const getBetAmountGql = gql`
  query GetBet {
    betAmount @client
  }
`;

export const getWinAmountGql = gql`
  query GetWin {
    winAmount @client
  }
`;

export const getIsSlotStoppedGql = gql`
  query IsSlotStopped {
    isSlotStopped @client
  }
`;

export const getAutoSpinsSettingsGql = gql`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`;

export const getAutoSpinsGql = gql`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`;
export const getGameModeGql = gql`
  query GetAutoSpin {
    gameMode @client
  }
`;

export const getSlotConfigGql = gql`
  query GetSlotConfig {
    slotConfig @client {
      id
      lines
    }
  }
`;

export const slotHistoryGql = gql`
  query slotHistory($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        count
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          coinAmount
          coinValue
          result {
            reelPositions
            winCoinAmount
          }
          data
          reelSetId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const userBonusBetsGql = gql`
  query userBonusBets($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        count
      }
      edges {
        cursor
        node {
          id
          coinAmount
          coinValue
          result {
            reelPositions
            winCoinAmount
          }
          data
          reelSetId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const slotBetGql = gql`
  query bet($input: GetBetInput!) {
    bet(input: $input) {
      id
      coinAmount
      coinValue
      slotId
      status
      reelSetId
      result {
        reelPositions
        winCoinAmount
      }
      data
    }
  }
`;

export const getSlotGql = gql`
  query getSlot($input: GetSlotInput!) {
    slot(input: $input) {
      id
      reels {
        id
        type
        layout
        additionalReelSets
      }
      lines
      icons {
        id
        type
        combos {
          type
          pattern
          rewards {
            type
            multiplier
            count
            bonusId
          }
          payoffType
        }
      }
      previewImage
      settings {
        betLines {
          max
          min
        }
        startPosition
      }
      clientSettings {
        coinAmounts {
          default
          quick
        }
        coinValues {
          code
          variants
        }
        features {
          id
          enabled
        }
        autoplay {
          options
          conditions {
            stopOnAnyWin {
              enabled
            }
            stopIfFeatureIsWon {
              enabled
            }
            stopIfSingleWinExceeds {
              enabled
              multipliers
            }
            stopIfBalanceDecreasesBy {
              enabled
              multipliers
            }
            stopIfBalanceIncreasesBy {
              enabled
              multipliers
            }
          }
        }
      }
      lineSets {
        id
        slotId
        lines
        coinAmountMultiplier
      }
    }
  }
`;

export const getBonuses = gql`
  query GetBonuses($input: GetBonusesInput) {
    bonuses(input: $input) {
      id
      type
      coinAmount
    }
  }
`;

export const getUserBonuses = gql`
  query GetUserBonuses($input: GetUserBonusesInput) {
    userBonuses(input: $input) {
      id
      coinAmount
      coinValue
      data
      betId
      totalWinAmount
      rounds
      roundsPlayed
      cascadeMultiplier
      bonusId
      status
    }
  }
`;

export const isStoppedGql = gql`
  query IsStopped {
    isSlotStopped @client
  }
`;

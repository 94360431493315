//import { MotionBlurFilter } from 'pixi-filters';
import { Container, Filter, Graphics, Sprite, Texture, filters, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { getRandomArrayElement } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import Slot from '../reels/slot';

//import BonusGameContainer from './bonusGameContainer';
import BonusSlot from './bonusSlot';

// cheatsheet
// [SlotId.A]: { default: '_BLK_01_A.png' }, // A
// [SlotId.K]: { default: '_BLK_01_K.png' }, // K
// [SlotId.Q]: { default: '_BLK_01_Q.png' }, // Q
// [SlotId.J]: { default: '_BLK_01_J.png' }, // J
// [SlotId.T]: { default: '_BLK_01_10.png' }, // 10
// [SlotId.C]: { default: 'CUBE_e0000.png' }, // CUBE
// [SlotId.D]: { default: 'cilinder_g0000.png' }, // CYLINDER
// [SlotId.E]: { default: 'pyramid_D0000.png' }, // PYRAMID
// [SlotId.F]: { default: 'blk_sphere_c0000.png' }, // SPHERE

// NOT BONUS GAME
// [SlotId.WL]: { default: 'wild_active0000.png' }, // WILD
// [SlotId.SC1]: { default: 'star_f0000.png' }, // BONUS
// [SlotId.SC2]: { default: 'blk_nuclear0000.png' }, // BOMB
// [SlotId.SC3]: { default: 'coin_up_0000.png' }, // IW UP
// [SlotId.SC4]: { default: 'coin_down_0000.png' }, // IW DOWN
// [SlotId.SC5]: { default: 'torus_g0000.png' }, // MULTIPLIER
// [SlotId.SC6]: { default: '_BLK_01_A.png' }, // ?!? EXTRA SPIN
export default class BonusReelContainer extends ViewContainer {
  public static readonly slotsList: SlotId[] = [
    SlotId.A,
    SlotId.K,
    SlotId.Q,
    SlotId.J,
    SlotId.T,
    SlotId.C,
    SlotId.D,
    SlotId.E,
    SlotId.F,
    SlotId.SC5,
    SlotId.SC6,
  ];

  private maskArea: Graphics | undefined;

  private drum = new Sprite(Texture.EMPTY);
  private frame = new Sprite(Texture.EMPTY);
  // force size to debug
  private slotSizeIndex: number = 0;
  private currentSlotsCount: number;
  private nextSlotsContainer: Container = new Container();
  private previousSlotsContainer: Container = new Container();
  private padReelsContainer: Container = new Container();
  private drumAnimationContainer: Container = new Container();
  private drumContainer: Container = new Container();
  private currentSlots: BonusSlot[] = [];
  private previousSlots: BonusSlot[] = [];
  public spinTween: Tween | undefined;
  private spinChain: AnimationChain | undefined;
  private motionBlurFilter: Filter = new filters.BlurFilterPass(false);

  private readonly slotCounts = [1, 3, 5];
  private readonly reelPadLength = 20;
  private readonly slotDistance = 155;
  private readonly startY = 20;

  // TODO: scale asset
  private readonly slotScale = 0.83;

  constructor() {
    super();

    this.y = this.slotDistance - 20;
    this.scale.set(0.9);

    this.currentSlotsCount = this.slotCounts[this.slotSizeIndex] || 1;

    this.addGraphic();
  }

  public nextReelAnimation(): AnimationChain {
    const duration = 1000;

    this.currentSlots.forEach((s) => s.pauseAnimations());
    this.previousSlotsContainer.visible = true;
    this.padReelsContainer.visible = true;

    this.previousSlotsContainer.destroy({ children: true });
    this.previousSlotsContainer = this.nextSlotsContainer;
    this.previousSlots = this.currentSlots;
    this.currentSlots = [];

    const startY = Math.round((-this.slotDistance * this.currentSlotsCount) / 2) + this.startY;
    this.nextSlotsContainer = new Container();
    for (let i = 0; i < this.currentSlotsCount; i++) {
      // temporary slots
      const slot = new BonusSlot(0, getRandomArrayElement(BonusReelContainer.slotsList) as SlotId as SlotId);
      slot.x = 0;
      slot.y = startY + this.slotDistance * i;
      slot.scale.set(this.slotScale);

      // TODO: freeze pad slots
      this.nextSlotsContainer.addChild(slot);
      this.currentSlots.push(slot);
    }

    this.drumAnimationContainer.addChild(this.nextSlotsContainer);

    this.previousSlotsContainer.y = 0;
    this.nextSlotsContainer.y =
      this.padReelsContainer.y - (this.slotDistance * this.currentSlotsCount) / 2 - this.startY;

    console.log('target y: ', -this.nextSlotsContainer.y);
    AudioApi.play({ type: ISongs.SFX_Bonus_Spin_Start });
    const chain = new AnimationChain();
    const tween = new Tween({
      object: this.drumAnimationContainer as Container,
      property: TweenProperties.Y,
      propertyBeginValue: 0,
      target: -this.nextSlotsContainer.y,
      // todo set in settings
      duration: duration,
      isLoop: true,
    });

    this.spinTween = tween;
    this.spinChain = chain;

    chain.appendAnimation(tween);

    // const greenFilter = new filters.ColorMatrixFilter();
    // greenFilter.tint(0x00ff00);
    // this.nextSlotsContainer.filters = [greenFilter];
    // const redFilter = new filters.ColorMatrixFilter();
    // redFilter.tint(0xff0000);
    // this.previousSlotsContainer.filters = [redFilter];

    this.drumAnimationContainer.filters = [this.motionBlurFilter];

    chain.start();
    AudioApi.play({ type: ISongs.SFX_Bonus_Reel_Spinning_Loop, stopImmediately: [ISongs.SFX_Bonus_Spin_Start] });
    return chain;
  }

  public setNextSlots(reel: SlotId[]) {
    // if (reel.length !== this.currentSlotsCount) {
    //   console.error(`Unexpected bonus game reel length. Expected: ${this.currentSlotsCount}, Actual: ${reel.length}`);
    //   // TODO: Handle error properly and interrupt the game
    //   // BonusGameContainer.handleBoundsError();
    // }

    this.nextSlotsContainer.destroy({ children: true });
    const startY = Math.round((-this.slotDistance * this.currentSlotsCount) / 2) + this.startY;
    // TODO: change symbol without regenerating
    this.nextSlotsContainer = new Container();
    this.currentSlots = [];
    for (let i = 0; i < this.currentSlotsCount; i++) {
      // temporary slots
      const slot = new BonusSlot(0, reel[i] as SlotId);
      slot.x = 0;
      slot.y = startY + this.slotDistance * i;
      slot.scale.set(this.slotScale);

      // TODO: freeze pad slots
      this.nextSlotsContainer.addChild(slot);
      this.currentSlots.push(slot);
    }

    this.nextSlotsContainer.y =
      this.padReelsContainer.y - (this.slotDistance * this.currentSlotsCount) / 2 - this.startY;
    this.drumAnimationContainer.addChild(this.nextSlotsContainer);

    if (this.spinTween) {
      this.spinTween.isLoop = false;

      this.spinTween.addOnComplete(() => {
        AudioApi.play({ type: ISongs.SFX_Bonus_Spin_End, stopImmediately: [ISongs.SFX_Bonus_Reel_Spinning_Loop] });
        this.drumAnimationContainer.filters = [];
        this.previousSlotsContainer.visible = false;
        this.padReelsContainer.visible = false;
      });
    }

    // const greenFilter = new filters.ColorMatrixFilter();
    // greenFilter.tint(0x00ff00);
    // this.nextSlotsContainer.filters = [greenFilter];
  }

  public winAnimation(indexes: number[]): AnimationGroup {
    const animations = new AnimationGroup();
    for (const i of indexes) {
      const slot = this.currentSlots[i];
      if (slot !== undefined) {
        animations.addAnimation(slot.winAnimation());
      }
    }

    if (this.slotSizeIndex < this.slotCounts.length - 1) {
      this.slotSizeIndex++;
    }

    return animations;
  }

  public getByIndexes(indexes: number[]): BonusSlot[] {
    const slots: BonusSlot[] = [];
    for (const i of indexes) {
      const slot = this.currentSlots[i];
      if (slot) {
        slots.push(slot);
      } else {
        //BonusGameContainer.handleBoundsError();
      }
    }

    return slots;
  }

  public setSize(size: number) {
    this.currentSlotsCount = size || this.slotCounts[this.slotSizeIndex] || 1;
    this.slotSizeIndex = this.slotCounts.indexOf(this.currentSlotsCount);
    const count = this.currentSlotsCount;

    const drumTexture = utils.TextureCache[`bonus_reel_x${count}_bg.png`] || Texture.EMPTY;
    this.drum.texture = drumTexture;
    this.drum.anchor.set(0.5);

    const frameTexture = utils.TextureCache[`bonus_reel_x${count}.png`] || Texture.EMPTY;
    this.frame.texture = frameTexture;
    this.frame.anchor.set(0.5);
    this.frame.y = 7;

    const startY = Math.round((-this.slotDistance * this.currentSlotsCount) / 2) + this.startY;
    this.padReelsContainer.y = startY - this.slotDistance * this.reelPadLength;

    // TODO: adjust mask size with scale
    const slotWidth = 300;
    // leave some slack to hide under the frame
    const slotHeight = this.slotDistance * this.currentSlotsCount + 20;

    if (this.maskArea) this.maskArea.destroy();

    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(-slotWidth / 2, -slotHeight / 2 - 50, slotWidth, slotHeight)
      .endFill();
    this.maskArea.alpha = 0;

    this.drumAnimationContainer.mask = this.maskArea;

    this.addChild(this.maskArea);
  }

  public reset(seedSlots: SlotId[]) {
    this.slotSizeIndex = this.slotSizeIndex = this.slotCounts.indexOf(seedSlots.length);
    this.setSize(seedSlots.length);

    this.nextSlotsContainer.destroy({ children: true });
    this.nextSlotsContainer = new Container();
    this.drumAnimationContainer.addChild(this.nextSlotsContainer);
    this.currentSlots = [];

    const distance = this.slotDistance;
    const startY = Math.round((-distance * this.currentSlotsCount) / 2) + this.startY;
    for (let i = 0; i < this.currentSlotsCount; i++) {
      const slotId = seedSlots[i] || (getRandomArrayElement(BonusReelContainer.slotsList) as SlotId);
      if (!seedSlots[i]) {
        console.error('Insufficient seed slots');
      }
      const slot = new BonusSlot(0, slotId);
      slot.x = 0;
      slot.y = startY + distance * i;
      slot.scale.set(this.slotScale);

      this.nextSlotsContainer.addChild(slot);
    }

    this.drumAnimationContainer.addChild(this.nextSlotsContainer);
    this.drumAnimationContainer.y = 0;
  }

  private addGraphic() {
    this.drum.anchor.set(0.5);

    this.frame.anchor.set(0.5);

    const distance = this.slotDistance;

    for (let i = 0; i < this.reelPadLength; i++) {
      const randomSlotId = getRandomArrayElement(BonusReelContainer.slotsList) as SlotId;
      // No win animations
      // TODO: freeze pad slots
      const slot = new Slot(0, randomSlotId);
      slot.x = 0;
      slot.y = distance * i;
      slot.scale.set(this.slotScale);
      slot.pauseAnimations();

      this.padReelsContainer.addChild(slot);
    }

    const startY = Math.round((-distance * this.currentSlotsCount) / 2) + this.startY;
    this.padReelsContainer.y = startY - distance * this.reelPadLength;

    this.drumAnimationContainer.addChild(this.padReelsContainer);
    this.drumContainer.addChild(this.drumAnimationContainer);

    this.addChild(this.drum, this.drumAnimationContainer, this.frame);
  }
}

import type Animation from '../animation';
import AnimationChain from '../animationChain';
import type AnimationGroup from '../animationGroup';
import type { ISpinAnimation } from '../d';
import type Tween from '../tween';

export class CascadeAnimation extends AnimationChain {
  public animationName?: string;

  constructor(options: ISpinAnimation) {
    super(options);
    this.appendAnimation(options.disappearingAnimation);
    this.appendAnimation(options.waitingAnimation);
    if (options.appearingAnimation) {
      this.appendAnimation(options.appearingAnimation);
    }
  }

  public getAppearingAnimations(): Animation[] {
    const appearing = this.getAppearing() as AnimationChain;
    const appearingGroup = appearing.animations[appearing.animations.length - 1] as AnimationGroup;
    return appearingGroup.animations;
  }

  public getAppearingDelays(): Animation {
    const appearing = this.getAppearing() as AnimationChain;
    const tween = appearing.animations[0] as Tween;
    return tween;
  }

  public getDisappearing(): Animation {
    return this.animations[0] as Animation;
  }

  public getWaiting(): Animation {
    return this.animations[1] as Animation;
  }

  public getAppearing(): Animation {
    return this.animations[2] as Animation;
  }

  public setDisappearing(tween: Animation): void {
    this.animations[0] = tween;
  }

  public setWaiting(tween: Animation): void {
    this.animations[1] = tween;
  }

  public setAppearing(animationGroup: Animation): void {
    this.animations[2] = animationGroup;
  }
}

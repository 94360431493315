import { Point } from 'pixi.js';

import { ISongs } from '../../config';

import RandomEffectsContainer, { type RandomEffectsConfig } from './randomEffects';

const steamSoundEffects = [
  ISongs.SFX_Steam_01,
  ISongs.SFX_Steam_02,
  ISongs.SFX_Steam_03,
  ISongs.SFX_Steam_04,
  ISongs.SFX_Steam_Big_Slow_03,
];

function createAnimationsList(count: number, animationType: string, animationName: string) {
  return Array.from({ length: count }, () => RandomEffectsContainer.getSpineAnimation(animationType, animationName));
}

export function mainGameRandomSteamForegroundEffects(): RandomEffectsContainer {
  const animationsList = createAnimationsList(5, 'steampunk_steam', 'fast_stream');
  const config: RandomEffectsConfig = {
    minDelay: 2,
    maxDelay: 3,
    randomSoundEffects: steamSoundEffects,
    positions: {
      land: [
        { x: -800, y: -700, scale: new Point(-1, 1) },
        { x: -550, y: -400, scale: new Point(0.7, 1) },
        { x: 600, y: -200, scale: new Point(-1, 1), angle: -20 },
        { x: 500, y: 450 },
        { x: -520, y: 420 },
      ],
      port: [
        { x: -350, y: -900, scale: new Point(-1, 1) },
        { x: -500, y: -400, scale: new Point(0.7, 1) },
        { x: -500, y: 420, scale: new Point(-1, 1), angle: -20 },
        { x: 500, y: 400 },
        { x: 450, y: 1000 },
      ],
    },
  };

  const effects = new RandomEffectsContainer(animationsList, config);
  effects.alpha = 0.7;
  return effects;
}

export function mainGameRandomSteamBackgroundEffects(): RandomEffectsContainer {
  const animationsList = createAnimationsList(5, 'steampunk_steam', 'slow_stream');
  const config: RandomEffectsConfig = {
    minDelay: 2,
    maxDelay: 5,
    randomSoundEffects: steamSoundEffects,
    positions: {
      land: [
        { x: -700, y: -700, scale: new Point(-1, 1) },
        { x: -700, y: 700 },
        { x: 700, y: -700, angle: -20 },
        { x: 700, y: 700, scale: new Point(0.7, 1) },
        { x: -400, y: 1200 },
      ],
    },
  };

  return new RandomEffectsContainer(animationsList, config);
}

export function bonusGameSteamRandomForegroundEffects(): RandomEffectsContainer {
  const animationsList = [
    RandomEffectsContainer.getSpineAnimation('steampunk_steam', 'fast_stream'),
    (() => {
      const animation = RandomEffectsContainer.getSpriteAnimation('dynamic-animations', 'steam');
      animation.spriteAnimation.anchor.set(0, 1);
      animation.spriteAnimation.animationSpeed = 0.4;
      return animation;
    })(),
  ];

  const config: RandomEffectsConfig = {
    minDelay: 2,
    maxDelay: 3,
    randomSoundEffects: steamSoundEffects,
    positions: {
      land: [
        { x: 600, y: -150, scale: new Point(-1, 1), angle: -20 },
        { x: -550, y: 430, scale: new Point(-1, 1), angle: -10 },
      ],
      port: [
        { x: 500, y: -450, scale: new Point(-1, 1), angle: -20 },
        { x: -550, y: 430, scale: new Point(-1, 1), angle: -10 },
      ],
    },
  };

  const effects = new RandomEffectsContainer(animationsList, config);
  effects.alpha = 0.7;
  return effects;
}

export function bonusGameSteamRandomBackgroundEffects(): RandomEffectsContainer {
  const animationsList = createAnimationsList(7, 'steampunk_steam', 'slow_stream');
  const config: RandomEffectsConfig = {
    minDelay: 2,
    maxDelay: 4,
    randomSoundEffects: steamSoundEffects,
    positions: {
      land: [
        { x: -300, y: -450, scale: new Point(-1, 1) },
        { x: 400, y: -100 },
        { x: 450, y: -400, angle: -20 },
        { x: 0, y: 0 },
        { x: 500, y: 600, scale: new Point(0.7, 1), angle: 30 },
        { x: -600, y: 700, scale: new Point(1.2, 1), angle: -45 },
        { x: -350, y: 0 },
      ],
    },
  };

  return new RandomEffectsContainer(animationsList, config);
}

import gsap from 'gsap';
import i18n from 'i18next';
import _ from 'lodash';
import { Rectangle, Sprite, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureState, GameMode, IBonus, PopupOpeningTypes } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setBrokenGame,
  setCurrency,
  setIsAutoSpins,
  setIsPopupOpeningInProgress,
  setSlotConfig,
} from '../../gql/cache';
import type { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { Logic } from '../../logic';
import { ResourceTypes } from '../../resources.d';
import { getBonusIdByFeature, normalizeCoins, showCurrency } from '../../utils';
// import { Logic } from '../../logic';
// import { States } from '../../logic/config';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { PopupTypes, eventManager } from '../config';
import type { LineSet } from '../d';
import { PopupController } from '../popups/PopupController';

import { buyFeatureButtonDisabledTextStyle, buyFeatureButtonTextStyle } from './textStyles';

class BuyFeatureBtn extends ViewContainer {
  private btn: Sprite;
  private idleGlow: Sprite;
  private text: TextField;

  isDisabled: boolean;

  // bonus mode selection shortcut
  private betSettings: IBetSettings;
  private betAmount: number;
  private coinMultiplier: number;

  private currency = 'FUN';
  private readonly textWidth = 215;
  private readonly textHeight = 250;
  private readonly defaultTextPos = -30;
  private isIdle = true;

  constructor() {
    super();
    this.isDisabled = false;
    this.btn = this.initBuyFeatureBtn();

    this.text = this.initText();
    this.idleGlow = this.initIdleGlow();

    this.btn.addChild(this.idleGlow, this.text.text);
    this.addChild(this.btn);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });

    // selection shortcut
    this.betSettings = getBetsSetting();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.coinMultiplier = (setSlotConfig().lineSets[0] as LineSet).coinAmountMultiplier;
    this.currency = setCurrency();

    if (setBrokenGame()) {
      this.handleDisable(true);
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      // case GameMode.FREE_SPINS:
      //   this.visible = false;
      //   break;
      // case GameMode.RAGE_MODE:
      //   this.visible = false;
      //   break;
      case GameMode.BONUS_GAME:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initText() {
    const text = new TextField(
      i18n.t<string>('buyFeatureBtn'),
      this.textWidth - 30,
      this.textHeight - 30,
      buyFeatureButtonTextStyle,
    );

    text.text.interactive = false;
    text.text.hitArea = null;
    text.text.anchor.set(0.5);
    text.text.position.set(0, this.defaultTextPos);

    return text;
  }

  private initIdleGlow() {
    const maskTexture = Texture.from(ResourceTypes.buyFeature01MaskForCta);
    const mask = new Sprite(maskTexture);
    mask.anchor.set(0.5);
    const glowTexture = utils.TextureCache['buyFeature01_masked_cta.png'];
    const idleGlow = new Sprite(glowTexture);
    idleGlow.anchor.set(0.5);
    mask.y = -500;
    mask.hitArea = new Rectangle(0, 0, 1, 1);
    this.addChild(mask);
    idleGlow.mask = mask;

    gsap.to(mask, {
      y: 500,
      duration: 2,
      repeat: -1,
      onRepeat: () => {
        if (this.isIdle && this.visible && !this.isDisabled) {
          this.idleGlow.visible = true;
        } else {
          this.idleGlow.visible = false;
        }
      },
    });

    return idleGlow;
  }

  private initBuyFeatureBtn() {
    const texture = utils.TextureCache['buyFeature_normal.png'];
    const btn = new Sprite(texture);
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.buttonMode = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = utils.TextureCache['buyFeature_hover.png'] || Texture.EMPTY;
        this.text.setStyle(buyFeatureButtonTextStyle);

        this.isIdle = false;
        this.idleGlow.visible = false;

        this.text.text.position.set(0, this.defaultTextPos + 3);
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = utils.TextureCache['buyFeature_normal.png'] || Texture.EMPTY;

        this.isIdle = true;

        this.text.text.position.set(0, this.defaultTextPos);
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = utils.TextureCache['buyFeature_click.png'] || Texture.EMPTY;

        this.isIdle = false;
        this.idleGlow.visible = false;

        this.text.text.position.set(0, this.defaultTextPos + 6);
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = utils.TextureCache['buyFeature_normal.png'] || Texture.EMPTY;

        this.isIdle = true;

        this.text.text.position.set(0, this.defaultTextPos);
      }
    });

    const hitArea = new Rectangle(-this.textWidth / 2, -this.textHeight / 2, this.textWidth, this.textHeight);
    btn.hitArea = hitArea;

    // const graphics = new Graphics();

    // // Set the fill color and draw a rectangle
    // graphics.beginFill(0xff0000); // Red color
    // graphics.drawRect(-width / 2, -height / 2, width, height);
    // graphics.endFill();
    // graphics.alpha = 0.5;

    // btn.addChild(graphics);
    // // @ts-ignore
    // window.testHitArea = graphics;

    return btn;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.DISABLE_PAYTABLE);
      //AudioApi.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      //PopupController.the.openPopup(PopupTypes.BUY_FEATURE);

      AudioApi.play({ type: ISongs.SFX_Custom_click });
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, {
        totalCost: this.getTotalCost(FeatureState.BONUS_GAME),
        coinAmount: this.betSettings.bets[this.betAmount] as number,
        featureState: FeatureState.BONUS_GAME,
      });

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);

      this.isIdle = true;

      // // Go to bonus game hack (comment code above)
      // Logic.the.changeState(States.TRANSITION);
      // Logic.the.changeGameMode(GameMode.BONUS_GAME);
    }
  }

  // bonus mode selection shortcut ===>
  private getTotalCost = (featureState: FeatureState): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (featureState: FeatureState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    // TODO: add bonus type to server response
    return bonus.coinAmount || 306;
  };

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  //bonus mode selection shortcut

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;

    if (Logic.the.controller.gameMode !== GameMode.BASE_GAME) {
      disable = true;
    }

    this.isDisabled = disable;
    if (disable) {
      this.btn.buttonMode = false;
      this.btn.texture = utils.TextureCache['buyFeature_disable.png'] || Texture.EMPTY;
      this.text.setStyle(buyFeatureButtonDisabledTextStyle);

      this.idleGlow.visible = false;
    } else {
      this.btn.buttonMode = true;
      this.btn.texture = utils.TextureCache['buyFeature_normal.png'] || Texture.EMPTY;
      this.text.setStyle(buyFeatureButtonTextStyle);
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  protected override resize(_width: number, _height: number): void {
    const isPortrait = _height > _width;
    this.scale.set(1.2);
    this.position.set(-670, 300);
    if (isPortrait) {
      this.position.set(380, 1200);
    }
  }
}

export default BuyFeatureBtn;

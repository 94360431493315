import { Colors } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setCurrentBonus, setIsPopupOpened } from '../../gql/cache';
import { Logic } from '../../logic';
import { calcPercentage, canPressSpin } from '../../utils';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class SpinBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isSlotStopped = false;

  private isFreeSpinsWin = false;

  constructor() {
    super('spin');
    this.interactive = !this.isDisabled;
    this.btn.tint = Colors.GAME_COLOR;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();

    // setInterval(() => {
    //   if (this.interactive) this.handleClick();
    // }, 100);
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      this.isSlotStopped = isSlotStopped;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SHOW_FULL_SCREEN_DIALOG, () => {
      this.visible = false;
    });
    eventManager.on(EventTypes.HIDE_FULL_SCREEN_DIALOG, () => {
      this.visible = true;
    });
  };

  private handleUpdateIntent = (): void => {
    if (this.isAutoSpins) {
      this.handleDisable();
    } else {
      this.updateIntent(this.isSlotBusy ? 'skip' : 'spin');

      // autoskip
      // setTimeout(() => {
      //   this.handleClick();
      // }, 100);
    }
  };

  private handleDisable = (): void => {
    const isDisable = !canPressSpin({
      gameMode: Logic.the.controller.gameMode,
      isFreeSpinsWin: this.isFreeSpinsWin,
      bonusCurrentRound: setCurrentBonus().currentRound,
      isSpinInProgress: this.isSpinInProgress,
      isSlotBusy: this.isSlotBusy,
      isSlotStopped: this.isSlotStopped,
      isPopupOpened: setIsPopupOpened(),
      isAutoPlay: this.isAutoSpins,
    });

    this.setDisable(isDisable);
  };

  // autoclick
  // public override setDisable(disable: boolean): void {
  //   super.setDisable(disable);
  //   if (!disable) {
  //     setTimeout(() => {
  //       this.handleClick();
  //     }, 10);
  //   }
  // }

  public override setActive(isActive: boolean): void {
    super.setActive(isActive);
  }

  private handleClick = (): void => {
    if (!this.isDisabled) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
      eventManager.emit(EventTypes.TOGGLE_SPIN);
    }
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0.5);
    let size = calcPercentage(this.applicationSize.width, 17);
    const gap = calcPercentage(this.applicationSize.width, 0.95);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height / 2;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 17);
      this.btn.anchor.set(0.5, 1);
      x = this.applicationSize.width / 2;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default SpinBtn;

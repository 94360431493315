import { BitmapText, Loader, Point, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import SpriteAnimation from '../animations/sprite';
import { ViewContainer } from '../components/ViewContainer';
import { MULTIPLIER, eventManager } from '../config';

class CollectorGates extends ViewContainer {
  private multiplierIncrement = 0;
  private gates: { [key: string]: SpriteAnimation } = {};
  private gateOpen: { [key: string]: boolean } = {
    upper: false,
    lower: false,
  };

  private multiplier1: number;
  private multiplier2: number;
  private multiplierText1: BitmapText;
  private multiplierText2: BitmapText;

  constructor(multiplier1: number, multiplier2: number) {
    super();
    this.pivot.set(-10, 0);

    this.multiplier1 = multiplier1;
    this.multiplier2 = multiplier2;

    const gateTexture = Loader.shared.resources['game-elements']!.spritesheet!;
    const upperDoorTexture = gateTexture.animations['collector_door'] as Texture[];
    const lowerDoorTexture = upperDoorTexture; // Reuse the upper door texture for the lower door

    this.gates['upper'] = new SpriteAnimation({ isLoop: false }, upperDoorTexture);
    this.gates['upper'].spriteAnimation.animationSpeed = 0.2;
    this.gates['upper'].spriteAnimation.position.set(40, -440);
    this.gates['upper'].spriteAnimation.anchor.set(0.5);

    this.multiplierText2 = new BitmapText(`xd${this.multiplier2}`, {
      fontName: 'collector-doors-multiplier-font-export',
      fontSize: 180,
    });
    this.multiplierText2.anchor.set(0.5, 0.5);
    this.multiplierText2.position.set(10, -15);
    this.gates['upper'].spriteAnimation.addChild(this.multiplierText2);

    this.gates['lower'] = new SpriteAnimation({ isLoop: false }, lowerDoorTexture);
    this.gates['lower'].spriteAnimation.animationSpeed = 0.2;
    this.gates['lower'].spriteAnimation.position.set(40, this.gates['upper'].spriteAnimation.y + 175);
    this.gates['lower'].spriteAnimation.anchor.set(0.5);

    this.multiplierText1 = new BitmapText(`x${this.multiplier1}`, {
      fontName: 'collector-doors-multiplier-font-export',
      fontSize: 180,
    });
    this.multiplierText1.anchor.set(0.5, 0.5);
    this.multiplierText1.position.set(10, -15);
    this.gates['lower'].spriteAnimation.addChild(this.multiplierText1);

    this.addChild(this.gates['upper'].spriteAnimation, this.gates['lower'].spriteAnimation);

    this.addEvents();
  }

  private addEvents() {
    eventManager.on(EventTypes.COLLECT_MULTIPLIER, () => {
      this.multiplierIncrement++;
      this.multiplierText1.text = `x${this.multiplier1 + this.multiplierIncrement}`;
      this.multiplierText2.text = `x${this.multiplier2 + this.multiplierIncrement}`;
    });

    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => {
      this.multiplierIncrement = 0;
      this.multiplierText1.text = `x${this.multiplier1 + this.multiplierIncrement}`;
      this.multiplierText2.text = `x${this.multiplier2 + this.multiplierIncrement}`;
    });

    const lowerGate = this.gates['lower'] as SpriteAnimation;
    const lowerGateTextures = [...lowerGate?.spriteAnimation.textures];
    lowerGate.addOnComplete(() => {
      lowerGate.spriteAnimation.textures = [...lowerGateTextures] as Texture[];
      if (this.gateOpen['lower'] === true) {
        lowerGate.spriteAnimation.alpha = 0;
        lowerGate.spriteAnimation.gotoAndStop(upperGate.spriteAnimation.textures.length - 1);
        lowerGate.spriteAnimation.textures.reverse();
      } else {
        this.multiplierText1.alpha = 1;
      }
    });

    const upperGate = this.gates['upper'] as SpriteAnimation;
    upperGate.addOnComplete(() => {
      upperGate.spriteAnimation.textures = [...lowerGateTextures] as Texture[];
      if (this.gateOpen['upper'] === true && upperGate?.spriteAnimation) {
        upperGate.spriteAnimation.alpha = 0;
        upperGate.spriteAnimation.gotoAndStop(upperGate.spriteAnimation.textures.length - 1);
        upperGate.spriteAnimation.textures.reverse();
      } else {
        this.multiplierText2.alpha = 1;
      }
    });
  }

  public setPositions(position1: Point, position2: Point) {
    this.gates['lower']?.spriteAnimation.position.set(position1.x, position1.y);
    this.gates['upper']?.spriteAnimation.position.set(position2?.x, position2?.y);
  }

  public openGate() {
    if (!this.gateOpen['lower']) {
      MULTIPLIER.current = this.multiplier1 + this.multiplierIncrement;
      eventManager.emit(EventTypes.UPDATE_MULTIPLIER);

      this.toggleLowerGate();
      AudioApi.play({ type: ISongs.SFX_Collector_Door_Open, stopPrev: true });
    } else if (!this.gateOpen['upper']) {
      MULTIPLIER.current = this.multiplier2 + this.multiplierIncrement;
      eventManager.emit(EventTypes.UPDATE_MULTIPLIER);

      this.toggleUpperGate();
      AudioApi.play({ type: ISongs.SFX_Collector_Door_Open, stopPrev: true });
    }
  }

  public closeGates() {
    if (this.gateOpen['upper']) {
      this.toggleUpperGate();
      AudioApi.play({ type: ISongs.SFX_Collector_Door_Close, stopPrev: true });
    }
    if (this.gateOpen['lower']) {
      this.toggleLowerGate();
      AudioApi.play({ type: ISongs.SFX_Collector_Door_Close, stopPrev: true });
    }
  }

  private toggleUpperGate() {
    this.toggleGate('upper');
    this.multiplierText2.alpha = 0;
  }

  private toggleLowerGate() {
    this.toggleGate('lower');
    this.multiplierText1.alpha = 0;
  }

  private toggleGate(gateKey: 'upper' | 'lower') {
    const currentDoor = this.gates[gateKey] as SpriteAnimation;

    currentDoor.spriteAnimation.stop();
    currentDoor.spriteAnimation.alpha = 1;
    currentDoor.spriteAnimation.play();

    this.gateOpen[gateKey] = !this.gateOpen[gateKey];
  }

  protected override resize(_width: number, _height: number): void {
    this.multiplierText1.angle = 0;
    this.multiplierText1.scale.set(1);
    this.multiplierText1.pivot.set(0, 0);
    this.multiplierText2.angle = 0;
    this.multiplierText2.scale.set(1);
    this.multiplierText2.pivot.set(0, 0);

    const isPortrait = _height > _width;

    if (isPortrait) {
      this.multiplierText1.angle = -90;
      this.multiplierText1.scale.set(-1, 1);
      this.multiplierText1.pivot.set(-10, 15);
      this.multiplierText2.angle = -90;
      this.multiplierText2.scale.set(-1, 1);
      this.multiplierText2.pivot.set(-10, 15);
    }
  }
}
export default CollectorGates;

//import { Text } from 'pixi.js';
import { Texture, utils } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setBetResult } from '../../gql/cache';
import { getBetResult } from '../../utils';
import { eventManager } from '../config';
import { TILE_FLIP_LOOP_COUNT } from '../gameView/config';
import MultiplierContainer from '../multiplierContainer/multiplierContainer';

export default class BonusMultiplier extends MultiplierContainer {
  private readonly defaultMultiplier = 25;

  constructor() {
    super();

    this.x = 450;
    this.y = 670;

    this.scale.set(0.85);

    this.multiplierText.anchor.set(-1, 1);
    this.multiplierText.x = -200;

    this.textHolder.x = 10;
    this.textHolder.scale.set(1.1);

    const glowTexture = utils.TextureCache['bonus_mutiplier_glow.png'] as Texture;
    this.winGlowEffect.texture = glowTexture;
    this.winGlowEffect.anchor.set(0.5);
    this.winGlowEffect.position.set(70, -50);
    this.winGlowEffect.angle = 90;
    this.winGlowEffect.scale.set(1);
    this.winGlowEffect.name = 'winGlowEffect';

    this.setMultiplier(this.defaultMultiplier);
  }

  protected override addEvents(): void {
    eventManager.on(EventTypes.BONUS_GAME_MULTIPLIER, () => {
      const result = getBetResult(setBetResult());
      this.setMultiplier(result.bet.data.features.winMultiplier * this.defaultMultiplier);
    });

    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => {
      this.setMultiplier(this.defaultMultiplier);
    });

    eventManager.on(EventTypes.START_BONUS_MULTIPLIER_ANIMATION, () => {
      const betResult = getBetResult(setBetResult());
      const multiplier = betResult.bet.data.features.winMultiplier;
      this.setMultiplier(multiplier * this.defaultMultiplier);
      this.applyMultiplier();
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        this.endApplyMultiplier();
      });
    });

    const loops = TILE_FLIP_LOOP_COUNT;
    let currentLoopOnes = 0;
    this.onesTileFlipAnimation.spriteAnimation.onLoop = () => {
      if (currentLoopOnes >= loops) {
        this.onesTileFlipAnimation.spriteAnimation.visible = false;
        this.onesTileFlipAnimation.spriteAnimation.stop();
        currentLoopOnes = 0;
      }

      currentLoopOnes++;
    };

    let currentLoopTens = 0;
    this.tileFlipAnimation.spriteAnimation.onLoop = () => {
      if (currentLoopTens >= loops) {
        this.tileFlipAnimation.spriteAnimation.visible = false;
        this.tileFlipAnimation.spriteAnimation.stop();
        currentLoopTens = 0;
      }

      currentLoopTens++;
    };
  }

  protected override resize(_width: number, _height: number): void {}
}

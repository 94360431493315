export const gameRules1 = [
  { key: 'infoGameRulesMainGame1', buyFeatureOnly: false },
  { key: 'infoGameRulesMainGame2', buyFeatureOnly: false },
];
export const gameRules2 = [
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_9', buyFeatureOnly: false },
  { key: 'infoGameRules1_10', buyFeatureOnly: false },
];

export const gameRules3 = [
  { key: 'infoGameRulesSteamBonusGame_1', buyFeatureOnly: false },
  { key: 'infoGameRulesSteamBonusGame_2', buyFeatureOnly: false },
  { key: 'infoGameRulesSteamBonusGame_3', buyFeatureOnly: false },
  { key: 'infoGameRulesSteamBonusGame_4', buyFeatureOnly: false },
];
export const gameRules4 = [
  { key: 'infoGameRulesSteamBonusGame_5', buyFeatureOnly: false },
  { key: 'infoGameRulesSteamBonusGame_6', buyFeatureOnly: false },
  { key: 'infoGameRulesSteamBonusGame_7', buyFeatureOnly: false },
  { key: 'infoGameRulesSteamBonusGame_8', buyFeatureOnly: false },
];

export const gameRulesPatterns1 = ['infoGameRulesPatterns_1', 'infoGameRulesPatterns_2'];
export const gameRulesPatterns2 = ['infoGameRulesPatterns_3'];

export const infoCascadeFeatureDescs1 = [
  'infoCascadeFeatureDesc_1',
  'infoCascadeFeatureDesc_2',
  'infoCascadeFeatureDesc_3',
];
export const infoCascadeFeatureDescs2 = ['infoCascadeFeatureDesc_4', 'infoCascadeFeatureDesc_5'];

export const infoBonusGameDescs1 = ['infoBonusGameDescs_1', 'infoBonusGameDescs_2'];
export const infoBonusGameDescs2 = ['infoBonusGameDescs_3', 'infoBonusGameDescs_4'];

export const infoBonusStarDesc = ['infoBonusStarDesc', 'infoBonusStarDesc_1'];

export const infoMultiplierDesc = ['infoMultiplierDesc'];

export const infoWildDesc = ['infoGameRules1_7', 'infoGameRules1_8', 'infoGameRules1_11'];

export const infoNukeDesc = ['infoNukeRules_1'];

export const infoSteamBonusExtraSpin = ['infoSteamBonusExtraSpin'];

export const infoInstantWinCoins = ['infoInstantWinCoins'];

export const infoBuyBonusFeature = ['infoBuyBonusFeature'];

export const infoTBD = ['infoTBD'];

export const infoFeatureBuy1 = ['infoFeatureBuyDesc_1', 'infoFeatureBuyDesc_2', 'infoFeatureBuyDesc_3'];
export const infoFeatureBuy2 = ['infoFeatureBuyDesc_4', 'infoFeatureBuyDesc_5', 'infoFeatureBuyDesc_6'];

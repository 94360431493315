import gsap from 'gsap';
import i18n from 'i18next';
import type { ITrackEntry, Spine } from 'pixi-spine';
import { Container, Graphics, ITextStyle, Rectangle, Sprite, Text, Texture, filters, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setBonusGameTotalWin, setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import BonusWings from '../bonusGameContainer/bonusWings';
import { modeButtonTextStyle, titleBaseTextStyle, totalCostValueTextStyle } from '../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';
import RandomEffectsContainer from '../effects/randomEffects';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BonusEndPopup extends Popup {
  private currency = 'FUN';

  private popupBg: Sprite = new Sprite(Texture.WHITE);

  private promptMessage: TextField;

  private loseMessage: TextField;

  private titleText: Sprite;

  private confirmBtn: Sprite;

  private totalCostValue: TextField;

  private betAmountBackplate: Sprite;

  private steamLeft: Spine;
  private steamRight: Spine;

  private disableBtnFilter = new filters.ColorMatrixFilter();

  private bonusWings = new BonusWings();

  private effectsContainer = new Container();

  constructor() {
    super();
    this.x = 0;
    this.y = 0;
    this.visible = false;

    this.currency = setCurrency();

    this.disableBtnFilter.blackAndWhite(true);

    this.steamLeft = RandomEffectsContainer.getSpineAnimation('steampunk_steam', 'fast_stream');
    this.steamRight = RandomEffectsContainer.getSpineAnimation('steampunk_steam', 'fast_stream');
    this.initPopupBg();
    this.initSteam();

    this.promptMessage = this.initPromptText();
    this.confirmBtn = this.initConfirmBtn();
    this.titleText = this.initTitle();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.totalCostValue = this.initTotalCostValue();
    this.loseMessage = this.initLoseText();

    this.init();
  }

  public override show(): void {
    super.show();

    this.visible = true;
    this.confirmBtn.interactive = true;

    const win = setBonusGameTotalWin();
    if (win > 0) {
      this.betAmountBackplate.visible = true;
      this.totalCostValue.getText().visible = true;
      this.promptMessage.getText().visible = true;
      this.loseMessage.getText().visible = false;
    } else {
      this.betAmountBackplate.visible = false;
      this.totalCostValue.getText().visible = false;
      this.promptMessage.getText().visible = false;
      this.loseMessage.getText().visible = true;
    }

    this.totalCostValue.text.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(setBonusGameTotalWin()),
      showCurrency: showCurrency(this.currency),
    })}`;

    this.bonusWings.resume();
    this.bonusWings.flapWings();

    this.steamLeft.state.timeScale = 1;
    this.steamRight.state.timeScale = 1;

    // setTimeout(() => {
    //   this.confirmBtn.emit('pointerdown');
    //   this.confirmBtn.emit('click');
    // }, 100);

    eventManager.emit(EventTypes.SHOW_FULL_SCREEN_DIALOG);
  }

  public override hide(): void {
    super.hide();
    this.visible = false;

    this.bonusWings.pause();

    eventManager.emit(EventTypes.HIDE_FULL_SCREEN_DIALOG);

    setBonusGameTotalWin(0);
  }

  private init(): void {
    // TODO: adjust to game scaling
    const size = 6000;
    const darkOverlay = new Graphics();
    darkOverlay.beginFill(0x000000, 0.8); // Dark color with 50% transparency
    darkOverlay.drawRect(-size / 2, -size / 2, size, size);
    darkOverlay.endFill();

    this.popupBg.addChild(this.titleText);

    const totalCostContainer = new Container();
    totalCostContainer.y = -30;
    totalCostContainer.addChild(this.betAmountBackplate, this.totalCostValue.getText());

    this.addChild(
      darkOverlay,
      this.bonusWings,
      this.effectsContainer,
      this.popupBg,
      this.titleText,
      this.promptMessage.getText(),
      totalCostContainer,
      this.loseMessage.getText(),
      this.confirmBtn,
    );
  }

  private initSteam() {
    this.steamLeft.state.timeScale = 1;
    this.steamLeft.angle = -80;
    this.steamLeft.y = -250;
    this.steamLeft.scale.set(2);
    this.steamRight.state.timeScale = 1;
    this.steamRight.angle = -80;
    this.steamRight.y = -250;
    this.steamRight.scale.set(2);
    const mirrorSteam = new Container();
    mirrorSteam.addChild(this.steamRight);
    mirrorSteam.scale.set(-1, 1);

    const listener = {
      complete: (entity: ITrackEntry) => {
        entity.loop = true;
        this.steamLeft.state.timeScale = 0;
        this.steamRight.state.timeScale = 0;

        gsap.delayedCall(5, () => {
          if (!this.visible) return;

          this.steamLeft.state.timeScale = 1;
          this.steamRight.state.timeScale = 1;
        });
      },
    };

    // Attach the onComplete listener
    this.steamLeft.state.addListener(listener);

    this.effectsContainer.addChild(this.steamLeft, mirrorSteam);
  }

  private initTotalCostValue(): TextField {
    const betValue = new TextField('0', 700, 200, totalCostValueTextStyle as Partial<ITextStyle>);
    betValue.text.name = 'totalCostValue';
    betValue.text.y = 170;
    betValue.text.x = 0;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initBetAmountBackplate(): Sprite {
    const texture = utils.TextureCache['menu_field.png'];
    const input = new Sprite(texture);
    input.name = 'promptTextBackplate';
    input.y = 50;
    // input.x = 0;
    input.anchor.set(0.5);

    return input;
  }

  private initPopupBg(): void {
    this.popupBg.name = 'this.popupBg';
    this.popupBg.x = 0;
    this.popupBg.y = 0;
    this.popupBg.anchor.set(0.5);
    this.popupBg.texture = utils.TextureCache['menu_platebg.png'] || Texture.EMPTY;
  }

  private initTitle(): Sprite {
    const title = new Text(i18n.t<string>('bonusEndTitle'), titleBaseTextStyle);

    title.y = -290;
    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initPromptText(): TextField {
    const title = new TextField(i18n.t<string>('freeSpinsYouWon'), 600, 200, titleBaseTextStyle as Partial<ITextStyle>);
    title.text.y = -60;
    title.text.x = 0;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initLoseText(): TextField {
    const title = new TextField(i18n.t<string>('freeSpinsNoWin'), 600, 200, titleBaseTextStyle as Partial<ITextStyle>);
    title.text.y = 60;
    title.text.x = 0;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initConfirmBtn(): Sprite {
    const confirmBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['ok_normal.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['ok_hover.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['ok_press.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['ok_normal.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioApi.play({ type: ISongs.SFX_Custom_click });
        this.closeInstructions();
      },
      onTouchStart: () => {
        AudioApi.play({ type: ISongs.SFX_Custom_click });
        this.closeInstructions();
      },
    });

    confirmBtn.name = 'confirmBtn';
    confirmBtn.anchor.set(0.5, 0.5);
    confirmBtn.x = 0;
    confirmBtn.y = 350;

    const width = 186;
    const height = 134;
    const hitArea = new Rectangle(-width / 2, -height / 2, width, height);
    confirmBtn.hitArea = hitArea;

    return confirmBtn;
  }

  private closeInstructions(): void {
    PopupController.the.closeCurrentPopup();
  }

  protected override resize(_width: number, _height: number): void {
    const isLandscape = _width >= _height;

    if (isLandscape) {
      this.scale.set(1);

      this.bonusWings.wingLeftSpine.position.set(-580, -200);
      this.bonusWings.wingLeftSpine.angle = 0;
      this.bonusWings.wingRightSpine.position.set(580, -200);
      this.bonusWings.wingRightSpine.angle = 0;
    } else {
      this.scale.set(0.75);

      this.bonusWings.wingLeftSpine.position.set(-450, -300);
      this.bonusWings.wingLeftSpine.angle = 10;
      this.bonusWings.wingRightSpine.position.set(450, -300);
      this.bonusWings.wingRightSpine.angle = -10;
    }
  }
}

export default BonusEndPopup;

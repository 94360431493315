import { utils } from 'pixi.js';

export * from './textStyles';
class CustomEmitter extends utils.EventEmitter {
  override on(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.on(event, fn, context);
  }

  override once(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.once(event, fn, context);
  }

  override addListener(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.addListener(event, fn, context);
  }

  override emit(event: string | symbol, ...args: unknown[]): boolean {
    return super.emit(event, ...args);
  }
}
export const eventManager = new CustomEmitter();

window.eventManager = eventManager; // Trick to proxy eventManager to cheats file
export enum PopupTypes {
  BUY_FEATURE,
  BUY_FEATURE_CONFIRMATION,
  FREE_SPINS,
  FREE_SPINS_END,
  RAGE_MODE,
  RAGE_MODE_INFO,
  BONUS_INSTRUCTIONS,
  BONUS_FINISH,
}
// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = true;
export const APPLICATION_FPS = 60;

// RESET ANIMATION
export const RESET_ANIMATION_BASE_DURATION = 300;
export const RESET_ANIMATION_TURBO_DURATION = 200;

// SLOT SETTINGS
export const SLOT_SCALE = 1;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10; // 3
export const BASE_WIN_TITLE_SCALE = 1.6;

// TODO: remove
const __TIME_SCALE__ = 1;
// BIG WIN SETTINGS
export const BIG_WIN_AMOUNT_LIMIT = 25; //4
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03 * __TIME_SCALE__;

// MEGA WIN SETTINGS
export const MEGA_WIN_AMOUNT_LIMIT = 40; //5
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1 * __TIME_SCALE__;

// GREAT WIN SETTINGS
export const GREAT_WIN_AMOUNT_LIMIT = 60; //6
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2 * __TIME_SCALE__;

// EPIC WIN SETTINGS
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5 * __TIME_SCALE__;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 5;

export const SLOTS_PER_REEL_AMOUNT = 5;
// {
//   value: 3,
//   get current() {
//     return this.value;
//   },

//   set current(newValue) {
//     this.value = newValue;
//   },
// };

export const MULTIPLIER = {
  value: 1,
  get current() {
    return this.value;
  },

  set current(newValue) {
    this.value = newValue;
  },
};

export const REEL_WIDTH = 190;
export const SLOT_WIDTH = 185;
export const SLOT_HEIGHT = 190;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * 5;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * 5;
export const GAME_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;

// COUNT UP
export const COUNT_UP_MESSAGE_X = 10;
export const COUNT_UP_MESSAGE_Y = 440;

// REELS SETTINGS
export const BASE_DISAPPEARING_DURATION = 300;
export const TURBO_DISAPPEARING_DURATION = 180;
export const BASE_APPEARING_DURATION = 300;
export const TURBO_APPEARING_DURATION = 140;
export enum ReelState {
  IDLE,
  DISAPPEARING,
  WAITING,
  APPEARING,
}
// NUMBER FORMAT
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// SPIN ANIMATION SETTINGS
export const DELAY_BETWEEN_REELS = 600;
export const FORCE_STOP_CASCADE_ANIMATION_DURATION = 200;
export const FORCE_STOP_CASCADE_PER_EACH_DURATION = 0;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = SLOT_WIDTH;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_BORDER_COLOR = 0xd6af5d;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 2000;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

import { Application, Container, Sprite, Texture } from 'pixi.js';

import { introContents } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import { setIsProcessToGame, setProgress } from '../../gql/cache';
import { Logic } from '../../logic';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage, handleChangeRestriction } from '../../utils';
import { eventManager } from '../config';
import SoundBtn from '../controlButtons/soundBtn';
import { UiButton } from '../ui/uiButton';

import Carousel from './Carousel';

class IntroScreen {
  private readonly application: Application;

  private container = new Container();

  private static introScreen: IntroScreen;

  public static initIntroScreen = (): void => {
    IntroScreen.introScreen = new IntroScreen(Logic.the.application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new Sprite(Texture.from(ResourceTypes.introBg));

  private backgroundContainer = new Container();

  private playBtn: UiButton;

  private soundBtn: SoundBtn;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor(application: Application) {
    this.application = application;
    this.background.anchor.set(0.5, 0.5);
    this.backgroundContainer.name = 'this.backgroundContainer';
    this.backgroundContainer.addChild(this.background);
    this.carousel = new Carousel(introContents);
    // @ts-ignore
    window.carousel = this.carousel;
    this.container.name = 'introContainer';
    this.playBtn = this.initPlayBtn();
    this.soundBtn = new SoundBtn();
    this.container.addChild(this.carousel, this.playBtn, this.soundBtn);
    this.application.stage.addChild(this.backgroundContainer, this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
  }

  public getApplication(): Application {
    return this.application;
  }

  private initPlayBtn(): UiButton {
    const btn = new UiButton('play');
    btn.btn.anchor.set(1, 0.5);
    btn.interactive = true;

    const clickCallback = () => {
      setProgress({ ...setProgress(), wasLoaded: true });
      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
      setTimeout(() => handleChangeRestriction(Logic.the.controller.gameMode));
    };
    btn.on('click', clickCallback);
    btn.on('touchend', clickCallback);

    return btn;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
    const isPortraitMode = width < height;
    const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 0.3);
    this.background.texture = Texture.from(ResourceTypes.introBg);
    this.setBgSize(width, height);
    // const heightWithBottomGap = isPortraitMode
    //   ? calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-portrait'), 10))
    //   : calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-landscape'), 10));
    this.playBtn.btn.anchor.set(1, 1);
    this.playBtn.x = width - playBtnGap;
    this.playBtn.y = height - this.playBtn.height / 16;
    this.playBtn.setSize(calcPercentage(width, 20));

    this.soundBtn.btn.anchor.set(0, 0);
    this.soundBtn.x = gap;
    this.soundBtn.y = gap;
    this.soundBtn.setSize(calcPercentage(width, 7.5));
    this.carousel.setSize(width * 0.55, height * 0.8, 0);
    this.carousel.x = width / 2;
    this.carousel.y = height * 0.01;
    //console.log(height - heightWithBottomGap);
    if (isPortraitMode) {
      this.carousel.setSize(width, height, 0);
      this.carousel.scale.set(this.carousel.scale._x * 0.65);
      this.carousel.y = height / 2 - this.carousel.height / 2;
      this.soundBtn.btn.anchor.set(1, 0);
      this.soundBtn.x = width - gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(height, 5));

      this.playBtn.setSize(calcPercentage(width, 32));
      this.playBtn.btn.anchor.set(0.5, 0);
      const playMaxY = height - this.playBtn.height;
      const playY = this.carousel.getBounds().y + this.carousel.height + height * 0.07;
      this.playBtn.y = Math.min(playMaxY, playY);
      this.playBtn.x = width / 2;
    }
  }

  private destroy(): void {
    this.application.stage.removeChild(this.backgroundContainer);
    this.application.stage.removeChild(this.container);
    setIsProcessToGame(true);
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;

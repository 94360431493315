import { gsap } from 'gsap';
import { Container, Loader, Rectangle, Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { randomInteger, shuffleArray } from '../../../utils';
import { isLowPerformance } from '../../../utils/renderStats';
import SpriteAnimation from '../../animations/sprite';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type Gates from '../../gates/gates';
import type Reel from '../reel';
import type Slot from '../slot';

interface slotWithBlick extends Slot {
  blinkAnimation: gsap.core.Timeline;
  blink: SpriteAnimation;
  rays: Sprite;
}

class ReelsForegroundContainer extends ViewContainer {
  visibleLines: number[];
  reels: Reel[];

  constructor() {
    super();
    this.name = 'ReelsForegroundContainer';
    this.visibleLines = [1, 2, 3];
    this.reels = [];
    this.hitArea = new Rectangle(0, 0, 1, 1);

    eventManager.addListener(EventTypes.ADD_SLOT_BLICKS, this.addBlicks.bind(this));
    eventManager.addListener(EventTypes.END_TOGGLE_GATE, this.setVisibleLines.bind(this));
    eventManager.addListener(EventTypes.CLEAR_SLOT_BLICKS, this.clearSlotBlicks.bind(this));
    eventManager.addListener(EventTypes.UPDATE_SLOT_BLICKS, this.updateBlicks.bind(this));
    eventManager.addListener(EventTypes.PAUSE_SLOT_BLICK, this.pauseAll.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BONUS_GAME:
        this.visible = false;
        break;
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      default:
        break;
    }
  }

  private pauseAll() {
    for (const reel of this.reels) {
      for (const slot of reel.slots) {
        if ((slot as slotWithBlick).blinkAnimation) {
          (slot as slotWithBlick).blink.spriteAnimation.visible = false;
          (slot as slotWithBlick).rays.visible = false;
        }
      }
    }
  }

  private clearSlotBlicks() {
    this.removeChildren();
    for (const reel of this.reels) {
      for (const slot of reel.slots) {
        if ((slot as slotWithBlick).blinkAnimation) {
          (slot as slotWithBlick).blinkAnimation.kill();
        }
      }
    }
    this.reels = [];
  }

  private setVisibleLines(gates: Gates) {
    const upperGateLineIndex = 4;
    const lowerGateLineIndex = 0;

    if (gates.upperGate.currentState === 'open' && !this.visibleLines.includes(upperGateLineIndex)) {
      this.visibleLines.unshift(upperGateLineIndex);
    }

    if (gates.upperGate.currentState === 'close' && this.visibleLines.includes(upperGateLineIndex)) {
      this.visibleLines = this.visibleLines.filter((index) => index !== upperGateLineIndex);
    }

    if (gates.lowerGate.currentState === 'open' && !this.visibleLines.includes(lowerGateLineIndex)) {
      this.visibleLines.push(lowerGateLineIndex);
    }

    if (gates.lowerGate.currentState === 'close' && this.visibleLines.includes(lowerGateLineIndex)) {
      this.visibleLines = this.visibleLines.filter((index) => index !== lowerGateLineIndex);
    }

    this.updateBlicks();
  }

  public updateBlicks() {
    if (!this.reels || isLowPerformance) return;

    for (const reel of this.reels) {
      for (const slot of reel.slots) {
        if (slot.isEmpty || slot.skipGlow) {
          continue;
        }

        if (slot.slotId.includes('SC') && this.visibleLines.includes(slot.id)) {
          const scatterSlot = slot as slotWithBlick;
          if (scatterSlot.blinkAnimation) {
            scatterSlot.blink.spriteAnimation.visible = true;
            scatterSlot.rays.visible = true;
            scatterSlot.rays.alpha = 0;
            gsap.to(scatterSlot.rays, {
              alpha: 1,
              duration: 0.2,
            });

            scatterSlot.blinkAnimation.play();

            scatterSlot.blink.spriteAnimation.parent.position.set(slot.x + slot.parent.x, slot.y + slot.parent.y);
          }
        }

        if (slot.slotId.includes('SC')) {
          const scatterSlot = slot as slotWithBlick;
          if (!this.visibleLines.includes(scatterSlot.id) && scatterSlot.blink) {
            scatterSlot.blink.spriteAnimation.visible = false;
            scatterSlot.rays.visible = false;
          }
        }
      }
    }
  }

  public addBlicks(reel: Reel) {
    if (isLowPerformance) return;

    this.reels.push(reel);
    for (const slot of reel.slots) {
      if (slot.slotId.includes('SC')) {
        this.addBlickAnimation(slot);
      }
    }
  }

  private addBlickAnimation(slot: Slot) {
    //sprite blink
    const slotSheet = Loader.shared.resources['dynamic-animations']!.spritesheet!;
    const texture = slotSheet.animations['scater_blicks'] as Texture[];
    shuffleArray(texture);
    const scatterBlink = new SpriteAnimation({ isLoop: true }, texture);
    scatterBlink.spriteAnimation.anchor.set(0.5, 0.5);
    scatterBlink.spriteAnimation.alpha = 0;
    scatterBlink.spriteAnimation.visible = false;
    scatterBlink.spriteAnimation.animationSpeed = 0.3;

    const rays = new Sprite(Texture.from('scater_reys_0.png'));
    const rays1 = new Sprite(Texture.from('scater_reys_1.png'));
    rays.anchor.set(0.5, 0.5);
    rays.visible = false;
    rays.alpha = 0;
    rays.scale.set(0);

    rays1.anchor.set(0.5, 0.5);
    rays1.visible = false;
    rays1.alpha = 0;
    rays1.scale.set(0);

    const raysAnimationDuration = 0.3;
    const raysAnimation = gsap.timeline();
    raysAnimation.to([rays, rays1], {
      alpha: 0.7,
      duration: raysAnimationDuration / 2,
      onStart: () => {
        rays.alpha = 0;
        gsap.to(rays.scale, { x: 0.58, y: 0.58, duration: raysAnimationDuration / 2 });
      },
    });

    raysAnimation.to(rays, {
      angle: 360,
      duration: 10,
      repeat: -1,
      ease: 'none',
    });

    raysAnimation.to(rays1, {
      angle: -360,
      duration: 10,
      repeat: -1,
      ease: 'none',
    });

    const blinkPositions = [
      { x: -85, y: -randomInteger(1, 85) },
      { x: 85, y: randomInteger(1, 85) },
      { x: 85, y: -randomInteger(1, 85) },
      { x: -85, y: randomInteger(1, 85) },
      { x: -85, y: -randomInteger(1, 85) },
      { x: 85, y: randomInteger(1, 85) },
      { x: 85, y: -randomInteger(1, 85) },
      { x: -85, y: randomInteger(1, 85) },

      { x: -randomInteger(1, 85), y: -85 },
      { x: randomInteger(1, 85), y: 85 },
      { x: randomInteger(1, 85), y: -85 },
      { x: -randomInteger(1, 85), y: 85 },
      { x: -randomInteger(1, 85), y: -85 },
      { x: randomInteger(1, 85), y: 85 },
      { x: randomInteger(1, 85), y: -85 },
      { x: -randomInteger(1, 85), y: 85 },
    ];

    const positionContainer = new Container();
    positionContainer.addChild(scatterBlink.spriteAnimation);
    positionContainer.addChild(rays);
    positionContainer.width = 10;
    positionContainer.height = 10;
    positionContainer.position.set(slot.x + slot.parent.x, slot.y + slot.parent.y);
    this.addChild(positionContainer);

    const blinkAnimationRepeatDelay = randomInteger(100, 500) / 100;
    const blinkAnimation = gsap.timeline({
      paused: true,
      repeat: -1,
      repeatDelay: blinkAnimationRepeatDelay,
      //   onRepeat: () => {
      //     blinkAnimation.repeatDelay(randomInteger(100, 500) / 100)
      //   }
    });

    blinkAnimation.to({}, { alpha: 1, duration: blinkAnimationRepeatDelay });

    const clusterBlinks = gsap.timeline({
      repeat: randomInteger(3, 7),
      repeatDelay: randomInteger(5, 20) / 100,
      onRepeat: () => {},
      onStart: () => {
        raysAnimation.restart();
      },
    });

    const blink = gsap.to(scatterBlink.spriteAnimation, {
      alpha: 1,
      duration: randomInteger(10, 20) / 100,
      onStart: () => {
        const randomPosition = blinkPositions[randomInteger(0, blinkPositions.length - 1)] as {
          x: number;
          y: number;
        };

        scatterBlink.spriteAnimation.position.set(randomPosition.x, randomPosition.y);
        scatterBlink.start();
        gsap.to(scatterBlink.spriteAnimation.scale, {
          x: randomInteger(5, 10) / 10,
          y: randomInteger(5, 10) / 10,
          duration: randomInteger(10, 20) / 10,
        });
      },
      onComplete: () => {
        scatterBlink.spriteAnimation.alpha = 0;
      },
    });

    clusterBlinks.add(blink);
    blinkAnimation.add(clusterBlinks);

    Object.assign(slot, { blinkAnimation, blink: scatterBlink, rays });
    if (this.visibleLines.includes(slot.id)) {
      blinkAnimation.play();
      rays.visible = true;
      scatterBlink.spriteAnimation.visible = true;
    }
  }
}

export default ReelsForegroundContainer;

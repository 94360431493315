import { Group, Layer } from '@pixi/layers';
import { gsap } from 'gsap';
import i18n from 'i18next';
import { Container, type Sprite, Text, filters } from 'pixi.js';

import { SlotId } from '../../config';
import { Cascade, EventTypes, GameMode } from '../../global.d';
import { setCurrentBonus, setCurrentIsTurboSpin, setSlotConfig, setUserLastBetResult } from '../../gql/cache';
import { Logic } from '../../logic';
import { cascadeEase, easeOutSine, getCascadeColumns, getSlotOrderBySlotId, shuffleArray } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { CallbackPriority, TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import {
  BASE_APPEARING_DURATION,
  DELAY_BETWEEN_REELS,
  FORCE_STOP_CASCADE_ANIMATION_DURATION,
  FORCE_STOP_CASCADE_PER_EACH_DURATION,
  REELS_AMOUNT,
  RESET_ANIMATION_BASE_DURATION,
  RESET_ANIMATION_TURBO_DURATION,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  TURBO_APPEARING_DURATION,
  eventManager,
  restoredGameTextStyle,
} from '../config';
import type { Icon } from '../d';
import type Gates from '../gates/gates';

import Reel from './reel';
import type Slot from './slot';

class ReelsContainer extends ViewContainer {
  public readonly slotsList: SlotId[] = [
    SlotId.A,
    SlotId.K,
    SlotId.Q,
    SlotId.J,
    SlotId.T,
    SlotId.C,
    SlotId.D,
    SlotId.E,
    SlotId.F,
    SlotId.SC1,
    SlotId.SC2,
    SlotId.SC3,
    SlotId.SC5,
  ];

  public reels: Reel[] = [];

  public landedReels: number[] = [];

  private landingContainer: ViewContainer = new ViewContainer();

  private isSoundPlayed = false;

  private isForceStopped = false;

  private layer: Layer;

  private slotGroup: Group;

  private darkenFilter = new filters.ColorMatrixFilter();

  private restoredText: Text;

  private idsToShow: number[];

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();

    this.idsToShow = [1, 2, 3];
    this.darkenFilter.brightness(0.5, true);

    this.restoredText = new Text(i18n.t<string>('restoredGame'), restoredGameTextStyle);
    this.restoredText.anchor.set(0.5);
    this.restoredText.y = 400;
    this.restoredText.x = 475;
    this.restoredText.alpha = 0;

    this.slotGroup = new Group(1, (slot) => {
      slot.zOrder = getSlotOrderBySlotId((slot as Slot).slotId);
    });
    this.landingContainer.sortableChildren = true;
    this.layer = new Layer(this.slotGroup);
    this.initReels(reels, startPosition, this.slotGroup);
    this.addChild(this.layer);
    this.addChild(this.landingContainer);

    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.setSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
    eventManager.addListener(EventTypes.REEL_LANDED, this.checkLandedReels.bind(this));
    eventManager.addListener(EventTypes.START_TOGGLE_GATE, this.setEmptySlots.bind(this));

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.filters = [];
      this.restoredText.alpha = 0;

      this.landedReels = [];
      this.isForceStopped = false;
    });
    eventManager.addListener(
      EventTypes.START_RANDOM_WILDS_ANIMATION,
      this.createReplaceRandomWildsAnimation.bind(this),
    );
    eventManager.addListener(EventTypes.END_WAITING_ANIMATION, () => {
      this.reels.forEach((reel: Reel) => {
        reel.cascadeAnimation?.getWaiting().cleanUpOnComplete();
      });
    });
    this.sortableChildren = true;

    // eventManager.addListener(EventTypes.OPEN_POPUP, () => {
    //   this.reels.forEach((r) => {
    //     r.slots.forEach((s) => {
    //       s.pauseAnimations();
    //     });
    //   });
    // });

    // eventManager.addListener(EventTypes.CLOSE_POPUP, () => {
    //   if (Logic.the.controller.gameMode !== GameMode.BONUS_GAME) {
    //     this.reels.forEach((r) => {
    //       r.slots.forEach((s) => {
    //         s.resumeAnimations();
    //       });
    //     });
    //   }
    // });
  }

  private setEmptySlots(gates: Gates) {
    let emptyLineIndex = -1;
    //
    if (gates.upperGate.currentState === 'open' && gates.lowerGate.currentState === 'close') {
      emptyLineIndex = 4;
    }

    if (gates.upperGate.currentState === 'open' && gates.lowerGate.currentState === 'open') {
      emptyLineIndex = 0;
    }

    if (emptyLineIndex >= 0) {
      for (const reel of this.reels) {
        for (const slot of reel.slots) {
          if (slot.id === emptyLineIndex) {
            slot.changeSlot();
          }
        }
      }
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    let isMSBonus = true;

    switch (settings.mode) {
      case GameMode.BONUS_GAME:
        this.reels.forEach((r) => {
          r.slots.forEach((s) => {
            s.pauseAnimations();
          });
        });
        eventManager.emit(EventTypes.END_SLOT_BONUS_STATE);
        break;
      case GameMode.BASE_GAME:
        this.reels.forEach((r) => {
          r.slots.forEach((s) => {
            s.resumeAnimations();
          });
        });

        this.idsToShow = [1, 2, 3];
        break;
      case GameMode.ONE_BONUS_LINE:
        this.idsToShow = [1, 2, 3, 4];
        break;
      case GameMode.ALL_BONUS_LINES:
        isMSBonus = false;
        this.idsToShow = [0, 1, 2, 3, 4];
        break;
      default:
        break;
    }

    this.reels.forEach((r) => {
      r.isMSBonus = isMSBonus;
    });
  }

  private checkLandedReels(id: number): void {
    this.landedReels.push(id);
    if (this.landedReels.length === REELS_AMOUNT) {
      this.landedReels = [];
      eventManager.emit(EventTypes.REELS_STOPPED);
    }
  }

  public getCurrentSpinResult(): Icon[] {
    const spinResult: Icon[] = [];
    for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        const slotConfig = setSlotConfig();
        const currentIcon = slotConfig.icons.find(
          (icon) =>
            icon.id ===
            (this.reels[i as number] as Reel).slots.find((slot) => slot.id === SLOTS_PER_REEL_AMOUNT - j - 1)!.slotId,
        )!;

        // const currentIcon = slotConfig.icons.find((icon) => {
        //   const currentReel = this.reels[i as number];
        //   const reelSlots = (currentReel as Reel).slots;
        //   const slotId = SLOTS_PER_REEL_AMOUNT - j - 1;
        //   return icon.id === reelSlots.find((slot) => slot.id === slotId)?.slotId;
        // }, this)!;

        // //NOTE hardcoded data remove it!!!
        // if (!currentIcon) {
        //   console.log('NO ICON');
        //   currentIcon = slotConfig.icons[0] as Icon;
        // }

        spinResult.push(currentIcon);
      }
    }

    return spinResult;
  }

  public createResetReelsAnimation(winPositions: number[][], extraWildPositions: number[]): Animation {
    const cascade = winPositions
      .reduce((res, current) => {
        return [...res, ...current];
      }, [])
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter((v) => !extraWildPositions.includes(v));

    const sortedCascade = cascade.sort();
    sortedCascade.forEach((elem) => {
      const reel = this.reels[elem % REELS_AMOUNT] as Reel;
      const index = reel.slots.findIndex((slot) => slot.id === 4 - Math.floor(elem / REELS_AMOUNT));
      reel.slots.splice(index, 1);
    });

    this.reels.forEach((reel) => {
      reel.slots.forEach((slot, index) => {
        if (slot.id !== reel.slots.length - index - 1) slot.id = reel.slots.length - index - 1;
        if (this.idsToShow.includes(slot.id)) {
          slot.renderable = true;
        } else {
          slot.renderable = false;
        }
      });
    });

    const isTurboSpin = setCurrentIsTurboSpin() && Logic.the.controller.gameMode === GameMode.BASE_GAME;
    const animation = new AnimationGroup();
    this.reels.forEach((reel, reelIndex) => {
      const chain = new AnimationChain();
      chain.appendAnimation(Tween.createDelayAnimation(reelIndex * 20));
      const group = new AnimationGroup();
      reel.slots.forEach((slot) => {
        const propertyBeginValue = slot.y;
        //NOTE drop slots animation after win(fill gaps, not drop on spin)
        const target = (SLOTS_PER_REEL_AMOUNT - slot.id - 0.5) * SLOT_HEIGHT;
        group.addAnimation(
          new Tween({
            object: slot,
            property: TweenProperties.Y,
            propertyBeginValue,
            target: target,
            duration: isTurboSpin ? RESET_ANIMATION_TURBO_DURATION : RESET_ANIMATION_BASE_DURATION,
          }),
        );
      });
      chain.appendAnimation(group);
      animation.addAnimation(chain);
    });
    return animation;
  }

  private rollbackReels(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      (this.reels[i as number] as Reel).cascadeAnimation?.getDisappearing().end();
      (this.reels[i as number] as Reel).cascadeAnimation?.getWaiting().end();
      (this.reels[i as number] as Reel).slots.forEach((slot, id) => {
        slot.y = (id + 0.5) * SLOT_HEIGHT;
      });
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result as [][];
  }

  private getSeedReel(): SlotId[] {
    const maxRepeats = 2;
    let randomizedslots: SlotId[] = [];
    for (let i = 0; i < maxRepeats; i++) {
      randomizedslots.push(...this.slotsList);
    }

    randomizedslots = shuffleArray(randomizedslots);

    return randomizedslots;
  }

  private initReels(reels: SlotId[][], startPosition: number[], slotGroup: Group): void {
    //NOTE set the reels the first time(no animation)
    const seedSlots = this.getSeedReel();
    const container = new Container();
    this.addChildAt(container, 0);
    if (!setUserLastBetResult().id) {
      reels = this.splitArrayIntoChunks(seedSlots, 5);
      startPosition = [0, 0, 0, 0, 0];
    } else if (setCurrentBonus().isActive) {
      this.filters = [this.darkenFilter];
      this.restoredText.alpha = 1;
    }

    const cascades = getCascadeColumns({
      reelPositions: startPosition,
      layout: reels,
      cascades: [],
    });

    // cascades[0][0]?.id = 'A' as SlotId;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? (startPosition[i as number] as number) : 0;
      const reel = new Reel(i, reels[i as number] as SlotId[], position, cascades[i as number] as Icon[], slotGroup);
      this.reels[i as number] = reel;
      this.addChildAt(reel.container, 0);
      //reel.container.alpha = 0;
      eventManager.emit(EventTypes.ADD_SLOT_BLICKS, reel);
    }

    // Hack to add the text on top
    setTimeout(() => {
      this.parent.addChild(this.restoredText);
    }, 10);
  }

  private forceStopReels(): void {
    this.isForceStopped = true;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const appearingAnimations = (this.reels[i as number] as Reel).cascadeAnimation!.getAppearingAnimations();
      const delayAnim = (this.reels[i as number] as Reel).cascadeAnimation!.getAppearingDelays();
      delayAnim.duration = FORCE_STOP_CASCADE_PER_EACH_DURATION;
      appearingAnimations.forEach((animation, _index) => {
        animation.duration = FORCE_STOP_CASCADE_ANIMATION_DURATION;
      });
    }
  }

  private setupAnimationTarget(layout: Icon[][], isStopped: boolean): void {
    //NOTE Reels enter animations(drop to position, no leave animation!)

    const isTurboSpin = setCurrentIsTurboSpin() && Logic.the.controller.gameMode !== GameMode.BONUS_GAME;
    this.isSoundPlayed = false;
    for (let j = 0; j < this.reels.length; j++) {
      const reel = this.reels[j as number] as Reel;
      const appearingChain = new AnimationChain();
      if (!isTurboSpin && !isStopped) {
        appearingChain.appendAnimation(Tween.createDelayAnimation(DELAY_BETWEEN_REELS + j * 400));
      }
      const appearingAnimation = new AnimationGroup();
      appearingChain.appendAnimation(appearingAnimation);
      reel.cascadeAnimation?.appendAnimation(appearingChain);
      const waitingAnimation = reel.cascadeAnimation!.getWaiting();
      // Edge case when you switch tabs after you click Spin and result is not here.
      // and you come back to tab after waiting animation duration is finished.
      if (waitingAnimation.ended) {
        waitingAnimation.duration = 1;
        waitingAnimation.start();
      }
      waitingAnimation.addOnComplete(() => {
        const column = layout[j as number] as Icon[];
        reel.createSlots(
          column.map((icon) => icon.id),
          this.slotGroup,
        );
        for (let i = 0; i < reel.slots.length; i++) {
          const slot = reel.slots[reel.slots.length - i - 1] as Slot;
          const target = (SLOTS_PER_REEL_AMOUNT - i - 0.5) * SLOT_HEIGHT;
          const propertyBeginValue = (SLOTS_PER_REEL_AMOUNT - i - 6) * SLOT_HEIGHT;
          slot.y = propertyBeginValue;
          const baseDuration = isTurboSpin ? TURBO_APPEARING_DURATION : BASE_APPEARING_DURATION;
          const appearing = new Tween({
            object: slot,
            property: TweenProperties.Y,
            propertyBeginValue,
            target,
            duration: isStopped ? FORCE_STOP_CASCADE_ANIMATION_DURATION : baseDuration,
            easing: i < 4 ? cascadeEase : (n) => n,
          });
          appearing.addOnComplete(() => {
            if (slot.id < SLOTS_PER_REEL_AMOUNT) {
              slot.onSlotStopped();
              this.createLandAnimation({ slot: slot, col: j, isTurboSpin: isTurboSpin });
            }
          }, CallbackPriority.HIGH);
          appearingAnimation.addAnimation(appearing);
        }
        appearingAnimation.addOnStart(() => {
          reel.changeState(ReelState.APPEARING);
        });
        appearingAnimation.addOnComplete(() => {
          reel.changeState(ReelState.IDLE);
        });
      });

      waitingAnimation.end();
    }
  }

  private createReplaceRandomWildsAnimation(cascade: Cascade, nextCascadeId: number): void {
    eventManager.emit(EventTypes.START_GENERAL_RANDOM_WILDS);
    const positions = cascade.cascadeFall.reduce<number[]>((res, line, row) => {
      const linePositions = line.reduce<number[]>((res, slot, col) => {
        if (slot === SlotId.WL) return [...res, row * REELS_AMOUNT + col];
        return res;
      }, []);
      return [...res, ...linePositions];
    }, []) as number[];
    for (let i = 0; i < positions.length; i++) {
      const slot = (this.reels[(positions[i as number] as number) % REELS_AMOUNT] as Reel).slots.find(
        (slot) => slot.id === SLOTS_PER_REEL_AMOUNT - 1 - Math.floor((positions[i as number] as number) / REELS_AMOUNT),
      );
      eventManager.emit(EventTypes.START_SINGLE_RANDOM_WILD, slot, i === positions.length - 1, nextCascadeId);
    }
  }

  private createLandAnimation(options: { slot: Slot; col: number; isTurboSpin: boolean }): void {
    const landAnimation = new AnimationChain();
    if (options.col === 0 && options.slot.id === 0) {
      const startY = this.parent.parent.y;
      gsap.fromTo(
        this.parent.parent,
        { y: startY },
        {
          y: '+=6',
          duration: options.isTurboSpin ? 0.2 : 0.3,
          repeat: options.isTurboSpin ? 1 : 5,
          ease: 'back.out(2)',
          onComplete: () => {
            gsap.to(this.parent.parent, { y: startY, duration: 0.1 });
          },
        },
      );
    }

    const out = new Tween({
      object: options.slot,
      property: TweenProperties.Y,
      propertyBeginValue: options.slot.y,
      target: options.slot.y + 16,
      duration: 60,
      easing: easeOutSine,
    });

    landAnimation.appendAnimation(out);
    const back = new Tween({
      object: options.slot,
      property: TweenProperties.Y,
      propertyBeginValue: options.slot.y,
      target: options.slot.y - 20,
      duration: 180,
      easing: easeOutSine,
    });
    landAnimation.appendAnimation(back);

    const out1 = new Tween({
      object: options.slot,
      property: TweenProperties.Y,
      propertyBeginValue: options.slot.y,
      target: options.slot.y + 6,
      duration: 100,
      easing: easeOutSine,
    });
    landAnimation.appendAnimation(out1);

    const backToPos = new Tween({
      object: options.slot,
      property: TweenProperties.Y,
      propertyBeginValue: options.slot.y,
      target: options.slot.y,
      duration: 30,
      easing: easeOutSine,
    });
    landAnimation.appendAnimation(backToPos);

    landAnimation.addOnComplete(() => {
      if (options.slot.id === SLOTS_PER_REEL_AMOUNT - 1) {
        eventManager.emit(EventTypes.REEL_LANDED, options.col);
      }
    });

    landAnimation.start();
  }

  private setSlotsVisibility(slots: number[], _visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const slot = (this.reels[x as number] as Reel).slots.find((slot) => slot.id === 4 - y);
      (this.reels[x as number] as Reel).container.removeChild(slot as Sprite);
    });
  }
}

export default ReelsContainer;

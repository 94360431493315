import { gsap } from 'gsap';
import { Loader, Sprite, Texture, filters, utils } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { Logic } from '../../logic';
import { getFromMappedSymbol, randomInteger, shuffleArray } from '../../utils';
import SpriteAnimation from '../animations/sprite';
import { SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_SCALE, SLOT_WIDTH, eventManager } from '../config';

// function shuffleArray<Type>(array: Type[]): void {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     const temp = array[i] as Type;
//     array[i] = array[j] as Type;
//     array[j] = temp;
//   }
// }

function* infiniteGen<Type>(array: Type[], toShuffle?: boolean): Generator<Type> {
  if (toShuffle) shuffleArray(array);
  let index = 0;
  while (true) {
    const nextValue = array[index % array.length];
    index++;
    if (nextValue !== undefined) yield nextValue;
  }
}

const gen = infiniteGen([0, 3, 6], true);

class Slot extends Sprite {
  public id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  public isEmpty: boolean;
  private isMSBonus: boolean;
  public skipGlow: boolean;

  public slotSpinAnimation: SpriteAnimation | undefined;
  constructor(id: number, slotId: SlotId, isMSBonus?: boolean, deSyncAnimation = true, isGrid?: boolean) {
    // super(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId)));
    let currentSlotID = slotId;
    if (slotId === SlotId.MS1) {
      currentSlotID = SlotId.SC1;
      if (!isMSBonus) {
        currentSlotID = SlotId.T;
      }
    }
    super(utils.TextureCache[getFromMappedSymbol(MAPPED_SYMBOLS, currentSlotID)]);
    this.isEmpty = false;
    this.slotSpinAnimation = undefined;
    this.isMSBonus = isMSBonus || false;
    this.skipGlow = false;
    let idsToHide: number[] = [0, 4];
    switch (Logic.the.controller.gameMode) {
      case 4:
        idsToHide = [0];
        break;
      case 5:
        idsToHide = [];
        break;

      default:
        idsToHide = [0, 4];
        break;
    }

    if (idsToHide.includes(id) && isGrid) {
      this.renderable = false;
    }

    if (isGrid) {
      eventManager.on(EventTypes.END_SLOT_BONUS_STATE, this.setAfterBonusState.bind(this));
    }

    const idToAnimationName: { [key: string]: string } = {
      E: 'CUBE_e',
      F: 'cilinder_g',
      C: 'blk_sphere_c',
      SC1: 'star_f',
      D: 'pyramid_D',
      WL: 'wild',
      SC5: 'torus_g',
      //   K: 'coin_full',
      SC3: 'coin_up',
      SC4: 'coin_down',
      SC2: 'blk_nuclear',
      SC6: 'extraspin',
    };

    if (Object.keys(idToAnimationName).includes(currentSlotID)) {
      const slotSheet = Loader.shared.resources['symbols']!.spritesheet!;
      const animationName: string = idToAnimationName[currentSlotID] as string;
      const texture = [...(slotSheet.animations[animationName] as Texture[])];
      //texture.sort((t) => t.textureCacheIds[0] as string)

      if (deSyncAnimation) {
        //start from random frame
        const startFrame = gen.next().value as number;
        for (let index = 0; index < startFrame; index++) {
          texture.push(texture.shift() as Texture);
        }
      }

      const slotSpinAnimation = new SpriteAnimation({ isLoop: true }, texture);
      //   const direction = id % 2 === 0 ? -1 : 1;

      slotSpinAnimation.spriteAnimation.animationSpeed = 0.2;
      slotSpinAnimation.spriteAnimation.anchor.set(0.5, 0.5);
      this.slotSpinAnimation = slotSpinAnimation as SpriteAnimation;
      slotSpinAnimation.start();
      this.addChild(slotSpinAnimation.spriteAnimation);
    }

    this.id = id;
    this.slotId = currentSlotID;
    this.textureSlotId = currentSlotID;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.anchor.set(0.5, 0.5);
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = SLOT_WIDTH / 2;
    this.zIndex = 1;
  }

  public destroySlot() {
    const sprite = this.slotSpinAnimation;
    if (sprite && !sprite.spriteAnimation.destroyed) {
      sprite.skip();
      this.removeChild(sprite.spriteAnimation);
      sprite.spriteAnimation.destroy();
    }
  }

  public pauseAnimations(reset = false) {
    const sprite = this.slotSpinAnimation;
    if (sprite && !sprite.spriteAnimation.destroyed) {
      if (reset) {
        sprite.spriteAnimation.stop();
        sprite.spriteAnimation.gotoAndStop(0);
      } else {
        sprite.spriteAnimation.stop();
      }
    }
  }

  public resumeAnimations() {
    const sprite = this.slotSpinAnimation;
    if (sprite && !sprite.spriteAnimation.destroyed) {
      sprite.spriteAnimation.play();
    }
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public changeSlot(): void {
    const slotIdArr = [SlotId.empty01, SlotId.empty02, SlotId.empty03, SlotId.empty04, SlotId.empty05, SlotId.empty06];
    const randomEmptySlotId = slotIdArr[randomInteger(0, slotIdArr.length - 1)] as SlotId;
    this.changeTexture(randomEmptySlotId);
    this.slotSpinAnimation?.spriteAnimation.destroy();
    this.isEmpty = true;
    this.filters = null;
    this.tint = 0xffffff;
    if (this.slotSpinAnimation) {
      this.slotSpinAnimation.spriteAnimation.tint = 0xffffff;
    }
    this.renderable = true;
    // this.slotId = slotId;
  }

  private setAfterBonusState() {
    const isBonus = this.slotId === 'SC1';
    if (isBonus) {
      const filter = new filters.ColorMatrixFilter();
      filter.blackAndWhite(true);
      this.filters = [filter];
      const tint = 0x909090;

      this.tint = tint;
      if (this.slotSpinAnimation) {
        this.slotSpinAnimation.spriteAnimation.tint = tint;
      }
    } else {
      this.filters = null;
      this.tint = 0xffffff;
      if (this.slotSpinAnimation) {
        this.slotSpinAnimation.spriteAnimation.tint = 0xffffff;
      }
    }
  }

  public bonusSymbolAnimation() {
    eventManager.emit(EventTypes.BONUS_BLINKS_ON);
    this.tint = 0xffffff;
    this.filters = null;
    if (this.isEmpty) return;
    const filter = new filters.ColorMatrixFilter();
    const isBonus = this.slotId === 'SC1' || (this.isMSBonus && this.slotId === 'MS1');
    if (isBonus) {
      const bValue = { value: -0.2 };
      filter.matrix = [1, 0, 0, 0, bValue.value, 0, 1, 0, 0, bValue.value, 0, 0, 1, 0, bValue.value, 0, 0, 0, 1, 0];
      this.filters = [filter];
      // light to dark animation
      gsap.to(bValue, {
        value: 0.2,
        duration: 0.5,
        yoyo: true,
        repeat: -1,
        ease: 'none',
        onUpdate: () => {
          filter.matrix = [1, 0, 0, 0, bValue.value, 0, 1, 0, 0, bValue.value, 0, 0, 1, 0, bValue.value, 0, 0, 0, 1, 0];
        },
      });
    } else {
      filter.blackAndWhite(true);
      this.filters = [filter];
      const tint = 0x909090;

      this.tint = tint;
      if (this.slotSpinAnimation) {
        this.slotSpinAnimation.spriteAnimation.tint = tint;
      }
    }
  }

  public onSlotStopped(): void {
    // todo add sound/animation on slot stop
  }
}

export default Slot;

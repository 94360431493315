import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [20, 50, 75, 100],
    minBet: 20,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.wL,
      combos: [],
    },
    {
      slug: ResourceTypes.c,
      combos: [
        { pattern: 'x10', multiplier: 100 },
        { pattern: 'x9', multiplier: 90 },
        { pattern: 'x8', multiplier: 80 },
        { pattern: 'x6', multiplier: 60 },
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 40 },
        { pattern: 'x3', multiplier: 30 },
      ],
    },
    {
      slug: ResourceTypes.d,
      combos: [
        { pattern: 'x10', multiplier: 80 },
        { pattern: 'x9', multiplier: 72 },
        { pattern: 'x8', multiplier: 64 },
        { pattern: 'x6', multiplier: 48 },
        { pattern: 'x5', multiplier: 40 },
        { pattern: 'x4', multiplier: 32 },
        { pattern: 'x3', multiplier: 24 },
      ],
    },
    {
      slug: ResourceTypes.e,
      combos: [
        { pattern: 'x10', multiplier: 60 },
        { pattern: 'x9', multiplier: 54 },
        { pattern: 'x8', multiplier: 48 },
        { pattern: 'x6', multiplier: 36 },
        { pattern: 'x5', multiplier: 30 },
        { pattern: 'x4', multiplier: 24 },
        { pattern: 'x3', multiplier: 18 },
      ],
    },
    {
      slug: ResourceTypes.f,
      combos: [
        { pattern: 'x10', multiplier: 50 },
        { pattern: 'x9', multiplier: 45 },
        { pattern: 'x8', multiplier: 40 },
        { pattern: 'x6', multiplier: 30 },
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 15 },
      ],
    },
    {
      slug: ResourceTypes.a,
      combos: [
        { pattern: 'x10', multiplier: 20 },
        { pattern: 'x9', multiplier: 18 },
        { pattern: 'x8', multiplier: 16 },
        { pattern: 'x6', multiplier: 12 },
        { pattern: 'x5', multiplier: 10 },
        { pattern: 'x4', multiplier: 8 },
        { pattern: 'x3', multiplier: 6 },
      ],
    },
    {
      slug: ResourceTypes.k,
      combos: [
        { pattern: 'x10', multiplier: 20 },
        { pattern: 'x9', multiplier: 18 },
        { pattern: 'x8', multiplier: 16 },
        { pattern: 'x6', multiplier: 12 },
        { pattern: 'x5', multiplier: 10 },
        { pattern: 'x4', multiplier: 8 },
        { pattern: 'x3', multiplier: 6 },
      ],
    },
    {
      slug: ResourceTypes.q,
      combos: [
        { pattern: 'x10', multiplier: 10 },
        { pattern: 'x9', multiplier: 9 },
        { pattern: 'x8', multiplier: 8 },
        { pattern: 'x6', multiplier: 6 },
        { pattern: 'x5', multiplier: 5 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 3 },
      ],
    },
    {
      slug: ResourceTypes.j,
      combos: [
        { pattern: 'x10', multiplier: 10 },
        { pattern: 'x9', multiplier: 9 },
        { pattern: 'x8', multiplier: 8 },
        { pattern: 'x6', multiplier: 6 },
        { pattern: 'x5', multiplier: 5 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 3 },
      ],
    },
    {
      slug: ResourceTypes.t,
      combos: [
        { pattern: 'x10', multiplier: 10 },
        { pattern: 'x9', multiplier: 9 },
        { pattern: 'x8', multiplier: 8 },
        { pattern: 'x6', multiplier: 6 },
        { pattern: 'x5', multiplier: 5 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 3 },
      ],
    },
  ],
};

export enum SlotId {
  WL = 'WL',
  A = 'A',
  K = 'K',
  Q = 'Q',
  J = 'J',
  T = 'T',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  SC1 = 'SC1',
  MS1 = 'MS1',
  SC2 = 'SC2',
  SC3 = 'SC3',
  SC4 = 'SC4',
  SC5 = 'SC5',
  SC6 = 'SC6',
  coin = 'coin',
  empty01 = 'empty01',
  empty02 = 'empty02',
  empty03 = 'empty03',
  empty04 = 'empty04',
  empty05 = 'empty05',
  empty06 = 'empty06',
}

export const BONUS_GAME_WAIT_DELAY = 4; //seconds

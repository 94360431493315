import { Container, Graphics, Sprite, utils } from 'pixi.js';

import type { SlotId } from '../../config/config';
import { REELS_AMOUNT, REEL_WIDTH, SLOT_HEIGHT } from '../config';

export const patternsMap = [
  //plus
  {
    1: 'single_short_up',
    5: 'single_short_left',
    6: 'single_center_center',
    7: 'single_short_right',
    11: 'single_short_down',
  },
  {
    2: 'single_short_up',
    6: 'single_short_left',
    7: 'single_center_center',
    8: 'single_short_right',
    12: 'single_short_down',
  },
  {
    3: 'single_short_up',
    7: 'single_short_left',
    8: 'single_center_center',
    9: 'single_short_right',
    13: 'single_short_down',
  },
  {
    6: 'single_short_up',
    10: 'single_short_left',
    11: 'single_center_center',
    12: 'single_short_right',
    16: 'single_short_down',
  },
  {
    7: 'single_short_up',
    11: 'single_short_left',
    12: 'single_center_center',
    13: 'single_short_right',
    17: 'single_short_down',
  },
  {
    8: 'single_short_up',
    12: 'single_short_left',
    13: 'single_center_center',
    14: 'single_short_right',
    18: 'single_short_down',
  },
  {
    11: 'single_short_up',
    15: 'single_short_left',
    16: 'single_center_center',
    17: 'single_short_right',
    21: 'single_short_down',
  },
  {
    12: 'single_short_up',
    16: 'single_short_left',
    17: 'single_center_center',
    18: 'single_short_right',
    22: 'single_short_down',
  },
  {
    13: 'single_short_up',
    17: 'single_short_left',
    18: 'single_center_center',
    19: 'single_short_right',
    23: 'single_short_down',
  },
  //horizontal 3 slot line
  {
    0: 'single_short_left',
    1: 'single_center_center',
    2: 'single_short_right',
  },
  {
    1: 'single_short_left',
    2: 'single_center_center',
    3: 'single_short_right',
  },
  {
    2: 'single_short_left',
    3: 'single_center_center',
    4: 'single_short_right',
  },
  {
    5: 'single_short_left',
    6: 'single_center_center',
    7: 'single_short_right',
  },
  {
    6: 'single_short_left',
    7: 'single_center_center',
    8: 'single_short_right',
  },
  {
    7: 'single_short_left',
    8: 'single_center_center',
    9: 'single_short_right',
  },
  {
    10: 'single_short_left',
    11: 'single_center_center',
    12: 'single_short_right',
  },
  {
    11: 'single_short_left',
    12: 'single_center_center',
    13: 'single_short_right',
  },
  {
    12: 'single_short_left',
    13: 'single_center_center',
    14: 'single_short_right',
  },
  {
    15: 'single_short_left',
    16: 'single_center_center',
    17: 'single_short_right',
  },
  {
    16: 'single_short_left',
    17: 'single_center_center',
    18: 'single_short_right',
  },
  {
    17: 'single_short_left',
    18: 'single_center_center',
    19: 'single_short_right',
  },
  {
    20: 'single_short_left',
    21: 'single_center_center',
    22: 'single_short_right',
  },
  {
    21: 'single_short_left',
    22: 'single_center_center',
    23: 'single_short_right',
  },
  {
    22: 'single_short_left',
    23: 'single_center_center',
    24: 'single_short_right',
  },
  ////vertical 3 slot line
  {
    0: 'single_short_up',
    5: 'single_center_center',
    10: 'single_short_down',
  },
  {
    1: 'single_short_up',
    6: 'single_center_center',
    11: 'single_short_down',
  },
  {
    2: 'single_short_up',
    7: 'single_center_center',
    12: 'single_short_down',
  },
  {
    3: 'single_short_up',
    8: 'single_center_center',
    13: 'single_short_down',
  },
  {
    4: 'single_short_up',
    9: 'single_center_center',
    14: 'single_short_down',
  },
  {
    5: 'single_short_up',
    10: 'single_center_center',
    15: 'single_short_down',
  },
  {
    6: 'single_short_up',
    11: 'single_center_center',
    16: 'single_short_down',
  },
  {
    7: 'single_short_up',
    12: 'single_center_center',
    17: 'single_short_down',
  },
  {
    8: 'single_short_up',
    13: 'single_center_center',
    18: 'single_short_down',
  },
  {
    9: 'single_short_up',
    14: 'single_center_center',
    19: 'single_short_down',
  },
  {
    10: 'single_short_up',
    15: 'single_center_center',
    20: 'single_short_down',
  },
  {
    11: 'single_short_up',
    16: 'single_center_center',
    21: 'single_short_down',
  },
  {
    12: 'single_short_up',
    17: 'single_center_center',
    22: 'single_short_down',
  },
  {
    13: 'single_short_up',
    18: 'single_center_center',
    23: 'single_short_down',
  },
  {
    14: 'single_short_up',
    19: 'single_center_center',
    24: 'single_short_down',
  },
  // horizontal 4x
  {
    0: 'single_short_left',
    1: 'single_center_center',
    2: 'single_long_right',
    3: 'no_visual',
  },
  {
    1: 'single_short_left',
    2: 'single_center_center',
    3: 'single_long_right',
    4: 'no_visual',
  },
  {
    5: 'single_short_left',
    6: 'single_center_center',
    7: 'single_long_right',
    8: 'no_visual',
  },
  {
    6: 'single_short_left',
    7: 'single_center_center',
    8: 'single_long_right',
    9: 'no_visual',
  },
  {
    10: 'single_short_left',
    11: 'single_center_center',
    12: 'single_long_right',
    13: 'no_visual',
  },
  {
    11: 'single_short_left',
    12: 'single_center_center',
    13: 'single_long_right',
    14: 'no_visual',
  },
  {
    15: 'single_short_left',
    16: 'single_center_center',
    17: 'single_long_right',
    18: 'no_visual',
  },
  {
    16: 'single_short_left',
    17: 'single_center_center',
    18: 'single_long_right',
    19: 'no_visual',
  },
  {
    20: 'single_short_left',
    21: 'single_center_center',
    22: 'single_long_right',
    23: 'no_visual',
  },
  {
    21: 'single_short_left',
    22: 'single_center_center',
    23: 'single_long_right',
    24: 'no_visual',
  },
  // horizontal 5x
  {
    0: ' no_visual',
    1: 'single_long_left',
    2: 'single_center_center',
    3: 'single_long_right',
    4: 'no_visual',
  },
  {
    5: ' no_visual',
    6: 'single_long_left',
    7: 'single_center_center',
    8: 'single_long_right',
    9: 'no_visual',
  },
  {
    10: ' no_visual',
    11: 'single_long_left',
    12: 'single_center_center',
    13: 'single_long_right',
    14: 'no_visual',
  },
  {
    15: ' no_visual',
    16: 'single_long_left',
    17: 'single_center_center',
    18: 'single_long_right',
    19: 'no_visual',
  },
  {
    20: ' no_visual',
    21: 'single_long_left',
    22: 'single_center_center',
    23: 'single_long_right',
    24: 'no_visual',
  },
  // 5x vertical
  {
    0: ' no_visual',
    5: 'single_long_up',
    10: 'single_center_center',
    15: 'single_long_down',
    20: 'no_visual',
  },
  {
    1: ' no_visual',
    6: 'single_long_up',
    11: 'single_center_center',
    16: 'single_long_down',
    21: 'no_visual',
  },
  {
    2: ' no_visual',
    7: 'single_long_up',
    12: 'single_center_center',
    17: 'single_long_down',
    22: 'no_visual',
  },
  {
    3: ' no_visual',
    8: 'single_long_up',
    13: 'single_center_center',
    18: 'single_long_down',
    23: 'no_visual',
  },
  {
    4: ' no_visual',
    9: 'single_long_up',
    14: 'single_center_center',
    19: 'single_long_down',
    24: 'no_visual',
  },
  //vertical 4x
  {
    0: 'single_short_up',
    5: 'single_center_center',
    10: 'single_long_down',
    15: 'no_visual',
  },
  {
    5: 'single_short_up',
    10: 'single_center_center',
    15: 'single_long_down',
    20: 'no_visual',
  },
  {
    1: 'single_short_up',
    6: 'single_center_center',
    11: 'single_long_down',
    16: 'no_visual',
  },
  {
    6: 'single_short_up',
    11: 'single_center_center',
    16: 'single_long_down',
    21: 'no_visual',
  },
  {
    2: 'single_short_up',
    7: 'single_center_center',
    12: 'single_long_down',
    17: 'no_visual',
  },
  {
    7: 'single_short_up',
    12: 'single_center_center',
    17: 'single_long_down',
    22: 'no_visual',
  },
  {
    3: 'single_short_up',
    8: 'single_center_center',
    13: 'single_long_down',
    18: 'no_visual',
  },
  {
    8: 'single_short_up',
    13: 'single_center_center',
    18: 'single_long_down',
    23: 'no_visual',
  },
  {
    4: 'single_short_up',
    9: 'single_center_center',
    14: 'single_long_down',
    19: 'no_visual',
  },
  {
    9: 'single_short_up',
    14: 'single_center_center',
    19: 'single_long_down',
    24: 'no_visual',
  },

  //small T-shape v3
  {
    1: 'single_short_up',
    5: 'single_short_left',
    6: 'single_center_center',
    7: 'single_short_right',
  },
  {
    2: 'single_short_up',
    6: 'single_short_left',
    7: 'single_center_center',
    8: 'single_short_right',
  },
  {
    3: 'single_short_up',
    7: 'single_short_left',
    8: 'single_center_center',
    9: 'single_short_right',
  },
  {
    6: 'single_short_up',
    10: 'single_short_left',
    11: 'single_center_center',
    12: 'single_short_right',
  },
  {
    7: 'single_short_up',
    11: 'single_short_left',
    12: 'single_center_center',
    13: 'single_short_right',
  },
  {
    8: 'single_short_up',
    12: 'single_short_left',
    13: 'single_center_center',
    14: 'single_short_right',
  },
  {
    11: 'single_short_up',
    15: 'single_short_left',
    16: 'single_center_center',
    17: 'single_short_right',
  },
  {
    12: 'single_short_up',
    16: 'single_short_left',
    17: 'single_center_center',
    18: 'single_short_right',
  },
  {
    13: 'single_short_up',
    17: 'single_short_left',
    18: 'single_center_center',
    19: 'single_short_right',
  },
  {
    16: 'single_short_up',
    20: 'single_short_left',
    21: 'single_center_center',
    22: 'single_short_right',
  },
  {
    17: 'single_short_up',
    21: 'single_short_left',
    22: 'single_center_center',
    23: 'single_short_right',
  },
  {
    18: 'single_short_up',
    22: 'single_short_left',
    23: 'single_center_center',
    24: 'single_short_right',
  },

  //small T-shape v1
  {
    0: 'single_short_left',
    1: 'single_center_center',
    2: 'single_short_right',
    6: 'single_short_down',
  },
  {
    1: 'single_short_left',
    2: 'single_center_center',
    3: 'single_short_right',
    7: 'single_short_down',
  },
  {
    2: 'single_short_left',
    3: 'single_center_center',
    4: 'single_short_right',
    8: 'single_short_down',
  },
  {
    5: 'single_short_left',
    6: 'single_center_center',
    7: 'single_short_right',
    11: 'single_short_down',
  },
  {
    6: 'single_short_left',
    7: 'single_center_center',
    8: 'single_short_right',
    12: 'single_short_down',
  },
  {
    7: 'single_short_left',
    8: 'single_center_center',
    9: 'single_short_right',
    13: 'single_short_down',
  },
  {
    10: 'single_short_left',
    11: 'single_center_center',
    12: 'single_short_right',
    16: 'single_short_down',
  },
  {
    11: 'single_short_left',
    12: 'single_center_center',
    13: 'single_short_right',
    17: 'single_short_down',
  },
  {
    12: 'single_short_left',
    13: 'single_center_center',
    14: 'single_short_right',
    18: 'single_short_down',
  },

  {
    15: 'single_short_left',
    16: 'single_center_center',
    17: 'single_short_right',
    21: 'single_short_down',
  },
  {
    16: 'single_short_left',
    17: 'single_center_center',
    18: 'single_short_right',
    22: 'single_short_down',
  },
  {
    17: 'single_short_left',
    18: 'single_center_center',
    19: 'single_short_right',
    23: 'single_short_down',
  },

  // T-shape v2
  {
    0: 'single_short_up',
    5: 'single_center_center',
    10: 'single_short_down',
    6: 'single_short_right',
  },
  {
    1: 'single_short_up',
    6: 'single_center_center',
    11: 'single_short_down',
    7: 'single_short_right',
  },
  {
    2: 'single_short_up',
    7: 'single_center_center',
    12: 'single_short_down',
    8: 'single_short_right',
  },
  {
    3: 'single_short_up',
    8: 'single_center_center',
    13: 'single_short_down',
    9: 'single_short_right',
  },
  {
    5: 'single_short_up',
    10: 'single_center_center',
    15: 'single_short_down',
    11: 'single_short_right',
  },
  {
    6: 'single_short_up',
    11: 'single_center_center',
    16: 'single_short_down',
    12: 'single_short_right',
  },
  {
    7: 'single_short_up',
    12: 'single_center_center',
    17: 'single_short_down',
    13: 'single_short_right',
  },
  {
    8: 'single_short_up',
    13: 'single_center_center',
    18: 'single_short_down',
    14: 'single_short_right',
  },

  {
    10: 'single_short_up',
    15: 'single_center_center',
    20: 'single_short_down',
    16: 'single_short_right',
  },
  {
    11: 'single_short_up',
    16: 'single_center_center',
    21: 'single_short_down',
    17: 'single_short_right',
  },
  {
    12: 'single_short_up',
    17: 'single_center_center',
    22: 'single_short_down',
    18: 'single_short_right',
  },
  {
    13: 'single_short_up',
    18: 'single_center_center',
    23: 'single_short_down',
    19: 'single_short_right',
  },

  //T-shape v4
  {
    1: 'single_short_up',
    5: 'single_short_left',
    6: 'single_center_center',
    11: 'single_short_down',
  },
  {
    2: 'single_short_up',
    6: 'single_short_left',
    7: 'single_center_center',
    12: 'single_short_down',
  },
  {
    3: 'single_short_up',
    7: 'single_short_left',
    8: 'single_center_center',
    13: 'single_short_down',
  },
  {
    4: 'single_short_up',
    8: 'single_short_left',
    9: 'single_center_center',
    14: 'single_short_down',
  },
  {
    6: 'single_short_up',
    10: 'single_short_left',
    11: 'single_center_center',
    16: 'single_short_down',
  },
  {
    7: 'single_short_up',
    11: 'single_short_left',
    12: 'single_center_center',
    17: 'single_short_down',
  },
  {
    8: 'single_short_up',
    12: 'single_short_left',
    13: 'single_center_center',
    18: 'single_short_down',
  },
  {
    9: 'single_short_up',
    13: 'single_short_left',
    14: 'single_center_center',
    19: 'single_short_down',
  },

  {
    11: 'single_short_up',
    15: 'single_short_left',
    16: 'single_center_center',
    21: 'single_short_down',
  },
  {
    12: 'single_short_up',
    16: 'single_short_left',
    17: 'single_center_center',
    22: 'single_short_down',
  },
  {
    13: 'single_short_up',
    17: 'single_short_left',
    18: 'single_center_center',
    23: 'single_short_down',
  },
  {
    14: 'single_short_up',
    18: 'single_short_left',
    19: 'single_center_center',
    24: 'single_short_down',
  },

  //2x2 square
  {
    0: 'no_visual',
    1: 'double_super_short_up',
    5: 'double_super_short_down',
    6: 'double_width_center',
  },
  {
    1: 'no_visual',
    2: 'double_super_short_up',
    6: 'double_super_short_down',
    7: 'double_width_center',
  },
  {
    2: 'no_visual',
    3: 'double_super_short_up',
    7: 'double_super_short_down',
    8: 'double_width_center',
  },
  {
    3: 'no_visual',
    4: 'double_super_short_up',
    8: 'double_super_short_down',
    9: 'double_width_center',
  },
  {
    5: 'no_visual',
    6: 'double_super_short_up',
    10: 'double_super_short_down',
    11: 'double_width_center',
  },
  {
    6: 'no_visual',
    7: 'double_super_short_up',
    11: 'double_super_short_down',
    12: 'double_width_center',
  },
  {
    7: 'no_visual',
    8: 'double_super_short_up',
    12: 'double_super_short_down',
    13: 'double_width_center',
  },
  {
    8: 'no_visual',
    9: 'double_super_short_up',
    13: 'double_super_short_down',
    14: 'double_width_center',
  },
  {
    10: 'no_visual',
    11: 'double_super_short_up',
    15: 'double_super_short_down',
    16: 'double_width_center',
  },
  {
    11: 'no_visual',
    12: 'double_super_short_up',
    16: 'double_super_short_down',
    17: 'double_width_center',
  },
  {
    12: 'no_visual',
    13: 'double_super_short_up',
    17: 'double_super_short_down',
    18: 'double_width_center',
  },
  {
    13: 'no_visual',
    14: 'double_super_short_up',
    18: 'double_super_short_down',
    19: 'double_width_center',
  },

  {
    15: 'no_visual',
    16: 'double_super_short_up',
    20: 'double_super_short_down',
    21: 'double_width_center',
  },
  {
    16: 'no_visual',
    17: 'double_super_short_up',
    21: 'double_super_short_down',
    22: 'double_width_center',
  },
  {
    17: 'no_visual',
    18: 'double_super_short_up',
    22: 'double_super_short_down',
    23: 'double_width_center',
  },
  {
    18: 'no_visual',
    19: 'double_super_short_up',
    23: 'double_super_short_down',
    24: 'double_width_center',
  },
  //horizontal double x5
  {
    5: 'no_visual',
    6: 'double_long_left',
    7: 'no_visual',
    8: 'double_long_right',
    9: 'no_visual',
    0: 'no_visual',
    1: 'no_visual',
    2: 'double_hight_center',
    3: 'no_visual',
    4: 'no_visual',
  },
  {
    10: 'no_visual',
    11: 'double_long_left',
    12: 'no_visual',
    13: 'double_long_right',
    14: 'no_visual',
    5: 'no_visual',
    6: 'no_visual',
    7: 'double_hight_center',
    8: 'no_visual',
    9: 'no_visual',
  },
  {
    15: 'no_visual',
    16: 'double_long_left',
    17: 'no_visual',
    18: 'double_long_right',
    19: 'no_visual',
    10: 'no_visual',
    11: 'no_visual',
    12: 'double_hight_center',
    13: 'no_visual',
    14: 'no_visual',
  },
  {
    20: 'no_visual',
    21: 'double_long_left',
    22: 'no_visual',
    23: 'double_long_right',
    24: 'no_visual',
    15: 'no_visual',
    16: 'no_visual',
    17: 'double_hight_center',
    18: 'no_visual',
    19: 'no_visual',
  },
  // horizontal double 4x
  {
    5: 'double_short_left',
    6: 'no_visual',
    7: 'double_long_right',
    8: 'no_visual',
    0: 'no_visual',
    1: 'double_hight_center',
    2: 'no_visual',
    3: 'no_visual',
  },
  {
    6: 'double_short_left',
    7: 'no_visual',
    8: 'double_long_right',
    9: 'no_visual',
    1: 'no_visual',
    2: 'double_hight_center',
    3: 'no_visual',
    4: 'no_visual',
  },
  {
    10: 'double_short_left',
    11: 'no_visual',
    12: 'double_long_right',
    13: 'no_visual',
    5: 'no_visual',
    6: 'double_hight_center',
    7: 'no_visual',
    8: 'no_visual',
  },
  {
    11: 'double_short_left',
    12: 'no_visual',
    13: 'double_long_right',
    14: 'no_visual',
    6: 'no_visual',
    7: 'double_hight_center',
    8: 'no_visual',
    9: 'no_visual',
  },
  {
    15: 'double_short_left',
    16: 'no_visual',
    17: 'double_long_right',
    18: 'no_visual',
    10: 'no_visual',
    11: 'double_hight_center',
    12: 'no_visual',
    13: 'no_visual',
  },
  {
    16: 'double_short_left',
    17: 'no_visual',
    18: 'double_long_right',
    19: 'no_visual',
    11: 'no_visual',
    12: 'double_hight_center',
    13: 'no_visual',
    14: 'no_visual',
  },
  {
    20: 'double_short_left',
    21: 'no_visual',
    22: 'double_long_right',
    23: 'no_visual',
    15: 'no_visual',
    16: 'double_hight_center',
    17: 'no_visual',
    18: 'no_visual',
  },
  {
    21: 'double_short_left',
    22: 'no_visual',
    23: 'double_long_right',
    24: 'no_visual',
    25: 'no_visual',
    17: 'double_hight_center',
    18: 'no_visual',
    19: 'no_visual',
  },
  // horizontal double 3x
  {
    10: 'double_short_left',
    11: 'no_visual',
    12: 'double_short_right',
    5: 'no_visual',
    6: 'double_hight_center',
    7: 'no_visual',
  },
  {
    5: 'double_short_left',
    6: 'no_visual',
    7: 'double_short_right',
    0: 'no_visual',
    1: 'double_hight_center',
    2: 'no_visual',
  },
  {
    6: 'double_short_left',
    7: 'no_visual',
    8: 'double_short_right',
    1: 'no_visual',
    2: 'double_hight_center',
    3: 'no_visual',
  },
  {
    7: 'double_short_left',
    8: 'no_visual',
    9: 'double_short_right',
    2: 'no_visual',
    3: 'double_hight_center',
    4: 'no_visual',
  },

  {
    10: 'double_short_left',
    11: 'no_visual',
    12: 'double_short_right',
    5: 'no_visual',
    6: 'double_hight_center',
    7: 'no_visual',
  },

  {
    11: 'double_short_left',
    12: 'no_visual',
    13: 'double_short_right',
    6: 'no_visual',
    7: 'double_hight_center',
    8: 'no_visual',
  },

  {
    12: 'double_short_left',
    13: 'no_visual',
    14: 'double_short_right',
    7: 'no_visual',
    8: 'double_hight_center',
    9: 'no_visual',
  },

  {
    15: 'double_short_left',
    16: 'no_visual',
    17: 'double_short_right',
    10: 'no_visual',
    11: 'double_hight_center',
    12: 'no_visual',
  },

  {
    16: 'double_short_left',
    17: 'no_visual',
    18: 'double_short_right',
    11: 'no_visual',
    12: 'double_hight_center',
    13: 'no_visual',
  },

  {
    17: 'double_short_left',
    18: 'no_visual',
    19: 'double_short_right',
    12: 'no_visual',
    13: 'double_hight_center',
    14: 'no_visual',
  },
  // vertical double x3
  {
    10: 'no_visual',
    11: 'no_visual',
    5: 'double_short_up',
    6: 'double_width_1_center',
    0: 'double_short_down',
    1: 'no_visual',
  },
  {
    11: 'no_visual',
    12: 'no_visual',
    6: 'double_short_up',
    7: 'double_width_1_center',
    1: 'double_short_down',
    2: 'no_visual',
  },
  {
    12: 'no_visual',
    13: 'no_visual',
    7: 'double_short_up',
    8: 'double_width_1_center',
    2: 'double_short_down',
    3: 'no_visual',
  },
  {
    13: 'no_visual',
    14: 'no_visual',
    8: 'double_short_up',
    9: 'double_width_1_center',
    3: 'double_short_down',
    4: 'no_visual',
  },
  {
    15: 'no_visual',
    16: 'no_visual',
    10: 'double_short_up',
    11: 'double_width_1_center',
    5: 'double_short_down',
    6: 'no_visual',
  },
  {
    16: 'no_visual',
    17: 'no_visual',
    11: 'double_short_up',
    12: 'double_width_1_center',
    6: 'double_short_down',
    7: 'no_visual',
  },
  {
    17: 'no_visual',
    18: 'no_visual',
    12: 'double_short_up',
    13: 'double_width_1_center',
    7: 'double_short_down',
    8: 'no_visual',
  },
  {
    18: 'no_visual',
    19: 'no_visual',
    13: 'double_short_up',
    14: 'double_width_1_center',
    8: 'double_short_down',
    9: 'no_visual',
  },
  {
    20: 'no_visual',
    21: 'no_visual',
    15: 'double_short_up',
    16: 'double_width_1_center',
    10: 'double_short_down',
    11: 'no_visual',
  },
  {
    21: 'no_visual',
    22: 'no_visual',
    16: 'double_short_up',
    17: 'double_width_1_center',
    11: 'double_short_down',
    12: 'no_visual',
  },
  {
    21: 'no_visual',
    22: 'no_visual',
    16: 'double_short_up',
    17: 'double_width_1_center',
    11: 'double_short_down',
    12: 'no_visual',
  },
  {
    22: 'no_visual',
    23: 'no_visual',
    17: 'double_short_up',
    18: 'double_width_1_center',
    12: 'double_short_down',
    13: 'no_visual',
  },
  {
    23: 'no_visual',
    24: 'no_visual',
    18: 'double_short_up',
    19: 'double_width_1_center',
    13: 'double_short_down',
    14: 'no_visual',
  },

  //TODO and big square
];

export const comboPatterns = [
  {
    name: '3x3',
    ids: [0, 1, 2, 5, 6, 7, 10, 11, 12],
    subPatterns: [
      [0, 1, 2, 5, 6, 7],
      [10, 11, 12],
    ],
  },
  {
    name: '3x3',
    ids: [1, 2, 3, 6, 7, 8, 11, 12, 13],
    subPatterns: [
      [1, 2, 3, 6, 7, 8],
      [11, 12, 13],
    ],
  },
  {
    name: '3x3',
    ids: [2, 3, 4, 7, 8, 9, 12, 13, 14],
    subPatterns: [
      [2, 3, 4, 7, 8, 9],
      [12, 13, 14],
    ],
  },
  {
    name: '3x3',
    ids: [5, 6, 7, 10, 11, 12, 15, 16, 17],
    subPatterns: [
      [5, 6, 7, 10, 11, 12],
      [15, 16, 17],
    ],
  },
  {
    name: '3x3',
    ids: [6, 7, 8, 11, 12, 13, 16, 17, 18],
    subPatterns: [
      [6, 7, 8, 11, 12, 13],
      [16, 17, 18],
    ],
  },
  {
    name: '3x3',
    ids: [7, 8, 9, 12, 13, 14, 17, 18, 19],
    subPatterns: [
      [7, 8, 9, 12, 13, 14],
      [17, 18, 19],
    ],
  },
  {
    name: '3x3',
    ids: [10, 11, 12, 15, 16, 17, 20, 21, 22],
    subPatterns: [
      [10, 11, 12, 15, 16, 17],
      [20, 21, 22],
    ],
  },
  {
    name: '3x3',
    ids: [11, 12, 13, 16, 17, 18, 21, 22, 23],
    subPatterns: [
      [11, 12, 13, 16, 17, 18],
      [21, 22, 23],
    ],
  },
  {
    name: '3x3',
    ids: [12, 13, 14, 17, 18, 19, 22, 23, 24],
    subPatterns: [
      [12, 13, 14, 17, 18, 19],
      [22, 23, 24],
    ],
  },
];

export const getPattern = (currentPatternData: { [key: number]: string }, slotId: SlotId) => {
  let centerPosId = -1;
  for (const key in currentPatternData) {
    if (currentPatternData[key]?.includes('center')) {
      centerPosId = +key;
    }
  }

  if (centerPosId === -1) {
    throw new Error('Any pattern needs to have center position');
  }

  const currentPattern = new Container();
  for (const key in currentPatternData) {
    const data = currentPatternData[key] as string;
    if (data.includes('no')) {
      continue;
    }

    if (data) {
      const shoulder = getShoulder(data, slotId) as Container;
      currentPattern.addChild(shoulder);

      setShoulder(shoulder, data, +key, centerPosId);
    }
  }

  return currentPattern;
};

const setShoulder = (shoulder: Container, type: string, key: number, centerPosId: number) => {
  const centerPos: { x: number; y: number } = {
    x: REEL_WIDTH / 2 + REEL_WIDTH * (centerPosId % REELS_AMOUNT),
    y: SLOT_HEIGHT * Math.floor(centerPosId / REELS_AMOUNT) + SLOT_HEIGHT / 2,
  };
  let position: { x: number; y: number } = { x: -1, y: -1 };
  let angle = 0;
  switch (type) {
    case 'single_short_up':
      position = { x: centerPos.x, y: SLOT_HEIGHT * Math.floor(key / REELS_AMOUNT) + SLOT_HEIGHT };
      break;
    case 'single_short_down':
      angle = 180;
      position = { x: centerPos.x, y: SLOT_HEIGHT * Math.floor(+key / REELS_AMOUNT) };
      break;
    case 'single_short_right':
      angle = 90;
      position = { x: REEL_WIDTH * (key % REELS_AMOUNT), y: centerPos.y };
      break;
    case 'single_short_left':
      angle = -90;
      position = { x: REEL_WIDTH + REEL_WIDTH * (+key % REELS_AMOUNT), y: centerPos.y };
      break;
    case 'single_long_right':
      angle = 90;
      position = { x: REEL_WIDTH + REEL_WIDTH * (+key % REELS_AMOUNT), y: centerPos.y };
      break;
    case 'single_long_left':
      angle = -90;
      position = { x: REEL_WIDTH * (+key % REELS_AMOUNT), y: centerPos.y };
      break;
    case 'single_long_down':
      angle = 180;
      position = { x: centerPos.x, y: centerPos.y + SLOT_HEIGHT * 1.5 };
      break;

    case 'double_hight_center':
      position = { x: centerPos.x, y: centerPos.y + SLOT_HEIGHT / 2 };
      break;
    case 'double_width_center':
      position = { x: centerPos.x - REEL_WIDTH / 2, y: centerPos.y - SLOT_HEIGHT / 2 };
      break;
    case 'double_width_1_center':
      position = { x: centerPos.x - REEL_WIDTH / 2, y: centerPos.y };
      break;

    case 'double_long_right':
      angle = 90;
      position = { x: REEL_WIDTH + REEL_WIDTH * (+key % REELS_AMOUNT), y: centerPos.y + SLOT_HEIGHT / 2 };
      break;

    case 'double_long_left':
      angle = -90;
      position = { x: REEL_WIDTH * (+key % REELS_AMOUNT), y: centerPos.y + SLOT_HEIGHT / 2 };
      break;
    case 'double_short_left':
      angle = -90;
      position = { x: REEL_WIDTH + REEL_WIDTH * (+key % REELS_AMOUNT), y: centerPos.y + SLOT_HEIGHT / 2 };
      break;
    case 'double_short_right':
      angle = 90;
      position = { x: REEL_WIDTH * (+key % REELS_AMOUNT), y: centerPos.y + SLOT_HEIGHT / 2 };
      break;
    // here
    case 'double_short_up':
      position = { x: centerPos.x - REEL_WIDTH / 2, y: SLOT_HEIGHT * Math.floor(key / REELS_AMOUNT) };
      break;
    case 'double_short_down':
      angle = 180;
      position = { x: centerPos.x - REEL_WIDTH / 2, y: centerPos.y + SLOT_HEIGHT / 2 };
      break;

    case 'double_super_short_down':
      angle = 180;
      position = {
        x: centerPos.x - REEL_WIDTH / 2,
        y: SLOT_HEIGHT * Math.floor(key / REELS_AMOUNT),
      };
      break;
    case 'double_super_short_up':
      shoulder.scale.set(-1, 1);
      position = { x: centerPos.x - REEL_WIDTH / 2, y: SLOT_HEIGHT * Math.floor(key / REELS_AMOUNT) + SLOT_HEIGHT };
      break;

    default:
      position = { x: centerPos.x, y: centerPos.y };
      break;
  }

  shoulder.position.set(position.x, position.y);
  shoulder.angle = angle;
  return position;
};

const getShoulder = (imageData: string, slotId: SlotId) => {
  type Pattern = {
    single: string;
    double: string;
    center: string;
    centerW: string;
    centerH: string;
  };

  type ImagesMap = {
    [key: string]: Pattern;
  };

  const imagesMap: ImagesMap = {
    A: {
      single: 'pattern_L1_LowPrize_0.png',
      double: 'pattern_L2_LowPrize_1.png',
      center: 'pattern_L1_A_center.png',
      centerW: 'patterns_L2_A_horisontal.png',
      centerH: 'patterns_L2_A_vertical.png',
    },
    T: {
      single: 'pattern_L1_LowPrize_0.png',
      double: 'pattern_L2_LowPrize_1.png',
      center: 'pattern_L1_10_center.png',
      centerW: 'patterns_L2_10_horisontal.png',
      centerH: 'patterns_L2_10_vertical.png',
    },
    F: {
      single: 'pattern_L1_cilinder_0.png',
      double: 'pattern_L2_cilinder_1.png',
      center: 'pattern_L1_cilinder_center.png',
      centerW: 'pattern_L2_cilinder_center_horizontal.png',
      centerH: 'pattern_L2_cilinder_center_vertical.png',
    },
    E: {
      single: 'pattern_L1_cube_0.png',
      double: 'pattern_L2_cube_1.png',
      center: 'pattern_L1_cube_center.png',
      centerW: 'pattern_L2_cube_center_horizontal.png',
      centerH: 'pattern_L2_cube_center_vertical.png',
    },
    C: {
      single: 'pattern_L1_sphere_0.png',
      double: 'pattern_L2_sphere_1.png',
      center: 'pattern_L1_sphere_center.png',
      centerW: 'pattern_L2_sphere_center_horizontal.png',
      centerH: 'pattern_L2_sphere_center_vertical.png',
    },
    SC1: {
      single: '',
      double: '',
      center: '',
      centerW: '',
      centerH: '',
    },
    D: {
      single: 'pattern_L1_pyramide_0.png',
      double: 'pattern_L2_pyramide_1.png',
      center: 'pattern_L1_pyramide_center.png',
      centerW: 'pattern_L2_pyramide_center_horizontal.png',
      centerH: 'pattern_L2_pyramide_center_vertical.png',
    },
    K: {
      single: 'pattern_L1_LowPrize_0.png',
      double: 'pattern_L2_LowPrize_1.png',
      center: 'pattern_L1_K_center.png',
      centerW: 'patterns_L2_K_horisontal.png',
      centerH: 'patterns_L2_K_vertical.png',
    },
    SC5: {
      single: '',
      double: '',
      center: '',
      centerW: '',
      centerH: '',
    },
    J: {
      single: 'pattern_L1_LowPrize_0.png',
      double: 'pattern_L2_LowPrize_1.png',
      center: 'pattern_L1_J_center.png',
      centerW: 'patterns_L2_J_horisontal.png',
      centerH: 'patterns_L2_J_vertical.png',
    },
    WL: {
      single: '',
      double: '',
      center: '',
      centerW: '',
      centerH: '',
    },
    SC4: {
      single: '',
      double: '',
      center: '',
      centerW: '',
      centerH: '',
    },
    SC2: {
      single: '',
      double: '',
      center: '',
      centerW: '',
      centerH: '',
    },
    SC3: {
      single: '',
      double: '',
      center: '',
      centerW: '',
      centerH: '',
    },
    SC6: {
      single: '',
      double: '',
      center: '',
      centerW: '',
      centerH: '',
    },
    Q: {
      single: 'pattern_L1_LowPrize_0.png',
      double: 'pattern_L2_LowPrize_1.png',
      center: 'pattern_L1_Q_center.png',
      centerW: 'patterns_Q_horisontal.png',
      centerH: 'patterns_Q_vertical.png',
    },
  };

  const shoulder = new Container();
  const slotImages = imagesMap[slotId] as Pattern;
  let imageName = imageData.includes('double') ? slotImages.double : slotImages.single;
  if (imageData.includes('center')) {
    imageName = slotImages.center;
    if (imageData.includes('width')) {
      imageName = slotImages.centerW;
    }
    if (imageData.includes('hight')) {
      imageName = slotImages.centerH;
    }
  }

  const shoulderImage = new Sprite(utils.TextureCache[imageName]);
  if (imageData.includes('center')) {
    shoulderImage.name = 'center';
  }
  // shoulderImage.anchor.set(0.5, 0.5);

  shoulder.addChild(shoulderImage);

  if (imageData.includes('short')) {
    const maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, shoulderImage.width, shoulderImage.height / 2)
      .endFill();
    //   maskArea.pivot.set(shoulderImage.width / 2, shoulderImage.height / 2);
    shoulder.mask = maskArea;
    shoulder.addChild(shoulderImage, maskArea);
  }

  shoulder.pivot.set(shoulder.width / 2, shoulder.height / 2);
  return shoulder;
};

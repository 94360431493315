import { Sprite, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

import WildSlotContainer from './wildSlotContainer';

class WildCollectorContainer extends ViewContainer {
  public slots: { [key: string]: WildSlotContainer } = {};

  constructor() {
    super();
    this.y = 35;
    this.name = 'WildCollectorContainer';
    this.addGraphics();
    this.addEvents();
  }

  protected override resize(_width: number, _height: number): void {
    super.resize(_width, _height);
    // default landscape
    this.x = 125;
    this.y = 15;

    const isPortrait = _height > _width;

    if (isPortrait) {
      this.x = -125;
      this.y = 35;
    }
  }

  private addEvents() {
    //eventManager.on(EventTypes.TOGGLE_SPIN, () => this.reset());
    //eventManager.on(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => this.reset());
    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => this.reset());

    eventManager.on(EventTypes.WILD_COLLECTOR, (type) => {
      if (type) {
        this.incrementSlot(type);
        AudioApi.play({ type: ISongs.SFX_WIN_FeatureTrigger, stopPrev: true });
      }
    });
  }

  private addGraphics() {
    const frameTexture = utils.TextureCache['wild_collector_empty_bg.png'];
    const frame = new Sprite(frameTexture);
    frame.anchor.set(0.5);

    this.slots[MAPPED_SYMBOLS.C.default] = new WildSlotContainer(SlotId.C, 1);
    this.slots[MAPPED_SYMBOLS.D.default] = new WildSlotContainer(SlotId.D, 2);
    this.slots[MAPPED_SYMBOLS.E.default] = new WildSlotContainer(SlotId.E, 3);
    this.slots[MAPPED_SYMBOLS.F.default] = new WildSlotContainer(SlotId.F, 4);

    for (const key in this.slots) {
      const slotContainer = this.slots[key];
      if (slotContainer) {
        this.addChild(slotContainer);
      }
    }

    this.addChildAt(frame, 0);
  }

  private incrementSlot(type: string) {
    const slot = this.slots[type.toLowerCase()];
    if (slot) {
      slot.incrementCounter();
    }
  }

  private reset() {
    for (const key in this.slots) {
      const slotContainer = this.slots[key];
      if (slotContainer) {
        slotContainer.resetCounter();
      }
    }
  }
}

export default WildCollectorContainer;

import gsap from 'gsap';
import i18n from 'i18next';
import { BitmapText, Container, Loader, Sprite, Text, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setCurrentBonus } from '../../gql/cache';
import { Logic } from '../../logic';
import SpriteAnimation from '../animations/sprite';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';
import { TILE_FLIP_LOOP_COUNT } from '../gameView/config';

import { spinsLeftTitleStyle } from './textStyles';

export default class BonusTurnsCounter extends ViewContainer {
  protected turnsCountText: BitmapText;

  protected tileFlipTensAnimation: SpriteAnimation;

  protected tileFlipOnesAnimation: SpriteAnimation;

  private containerGlow: Sprite;

  protected turnsLeft: number = 0;

  protected spinsLeftText: Text;

  protected spinsLeftTextContainer: Container;

  protected titlePlate: Sprite;

  protected link: Sprite;

  constructor() {
    super();
    this.position.set(-500, 630);

    const linkTexture = utils.TextureCache['bonus_spins_lefts_joint.png'];
    this.link = new Sprite(linkTexture);
    this.link.anchor.set(0.5);

    const containerSheet = Loader.shared.resources['bonus-game']!.spritesheet!;
    const containerTexture = containerSheet.animations['bonus_spins_lefts_bg_frame'] as Texture[];
    const containerAnimation = new SpriteAnimation({ isLoop: true }, containerTexture);
    containerAnimation.spriteAnimation.anchor.set(0.5, 0.5);
    containerAnimation.start();
    containerAnimation.spriteAnimation.animationSpeed = 0.2;

    const texture = utils.TextureCache['spins_lefts_glow.png'];
    this.containerGlow = new Sprite(texture);
    this.containerGlow.anchor.set(0.5);
    this.containerGlow.alpha = 0;

    const titleTexture = utils.TextureCache['bonus_spins_lefts_text_plate.png'];
    this.titlePlate = new Sprite(titleTexture);
    this.titlePlate.anchor.set(0.5);
    this.titlePlate.y = 30;

    const flipScale = 0.7;
    const tileFlipTensSheet = Loader.shared.resources['game']!.spritesheet!;
    const tileFlipTensTexture = tileFlipTensSheet.animations['prize_num_flip'] as Texture[];
    this.tileFlipTensAnimation = new SpriteAnimation({ isLoop: true }, tileFlipTensTexture);
    this.tileFlipTensAnimation.spriteAnimation.anchor.set(0.5, 0.5);
    this.tileFlipTensAnimation.spriteAnimation.position.set(-35, 2);
    this.tileFlipTensAnimation.spriteAnimation.animationSpeed = 0.2;
    this.tileFlipTensAnimation.spriteAnimation.scale.set(flipScale);
    this.tileFlipTensAnimation.spriteAnimation.visible = false;

    this.tileFlipOnesAnimation = new SpriteAnimation({ isLoop: true }, tileFlipTensTexture);
    this.tileFlipOnesAnimation.spriteAnimation.anchor.set(0.5, 0.5);
    this.tileFlipOnesAnimation.spriteAnimation.position.set(25, 2);
    this.tileFlipOnesAnimation.spriteAnimation.scale.set(flipScale);
    this.tileFlipOnesAnimation.spriteAnimation.animationSpeed = 0.2;
    this.tileFlipOnesAnimation.spriteAnimation.visible = false;

    this.turnsCountText = new BitmapText('00', {
      fontName: 'prizeCounterSpriteSheet-export',
      fontSize: 125,
      letterSpacing: -10,
    });

    Logic.the.controller.gameMode === GameMode.BONUS_GAME && this.setTurns(this.turnsLeft);

    this.turnsCountText.position.set(2, -20);
    this.turnsCountText.anchor.set(0.5);

    this.spinsLeftText = new Text(i18n.t<string>('spinsLeft'), spinsLeftTitleStyle);
    this.spinsLeftText.anchor.set(0.5);
    this.spinsLeftText.x = 5;
    this.spinsLeftText.y = -130;

    this.spinsLeftTextContainer = new Container();
    this.spinsLeftTextContainer.addChild(this.titlePlate, this.spinsLeftText);
    this.spinsLeftTextContainer.scale.set(0.8);
    this.spinsLeftTextContainer.y = -20;

    this.addChild(
      this.link,
      containerAnimation.spriteAnimation,
      this.spinsLeftTextContainer,
      this.turnsCountText,
      this.tileFlipTensAnimation.spriteAnimation,
      this.tileFlipOnesAnimation.spriteAnimation,
      this.containerGlow,
    );

    this.addEvents();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    switch (_settings.mode) {
      case GameMode.BONUS_GAME:
        this.setTurns(setCurrentBonus().roundsLeft);
        break;
      default:
        this.setTurns(0);
        break;
    }
  }

  private addEvents() {
    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => this.bonusTurn());

    const loops = TILE_FLIP_LOOP_COUNT;
    let currentLoop = 0;
    Logic.the.controller.gameMode === GameMode.BONUS_GAME &&
      AudioApi.play({ type: ISongs.SFX_Bonus_Spin_End, stopPrev: true });
    this.tileFlipOnesAnimation.spriteAnimation.onLoop = () => {
      if (currentLoop >= loops) {
        this.tileFlipTensAnimation.spriteAnimation.visible = false;
        this.tileFlipTensAnimation.spriteAnimation.stop();
        this.tileFlipOnesAnimation.spriteAnimation.visible = false;
        this.tileFlipOnesAnimation.spriteAnimation.stop();
        currentLoop = 0;
      }

      currentLoop++;
    };

    this.addCounterSpecificEvents();
  }

  protected addCounterSpecificEvents() {
    eventManager.on(EventTypes.BONUS_GAME_EXTRA_SPIN, () => {
      AudioApi.play({ type: ISongs.SFX_Extra_spin_match_and_collect });
      setCurrentBonus({
        ...setCurrentBonus(),
        rounds: setCurrentBonus().rounds + 1,
      });
      const turnsLeft = setCurrentBonus().roundsLeft;
      this.setTurns(turnsLeft);

      const blinkDuration = 0.2;
      const timeline = gsap.timeline({ repeat: 3 });
      timeline
        .to(this.containerGlow, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' })
        .to(this.containerGlow, { alpha: 0, duration: blinkDuration, ease: 'bounceOut' });
    });
  }

  protected bonusTurn() {
    if (Logic.the.controller.gameMode !== GameMode.BONUS_GAME) return;

    this.setTurns(this.turnsLeft - 1);
  }

  public setTurns(turns: number) {
    this.turnsLeft = turns;
    const turnsText = String(turns).padStart(2, '0');

    if (this.turnsCountText.text === turnsText) return;

    // only update 1st  digit on change
    if (turnsText[0] !== this.turnsCountText.text[0]) {
      this.tileFlipTensAnimation.spriteAnimation.visible = true;
      this.tileFlipTensAnimation.spriteAnimation.play();
    }

    this.tileFlipOnesAnimation.spriteAnimation.visible = true;
    this.tileFlipOnesAnimation.spriteAnimation.play();

    this.turnsCountText.text = turnsText;
  }
}

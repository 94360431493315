import * as PIXI from 'pixi.js';

import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

declare global {
  interface Window {
    __ENV__: {
      REACT_APP_TRANSLATE_URL: string;
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
    };
    PIXI: PIXI;
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface Cascade {
  extraWildPositions?: number[];
  isRandomWilds: boolean;
  winPositions: number[][];
  isBonus: boolean;
  instantWin: number;
  isMultiplier: boolean;
  isNuke: boolean;
  winAmounts: number[];
  cascadeFall: (SlotId | '')[][];
}

export interface PayLine {
  lineId: number;
  winPositions: number[];
  amount: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      bonuses: UserBonus[];
      features: {
        cascade: Cascade[];
        multiplier: number;
        winMultiplier: number;
        scatterWins: {
          rewards: {
            multiplier?: number;
          }[];
        }[];
        // NOTE hardcode property
        cascadeColumns?: [];
        gameRoundStore: {
          reelState: {
            bonusState?: {
              spinsLeft: number;
              spinsLeftNext: number;
              activeRowsPerReel: number[];
              activeRowsPerReelNext: number[];
            };
            spinsLeftAtNumActiveRows: number;
          };
        };
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: PayLine[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
}
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  betId: string;
  coinAmount: number;
  rounds: number;
  roundsPlayed: number;
  roundsLeft: number;
  data: {
    cascadeMultiplier: number;
  };
  cascadeMultiplier: number;
  reelSetId?: string;
  totalWinAmount: number;
};
export enum GameMode {
  BASE_GAME,
  FREE_SPINS,
  RAGE_MODE,
  BUY_FEATURE,
  ONE_BONUS_LINE,
  ALL_BONUS_LINES,
  BONUS_GAME,
}

export type BuyFeatureEnterProps = {
  bonusId: string;
};
export type FreeSpinsEnterProps = {
  bonus: UserBonus;
};
export type EnterProps = (BuyFeatureEnterProps | FreeSpinsEnterProps) & {
  immediate?: boolean;
};

export enum FeatureState {
  BASE,
  FREE_SPINS,
  RAGE_MODE,
  ONE_BONUS_LINE,
  ALL_BONUS_LINES,
  BONUS_GAME,
}
export const baseReelSetIds = ['0459a1ee-e666-4a08-acb5-72ff6b1eee1d', 'ea3b5295-221b-4ea9-88b5-12fb73756a59'];
export const bonusesId = {
  //[GameMode.FREE_SPINS]: 'e1094c83-d597-4f40-abe8-05c53e263807',
  [GameMode.ONE_BONUS_LINE]: 'a1332307-fd9a-4afe-a771-6479d3ac82ce',
  [GameMode.ALL_BONUS_LINES]: '1fa82659-18ce-48c4-ba09-83ee29721a05',
  [GameMode.BONUS_GAME]: 'da7ce89f-c621-4691-a387-8d782a2f96ca',
};
export const buyFeatureBonusesId = {
  // [FeatureState.BASE]: '06816cc5-3ed5-4c8a-a32e-78a715e036cf',
  // [FeatureState.RAGE]: '656bd5d3-94d9-4b94-9aad-c25310aeaa14',
  // TODO: add to backend
  // [FeatureState.ONE_BONUS_LINE]: '06816cc5-3ed5-4c8a-a32e-78a715e036cf', //'0dfad39a-16b3-417a-b09f-a717db4be701',
  // [FeatureState.ALL_BONUS_LINES]: '656bd5d3-94d9-4b94-9aad-c25310aeaa14', //'d4567e28-d26c-4730-a1a7-c71a3711731c',
  [FeatureState.BONUS_GAME]: 'c0518116-410b-4095-8978-292ed92d8358', // FREE SPINS on server
};
export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  isActive: boolean;
  bonusId: string;
  roundsPlayed: number;
  currentRound: number;
  rounds: number;
  reelSetId: string;
  totalWinAmount: number;
  cascadeMultiplier: number;
  betId: string;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  mobileTitlePosition?: number;
  titleStyles?: TextStyle;
  mobileTitleStyle?: TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  mobileSubtitlePosition?: number;
  subtitleStyles?: TextStyle;
  mobileSubtitleStyle?: TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: TextStyle;
  mobileAdditionalStyle?: TextStyle;
  preventDefaultDestroy?: boolean;
  mobileSubtitleBorderWidth?: number;
  mobileSubtitleBorderHeight?: number;
  subtitleBorderWidth?: number;
  subtitleBorderHeight?: number;
  callback?: () => void;
  onInitCallback?: () => void;
};

export enum EventTypes {
  END_SLOT_BONUS_STATE = 'endSlotBonusState',
  BONUS_BLINKS_ON = 'bonusBlinkson',
  END_TOGGLE_GATE = 'endToggleGate',
  START_TOGGLE_GATE = 'StartToggleGate',
  BROKEN_GAME = 'brokenGame',
  END_BIG_WIN_PRESENTATION = 'endBigWinPresentation',
  CHANGE_MODE = 'changeMode',
  CLOSE_ALL_STARS = 'closeAllStars',
  COUNT_UP_END = 'countUpEnd',
  CLOSE_ALL_MULTIPLIER_STARS = 'closeAllMultiplierStart',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAYTABLE = 'disablePaytable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_CASCADE_FEATURE = 'endCascadeFeature',
  END_FREE_SPINS = 'endFreeSpins',
  ENABLE_PAYTABLE = 'enablePaytable',
  IMMEDIATE_CLOSE_STARS = 'immediateCloseStars',
  IMMEDIATE_CLOSE_ALL_MULTIPLIER_STARS = 'immediateCloseAllMultiplierStars',
  FORCE_STOP_REELS = 'forceStopReels',
  FORCE_RESIZE = 'forceResize',
  GAME_READY = 'gameReady',
  HIDE_COINS = 'hideCoins',
  HIDE_COUNT_UP = 'hideCountUp',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  NEXT_CASCADE = 'nextCascade',
  MULTIPLIER_STAR_WIN = 'multiplierStarWin',
  OPEN_STAR = 'openStar',
  OPEN_MULTIPLIER_STAR = 'openMultiplierStar',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  REELS_STOPPED = 'reelsStopped',
  REEL_LANDED = 'reelLanded',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_COUNT_UP = 'startCountUp',
  START_MULTIPLIER_ANIMATION = 'startMultiplierAnimation',
  START_BONUS_MULTIPLIER_ANIMATION = 'startBonusMultiplierAnimation',
  START_BIG_WIN_PRESENTATION = 'startBigWinPresentation',
  START_GENERAL_RANDOM_WILDS = 'startGeneralRandomWilds',
  START_SINGLE_RANDOM_WILD = 'startSingleRandomWilds',
  START_CASCADE_FEATURE = 'startCascadeFeature',
  START_FREE_SPINS = 'startFreeSpins',
  START_RAGE_MODE = 'startRageMode',
  START_RANDOM_WILDS_ANIMATION = 'startRandomWildsAnimation',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_CURRENT_RESULT_MINI_PAYTABLE = 'setCurrentResultMiniPayTable',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_POPUP = 'openPopup',
  OPEN_POPUP_BG = 'openPopupBg',
  CLOSE_POPUP = 'closePopup',
  CLOSE_POPUP_BG = 'closePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  WIN_STAR = 'winStar',
  PHOENIX_ANTICIPATION_START = 'phoenixStart',
  PHOENIX_ANTICIPATION_END = 'phoenixEnd',
  PHOENIX_ANTICIPATION_SKIP = 'phoenixSkip',
  END_WAITING_ANIMATION = 'removeErrorHandler',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  //new event
  START_BONUS_SPINS = 'startBonusSpins',
  COLLECT_PATTERN = 'collectPattern',
  COLLECT_BONUS = 'collectBonus',
  COLLECT_MULTIPLIER = 'collectMultiplier',
  MULTIPLIER_TURN = 'multiplierTurn',
  UPDATE_MULTIPLIER = 'updateMultiplier',
  BONUS_GAME_MULTIPLIER = 'bonusGameMultiplier',
  END_BONUS_SPINS = 'endBonusSpins',
  WILD_ACTIVATE = 'wildActive',
  WILD_COLLECTOR = 'wildCollector',
  //START_BONUS_GAME = 'startBonusGame',
  COLLECT_INSTANT_WIN = 'collectInstantWin',
  BONUS_GAME_COLLECT = 'bonusGameCollect',
  SETUP_BONUS_REEL_POSITIONS = 'setupBonusReelPositions',
  BONUS_GAME_EXTRA_SPIN = 'bonusGameExtraSpin',
  ADD_SLOT_BLICKS = 'addSlotBlicks',
  CLEAR_SLOT_BLICKS = 'clearSlotBlicks',
  UPDATE_SLOT_BLICKS = 'updateSlotBlicks',
  PAUSE_SLOT_BLICK = 'pauseSlotBlicks',
  SHOW_FULL_SCREEN_DIALOG = 'showFullScreenDialog',
  HIDE_FULL_SCREEN_DIALOG = 'hideFullScreenDialog',
  OPEN_LOWER_GATE = 'openLowerGate',
  CLOSE_LOWER_GATE = 'closeLowerGate',
  STEAMPUNK_ANTICIPATION_START = 'steamPunkAnticipationStart',
  STEAMPUNK_ANTICIPATION_END = 'steamPunkAnticipationEnd',
  SHAKE_SLOT = 'shakeSlot',
  BASE_GAME_IDLE = 'baseGameIdle',
  LOW_PERFORMANCE = 'lowPerformance',
  HIGH_PERFORMANCE = 'highPerformance',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum MysterySymbol {
  BONUS = 'SC1',
  TEN = 'T',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}
export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
}

// TODO: enum all symbols
export type ReelsPattern = {
  type: string;
  count: number;
  wild: string[];
};

export type BonusWinPosition = { position: number[]; bonus?: string };

export type BonusTurnData = {
  winPositions: BonusWinPosition[];
  reels: SlotId[][];
};

export type BonusTurnInputData = {
  activeRowsPerReel: number[];
  reelPositions: number[];
  layout: SlotId[][];
  bonuses: UserBonus[];
  payLines: PayLine[];
  isMultiplier: boolean;
};

export enum CollectorTargetTypes {
  bonusComboCollector,
  bonusTurnsCounter,
  bonusMultiplier,
}

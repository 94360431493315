import gsap from 'gsap';
import i18n from 'i18next';
import type { Spine } from 'pixi-spine';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { GameMode } from '../../global.d';
import { setCurrentBonus } from '../../gql/cache';
import { Logic } from '../../logic';
import BonusTurnsCounter from '../bonusGameContainer/bonusTurnsCounter';
import RandomEffectsContainer from '../effects/randomEffects';

export default class BonusLinesTurnsCounter extends BonusTurnsCounter {
  protected override turnsLeft = 0;
  private steamEffect: Spine;

  constructor() {
    super();

    this.position.set(-580, -225);
    this.scale.set(0.7);

    this.spinsLeftText.text = i18n.t<string>('spinsLeft');
    this.spinsLeftText.y = 185;

    this.titlePlate.angle = 180;

    this.link.angle = 90;

    this.spinsLeftTextContainer.y = 15;
    this.spinsLeftTextContainer.scale.set(0.6);

    this.steamEffect = RandomEffectsContainer.getSpineAnimation('steampunk_steam', 'fast_stream');
    this.steamEffect.x = -150;
    this.steamEffect.angle = -30;

    this.addChild(this.steamEffect);
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    switch (_settings.mode) {
      case GameMode.ALL_BONUS_LINES:
      case GameMode.ONE_BONUS_LINE:
        if (this.turnsLeft === 0) {
          this.show();
        }
        this.turnsLeft = setCurrentBonus().roundsLeft;
        this.setTurns(this.turnsLeft);
        break;
      default:
        this.turnsLeft = 0;
        this.setTurns(this.turnsLeft);
        this.hide();
        break;
    }
  }

  protected override bonusTurn() {
    if (
      Logic.the.controller.gameMode !== GameMode.ALL_BONUS_LINES &&
      Logic.the.controller.gameMode !== GameMode.ONE_BONUS_LINE
    )
      return;
    AudioApi.play({ type: ISongs.SFX_Bonus_Spin_End });
    this.setTurns(this.turnsLeft - 1);
  }

  protected override addCounterSpecificEvents() {}

  private show() {
    gsap.fromTo(this, { y: -225 }, { y: -25, ease: 'back.out(1.7)' });
    gsap.fromTo(this.scale, { x: 0.7, y: 0.7 }, { x: 1, y: 1 });
    this.steamEffect.state.setAnimation(0, 'fast_stream', false);
    this.steamEffect.state.timeScale = 0.5;
    AudioApi.play({ type: ISongs.SFX_Bonus_Spins_Counter });
  }

  private hide() {
    gsap.fromTo(this, { y: -25 }, { y: -225, ease: 'back.in(1.7)' });
    gsap.fromTo(this.scale, { x: 1, y: 1 }, { x: 0.7, y: 0.7 });
  }
}

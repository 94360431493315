import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import {
  BuyFeatureEnterProps,
  EventTypes,
  FeatureState,
  GameMode,
  UserBonus,
  buyFeatureBonusesId,
} from '../../global.d';
import {
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setIsFreeSpinsWin,
  setIsPopupOpened,
} from '../../gql/cache';
import client from '../../gql/client';
import { buyBonusGql } from '../../gql/mutation';
import { eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BuyFeatureController extends Controller {
  public gameMode = GameMode.BUY_FEATURE;

  public static the = new BuyFeatureController();

  private constructor() {
    super();
  }

  override enterController(prevGameMode: GameMode, props: BuyFeatureEnterProps): void {
    if (prevGameMode !== GameMode.BASE_GAME) throw new Error('CANT BUY FEATURE NOT IN BASE MODE');

    // TODO: Bonus override
    // if (props?.bonusId === buyFeatureBonusesId[FeatureState.BONUS_GAME]) {
    //   client
    //     .mutate<{
    //       buyBonus: {
    //         bonus: UserBonus;
    //         id: string;
    //         balance: { amount: number; currency: string };
    //         data: {
    //           cascadeMultiplier: number;
    //         };
    //       };
    //     }>({
    //       mutation: buyBonusGql,
    //       variables: {
    //         input: {
    //           id: buyFeatureBonusesId[FeatureState.ALL_BONUS_LINES],
    //           coinValue: setCoinValue(),
    //           coinAmount: setCoinAmount(),
    //         },
    //       },
    //     })
    //     .then((res) => {
    //       const { bonus, balance, data } = res.data!.buyBonus;
    //       eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    //       eventManager.emit(EventTypes.UPDATE_USER_BALANCE, balance);
    //       PopupController.the.closeCurrentPopup();
    //       setIsPopupOpened(false);
    //       setCurrentBonus({
    //         ...bonus,
    //         id: res.data!.buyBonus.id,
    //         isActive: true,
    //         currentRound: 0,
    //       });

    //       Logic.the.changeGameMode(GameMode.BONUS_GAME, {
    //         bonus,
    //       });

    //       console.log(data);
    //     });

    //   return;
    // }
    // <======== bonus override
    client
      .mutate<{
        buyBonus: {
          bonus: UserBonus;
          id: string;
          balance: { amount: number; currency: string };
          data: {
            cascadeMultiplier: number;
          };
        };
      }>({
        mutation: buyBonusGql,
        variables: {
          input: {
            id: props?.bonusId,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      })
      .then((res) => {
        const { bonus, balance, data } = res.data!.buyBonus;
        eventManager.emit(EventTypes.DISABLE_PAYTABLE);
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, balance);
        PopupController.the.closeCurrentPopup();
        setIsPopupOpened(false);
        setIsFreeSpinsWin(true);
        setFreeSpinsTotalWin(0);
        setCurrentBonus({
          ...bonus,
          id: res.data!.buyBonus.id,
          isActive: true,
          currentRound: 0,
          roundsLeft: bonus.rounds ?? 0,
        });

        if (buyFeatureBonusesId[FeatureState.ONE_BONUS_LINE] === props.bonusId) {
          Logic.the.changeGameMode(GameMode.ONE_BONUS_LINE, {
            bonus,
          });
        } else if (buyFeatureBonusesId[FeatureState.ALL_BONUS_LINES] === props.bonusId) {
          Logic.the.changeGameMode(GameMode.ALL_BONUS_LINES, {
            bonus,
          });
        } else if (buyFeatureBonusesId[FeatureState.BONUS_GAME] === props.bonusId) {
          Logic.the.changeGameMode(GameMode.BONUS_GAME, {
            bonus,
          });
        }

        console.log(data);
      });
  }

  public override exitController(_nextGameMode: GameMode): void {
    AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
    AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
  }
}

import * as particles from '@pixi/particle-emitter';
import type { EmitterConfigV1, EmitterConfigV2, EmitterConfigV3 } from '@pixi/particle-emitter';
import { ParticleContainer, Texture } from 'pixi.js';

// type emitterDataType = {
//   name?: string;
//   newConfig?: emitterConfigType;
//   textures?: Texture[];
// };

type emitterConfigType = EmitterConfigV1 | EmitterConfigV2 | EmitterConfigV3;
// in emitter config v1 and v2 the textures are separate from the config
// in emitter config v3 textures are part of the emitter config
export class ParticlesAnimation extends ParticleContainer {
  public emitter: particles.Emitter;
  constructor(name?: string, textures?: Texture[]) {
    super();
    if (!textures || !textures.length) {
      console.warn('particle animation ' + name + ' will use default texture');
    }

    if (!name || !cList[name]) {
      console.warn('No predefined particle animations found for name ' + name + '. Showing default particle animation');
      name = 'default';
    }

    let emitterConfig = cList[name] as EmitterConfigV3;
    if (!emitterConfig.behaviors || !Array.isArray(emitterConfig.behaviors)) {
      if (!textures || !textures.length) {
        emitterConfig = particles.upgradeConfig(emitterConfig, [Texture.WHITE]);
      } else {
        emitterConfig = particles.upgradeConfig(emitterConfig, textures);
      }
    } else {
      //TODO how to add multiple textures v3 config
      let textureConfig = emitterConfig.behaviors.find((obj) => obj.type === 'textureSingle');
      if (textureConfig && textures && textures.length) {
        textureConfig.config.texture = textures[0];
      }

      textureConfig = emitterConfig.behaviors.find((obj) => obj.type === 'textureRandom');
      if (textureConfig) {
        textureConfig.config.textures = textures;
      }
    }

    this.emitter = new particles.Emitter(this, emitterConfig);
  }
}

// A list of predefined particle animations configurations
// the list can contain emitterConfig v1, v2 and v3
// in case of emitterConfig v3 the textures will be overwrite if textures are passed in the constructor
const cList: { [key: string]: emitterConfigType } = {
  //slow infinite burst
  default: {
    lifetime: {
      min: 0.5,
      max: 0.5,
    },
    frequency: 0.1,
    spawnChance: 1,
    particlesPerWave: 1,
    emitterLifetime: -1,
    maxParticles: 10,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    behaviors: [
      {
        type: 'alpha',
        config: {
          alpha: {
            list: [
              {
                value: 0.8,
                time: 0,
              },
              {
                value: 0.4,
                time: 1,
              },
            ],
          },
        },
      },
      {
        type: 'scale',
        config: {
          scale: {
            list: [
              {
                value: 0.5,
                time: 0,
              },
              {
                value: 0.3,
                time: 1,
              },
            ],
          },
        },
      },
      {
        type: 'moveSpeed',
        config: {
          speed: {
            list: [
              {
                value: 200,
                time: 0,
              },
              {
                value: 100,
                time: 1,
              },
            ],
            isStepped: false,
          },
        },
      },
      {
        type: 'rotationStatic',
        config: {
          min: 0,
          max: 360,
        },
      },
      {
        type: 'spawnShape',
        config: {
          type: 'torus',
          data: {
            x: 0,
            y: 0,
            radius: 10,
          },
        },
      },
      {
        type: 'textureSingle',
        config: {
          texture: Texture.WHITE,
        },
      },
    ],
  },
  //slow infinite burst
  lighting: {
    lifetime: {
      min: 0.5,
      max: 0.5,
    },
    frequency: 0.1,
    spawnChance: 1,
    particlesPerWave: 1,
    emitterLifetime: -1,
    maxParticles: 10,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    behaviors: [
      {
        type: 'alpha',
        config: {
          alpha: {
            list: [
              {
                value: 1,
                time: 0,
              },
              {
                value: 0.7,
                time: 1,
              },
            ],
          },
        },
      },
      {
        type: 'scale',
        config: {
          scale: {
            list: [
              {
                value: 0.6,
                time: 0,
              },
              {
                value: 0.3,
                time: 1,
              },
            ],
          },
        },
      },
      {
        type: 'moveSpeed',
        config: {
          speed: {
            list: [
              {
                value: 200,
                time: 0,
              },
              {
                value: 100,
                time: 1,
              },
            ],
            isStepped: false,
          },
        },
      },
      {
        type: 'rotationStatic',
        config: {
          min: 0,
          max: 360,
        },
      },
      {
        type: 'spawnShape',
        config: {
          type: 'torus',
          data: {
            x: 0,
            y: 0,
            radius: 10,
          },
        },
      },
      {
        type: 'textureRandom',
        config: {
          textures: [Texture.WHITE],
        },
      },
    ],
  },
  //coped of pixi editor
  flame: {
    alpha: {
      start: 0.62,
      end: 0,
    },
    scale: {
      start: 0.25,
      end: 0.75,
      minimumScaleMultiplier: 1,
    },
    color: {
      start: '#fff191',
      end: '#ff622c',
    },
    speed: {
      start: 500,
      end: 500,
      minimumSpeedMultiplier: 1,
    },
    acceleration: {
      x: 0,
      y: 0,
    },
    maxSpeed: 0,
    startRotation: {
      min: 265,
      max: 275,
    },
    noRotation: false,
    rotationSpeed: {
      min: 50,
      max: 50,
    },
    lifetime: {
      min: 0.1,
      max: 0.75,
    },
    blendMode: 'normal',
    frequency: 0.001,
    emitterLifetime: -1,
    maxParticles: 1000,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    spawnType: 'circle',
    spawnCircle: {
      x: 0,
      y: 0,
      r: 10,
    },
  },
  //coped of pixi editor
  bubbles: {
    alpha: {
      start: 1,
      end: 0.22,
    },
    scale: {
      start: 0.25,
      end: 0.75,
      minimumScaleMultiplier: 0.5,
    },
    color: {
      start: '#ffffff',
      end: '#ffffff',
    },
    speed: {
      start: 200,
      end: 50,
      minimumSpeedMultiplier: 1,
    },
    acceleration: {
      x: 0,
      y: 0,
    },
    maxSpeed: 0,
    startRotation: {
      min: 0,
      max: 360,
    },
    noRotation: false,
    rotationSpeed: {
      min: 0,
      max: 10,
    },
    lifetime: {
      min: 4,
      max: 4,
    },
    blendMode: 'normal',
    frequency: 0.016,
    emitterLifetime: -1,
    maxParticles: 500,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    spawnType: 'point',
  },
  //coped of pixi editor
  explosion1: {
    alpha: {
      start: 0.8,
      end: 0.1,
    },
    scale: {
      start: 1,
      end: 0.3,
      minimumScaleMultiplier: 1,
    },
    color: {
      start: '#fb1010',
      end: '#f5b830',
    },
    speed: {
      start: 200,
      end: 100,
      minimumSpeedMultiplier: 1,
    },
    acceleration: {
      x: 0,
      y: 0,
    },
    maxSpeed: 0,
    startRotation: {
      min: 0,
      max: 360,
    },
    noRotation: false,
    rotationSpeed: {
      min: 0,
      max: 0,
    },
    lifetime: {
      min: 0.5,
      max: 0.5,
    },
    blendMode: 'normal',
    frequency: 0.008,
    emitterLifetime: 0.31,
    maxParticles: 1000,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    spawnType: 'circle',
    spawnCircle: {
      x: 0,
      y: 0,
      r: 10,
    },
  },
  explosion3: {
    alpha: {
      start: 0.74,
      end: 0,
    },
    scale: {
      start: 1,
      end: 1.2,
      minimumScaleMultiplier: 1,
    },
    color: {
      start: '#ffdfa0',
      end: '#100f0c',
    },
    speed: {
      start: 700,
      end: 0,
      minimumSpeedMultiplier: 1,
    },
    acceleration: {
      x: 0,
      y: 0,
    },
    maxSpeed: 0,
    startRotation: {
      min: 0,
      max: 360,
    },
    noRotation: false,
    rotationSpeed: {
      min: 0,
      max: 200,
    },
    lifetime: {
      min: 0.5,
      max: 1,
    },
    blendMode: 'normal',
    ease: [
      {
        s: 0,
        cp: 0.329,
        e: 0.548,
      },
      {
        s: 0.548,
        cp: 0.767,
        e: 0.876,
      },
      {
        s: 0.876,
        cp: 0.985,
        e: 1,
      },
    ],
    frequency: 0.01,
    emitterLifetime: 0.2,
    maxParticles: 30,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: true,
    spawnType: 'point',
  },
  squareBurst: {
    alpha: {
      start: 0.74,
      end: 0.1,
    },
    scale: {
      start: 0.1,
      end: 0.7,
      minimumScaleMultiplier: 1,
    },
    color: {
      start: '#ffffff',
      end: '#100f0c',
    },
    speed: {
      start: 50,
      end: 10,
      minimumSpeedMultiplier: 1,
    },
    acceleration: {
      x: 0,
      y: 0,
    },
    maxSpeed: 0,
    startRotation: {
      min: 0,
      max: 360,
    },
    noRotation: false,
    rotationSpeed: {
      min: -100,
      max: 100,
    },
    lifetime: {
      min: 0.1,
      max: 1,
    },
    blendMode: 'normal',
    ease: [
      {
        s: 0,
        cp: 0.329,
        e: 0.548,
      },
      {
        s: 0.548,
        cp: 0.767,
        e: 0.876,
      },
      {
        s: 0.876,
        cp: 0.985,
        e: 1,
      },
    ],
    frequency: 0.02,
    emitterLifetime: -1,
    maxParticles: 30,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    spawnType: 'rect',
    spawnRect: {
      x: 0,
      y: 0,
      w: 300,
      h: 150,
    },
  },
  trail: {
    alpha: {
      start: 0.7,
      end: 0.1,
    },
    scale: {
      start: 1,
      end: 0.3,
      minimumScaleMultiplier: 1,
    },
    color: {
      start: '#e3f9ff',
      end: '#0ec8f8',
    },
    speed: {
      start: 0,
      end: 0,
      minimumSpeedMultiplier: 1,
    },
    acceleration: {
      x: 0,
      y: 0,
    },
    maxSpeed: 0,
    startRotation: {
      min: 0,
      max: 0,
    },
    noRotation: false,
    rotationSpeed: {
      min: 0,
      max: 0,
    },
    lifetime: {
      min: 0.2,
      max: 0.2,
    },
    blendMode: 'normal',
    frequency: 0.008,
    emitterLifetime: -1,
    maxParticles: 1000,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    spawnType: 'point',
  },
};

import i18n from 'i18next';
import MultiStyleText from 'pixi-multistyle-text';
import { Container, Sprite, Texture, isMobile } from 'pixi.js';

import type { ResourceTypes } from '../../../resources';
import { ITweenObject, TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';

import { Dot } from './Dot';
import {
  CAROUSEL_ANIMATION_DELAY,
  CAROUSEL_ANIMATION_DURATION,
  CAROUSEL_DOTS_GAP,
  CAROUSEL_DOTS_SIZE,
  CAROUSEL_IMAGE_HEIGHT,
  CAROUSEL_LETTER_SIZE,
  CAROUSEL_TEXT_DIVIDE_COLOR,
  CAROUSEL_TEXT_DIVIDE_SECOND_COLOR,
  CAROUSEL_TEXT_DIVIDE_THIRD_COLOR,
  CAROUSEL_TEXT_MOBILE_LETTER,
  CAROUSEL_TEXT_MOBILE_SIZE,
  CAROUSEL_TEXT_SIZE,
  CAROUSEL_TEXT_WORD_WRAP_WIDTH,
  DEFAULT_CAROUSEL_TEXT,
} from './config';

interface ISlide {
  img: ResourceTypes;
  txtKey: string;
}

type CarouselOptions = {
  textY: number;
};

class Carousel extends Container {
  private slides: ISlide[];

  private slidesContainer: Container;

  private dots: Container;

  private options: CarouselOptions;

  private slideIndex = 0;

  private texts: MultiStyleText[] = [];

  constructor(slides: ISlide[], options?: CarouselOptions) {
    super();
    const defaults: CarouselOptions = {
      textY: 0,
    };

    this.options = { ...defaults, ...options };
    this.slides = slides;
    this.slidesContainer = this.initSlides();
    this.dots = this.initDots();
    this.addChild(this.slidesContainer, this.dots);

    setInterval(() => {
      this.handleSlide();
    }, CAROUSEL_ANIMATION_DELAY);
  }

  private initSlides(): Container {
    const slides = new Container();
    for (let i = 0; i < this.slides.length; i++) {
      const container = new Container();
      container.name = 'slidesContainer';
      const texture = Texture.from((this.slides[i as number] as ISlide).img);
      const image = new Sprite(texture);
      image.anchor.set(0.5, 0);
      image.x = 0;
      image.name = `image_${i}`;
      image.width = (image.width * CAROUSEL_IMAGE_HEIGHT) / image.height;
      image.height = (image.height * CAROUSEL_IMAGE_HEIGHT) / image.height;
      const text = new MultiStyleText(i18n.t((this.slides[i as number] as ISlide).txtKey), {
        default: {
          ...DEFAULT_CAROUSEL_TEXT,
          fontSize: isMobile.any ? CAROUSEL_TEXT_MOBILE_SIZE : CAROUSEL_TEXT_SIZE,
          wordWrapWidth: CAROUSEL_TEXT_WORD_WRAP_WIDTH || image.width,
        },
        span: {
          fill: CAROUSEL_TEXT_DIVIDE_COLOR,
          fontWeight: '900',
          strokeThickness: 10,
        },
        p: {
          fill: CAROUSEL_TEXT_DIVIDE_SECOND_COLOR,
        },
        b: {
          fontWeight: '900',
          strokeThickness: 10,
        },
        h: {
          fill: CAROUSEL_TEXT_DIVIDE_THIRD_COLOR,
        },
        scaleLetter: {
          fontSize: isMobile.any ? CAROUSEL_TEXT_MOBILE_LETTER : CAROUSEL_LETTER_SIZE,
        },
      });
      text.anchor.set(0.5, 0);
      text.y = image.height + this.options.textY;
      text.x = 0;
      this.texts.push(text);

      container.addChild(image);
      container.addChild(text);
      if (i === 0) {
        container.alpha = 1;
      } else {
        container.alpha = 0;
      }

      slides.addChild(container);
    }

    return slides;
  }

  private initDots = (): Container => {
    const dotsContainer = new Container();
    dotsContainer.name = 'slidesContainer';
    for (let i = 0; i < this.slides.length; i++) {
      const dot = new Dot(`${i + 1}`, i === 0);
      dot.name = `${i}`;
      dot.x = i * (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_GAP);
      dotsContainer.addChild(dot);
    }
    dotsContainer.x = 0;
    return dotsContainer;
  };

  private handleSlide(): void {
    for (let i = 0; i < this.slides.length; i++) {
      if (this.slideIndex === i) {
        const fadeIn = new Tween({
          object: this.slidesContainer.children[i as number] as ITweenObject,
          property: TweenProperties.ALPHA,
          propertyBeginValue: this.slidesContainer.children[i as number]!.alpha,
          target: 1,
          duration: CAROUSEL_ANIMATION_DURATION,
        });
        fadeIn.start();
      } else {
        const fadeOut = new Tween({
          object: this.slidesContainer.children[i as number] as ITweenObject,
          property: TweenProperties.ALPHA,
          propertyBeginValue: this.slidesContainer.children[i as number]!.alpha,
          target: 0,
          duration: CAROUSEL_ANIMATION_DURATION,
        });
        fadeOut.start();
      }

      (this.dots.children[i as number] as Dot).setActive(i === this.slideIndex);
    }
    if (this.slideIndex === this.slides.length - 1) {
      this.slideIndex = 0;
    } else {
      this.slideIndex += 1;
    }
  }

  public setSize(width: number, height: number, bottomGap: number): void {
    if (width >= height) {
      this.scale.set(height / 900);
      this.dots.y = this.slidesContainer.height;
      this.dots.x = -this.dots.width / 2;
      this.y = height / 2 - this.height / 2;
    }
    if (width < height + bottomGap) {
      this.scale.set(width / 950);
      this.dots.y = this.slidesContainer.height;
      this.dots.x = -this.dots.width / 2;
      this.y = height / 2 - this.height / 1.3;
    }
    this.x = width / 2;
  }

  public setTextWidth(width: number) {
    for (const text of this.texts) {
      text.style.wordWrapWidth = width;
      text.dirty = true;
      setTimeout(() => {
        this.dots.y = this.slidesContainer.height;
      }, 50);
    }
  }

  public setTextScale(scale: number) {
    for (const text of this.texts) {
      text.scale.set(scale);
    }
  }
}
export default Carousel;

import type { Spine } from 'pixi-spine';

import { EventTypes } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

import RandomEffectsContainer from './randomEffects';

export default class SteampunkAnticipation extends ViewContainer {
  private effect: Spine;

  private isPlay: boolean;

  constructor() {
    super();

    this.x = 500;
    this.y = 500;

    this.visible = false;
    this.isPlay = false;
    this.effect = RandomEffectsContainer.getSpineAnimation('anticipation', 'animation');

    this.addEvents();
  }

  private addEvents() {
    eventManager.addListener(EventTypes.STEAMPUNK_ANTICIPATION_START, (newWild: { x: number; y: number }) => {
      this.position.set(newWild.x - 350, newWild.y - 500);
      this.playAnimation();
    });

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));

    this.effect.state.addListener({
      complete: () => {
        this.isPlay = false;
        this.hideAnticipation();
      },
    });
  }

  private hideAnticipation(): void {
    this.visible = false;
    eventManager.emit(EventTypes.STEAMPUNK_ANTICIPATION_END);
  }

  private playAnimation() {
    this.visible = true;
    this.isPlay = true;

    // Hack for OnComplete called immediately from 2nd animation on
    this.effect.destroy();
    this.effect = RandomEffectsContainer.getSpineAnimation('anticipation', 'animation');

    this.addChild(this.effect);

    this.effect.state.addListener({
      complete: () => {
        this.isPlay = false;
        this.hideAnticipation();
      },
    });

    //this.effect.state.setEmptyAnimation(0, 0);
    this.effect.state.setAnimation(0, 'animation', false);
    this.effect.state.timeScale = 2;
  }

  private resizeGameContainer(_width: number, _height: number, _deviceWidth: number, _deviceHeight: number) {
    // this.x = _width / 2;
    // this.y = _height / 2;
  }
}

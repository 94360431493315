import { GameMode } from '../../global.d';

//import { setCurrentBonus } from '../../gql/cache';
import { BonusModeController } from './BonusModeController';

export class AllBonusLinesController extends BonusModeController {
  public override gameMode: GameMode = GameMode.ALL_BONUS_LINES;

  public static override the = new AllBonusLinesController();

  // public override enterController(prevGameMode: GameMode, _props?: EnterProps | undefined): void {
  //   super.enterController(prevGameMode, _props);

  //   setCurrentBonus({
  //     ...setCurrentBonus(),
  //     gameMode: GameMode.ALL_BONUS_LINES,
  //     rounds: 10,
  //     currentRound: 0,
  //     isActive: true,
  //   });
  // }
}

import { EventTypes } from '../global.d';
import { Logic } from '../logic';
import { eventManager } from '../slotMachine/config';

const frameTimes: number[] = [];
let fps = 0;
const fpsLowThreshold = 20; // Set the threshold for low FPS detection
const fpsAcceptableThreshold = 24;
const fpsUpdateInterval = 1000; // Update interval in milliseconds (1 second)

// Function to calculate average FPS
function calculateFPS() {
  const now = performance.now();
  frameTimes.push(now);

  // Remove old frames beyond the update interval
  while (frameTimes.length > 0 && (frameTimes[0] ?? 0) <= now - fpsUpdateInterval) {
    frameTimes.shift();
  }

  // Calculate FPS
  fps = frameTimes.length / (fpsUpdateInterval / 1000);

  // Detect low FPS
  if (fps < fpsLowThreshold) {
    if (!isLowPerformance) {
      eventManager.emit(EventTypes.LOW_PERFORMANCE);
      console.warn(`Low FPS detected: ${fps.toFixed(2)}`);
    }
    isLowPerformance = true;
  }

  if (fps >= fpsAcceptableThreshold) {
    if (isLowPerformance) {
      eventManager.emit(EventTypes.HIGH_PERFORMANCE);
    }
    isLowPerformance = false;
  }
}

export const initRenderStats = () => {
  Logic.the.application.ticker.add(() => calculateFPS());
};

export let isLowPerformance = false;

import { gsap } from 'gsap';
import { Sprite, Texture, filters, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setBetResult, setCurrentBonus } from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { findGameModeById, getBetResult } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

import BonusLinesTurnsCounter from './bonusLinesTurnsCounter';

class BonusIndicatorContainer extends ViewContainer {
  // public props
  public get bonusTurnsCount() {
    return this._bonusTurnsCount;
  }
  // private
  private blinkAnimation: gsap.core.Tween;
  private readonly bonusIndicatorsCount = 3;
  private _bonusTurnsCount = 0;
  private tier = 0;
  private mainAnchor = 0.5;
  private bonusTier = new Sprite();
  private bonusTriggerIndicatorAlpha = 0;
  private bonusGameTriggerIndicator = new Sprite();
  private winGlow = new Sprite();
  private winGlow1 = new Sprite();
  private winGlow2 = new Sprite();
  private bonusModes = [GameMode.ONE_BONUS_LINE, GameMode.ALL_BONUS_LINES, GameMode.BONUS_GAME];

  constructor() {
    super();

    this.x = -10;
    this.y = 0;

    this.addGraphics();

    this.addEffects();

    this.addEvents();

    this.blinkAnimation = gsap.to({}, {});
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      // case GameMode.BASE_GAME:
      //   this.setBonusLevel(0);
      //   break;
      case GameMode.ONE_BONUS_LINE:
        this.setBonusLevel(1);
        break;
      case GameMode.ALL_BONUS_LINES:
        this.setBonusLevel(2);
        break;
      case GameMode.BONUS_GAME:
        this.setBonusLevel(3);
        break;
      default:
        break;
    }
  }

  private addGraphics() {
    const baseTexture = utils.TextureCache['bonus_collector_0.png'];
    const base = new Sprite(baseTexture);
    base.anchor.set(this.mainAnchor);

    this.bonusTier = new Sprite(this.getBonusTierTexture());
    this.bonusTier.anchor.set(this.mainAnchor);

    const texture = utils.TextureCache['bonus_collector_4.png'];
    this.bonusGameTriggerIndicator = new Sprite(texture);
    this.bonusGameTriggerIndicator.anchor.set(this.mainAnchor);
    this.bonusGameTriggerIndicator.alpha = this.bonusTriggerIndicatorAlpha;
    //this.bonusGameTriggerIndicator.x = 100;

    this.winGlow = new Sprite(this.getWinGlowTexture());
    this.winGlow.anchor.set(this.mainAnchor);

    const winGlowTexture = utils.TextureCache['bonus_collector_rays.png'];
    const createWinGlow = (options: { xPosition: number; yPosition: number; anchor: number }) => {
      const winGlow = new Sprite(winGlowTexture);
      winGlow.anchor.set(options.anchor);
      winGlow.position.set(options.xPosition, options.yPosition);
      winGlow.visible = false;
      return winGlow;
    };
    this.winGlow1 = createWinGlow({ xPosition: -510, yPosition: -180, anchor: this.mainAnchor });
    this.winGlow2 = createWinGlow({ xPosition: -510, yPosition: -290, anchor: this.mainAnchor });

    const turnsCounter = new BonusLinesTurnsCounter();

    this.addChild(
      turnsCounter,
      base,
      this.bonusTier,
      this.bonusGameTriggerIndicator,
      this.winGlow,
      this.winGlow1,
      this.winGlow2,
    );
  }

  private addEffects() {
    [this.winGlow1, this.winGlow2].forEach((glow) =>
      gsap.to(glow, {
        duration: 10,
        angle: 360,
        repeat: -1,
        ease: 'none',
      }),
    );
  }

  private addEvents() {
    eventManager.on(EventTypes.END_BONUS_SPINS, () => this.reset());
    eventManager.on(EventTypes.COLLECT_BONUS, () => {
      AudioApi.play({ type: ISongs.SFX_Bonus_Spin_Start });

      const result = getBetResult(setBetResult());
      const latestBonus = result.bet.data.bonuses[result.bet.data.bonuses.length - 1];
      const mode = findGameModeById(latestBonus?.bonusId as string);
      if (mode && Logic.the.controller.gameMode !== mode) {
        this.setBonusLevel(this.tier + 1, mode);
      }
    });

    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => this.blinkAnimationOff());

    eventManager.on(EventTypes.BONUS_BLINKS_ON, () => this.blinkAnimationOn());
  }

  private blinkAnimationOn() {
    const filter = new filters.ColorMatrixFilter();
    const bValue = { value: -0.3 };
    filter.matrix = [1, 0, 0, 0, bValue.value, 0, 1, 0, 0, bValue.value, 0, 0, 1, 0, bValue.value, 0, 0, 0, 1, 0];
    this.winGlow.filters = [filter];
    this.winGlow1.filters = [filter];
    this.winGlow2.filters = [filter];

    this.blinkAnimation = gsap.to(bValue, {
      value: 0.5,
      duration: 0.5,
      yoyo: true,
      repeat: -1,
      ease: 'none',
      onUpdate: () => {
        filter.matrix = [1, 0, 0, 0, bValue.value, 0, 1, 0, 0, bValue.value, 0, 0, 1, 0, bValue.value, 0, 0, 0, 1, 0];
      },
    });
  }

  private blinkAnimationOff() {
    this.blinkAnimation.kill();
    this.winGlow.filters = null;
    this.winGlow1.filters = null;
    this.winGlow2.filters = null;
  }

  private getBonusTierTexture(): Texture {
    return this.tier >= 0 && this.tier <= this.bonusIndicatorsCount
      ? utils.TextureCache[`bonus_collector_${this.tier}.png`] ?? Texture.EMPTY
      : Texture.EMPTY;
  }

  private getWinGlowTexture(): Texture {
    this.winGlow1.visible = this.tier >= 1;
    this.winGlow2.visible = this.tier >= 2;

    return this.tier > 0 && this.tier <= this.bonusIndicatorsCount
      ? utils.TextureCache[`bonus_collector_glow_0${this.tier}.png`] ?? Texture.EMPTY
      : Texture.EMPTY;
  }

  private setBonusLevel(tier: number, mode?: GameMode) {
    if (mode) {
      let expectedModeName = GameMode[this.bonusModes[tier - 1] as GameMode];
      if (tier === 0) {
        expectedModeName = GameMode[GameMode.BASE_GAME];
      }
      const actual = GameMode[mode];
      if (expectedModeName !== actual) {
        console.error(
          `Error: Collected bonuses do not match bonus mode. Expected: ${expectedModeName}, actual: ${actual}.`,
        );
      }
    }

    // after reels stopped
    eventManager.once(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE, () => {
      if (mode && Logic.the.controller.gameMode !== mode) {
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(mode);
      }
    });

    if (tier <= this.tier) {
      console.warn('Bonus level not increased by prize!');
      return;
    }
    if (tier < 0 || tier > this.bonusIndicatorsCount) {
      console.error('Error: bonus level out of bounds');
      return;
    }

    this.tier = tier;

    const oldIndicatorTexture = this.bonusTier.texture;
    const oldGlowTexture = this.winGlow.texture;
    this.bonusTier.texture = this.getBonusTierTexture();
    this.winGlow.texture = this.getWinGlowTexture();
    const blinkDuration = 0.1;
    const timeline = gsap.timeline({ repeat: 1 });
    if (tier < 3) {
      timeline
        .to(this.bonusTier, {
          duration: blinkDuration,
          onComplete: () => {
            this.bonusTier.texture = oldIndicatorTexture;
            this.winGlow.texture = oldGlowTexture;
          },
        })
        .to(this.bonusTier, {
          duration: blinkDuration * 1.5,
          onComplete: () => {
            this.bonusTier.texture = this.getBonusTierTexture();
            this.winGlow.texture = this.getWinGlowTexture();
          },
        });
    }

    if (this.tier >= 1) {
      // const waiting = Tween.createDelayAnimation(1);
      // waiting.addOnStart(() => {
      //   this.lightBonusCollectorIndicators(this.bonusSymbolsCollected);
      //   this.startBonusTurns();
      // });

      // TODO: use tween
      setTimeout(() => {
        this._bonusTurnsCount = setCurrentBonus().rounds;
        if (this.tier <= 2) {
          eventManager.emit(EventTypes.START_BONUS_SPINS);
        } else if (this.tier === 3) {
          const blinkDuration = 0.2;
          const timeline = gsap.timeline({ repeat: 2 });
          timeline
            .to(this.bonusGameTriggerIndicator, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' })
            .to(this.bonusGameTriggerIndicator, { alpha: 0, duration: blinkDuration, ease: 'bounceOut' });
          //eventManager.emit(EventTypes.START_BONUS_GAME);
        }
      }, 500);
    }
  }

  private reset() {
    this.tier = 0;
    this.bonusTier.texture = this.getBonusTierTexture();
    this.winGlow.texture = this.getWinGlowTexture();
    this.bonusGameTriggerIndicator.alpha = this.bonusTriggerIndicatorAlpha;
  }
}

export default BonusIndicatorContainer;

import { Graphics, Loader, Rectangle, Sprite, Texture, filters, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { bonusContent } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import SpriteAnimation from '../animations/sprite';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { eventManager } from '../config';
import Carousel from '../introScreen/Carousel';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BonusInstructionsPopup extends Popup {
  private popupBg: Sprite = new Sprite(Texture.WHITE);

  private confirmBtn: Sprite;

  private logo: SpriteAnimation;

  private carousel = new Carousel(bonusContent, { textY: -120 });

  private disableBtnFilter = new filters.ColorMatrixFilter();

  constructor() {
    super();
    this.x = 0;
    this.y = 0;
    this.visible = false;

    this.disableBtnFilter.blackAndWhite(true);

    this.initPopupBg();
    //this.promptMessage = this.initPromptText();
    this.confirmBtn = this.initConfirmBtn();

    this.logo = this.initGameLogo();

    this.init();
  }

  public override show(): void {
    super.show();
    this.visible = true;
    this.confirmBtn.interactive = true;

    // setTimeout(() => {
    //   this.confirmBtn.emit('pointerdown');
    //   this.confirmBtn.emit('click');
    // }, 100);

    eventManager.emit(EventTypes.SHOW_FULL_SCREEN_DIALOG);
  }

  public override hide(): void {
    super.hide();
    this.visible = false;

    eventManager.emit(EventTypes.HIDE_FULL_SCREEN_DIALOG);
  }

  private init(): void {
    this.popupBg.texture = utils.TextureCache['bonus_rulesbg.png'] || Texture.EMPTY;
    // TODO: adjust to game scaling
    const size = 6000;
    const darkOverlay = new Graphics();
    darkOverlay.beginFill(0x000000, 0.8); // Dark color with 50% transparency
    darkOverlay.drawRect(-size / 2, -size / 2, size, size);
    darkOverlay.endFill();

    const dialogAssetWidth = 1200;
    const dialogAssetHeight = 580;
    this.carousel.setSize(dialogAssetWidth, dialogAssetHeight, 0);
    this.carousel.position.set(0, -180);

    this.addChild(darkOverlay, this.popupBg, this.carousel, this.confirmBtn, this.logo.spriteAnimation);
  }

  private initGameLogo(): SpriteAnimation {
    const logoSheet = Loader.shared.resources['logo_animations']!.spritesheet!;
    const logoTexture = logoSheet.animations['bonus_logo'] as Texture[];
    const logoAnimation = new SpriteAnimation({ isLoop: true }, logoTexture);
    logoAnimation.spriteAnimation.anchor.set(0.5);
    //logoAnimation.start();

    logoAnimation.spriteAnimation.animationSpeed = 0.3;
    logoAnimation.spriteAnimation.name = 'bonus_logo';

    return logoAnimation;
  }

  private initPopupBg(): void {
    this.popupBg.name = 'popupBg';
    this.popupBg.x = 0;
    this.popupBg.y = 0;
    this.popupBg.anchor.set(0.5);
  }

  private initConfirmBtn(): Sprite {
    const confirmBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['ok_normal.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['ok_hover.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['ok_press.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['ok_normal.png'] || Texture.EMPTY,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioApi.play({ type: ISongs.SFX_Custom_click });
        this.closeInstructions();
      },
      onTouchStart: () => {
        AudioApi.play({ type: ISongs.SFX_Custom_click });
        this.closeInstructions();
      },
    });

    confirmBtn.name = 'confirmBtn';
    confirmBtn.anchor.set(0.5, 0.5);

    const width = 186;
    const height = 134;
    const hitArea = new Rectangle(-width / 2, -height / 2, width, height);
    confirmBtn.hitArea = hitArea;

    // const graphics = new Graphics();

    // // Set the fill color and draw a rectangle
    // graphics.beginFill(0xff0000); // Red color
    // graphics.drawRect(-width / 2 + 225, -height / 2 + 370, width, height);
    // graphics.endFill();
    // graphics.alpha = 0.5;

    // confirmBtn.addChild(graphics);
    // // @ts-ignore
    // window.testHitArea = graphics;

    return confirmBtn;
  }

  private closeInstructions(): void {
    // if (!this.featureState) {
    //   return;
    // }
    // this.confirmBtn.interactive = false;
    // eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));

    PopupController.the.closeCurrentPopup();
  }

  protected override resize(_width: number, _height: number): void {
    const isLandscape = _width >= _height;
    this.x = 0;

    this.scale.set(1.2);

    const dialogAssetWidth = 1200;
    const dialogAssetHeight = 680;
    this.carousel.setSize(dialogAssetWidth, dialogAssetHeight, 0);
    this.carousel.position.set(0, -240);
    this.carousel.setTextWidth(1550);
    this.carousel.setTextScale(0.95);

    this.popupBg.rotation = 0;
    this.popupBg.x = 0;
    this.popupBg.y = 0;
    this.popupBg.scale.set(1);

    this.logo.spriteAnimation.x = -15;
    this.logo.spriteAnimation.y = -280;
    this.logo.spriteAnimation.scale.set(0.8);

    this.confirmBtn.x = 0;
    this.confirmBtn.y = 380;
    this.confirmBtn.scale.set(0.75);

    if (!isLandscape) {
      this.x = -15;
      this.scale.set(1);

      this.carousel.setSize(dialogAssetHeight + 900, dialogAssetWidth + 500, 0);
      this.carousel.position.set(15, -700);
      this.carousel.setTextWidth(980);
      this.carousel.setTextScale(0.7);

      this.popupBg.rotation = Math.PI / 2;
      this.popupBg.x = 190;
      this.popupBg.scale.set(1.7, 2.15);

      this.logo.spriteAnimation.x = 20;
      this.logo.spriteAnimation.y = -750;
      this.logo.spriteAnimation.scale.set(1.15);

      this.confirmBtn.x = 0;
      this.confirmBtn.y = 370 + 400;
      this.confirmBtn.scale.set(1.5);
    }
  }
}

export default BonusInstructionsPopup;

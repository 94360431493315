import gsap from 'gsap';
import { Container, Graphics, Loader, Sprite, Text, Texture, filters, utils } from 'pixi.js';

import type { SlotId } from '../../config';
import {
  BonusTurnData,
  BonusTurnInputData,
  CollectorTargetTypes,
  EventTypes,
  GameMode,
  PayLine,
  UserBonus,
  bonusesId,
} from '../../global.d';
import {
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setIsErrorMessage,
  setIsRevokeThrowingError,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
} from '../../gql/cache';
import i18n from '../../i18next';
import { getBetResult, getBonusTurn, shuffleArray } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { CascadeAnimation } from '../animations/cascade/cascadeAnimation';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { PopupTypes, eventManager, restoredGameTextStyle } from '../config';
import type RandomEffectsContainer from '../effects/randomEffects';
import {
  bonusGameSteamRandomBackgroundEffects,
  bonusGameSteamRandomForegroundEffects,
} from '../effects/steamRandomEffects';
import { PopupController } from '../popups/PopupController';

import BonusComboCollector from './bonusComboCollector';
import BonusMultiplier from './bonusMultiplier';
import BonusReelContainer from './bonusReelContainer';
import type BonusSlot from './bonusSlot';
import BonusTurnsCounter from './bonusTurnsCounter';
import BonusWinAnimationContainer from './bonusWinAnimationContainer';
import BonusWings from './bonusWings';

interface WinSymbols {
  [type: string]: number;
}

class BonusGameContainer extends ViewContainer {
  private readonly reelsCount = 5;
  private spinInProgress = false;
  private readonly initialReelSizes = [1, 1, 5, 1, 1];

  private darkenFilter = new filters.ColorMatrixFilter();
  private reelsContainer = new Container();
  private reels: BonusReelContainer[] = [];

  private winAnimationContainer: BonusWinAnimationContainer;
  private wings: BonusWings;
  private turnsCounter: BonusTurnsCounter;
  private restoredText: Text;

  private nextReelLayout: number[];
  private reelAnimations: AnimationGroup | undefined;
  private turnData: BonusTurnData | undefined;

  private foregroundSteamEffects: RandomEffectsContainer;
  private backgroundSteamEffects: RandomEffectsContainer;

  constructor() {
    super();
    this.y = -130;
    this.scale.set(0.7);
    this.visible = false;
    this.interactive = false;
    this.interactiveChildren = false;

    this.nextReelLayout = [];

    // TODO: adjust to game scaling
    const size = 6000;
    const darkOverlay = new Graphics();
    darkOverlay.beginFill(0x000000, 0.8); //#A9A9A9
    darkOverlay.drawRect(-size / 2, -size / 2, size, size);
    darkOverlay.endFill();

    this.darkenFilter.brightness(0.5, true);

    const glowTexture = utils.TextureCache['bonus_animated_ring_white.png'];
    const glowEffect = new Sprite(glowTexture);
    glowEffect.anchor.set(0.5);
    glowEffect.scale.set(2.6);
    glowEffect.y = 200;
    glowEffect.alpha = 0.8;
    gsap.to(glowEffect, {
      angle: 360,
      duration: 20,
      repeat: -1,
      ease: 'none',
    });

    const bgTexture = utils.TextureCache['bonus_machine_bg.png'];
    const background = new Sprite(bgTexture);
    background.anchor.set(0.5);

    background.scale.set(0.75);
    background.y = -20;

    const distanceX = 220;
    const startX = Math.round((-distanceX * this.reelsCount + distanceX) / 2);

    for (let i = 0; i < this.reelsCount; i++) {
      const reel = new BonusReelContainer();
      reel.x = startX + distanceX * i;

      this.reels.push(reel);
    }

    const comboCollector = new BonusComboCollector();
    const multiplier = new BonusMultiplier();
    this.turnsCounter = new BonusTurnsCounter();

    const backTexture = utils.TextureCache['bonus_back_plate.png'];
    const backPlate = new Sprite(backTexture);
    backPlate.anchor.set(0.5);
    backPlate.y = -220;
    backPlate.x = -40;
    backPlate.scale.set(1.4);
    const logo = this.initGameLogo();
    logo.spriteAnimation.y = -100;
    backPlate.addChild(logo.spriteAnimation);

    this.wings = new BonusWings();

    this.foregroundSteamEffects = bonusGameSteamRandomForegroundEffects();
    this.foregroundSteamEffects.randomEffectsStop();

    this.backgroundSteamEffects = bonusGameSteamRandomBackgroundEffects();
    this.backgroundSteamEffects.randomEffectsStop();

    this.restoredText = new Text(i18n.t<string>('restoredGame'), restoredGameTextStyle);
    this.restoredText.anchor.set(0.5);
    this.restoredText.y = 100;
    this.restoredText.alpha = 0;

    this.reelsContainer.y = 20;
    this.reelsContainer.name = 'reelsContainer';
    this.reelsContainer.addChild(background, ...this.reels);

    const bottomWidgetsContainer = new Container();
    bottomWidgetsContainer.name = 'bottomWidgetsContainer';
    bottomWidgetsContainer.addChild(comboCollector, multiplier, this.turnsCounter);
    bottomWidgetsContainer.y = 80;

    this.addChild(
      darkOverlay,
      glowEffect,
      this.backgroundSteamEffects,
      this.wings,
      backPlate,
      bottomWidgetsContainer,
      this.reelsContainer,
      this.restoredText,
      this.foregroundSteamEffects,
    );

    const positions = [
      {
        name: CollectorTargetTypes.bonusTurnsCounter,
        getGlobalPosition: this.turnsCounter.toGlobal.bind(this.turnsCounter),
      },
      { name: CollectorTargetTypes.bonusMultiplier, getGlobalPosition: multiplier.toGlobal.bind(multiplier) },
    ];
    this.winAnimationContainer = new BonusWinAnimationContainer(comboCollector, positions);

    this.addChild(this.winAnimationContainer);

    this.addEvents();
  }

  private getTurnData(): BonusTurnInputData {
    const result = getBetResult(setBetResult());
    const activeRowsPerReel = result.bet.data.features.gameRoundStore.reelState.bonusState?.activeRowsPerReel ?? [];
    const reelPositions = result.bet.result.reelPositions;
    const layout = result.bet.reelSet.layout;
    const bonuses = result.bet.data.bonuses;
    const isMultiplier = result.bet.data.features.winMultiplier > 1;
    const payLines = result.paylines;

    return {
      activeRowsPerReel,
      reelPositions,
      layout,
      bonuses,
      payLines,
      isMultiplier,
    };
  }

  private getPrevTurnData(): BonusTurnInputData {
    const lastBetResult = setUserLastBetResult();
    const activeRowsPerReel = lastBetResult.data.features.gameRoundStore.reelState.bonusState?.activeRowsPerReel ?? [];
    const reelPositions = lastBetResult.result.reelPositions;

    const slotData = setSlotConfig();
    const layout = slotData.reels.find((reelSet) => reelSet.id === lastBetResult.reelSetId)!.layout;

    const bonuses = [setCurrentBonus()];
    const payLines = [] as PayLine[];
    const isMultiplier = lastBetResult.data.features.winMultiplier > 1;

    return {
      activeRowsPerReel,
      reelPositions,
      layout,
      bonuses,
      payLines,
      isMultiplier,
    };
  }

  private initGameLogo(): SpriteAnimation {
    const logoSheet = Loader.shared.resources['logo_animations']!.spritesheet!;
    const logoTexture = logoSheet.animations['bonus_logo'] as Texture[];
    const logoAnimation = new SpriteAnimation({ isLoop: true }, logoTexture);
    logoAnimation.spriteAnimation.anchor.set(0.5);
    logoAnimation.start();

    logoAnimation.spriteAnimation.animationSpeed = 0.3;
    logoAnimation.spriteAnimation.name = 'bonus_logo';

    return logoAnimation;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BONUS_GAME:
        this.show();
        this.foregroundSteamEffects.randomEffectsPlay();
        this.backgroundSteamEffects.randomEffectsPlay();
        break;
      default:
        if (this.visible) {
          this.hide();
          this.foregroundSteamEffects.randomEffectsStop();
          this.backgroundSteamEffects.randomEffectsStop();
        }

        break;
    }
  }

  private addEvents() {
    // TODO: Hide on bonus game finished
    // TODO: disable the game behind

    eventManager.on(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE, () => {
      this.spinInProgress = false;
      this.wings.enableDelay = true;
    });

    eventManager.on(EventTypes.SETUP_BONUS_REEL_POSITIONS, () => {
      this.setNextSlots();

      const callback = () => {
        if (this.reels[0]?.spinTween?.isLoop) {
          return;
        }

        this.reelAnimations?.removeOnComplete(callback);
        const chain = new AnimationChain();

        chain.appendAnimation(Tween.createDelayAnimation(300));
        const turn = this.turnData;
        const isWin = (turn?.winPositions.length as number) > 0;
        if (isWin) {
          const winAnimations = this.startWinAnimation();
          chain.appendAnimation(winAnimations);
          chain.appendAnimation(Tween.createDelayAnimation(300));
        }

        chain.addOnComplete(() => {
          const turns = setCurrentBonus().roundsLeft;
          this.turnsCounter.setTurns(turns);
          eventManager.emit(EventTypes.REELS_STOPPED);
        });

        chain.start();
      };

      this.reelAnimations?.addOnComplete(callback);
    });
  }

  private getSeedReel(): SlotId[] {
    const maxRepeats = 2;
    let randomizedSlots: SlotId[] = [];
    for (let i = 0; i < maxRepeats; i++) {
      randomizedSlots.push(...BonusReelContainer.slotsList);
    }
    randomizedSlots = shuffleArray(randomizedSlots);

    return randomizedSlots;
  }

  private startWinAnimation(): AnimationGroup {
    // TODO: migrate to gsap
    const winAnimations = new AnimationGroup();

    const turn = this.turnData;
    if (!this.turnData) return winAnimations;

    let winSymbols: WinSymbols = {};
    let winSlots: BonusSlot[] = [];

    for (const i in this.reels) {
      const reel = this.reels[i];

      const winIndexes: number[] =
        turn?.winPositions
          .filter((w) => w.position[0] === +i)
          .map((w) => w.position[1])
          .filter((i): i is number => i !== undefined) ?? [];

      for (const index of winIndexes) {
        let type: string | undefined = undefined;
        const r = turn?.reels[i] as SlotId[];
        type = r[index];

        if (type) {
          winSymbols[type] = winSymbols[type] ?? 0;
          winSymbols[type]++;
        }
      }

      if (reel && winIndexes.length > 0) {
        const anim = reel.winAnimation(winIndexes);
        winAnimations.addAnimation(anim);
        const slots = reel.getByIndexes(winIndexes);
        winSlots.push(...slots);
      }
    }

    const groups = winSlots.reduce((acc: Partial<Record<SlotId, BonusSlot[]>>, item) => {
      const key = item.slotId;
      if (!acc[key]) {
        acc[key] = [];
      }
      const a = acc[key] as BonusSlot[];
      a.push(item as BonusSlot);
      return acc;
    }, {});

    winAnimations.addOnComplete(() => {
      this.winAnimationContainer.collectSlotsAnimation(groups);

      winSymbols = {};
      winSlots = [];
    });

    return winAnimations;
  }

  public static handleBoundsError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.GAME.BOUNDS'),
      });
    }
  }

  private setNextSlots() {
    const turn = getBonusTurn(this.getTurnData());

    const result = getBetResult(setBetResult());
    this.nextReelLayout = result.bet.data.features.gameRoundStore.reelState.bonusState?.activeRowsPerReelNext ?? [];

    const extraSpin = result.bet.data.bonuses.find(
      () => (b: UserBonus) => b.bonusId === bonusesId[GameMode.BONUS_GAME],
    );
    setCurrentBonus({
      ...setCurrentBonus(),
      ...(extraSpin as UserBonus),
    });

    for (const i in this.reels) {
      const reel = this.reels[i];

      if (reel) {
        // TODO: empty slot?
        const slots = turn?.reels[i] as SlotId[];
        reel.setNextSlots(slots);
      }
    }

    this.turnData = turn as BonusTurnData;
  }

  public spin(): CascadeAnimation[] {
    this.spinInProgress = true;

    this.reelsContainer.filters = [];
    this.restoredText.alpha = 0;

    this.wings.flapConstantly('Soar');

    const reelAnimations = new AnimationGroup();
    for (const i in this.reels) {
      const reel = this.reels[i];
      if (reel) {
        reel.setSize(this.nextReelLayout[i] as number);
        const animation = reel.nextReelAnimation();
        reelAnimations.addAnimation(animation);
      }
    }

    this.reelAnimations = reelAnimations;

    const waiting = Tween.createDelayAnimation(12000);
    const cascadeAnimation = new CascadeAnimation({
      disappearingAnimation: reelAnimations,
      waitingAnimation: waiting,
    });

    cascadeAnimation.animationName = 'bonusGameReel';
    return [cascadeAnimation];
  }

  protected override resize(_width: number, _height: number): void {
    const isPortrait = _height > _width;

    const scale = isPortrait ? 0.9 : 0.7;
    const wingLeftPosition = isPortrait ? { x: -450, y: -380 } : { x: -580, y: 20 };
    const wingRightPosition = isPortrait ? { x: 450, y: -380 } : { x: 580, y: 20 };

    this.scale.set(scale);
    this.wings.wingLeftSpine.position.set(wingLeftPosition.x, wingLeftPosition.y);
    this.wings.wingRightSpine.position.set(wingRightPosition.x, wingRightPosition.y);
  }

  private show() {
    this.reset();
    this.wings.resume();
    this.visible = true;

    PopupController.the.openPopup(PopupTypes.BONUS_INSTRUCTIONS);

    eventManager.emit(EventTypes.END_BONUS_SPINS);
  }

  private reset() {
    let seedReel = this.getSeedReel();
    let reelLayout = this.initialReelSizes;
    this.nextReelLayout = this.initialReelSizes;

    // TODO: get info from lastBetResult
    const lastBetResult = setUserLastBetResult();
    if (setBrokenGame()) {
      if (lastBetResult.data.features.gameRoundStore.reelState.bonusState) {
        seedReel = getBonusTurn(this.getPrevTurnData()).reels.flat();
        reelLayout = lastBetResult.data.features.gameRoundStore.reelState.bonusState?.activeRowsPerReel ?? [];
        this.nextReelLayout =
          lastBetResult.data.features.gameRoundStore.reelState.bonusState?.activeRowsPerReelNext ?? [];
      }

      this.reelsContainer.filters = [this.darkenFilter];
      this.restoredText.alpha = 1;
    }

    for (let i = 0; i < this.reelsCount; i++) {
      const reel = this.reels[i];
      const size = reelLayout[i] || 0;
      const slots = seedReel.splice(0, size);
      reel?.reset(slots);
    }

    //turnIndex = 0;
  }

  private hide() {
    this.wings.pause();
    this.visible = false;
  }
}

export default BonusGameContainer;

import { gsap } from 'gsap';
import { Point, Sprite, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, type ReelsPattern } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';
import MultiplierContainer from '../multiplierContainer/multiplierContainer';

import CollectorGates from './comboCollectorGates';
import ComboCollectorSlot from './comboCollectorSlot';

class ComboCollectorContainer extends ViewContainer {
  protected readonly slotsCount = 5;
  protected readonly extraSlotsCount = 5;
  protected collectedTypesCount = 0;
  private isPortrait: boolean = false;
  private lowerGateOpened: boolean = false;
  private lowerGateDisplacement = 0;
  private prevCollectorOrientation = '';

  private gates: CollectorGates;
  private extraGates: CollectorGates;
  multiplierContainer: MultiplierContainer;
  protected extraSlotsContainer: Sprite = new Sprite();

  protected collectorSlots: ComboCollectorSlot[] = [];
  protected defaultSlots: ComboCollectorSlot[] = [];
  protected extraSlots: ComboCollectorSlot[] = [];
  protected slotsData: ReelsPattern[] = [];
  protected reservedSlots: { slot: ComboCollectorSlot; futureType: string }[] = [];

  constructor() {
    super();

    this.scale.set(0.92);

    this.multiplierContainer = new MultiplierContainer();
    this.name = 'ComboCollectorContainer';
    this.gates = new CollectorGates(2, 3);
    this.extraGates = new CollectorGates(5, 10);

    this.addGraphics();
    this.addEvents();
  }

  protected showExtraSlots(): gsap.core.Timeline {
    const chain = gsap.timeline();
    chain.to(this.parent, {
      x: -50,
      duration: 0.5,
      ease: 'bounce.out',
    });

    chain.fromTo(this.extraSlotsContainer, { visible: true, x: 0 }, { x: 200, duration: 0.5, ease: 'bounce.out' });
    AudioApi.play({ type: ISongs.SFX_Extra_Collector_Open });
    gsap.to(this.parent.scale, {
      x: 0.78,
      y: 0.78,
      duration: 0.5,
      ease: 'bounce.out',
    });

    return chain;
  }

  protected hideExtraSlots(): gsap.core.Timeline {
    const chain = gsap.timeline();
    chain.to(this.extraSlotsContainer, { x: 0, duration: 0.5 });
    chain.to(this.parent, {
      x: 0,
      duration: 0.5,
      ease: 'bounce.out',
      onStart: () => {
        AudioApi.play({ type: ISongs.SFX_Extra_Collector_Close });
        gsap.to(this.parent.scale, {
          x: 1,
          y: 1,
          duration: 0.5,
          ease: 'bounce.out',
        });
      },
    });
    return chain;
  }

  private lowerGateDisplacementAnimation(): gsap.core.Tween {
    const anim = gsap.to(this.parent.pivot, {
      x: -this.lowerGateDisplacement,
      duration: 0.5,
      ease: 'bounce.out',
    });

    return anim;
  }

  protected override resize(_width: number, _height: number): void {
    super.resize(_width, _height);
    this.isPortrait = _width < _height;
    if (!this?.parent.parent) {
      return;
    }

    // default landscape

    // assume component is added to sub containers to the main container for moving and scaling animations
    // + other elements relatively positioned and scaled
    if (_width >= _height) {
      this.parent.parent.x = 670; //550
      this.parent.parent.y = 10;
      this.parent.parent.rotation = 0;
      this.parent.parent.scale.set(1);

      this.parent.pivot.x = 0;

      this.collectorSlots.forEach((s) => s.rotateSlots('vertical'));
      this.arrangeSlots('landscape');
      this.prevCollectorOrientation = 'landscape';

      this.lowerGateDisplacement = 0;
    } else if (_width < _height) {
      // portrait
      this.parent.parent.x = -25;
      this.parent.parent.y = 670;
      this.parent.parent.rotation = Math.PI / 2; // Rotate by 90 degrees in radians
      this.parent.parent.scale.set(1, -1);

      this.collectorSlots.forEach((s) => s.rotateSlots('horizontal'));
      this.arrangeSlots('portrait');
      this.prevCollectorOrientation = 'portrait';

      if (this.lowerGateOpened) {
        this.lowerGateDisplacement = 180;
        this.parent.pivot.x = -this.lowerGateDisplacement;
      }
    }

    const dy = -5;
    let slot = this.collectorSlots[3];
    const pos1 = { x: 30, y: (slot?.y || 0) + dy } as Point;
    slot = this.collectorSlots[4];
    const pos2 = { x: 30, y: (slot?.y || 0) + dy } as Point;
    this.gates.setPositions(pos1, pos2);

    slot = this.collectorSlots[8];
    const pos3 = { x: 40, y: (slot?.y || 0) + dy } as Point;
    slot = this.collectorSlots[9];
    const pos4 = { x: 40, y: (slot?.y || 0) + dy } as Point;
    this.extraGates.setPositions(pos3, pos4);
  }

  protected addEvents() {
    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => {
      this.reset();
    });

    eventManager.on(EventTypes.COLLECT_PATTERN, (pattern: ReelsPattern) => {
      this.addCombo(pattern);
      AudioApi.play({ type: ISongs.SFX_Block_Land_In_Collector });
    });

    eventManager.on(EventTypes.COLLECT_INSTANT_WIN, () => {
      const pattern: ReelsPattern = {
        type: 'coin',
        count: 1,
        wild: [],
      };

      this.addCombo(pattern);
      AudioApi.play({ type: ISongs.SFX_Block_Land_In_Collector });
    });

    eventManager.on(EventTypes.OPEN_LOWER_GATE, () => {
      this.lowerGateOpened = true;
      if (this.isPortrait) {
        this.lowerGateDisplacement = 180;
        this.lowerGateDisplacementAnimation();
      }
    });
    eventManager.on(EventTypes.CLOSE_LOWER_GATE, () => {
      this.lowerGateOpened = false;
      this.lowerGateDisplacement = 0;
      this.lowerGateDisplacementAnimation();
    });
  }

  protected addGraphics() {
    const frameTexture = utils.TextureCache['maingame_collector_vertical_resource.png'];
    const frame = new Sprite(frameTexture);
    frame.anchor.set(0.5);
    frame.y = 20;

    const extraFrameTexture = utils.TextureCache['extra_collector_big.png'];
    const extraFrame = new Sprite(extraFrameTexture);
    extraFrame.x = 220;
    extraFrame.anchor.set(0.5);
    extraFrame.visible = false;
    this.extraSlotsContainer = extraFrame;

    let distance = 176;
    const startY = -435;
    for (let i = 0; i < this.slotsCount; i++) {
      const slot = new ComboCollectorSlot();
      slot.defaultX = 45;
      slot.x = slot.defaultX;
      slot.y = startY + distance * i;

      this.defaultSlots.push(slot);
    }
    distance = 177;
    // skip 1 slot
    for (let i = 1; i <= this.extraSlotsCount; i++) {
      const slot = new ComboCollectorSlot();
      slot.defaultX = 55;
      slot.x = slot.defaultX;
      slot.y = startY + distance * i - 137;

      this.extraSlots.push(slot);
    }

    // bottom to top
    this.arrangeSlots('landscape');

    this.addChild(extraFrame, frame, ...this.collectorSlots, this.gates, this.multiplierContainer);
    extraFrame.addChild(...this.extraSlots, this.extraGates);
  }

  protected arrangeSlots(arrangementType: string) {
    switch (arrangementType) {
      case 'landscape': {
        const defaultSlots = [...this.defaultSlots];
        const extra = [...this.extraSlots];
        this.collectorSlots = [...defaultSlots.reverse(), ...extra.reverse()];
        break;
      }
      case 'portrait':
        this.collectorSlots = [...this.defaultSlots, ...this.extraSlots];
        break;
      case 'default':
        const defaultSlots = [...this.defaultSlots];
        const extra = [...this.extraSlots];
        this.collectorSlots = [...defaultSlots.reverse(), ...extra.reverse()];
        break;
    }

    for (const i in this.collectorSlots) {
      const slot = this.collectorSlots[i] as ComboCollectorSlot;
      slot.reset();

      const data = this.slotsData[i];
      if (data) {
        slot.setSymbol(data, false);
      }
    }
  }

  protected reset() {
    for (const slot of this.collectorSlots) {
      slot.hideAnimation();
    }
    if (this.collectedTypesCount >= this.slotsCount) {
      this.hideExtraSlots();
    }

    this.gates.closeGates();
    this.extraGates.closeGates();

    this.collectedTypesCount = 0;

    this.slotsData = [];

    this.reservedSlots = [];
  }

  protected findEmptySlot(type: string, reserved = false): ComboCollectorSlot {
    // existing slot
    let slot: ComboCollectorSlot = this.collectorSlots.find(
      (s) => s.symbolName === type && !s.reserved,
    ) as ComboCollectorSlot;
    // reserved empty slot
    if (!slot) {
      slot = this.reservedSlots.find((x) => x.futureType === type)?.slot as ComboCollectorSlot;
    }
    // non reserved empty slot
    if (!slot) {
      slot = this.collectorSlots.find((s) => s.symbolName === '' && !s.reserved) as ComboCollectorSlot;
    }

    if (reserved) {
      this.reservedSlots.push({ slot: slot, futureType: type });
    }
    slot.reserved = reserved;

    return slot;
  }

  public predictGlobalPosition(type: string, reserved = false): Point {
    const slot = this.findEmptySlot(type, reserved);

    return slot.toGlobal(new Point(0, 0));
  }

  public predictPosition(type: string, reserved = false) {
    const slot = this.findEmptySlot(type, reserved);

    return { x: slot?.x, y: slot?.y };
  }

  protected addCombo(combination: ReelsPattern) {
    const slotIndex = this.collectorSlots.findIndex((c) => c.symbolName === combination.type);
    let slot = this.collectorSlots[slotIndex];
    if (slot) {
      slot.increment(combination.count);
      const data = { type: slot?.symbolName, count: slot?.count, wild: combination.wild } as ReelsPattern;
      this.slotsData[slotIndex] = data;
    } else {
      const combo = { ...combination };
      combo.count = combination.count;
      slot = this.collectorSlots.find((c) => c.symbolName === '');
      slot?.setSymbol(combo);
      this.collectedTypesCount++;

      const data = { type: slot?.symbolName, count: slot?.count, wild: combination.wild } as ReelsPattern;
      this.slotsData.push(data);

      if (this.collectedTypesCount >= 3) {
        this.gates.openGate();
      }

      if (this.collectedTypesCount >= 8) {
        this.extraGates.openGate();
      }

      if (this.collectedTypesCount === this.slotsCount) {
        this.showExtraSlots();
      }
    }
  }
}

export default ComboCollectorContainer;

import i18n from 'i18next';
import * as _ from 'lodash';
import { Graphics, ITextStyle, Rectangle, Sprite, Text, Texture, filters, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureState, IBonus } from '../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../gql/cache';
import type { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { getBonusIdByFeature, normalizeCoins, showCurrency } from '../../utils';
import { modeButtonTextStyle, titleBaseTextStyle, totalCostTextStyle } from '../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { PopupTypes, eventManager } from '../config';
import type { LineSet } from '../d';

import { PopupController } from './PopupController';
import { Popup } from './popup';

class BuyFeaturePopup extends Popup {
  private readonly popupBg: Sprite;

  private readonly oneRowModeBtn: SpriteButton;

  private readonly allRowsModeBtn: SpriteButton;

  private readonly bonusModeBtn: SpriteButton;

  private readonly cancelBtn: SpriteButton;

  private readonly titleText: Text;

  private betAmountTitle: TextField;

  private betAmountBackplate: Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private readonly coinMultiplier: number;

  private currency = 'FUN';

  private readonly isNoFunds: boolean;

  private balance: number;

  private featureState: FeatureState | undefined;

  private readonly modeButtonHitArea: Rectangle;

  private disableBtnFilter = new filters.ColorMatrixFilter();

  constructor() {
    super();
    this.betSettings = getBetsSetting();

    this.x = 0;
    this.y = 0;

    this.visible = false;
    this.coinMultiplier = (setSlotConfig().lineSets[0] as LineSet).coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();

    this.disableBtnFilter.blackAndWhite(true);

    this.popupBg = this.initPopupBg();
    this.modeButtonHitArea = this.initBonusModeBtnHit();
    this.oneRowModeBtn = this.initOneRowModeBtn();
    this.allRowsModeBtn = this.initAllRowsModeBtn();
    this.bonusModeBtn = this.initBonusModeBtn();
    this.titleText = this.initTitle();
    this.betAmountTitle = this.initBetAmountTitle();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.cancelBtn = this.initCancelBtn();
    this.init();
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      PopupController.the.closeCurrentPopup();
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
    });
  }

  public override show(): void {
    super.show();
    this.closeAllAnimationsInSlot();
    this.visible = true;

    if (this.balance < this.getFeaturePrice(FeatureState.ONE_BONUS_LINE)) {
      this.oneRowModeBtn.disable();
    }

    if (this.balance < this.getFeaturePrice(FeatureState.ALL_BONUS_LINES)) {
      this.allRowsModeBtn.disable();
    }

    if (this.balance < this.getFeaturePrice(FeatureState.BONUS_GAME)) {
      this.bonusModeBtn.disable();
    }
  }

  public close(): void {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_POPUP);
  }

  private init(): void {
    // TODO: adjust to game scaling
    const size = 6000;
    const darkOverlay = new Graphics();
    darkOverlay.beginFill(0x000000, 0.8); // Dark color with 50% transparency
    darkOverlay.drawRect(-size / 2, -size / 2, size, size);
    darkOverlay.endFill();

    this.addChild(darkOverlay);
    this.addChild(this.popupBg);
    this.popupBg.addChild(this.oneRowModeBtn);
    this.popupBg.addChild(this.allRowsModeBtn);
    this.popupBg.addChild(this.bonusModeBtn);
    this.popupBg.addChild(this.titleText);
    this.popupBg.addChild(this.cancelBtn);
  }

  private initPopupBg(): Sprite {
    const texture = utils.TextureCache['menu_platebg.png'];
    const popupBg = new Sprite(texture);
    popupBg.name = 'popupBg';
    popupBg.x = 0;
    popupBg.y = 0;
    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private getFeaturePrice(featureState: FeatureState): number {
    return normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState));
  }

  private initBonusModeBtnHit(): Rectangle {
    const hitWidth = 650;
    const hitHeight = 140;
    const hitArea = new Rectangle(-hitWidth / 2 + 20, -hitHeight / 2 - 90, hitWidth, hitHeight);

    return hitArea;
  }

  private initOneRowModeBtn(): SpriteButton {
    const oneRowBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['menu_btn.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['menu_btn_hover.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['menu_btn_click.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['menu_btn.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleOneRowBtnClick.bind(this),
      onTouchStart: this.handleOneRowBtnClick.bind(this),
      textFiled: this.initOneRowText(),
    });
    oneRowBtn.name = 'oneRowModeBtn';
    oneRowBtn.y = 0;

    oneRowBtn.hitArea = this.modeButtonHitArea;

    oneRowBtn.scale.set(0.9);

    return oneRowBtn;
  }

  private initAllRowsModeBtn(): SpriteButton {
    const allRowsBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['menu_btn.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['menu_btn_hover.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['menu_btn_click.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['menu_btn.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleAllRowsBtnClick.bind(this),
      onTouchStart: this.handleAllRowsBtnClick.bind(this),
      textFiled: this.initAllRowsButtonText(),
    });
    allRowsBtn.name = 'allRowsModeBtn';
    allRowsBtn.y = 170;

    allRowsBtn.hitArea = this.modeButtonHitArea;

    allRowsBtn.scale.set(0.9);

    return allRowsBtn;
  }

  private initBonusModeBtn(): SpriteButton {
    const bonusModeBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['menu_btn.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['menu_btn_hover.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['menu_btn_click.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['menu_btn.png'] || Texture.EMPTY,
        textStyle: modeButtonTextStyle as Partial<ITextStyle>,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleBonusGameBtnClick.bind(this),
      onTouchStart: this.handleBonusGameBtnClick.bind(this),
      textFiled: this.initBonusGameButtonText(),
    });
    bonusModeBtn.name = 'bonusModeBtn';
    bonusModeBtn.y = 340;

    bonusModeBtn.hitArea = this.modeButtonHitArea;

    bonusModeBtn.scale.set(0.9);

    return bonusModeBtn;
  }

  private initTitle(): Text {
    const title = new Text(i18n.t<string>('buyFeatureTitle'), titleBaseTextStyle);

    title.y = -290;
    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initBetAmountTitle(): TextField {
    const betAmountTitle = new TextField(
      i18n.t<string>('buyFeatureBetPerGame'),
      400,
      100,
      totalCostTextStyle as Partial<ITextStyle>,
    );
    betAmountTitle.text.name = 'betAmountTitle';
    betAmountTitle.text.y = -25;
    betAmountTitle.text.x = 0;
    betAmountTitle.text.anchor.set(0.5, 0);

    return betAmountTitle;
  }

  private initBetAmountBackplate(): Sprite {
    const texture = utils.TextureCache['Text Rectangle_Largest.png'];
    const input = new Sprite(texture);
    input.name = 'betAmountBackplate';
    input.y = 40;
    input.x = 0;
    input.anchor.set(0.5);

    return input;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: utils.TextureCache['menu_x.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.HOVER]: {
        texture: utils.TextureCache['menu_x_hover.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.PRESSED]: {
        texture: utils.TextureCache['menu_x_click.png'] || Texture.EMPTY,
      },
      [SpriteButtonState.DISABLED]: {
        texture: utils.TextureCache['menu_x.png'] || Texture.EMPTY,
        filters: [this.disableBtnFilter],
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.name = 'cancelBtn';
    // cancelBtn.y = -250;
    // cancelBtn.x = 400;Ф
    cancelBtn.anchor.set(0.5, 0.5);

    const width = 100;
    const height = 100;
    const hitArea = new Rectangle(width / 2 + 400, height / 2 - 450, width, height);
    cancelBtn.hitArea = hitArea;

    return cancelBtn;
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private handleOneRowBtnClick(): void {
    this.changeFeatureState(FeatureState.ONE_BONUS_LINE);
    this.handleClickOk();
  }

  private handleAllRowsBtnClick(): void {
    this.changeFeatureState(FeatureState.ALL_BONUS_LINES);
    this.handleClickOk();
  }

  private handleBonusGameBtnClick(): void {
    this.changeFeatureState(FeatureState.BONUS_GAME);
    this.handleClickOk();
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.ENABLE_PAYTABLE);
    PopupController.the.closeCurrentPopup();
  }

  private initOneRowText(): TextField {
    const text = new TextField(i18n.t<string>('buyOneRow'), 500, 200, modeButtonTextStyle as Partial<ITextStyle>);
    text.text.anchor.set(0.5);
    text.text.y = -80;

    return text;
  }

  private initAllRowsButtonText(): TextField {
    const text = new TextField(i18n.t<string>('buyAllRows'), 500, 200, modeButtonTextStyle as Partial<ITextStyle>);
    text.text.anchor.set(0.5);
    text.text.y = -80;

    return text;
  }

  private initBonusGameButtonText(): TextField {
    const text = new TextField(i18n.t<string>('buyBonusGame'), 500, 200, modeButtonTextStyle as Partial<ITextStyle>);
    text.text.anchor.set(0.5);
    text.text.y = -80;

    return text;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      //this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    AudioApi.play({ type: ISongs.SFX_UI_General });
  }

  private getTotalCost = (featureState: FeatureState): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (featureState: FeatureState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount || 306; // TODO: why this is needed?
  };

  private handleClickOk = (): void => {
    AudioApi.play({ type: ISongs.SFX_UI_General });
    if (!this.isNoFunds && this.featureState) {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, {
        totalCost: this.getTotalCost(this.featureState),
        coinAmount: this.betSettings.bets[this.betAmount] as number,
        featureState: this.featureState,
      });
    }
  };

  private closeAllAnimationsInSlot(): void {
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  protected override resize(_width: number, _height: number): void {
    // const isLandscape = _width >= _height;
    // if (isLandscape) {
    //   this.scale.set(1);
    // } else {
    //   this.scale.set(0.75);
    // }
  }
}

export default BuyFeaturePopup;

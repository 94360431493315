import gsap from 'gsap';
import { Sprite, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';
import Slot from '../reels/slot';

class WildSlotContainer extends ViewContainer {
  private slotId: SlotId;
  private index: number;
  private lightsCount = 3;
  private counter: 0 | 1 | 2 | 3 = 0;

  private lights: Sprite;
  private winGlow: Sprite;
  private symbol: Slot;

  constructor(slotId: SlotId, index: number) {
    super();
    this.slotId = slotId;
    this.index = index;

    this.symbol = new Slot(0, this.slotId, false, false);
    this.symbol.pauseAnimations(true);

    this.lights = new Sprite(this.getLightsTexture());
    this.lights.anchor.set(0.5);

    const texture = utils.TextureCache[`wild_collector-${this.index}_glow.png`] as Texture;
    this.winGlow = new Sprite(texture);
    this.winGlow.anchor.set(0.5);
    this.winGlow.alpha = 0;

    this.addGraphics();

    eventManager.on(EventTypes.NEXT_CASCADE, () => {
      if (this.counter >= 3) {
        this.resetCounter();
      }
    });
  }

  private addGraphics() {
    this.symbol.anchor.set(0.5);
    this.symbol.scale.set(2 / 3, 2 / 3);
    const distance = 240;
    // approximately {distance} * {4 slots} / 2 +-20
    const startX = -distance * 2 + 31;
    this.symbol.x = startX + distance * this.index;
    this.symbol.y = -643;
    this.symbol.name = 'symbol';

    this.addChild(this.lights, this.symbol, this.winGlow);
  }

  private getLightsTexture(): Texture {
    if (this.counter > 0 && this.counter <= this.lightsCount) {
      const countIndex = this.counter - 1;
      return (
        utils.TextureCache[`collector-${this.index}/wild_collector-${this.index}_${countIndex}.png`] || Texture.EMPTY
      );
    } else {
      return Texture.EMPTY;
    }
  }

  public incrementCounter() {
    if (this.counter === this.lightsCount) return;

    this.counter++;

    this.lights.texture = this.getLightsTexture();
    this.lights.anchor.set(0.5);

    if (this.counter === this.lightsCount) {
      this.symbol.resumeAnimations();
      const blinkDuration = 0.2;
      const timeline = gsap.timeline({
        repeat: 1,
        onStart: () => {
          AudioApi.play({ type: ISongs.SFX_Bomb_Alarm });
        },
        onComplete: () => {
          gsap.delayedCall(0.1, () => {
            this.symbol.pauseAnimations(true);
            AudioApi.stop({ type: ISongs.SFX_Bomb_Alarm });
            eventManager.emit(EventTypes.WILD_ACTIVATE);
          });
        },
      });
      timeline
        .to(this.winGlow, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' })
        .to(this.winGlow, { alpha: 0, duration: blinkDuration, ease: 'bounceOut' });
      // .to(this.winGlow, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' });

      //eventManager.emit(EventTypes.WILD_ACTIVATE);
    }
  }

  public decrementCounter() {
    this.counter--;

    this.lights.texture = this.getLightsTexture();
    this.lights.anchor.set(0.5);

    gsap.to(this.winGlow, {
      alpha: 0,
      duration: 0.3,
    });
  }

  public resetCounter() {
    this.counter = 0;

    this.lights.texture = this.getLightsTexture();
    this.lights.anchor.set(0.5);

    gsap.to(this.winGlow, {
      alpha: 0,
      duration: 0.3,
    });
  }
}

export default WildSlotContainer;

import gsap from 'gsap';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setGameHistory,
  setIsFreeSpinsWin,
  setIsProcessToGame,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setSlotConfig,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { WinStages, eventManager } from '../../slotMachine/config';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import { getBetResult, getSpinResult, getWinStage, normalizeCoins, saveReelPosition } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public override enterInitState(_prevState: States): void {
    Logic.the.changeState(States.INTRO);
    return;

    // if (setBrokenGame()) {
    //   Logic.the.changeState(States.BROKEN_GAME);
    //   return;
    // }
    // Logic.the.changeState(States.IDLE);
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProcessToGame(true);
    }
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.initIntroScreen();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      //TODO fix broken game
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterBrokenGameState(_prevState: States): void {
    setIsProcessToGame(true);
    const bonus = setCurrentBonus();
    SlotMachine.the().onBrokenGame(bonus);
    Logic.the.changeState(States.TRANSITION);
    Logic.the.changeGameMode(bonus.gameMode, { bonus, immediate: true });
  }

  public override enterIdleState(prevState: States): void {
    eventManager.emit(EventTypes.BASE_GAME_IDLE);
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (prevState === States.INIT || prevState === States.INTRO || prevState === States.BROKEN_GAME) {
      // const debug = new Debug();
      // debug.x = 800;
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.fadeOut(2000, ISongs.BGM_BG_Melo_Loop);
      AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
    }, 20000);
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory();
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    if (AudioApi.isPlaying(ISongs.BGM_BG_Base_Loop)) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop, stopPrev: true });
    }
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    eventManager.emit(EventTypes.CLOSE_ALL_STARS);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    if (betResult.bet.data.features.cascade.length > 0) {
      Logic.the.changeState(States.WIN_PRESENTATION);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterWinPresentationState(_prevState: States): void {
    eventManager.once(EventTypes.END_CASCADE_FEATURE, () => {
      const betResult = getBetResult(setBetResult());
      const { cascade } = betResult.bet.data.features;
      const accumulatedWin = cascade.reduce((sum, current) => {
        return sum + current.winAmounts.reduce((x, y) => x + y, 0);
      }, 0);
      const multiplier = betResult.bet.data.features.winMultiplier;
      const totalWin = betResult.bet.result.winCoinAmount;
      if (multiplier > 1 && totalWin > 0) {
        gsap.delayedCall(1, () => {
          eventManager.emit(EventTypes.START_COUNT_UP, accumulatedWin, totalWin, 0);
        });

        eventManager.emit(EventTypes.START_MULTIPLIER_ANIMATION);
        eventManager.once(EventTypes.COUNT_UP_END, () => {
          gsap.delayedCall(0.5, () => {
            Logic.the.changeState(States.AFTER_WIN);
          });
        });
      } else {
        Logic.the.changeState(States.AFTER_WIN);
      }
    });

    eventManager.emit(EventTypes.START_CASCADE_FEATURE, getBetResult(setBetResult()).bet.data.features.cascade);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setWinAmount(winCoinAmount);
    setLastRegularWinAmount(winCoinAmount);
    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      const multiplier = normalizeCoins(winCoinAmount) / normalizeCoins(setBetAmount());
      if (multiplier > 7) {
        //AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
        return;
      }
      if (multiplier >= 5) {
        //AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
        return;
      }
      if (multiplier >= 3) {
        //AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
      }
    }
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    if (result.bet.data.bonuses.length > 0) {
      //console.log('enter free spin');
      // AudioApi.play({
      //   type: ISongs.SFX_Match_Bonus,
      //   stopPrev: true,
      // });
      // setIsFreeSpinsWin(true);
      // eventManager.emit(EventTypes.WIN_STAR);
      const [bonus] = result.bet.data.bonuses;
      setCurrentBonus({
        ...(bonus as UserBonus),
        isActive: true,
        currentRound: 0,
        roundsLeft: bonus?.rounds ?? 0,
      });
      // setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
      // setTimeout(() => {
      //   Logic.the.changeState(States.TRANSITION);
      //   Logic.the.changeGameMode(GameMode.FREE_SPINS, {
      //     bonus: bonus as UserBonus,
      //   });
      // }, 1000);
      //return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps): void {
    eventManager.emit(EventTypes.ENABLE_PAYTABLE);
    AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_STARS);

    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      if (Logic.the.state.name !== States.IDLE) return;

      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });

    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);

    if (prevGameMode === GameMode.FREE_SPINS || prevGameMode === GameMode.RAGE_MODE) {
      setWinAmount(setFreeSpinsTotalWin());
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitController(_nextGameMode: GameMode): void {
    AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
    AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
  }

  public override setResult(result: ISettledBet): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });

    result.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setBetResult(result);
  }

  protected handleHistory(): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];
    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
    saveReelPosition(betResult.bet.result.reelPositions);
  }
}

import gsap from 'gsap';

import AudioApi from '@phoenix7dev/audio-api';

import { Logic } from '..';
import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setBetResult,
  setBonusGameTotalWin,
  setBrokenGame,
  setCurrentBonus,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import { PopupTypes, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getBGMSoundByGameMode, getBetResult, getSpinResult } from '../../utils';
import { States } from '../config';

import { BonusModeController } from './BonusModeController';

export class BonusGameController extends BonusModeController {
  public override gameMode: GameMode = GameMode.BONUS_GAME;

  public static override the = new BonusGameController();

  public override enterIdleState(_prevState: States): void {
    if (_prevState === States.SPIN) {
      AudioApi.play({ type: getBGMSoundByGameMode(this.gameMode) });
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }

    setIsSpinInProgress(false);
    setIsSlotBusy(false);

    console.log(setCurrentBonus().currentRound, setCurrentBonus().rounds);
    if (this.isOutOfTurns()) {
      // eventManager.once(EventTypes.END_BONUS_SPINS, () => {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BASE_GAME);
      // });
      return;
    }

    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps | undefined): void {
    eventManager.emit(EventTypes.ENABLE_PAYTABLE);
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_STARS);
    if (prevGameMode === null) return;
    AudioApi.play({ type: getBGMSoundByGameMode(this.gameMode) });
    Logic.the.changeState(States.IDLE);

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);

    if (setBonusGameTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBonusGameTotalWin());
    }
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    if (betResult.paylines.length > 0) {
      Logic.the.changeState(States.WIN_PRESENTATION);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterWinPresentationState(_prevState: States): void {
    const betResult = getBetResult(setBetResult());
    const { paylines } = betResult;
    const accumulatedWin = paylines.reduce((sum, current) => {
      return sum + current.amount;
    }, 0);
    const multiplier = betResult.bet.data.features.winMultiplier;
    const totalWin = betResult.bet.result.winCoinAmount;

    eventManager.once(EventTypes.COUNT_UP_END, () => {
      if (multiplier > 1 && totalWin > 0) {
        gsap.delayedCall(1, () => {
          eventManager.emit(EventTypes.START_COUNT_UP, accumulatedWin, totalWin, 0);
        });

        eventManager.emit(EventTypes.START_BONUS_MULTIPLIER_ANIMATION);
        eventManager.once(EventTypes.COUNT_UP_END, () => {
          gsap.delayedCall(0.5, () => {
            Logic.the.changeState(States.AFTER_WIN);
          });
        });
      } else {
        Logic.the.changeState(States.AFTER_WIN);
      }
    });

    const payLines = betResult.paylines;

    let total = 0;
    for (const payLine of payLines) {
      total += payLine.amount;
    }

    if (total > 0) {
      eventManager.emit(EventTypes.START_COUNT_UP, 0, total, 0);
    } else {
      eventManager.emit(EventTypes.COUNT_UP_END);
    }
  }

  public override enterJingleState(_prevState: States): void {
    if (setBonusGameTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBonusGameTotalWin());
    }
    Logic.the.changeState(States.IDLE);
  }

  public override setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setBonusGameTotalWin(setBonusGameTotalWin() + result.bet.result.winCoinAmount);
    result.bet.result.spinResult = spinResult;
    setBetResult(result);
  }

  public override exitController(_nextGameMode: GameMode): void {
    setLastRegularWinAmount(setBonusGameTotalWin());
    AudioApi.stop({ type: ISongs.BGM_Bonus_Loop });
    if (setBrokenGame()) setBrokenGame(false);

    if (this.isOutOfTurns()) {
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    }

    PopupController.the.openPopup(PopupTypes.BONUS_FINISH);
  }
}

import { makeVar } from '@apollo/client';

import { getUserConfig } from '@phoenix7dev/utils-fe';

import { GameMode, IBonus, ISettledBet, IUserBalance, PopupOpeningTypes, UserBonus } from '../global.d';

import type { IBet, IConfig, ISlotConfig, IStressful } from './d';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setBrokenGame = makeVar<boolean>(false);

export const setBetResult = makeVar<ISettledBet | null>(null);

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  result: {
    reelPositions: [0, 0, 0, 0, 0, 0],
    winCoinAmount: 0,
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
  reelSet: null,
  data: {
    bonuses: [],
    features: {
      cascade: [],
      multiplier: 1,
      winMultiplier: 1,
      gameRoundStore: {
        reelState: {
          spinsLeftAtNumActiveRows: -1,
        },
      },
    },
  },
});

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setIsProcessToGame = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(20);

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinValue = makeVar<number>(1);

export const setCoinAmount = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

// todo replace with real backend logic
export const setBonusGameTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.BASE_GAME);

export const setPrevGameMode = makeVar<GameMode>(GameMode.BASE_GAME);

export const setCurrency = makeVar<string>('FUN');

export const setUserBalance = makeVar<IUserBalance>({
  balance: { amount: 0, currency: '' },
  clientId: '',
  id: '',
});

export const setCurrentBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  gameMode: GameMode.FREE_SPINS,
  data: {
    cascadeMultiplier: 1,
  },
  coinAmount: 1,
  bonusId: '',
  roundsPlayed: 0,
  currentRound: 0,
  rounds: 8,
  roundsLeft: 8,
  reelSetId: '',
  totalWinAmount: 0,
  cascadeMultiplier: 1,
  betId: '',
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  previewImage: '',
  settings: {
    betLines: { max: 0, min: 0 },
    playerSettings: {
      betAmount: { min: 1, max: 1 },
      coinValue: {
        EUR: { min: 1, max: 1 },
        FUN: {
          min: 100,
          max: 100,
        },
      },
    },
    startPosition: [33, 17, 11, 19, 64],
  },
  id: '39573239-f13c-4a31-8a9e-f8dc5a010ab1', // 39573239-f13c-4a31-8a9e-f8dc5a010ab1 //'9776a55d-ac49-401b-9a9e-691b4b2c7683',
  lineSets: [
    {
      id: '',
      slotId: '',
      lines: [],
      coinAmountMultiplier: 0,
    },
  ],
  clientSettings: {
    coinAmounts: {
      default: [],
      quick: [],
    },
    coinValues: [],
    features: [],
    autoplay: {
      options: [],
      conditions: {
        stopOnAnyWin: {
          enabled: false,
        },
        stopIfFeatureIsWon: {
          enabled: false,
        },
        stopIfSingleWinExceeds: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceDecreasesBy: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceIncreasesBy: {
          enabled: false,
          multipliers: [],
        },
      },
    },
  },
  sessionId: '',
  reels: [],
  icons: [],
  isBuyFeatureEnabled: true,
});

export const setBonuses = makeVar<IBonus[]>([]);

export const setGameHistory = makeVar<boolean[]>([false, false, false]);

export const setCurrentIsTurboSpin = makeVar<boolean>(false);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsPopupOpened = makeVar<boolean>(false);
export const setIsOpenInfoPopup = makeVar<boolean>(false);
export const setIsOpenHistoryPopup = makeVar<boolean>(false);
export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);
export const setIsOpenAutoplayPopup = makeVar<boolean>(false);
export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);
export const setIsDuringBigWinLoop = makeVar<boolean>(false);
export const setIsSoundLoading = makeVar<boolean>(false);
export const setPhoenixAnticipation = makeVar<boolean>(false);

export const setIsErrorMessage = makeVar<boolean>(false);
export const setIsTimeoutErrorMessage = makeVar<boolean>(false);

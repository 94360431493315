import { Filter, ITextStyle, Sprite, Texture, isMobile } from 'pixi.js';

import type { TextField } from './TextField';

export enum SpriteButtonState {
  DEFAULT,
  HOVER,
  PRESSED,
  DISABLED,
  ACTIVE,
}
export interface SpriteButtonProps {
  [SpriteButtonState.DEFAULT]: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
    filters?: Filter[];
  };
  [SpriteButtonState.HOVER]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
    filters?: Filter[];
  };
  [SpriteButtonState.PRESSED]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
    filters?: Filter[];
  };
  [SpriteButtonState.DISABLED]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
    filters?: Filter[];
  };
  onClick?: () => void;
  onHover?: () => void;
  onTouchStart?: () => void;
  textFiled?: TextField;
}

export type StateFilterMapping = { [key in SpriteButtonState]?: Filter[] | undefined };

export class SpriteButton extends Sprite {
  protected state: SpriteButtonState = SpriteButtonState.DEFAULT;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected textures: { [key in SpriteButtonState]?: Texture };

  protected onClick: (() => void) | undefined;

  protected onHover: (() => void) | undefined;

  protected onTouchStart: (() => void) | undefined;

  protected textField?: TextField;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected textStyles?: { [key in SpriteButtonState]?: Partial<ITextStyle> | undefined };

  protected stateFilters?: StateFilterMapping;

  constructor(props: SpriteButtonProps) {
    super();
    this.anchor.set(0.5, 0.5);
    this.buttonMode = true;
    this.interactive = true;
    this.textures = {
      [SpriteButtonState.DEFAULT]: props[SpriteButtonState.DEFAULT].texture,
      [SpriteButtonState.HOVER]: props[SpriteButtonState.HOVER]
        ? props[SpriteButtonState.HOVER]!.texture
        : props[SpriteButtonState.DEFAULT].texture,
      [SpriteButtonState.PRESSED]: props[SpriteButtonState.PRESSED]
        ? props[SpriteButtonState.PRESSED]!.texture
        : props[SpriteButtonState.DEFAULT].texture,
      [SpriteButtonState.DISABLED]: props[SpriteButtonState.DISABLED]
        ? props[SpriteButtonState.DISABLED]!.texture
        : props[SpriteButtonState.DEFAULT].texture,
    };
    this.textField = props.textFiled as TextField;
    this.textStyles = {
      [SpriteButtonState.DEFAULT]: props[SpriteButtonState.DEFAULT].textStyle,
      [SpriteButtonState.HOVER]: props[SpriteButtonState.HOVER]
        ? props[SpriteButtonState.HOVER]!.textStyle
        : props[SpriteButtonState.DEFAULT].textStyle,
      [SpriteButtonState.PRESSED]: props[SpriteButtonState.PRESSED]
        ? props[SpriteButtonState.PRESSED]!.textStyle
        : props[SpriteButtonState.DEFAULT].textStyle,
      [SpriteButtonState.DISABLED]: props[SpriteButtonState.DISABLED]
        ? props[SpriteButtonState.DISABLED]!.textStyle
        : props[SpriteButtonState.DEFAULT].textStyle,
    };

    this.stateFilters = {
      [SpriteButtonState.DEFAULT]: props[SpriteButtonState.DEFAULT].filters,

      [SpriteButtonState.HOVER]: props[SpriteButtonState.HOVER]?.filters
        ? props[SpriteButtonState.HOVER]!.filters
        : props[SpriteButtonState.DEFAULT].filters,
      [SpriteButtonState.PRESSED]: props[SpriteButtonState.PRESSED]?.filters
        ? props[SpriteButtonState.PRESSED]!.filters
        : props[SpriteButtonState.DEFAULT].filters,
      [SpriteButtonState.DISABLED]: props[SpriteButtonState.DISABLED]?.filters
        ? props[SpriteButtonState.DISABLED]!.filters
        : props[SpriteButtonState.DEFAULT].filters,
    };
    this.onHover = props.onHover;
    this.onClick = props.onClick;
    this.onTouchStart = props.onTouchStart;
    this.changeState(SpriteButtonState.DEFAULT);
    this.on('click', this.onClickCallback.bind(this));
    this.on('touchstart', this.onTouchStartCallback.bind(this));
    this.on('mouseover', this.onMouseOverCallback.bind(this));
    this.on('mouseout', this.onMouseOutCallback.bind(this));
    this.on('mousedown', this.onMouseDownCallback.bind(this));
    this.on('mouseup', this.onMouseUpCallback.bind(this));
    if (this.textField) {
      this.addChild(this.textField.getText());
      this.textField.setStyle(this.textStyles![SpriteButtonState.DEFAULT]!);
    }
  }

  protected onClickCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.DEFAULT);
    if (this.onClick) {
      this.onClick();
    }
  }

  protected onTouchStartCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.onTouchStart) {
      this.onTouchStart();
    }
  }

  protected onMouseOverCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.HOVER);
    if (this.onHover) {
      this.onHover();
    }
  }

  protected onMouseOutCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.DEFAULT);
  }

  protected onMouseDownCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.PRESSED);
  }

  protected onMouseUpCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(isMobile.any ? SpriteButtonState.DEFAULT : SpriteButtonState.HOVER);
  }

  public disable(): void {
    this.changeState(SpriteButtonState.DISABLED);
  }

  public enable(): void {
    this.changeState(SpriteButtonState.DEFAULT);
  }

  protected changeState(state: SpriteButtonState): void {
    this.state = state;
    this.texture = this.textures[state as SpriteButtonState]!;
    if (this.textField && this.textStyles) {
      this.textField.setStyle(this.textStyles[state as SpriteButtonState]!);
    }

    if (this.stateFilters) {
      this.filters = this.stateFilters[state as SpriteButtonState]! || [];
    }
  }
}

import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { gameRules1, gameRules2 } from '../../config/gameRules';
import { setSlotConfig, setUserBalance } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation();
  const userBalance = useReactiveVar(setUserBalance);
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  const betSettings = getBetsSetting();
  const { currency } = userBalance.balance;
  const minBet = formatNumber({ currency: currency, value: betSettings.minBetCount, showCurrency: false });
  const maxBet = formatNumber({ currency: currency, value: betSettings.maxBetCount, showCurrency: false });

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoGameRulesTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {gameRules1
            .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
            .map((v) => (
              <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
            ))}
        </div>
        <div className={styles['col']}>
          {gameRules2
            .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
            .map((v) => (
              <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
            ))}
        </div>
      </div>

      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules2', {
            minBet,
          }),
        }}
      />
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules3', {
            maxBet,
          }),
        }}
      />

      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules4', {
            rtp: '96.48%',
          }),
        }}
      />
      {setSlotConfig().isBuyFeatureEnabled && (
        <>
          <p
            className={`${styles['p']} ${styles['center']}`}
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules5', {
                rtp: '97.22%',
              }),
            }}
          />
          {/*<p*/}
          {/*  className={`${styles['p']} ${styles['center']}`}*/}
          {/*  dangerouslySetInnerHTML={{*/}
          {/*    __html: t('infoGameRules6', {*/}
          {/*      rtp: 'infoTBD',*/}
          {/*    }),*/}
          {/*  }}*/}
          {/*/>*/}
        </>
      )}
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRules7', {
            version: window.__ENV__.APP_VERSION,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import {
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastRegularWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';
import { getBGMSoundByGameMode, getBetResult } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export abstract class BonusModeController extends BaseController {
  protected constructor() {
    super();
  }

  public override enterIdleState(_prevState: States): void {
    eventManager.emit(EventTypes.BASE_GAME_IDLE);
    if (_prevState === States.SPIN) {
      AudioApi.play({ type: getBGMSoundByGameMode(this.gameMode) });
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }

    if (
      _prevState === States.INIT ||
      _prevState === States.INTRO ||
      _prevState === States.BROKEN_GAME ||
      _prevState === States.TRANSITION
    ) {
      return;
    }

    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    this.handleHistory();

    console.log(setCurrentBonus().currentRound, setCurrentBonus().rounds);
    if (this.isOutOfTurns()) {
      // eventManager.once(EventTypes.END_BONUS_SPINS, () => {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BASE_GAME);
      // });
      return;
    }

    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
  }

  protected isOutOfTurns(): boolean {
    const roundsLeft = setCurrentBonus().roundsLeft ?? 0;
    return roundsLeft <= 0;
  }
  public override enterSpinState(_prevState: States): void {
    super.enterSpinState(_prevState);
    if (_prevState === 5) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
      AudioApi.play({ type: ISongs.BGM_Bonus_Loop });
    }
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });

    console.log(setCurrentBonus().currentRound);
  }

  public override exitController(_nextGameMode: GameMode): void {
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    GameMode.BONUS_GAME && AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    AudioApi.stop({ type: ISongs.BGM_Bonus_Loop });
    if (setBrokenGame()) setBrokenGame(false);

    if (this.isOutOfTurns()) {
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
      eventManager.emit(EventTypes.END_BONUS_SPINS);
    }
  }
}

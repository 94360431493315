import { faker } from '@faker-js/faker';
import gsap from 'gsap';
import { ITrackEntry, Spine } from 'pixi-spine';
import { type Container, type DisplayObject, Loader, type ObservablePoint, type Point, Text, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import type { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import SpriteAnimation from '../animations/sprite';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

// Define a type for properties that can be updated
// Define a type for properties that can be updated
type UpdatableProperties = {
  x?: number;
  y?: number;
  scale?: Point | ObservablePoint;
  rotation?: number;
  angle?: number;
  alpha?: number;
  visible?: boolean;
};

export type RandomEffectsConfig = {
  minDelay: number;
  maxDelay: number;
  positions?: {
    land: UpdatableProperties[];
    port?: UpdatableProperties[];
  };
  randomSoundEffects?: ISongs[];
};

const defaultConfig: RandomEffectsConfig = {
  minDelay: 3,
  maxDelay: 10,
};

export default class RandomEffectsContainer extends ViewContainer {
  public paused = true;

  private config: RandomEffectsConfig;
  private effectsList: (Container | Spine | SpriteAnimation)[];

  // show animation indexes on screen
  private readonly __DEBUG__ = false;

  private fadeAnimation: gsap.core.Tween | undefined;

  constructor(effectsList: (Container | Spine | SpriteAnimation)[], config: RandomEffectsConfig = defaultConfig) {
    super();

    this.config = config;
    this.effectsList = effectsList;
    this.interactiveChildren = false;
    this.interactive = false;

    let index = 0;
    for (const effect of effectsList) {
      let displayObject = effect as Container;
      if (displayObject instanceof SpriteAnimation) {
        displayObject = displayObject.spriteAnimation;
      }

      if (this.__DEBUG__) {
        const indexText = new Text('' + index);
        indexText.style.fill = '#66ff00';
        displayObject.addChild(indexText);
      }
      this.addChild(displayObject);

      index++;
    }

    this.setUpAnimation();
    this.addEvents();
  }

  private addEvents() {
    eventManager.on(EventTypes.LOW_PERFORMANCE, () => {
      if (this.fadeAnimation) {
        this.fadeAnimation.kill();
      }
      this.fadeAnimation = gsap.to(this, {
        alpha: 0,
        duration: 1,
        onComplete: () => {
          this.visible = false;
        },
      });
    });

    eventManager.on(EventTypes.HIGH_PERFORMANCE, () => {
      if (this.fadeAnimation) {
        this.fadeAnimation.kill();
      }
      this.visible = true;
      this.fadeAnimation = gsap.to(this, {
        alpha: 1,
        duration: 1,
      });
    });
  }

  public static getSpineAnimation(spriteSheet: string, animationName: string) {
    const animation = new Spine(Loader.shared.resources[spriteSheet]!.spineData!);
    animation.state.setAnimation(0, animationName, true);
    animation.state.timeScale = 0;

    return animation;
  }

  public static getSpriteAnimation(spriteName: string, animationName: string) {
    const textureSheet = Loader.shared.resources[spriteName]!.spritesheet!;
    const texture = textureSheet.animations[animationName] as Texture[];
    const animation = new SpriteAnimation({ isLoop: false }, texture);
    animation.spriteAnimation.anchor.set(0.5, 0.5);
    return animation;
  }

  protected override resize(_width: number, _height: number): void {
    const isPortrait = _height > _width;

    if (!this.config.positions) {
      return;
    }

    let positions = this.config.positions.land;
    if (isPortrait && this.config.positions.port) {
      positions = this.config.positions.port;
    }

    for (const i in this.effectsList) {
      const effect = this.effectsList[i];
      const props = positions[i];
      let displayObject: DisplayObject = effect as Container;
      if (displayObject instanceof SpriteAnimation) {
        displayObject = displayObject.spriteAnimation;
      }

      if (props) {
        for (const propName in props) {
          if (Object.prototype.hasOwnProperty.call(props, propName)) {
            const key = propName as keyof UpdatableProperties;
            const value = props[key];

            // Check if the value is defined and assign it
            if (value !== undefined) {
              (displayObject[key] as unknown) = value;
            }
          }
        }
      }
    }
  }

  private isVisible() {
    return this.visible && this.alpha && this.parent.visible && this.parent.alpha;
  }

  private setUpAnimation() {
    const delta = this.config.maxDelay - this.config.minDelay;
    const randomDelay = this.config.minDelay + delta * Math.random();

    gsap.delayedCall(randomDelay, () => {
      this.setUpAnimation();

      if (this.paused && this.isVisible()) return;

      const targets = [faker.helpers.arrayElement(this.effectsList)];
      // chance to satrt 2 effects at the same time
      if (Math.random() < 0.3) {
        const target = faker.helpers.arrayElement(this.effectsList.filter((e) => !targets.includes(e))) as
          | Container
          | Spine
          | SpriteAnimation;
        if (target) {
          targets.push(target);
        }
      }

      for (const target of targets) {
        // random sound effects
        if (this.config.randomSoundEffects && this.config.randomSoundEffects.length > 0) {
          const soundEffect = faker.helpers.arrayElement(this.config.randomSoundEffects);
          this.paused ? AudioApi.stop({ type: soundEffect }) : AudioApi.play({ type: soundEffect });
        }
        // animations
        if (target instanceof SpriteAnimation) {
          target.spriteAnimation.gotoAndPlay(0);
        } else if (target instanceof Spine) {
          target.state.timeScale = 0.9 + 0.2 * Math.random();
          gsap.to(target, {
            alpha: 1,
            duration: 0.2,
          });

          const listener = {
            complete: (entity: ITrackEntry) => {
              entity.loop = true;
              target.state.timeScale = 0;
              // Remove this listener after it triggers
              target.state.removeListener(listener);

              gsap.to(target, {
                alpha: 0,
                duration: 0.5,
              });
            },
          };

          // Attach the onComplete listener
          target.state.addListener(listener);
        }
      }
    });
  }

  public randomEffectsStop() {
    this.paused = true;
    this.visible = false;
  }

  public randomEffectsPlay() {
    this.paused = false;
    this.visible = true;
  }
}

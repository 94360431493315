import gsap from 'gsap';
import i18n from 'i18next';
import { Application, Container } from 'pixi.js';

import { BONUS_GAME_WAIT_DELAY } from '../config';
import { Cascade, EventTypes, GameMode, ReelSet, UserBonus } from '../global.d';
import {
  setBetResult,
  setBrokenGame,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setStressful,
  setUserLastBetResult,
} from '../gql/cache';
import { Logic } from '../logic';
import { getBetResult, getCascadeColumns, isBuyFeatureEnabled } from '../utils';

import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import type { CascadeAnimation } from './animations/cascade/cascadeAnimation';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BigWinContainer } from './bigWinPresentation/bigWinContainer';
import BonusGameContainer from './bonusGameContainer/bonusGameContainer';
import BonusIndicatorContainer from './bonusIndicator/bonusIndicatorContainer';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import ComboCollectorContainer from './comboCollector/comboCollectorContainer';
import { PopupTypes, REELS_AMOUNT, eventManager } from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuBtn from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import type { ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import MultiplierContainer from './multiplierContainer/multiplierContainer';
import PhoenixAnticipation from './phoenixAnticipation/phoenixAnticipation';
import { PopupController } from './popups/PopupController';
import BonusEndPopup from './popups/bonusEndPopup';
import BonusInstructionsPopup from './popups/bonusInstructionsPopup';
import BuyFeaturePopup from './popups/buyFeaturePopup';
import BuyFeaturePopupConfirm from './popups/buyFeaturePopupConfirm';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsForegroundContainer from './reels/foregraound/reelsForeground';
import type Reel from './reels/reel';
import ReelsContainer from './reels/reelsContainer';
import type Slot from './reels/slot';
import SafeArea from './safeArea/safeArea';
import WildCollectorContainer from './wildCollector/wildCollectorContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private application: Application;

  public isStopped = false;

  private static slotMachine: SlotMachine;

  private bonusGameWait: gsap.core.Tween | undefined;

  public static initSlotMachine = (slotData: ISlotData): void => {
    SlotMachine.slotMachine = new SlotMachine(Logic.the.application, slotData);
  };
  reelsForegroundContainer: ReelsForegroundContainer;

  public static the(): SlotMachine {
    return SlotMachine.slotMachine;
  }

  public gameView: GameView;

  public reelsContainer: ReelsContainer;

  public comboCollectorContainer: ComboCollectorContainer;

  public wildCollectorContainer: WildCollectorContainer;

  public multiplierContainer: MultiplierContainer;

  public bonusIndicatorContainer: BonusIndicatorContainer;

  public bonusGameContainer: BonusGameContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  private constructor(application: Application, slotData: ISlotData) {
    this.application = application;
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    const reelSet = setUserLastBetResult().id
      ? slotData.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotData.reels[0];

    this.reelsForegroundContainer = new ReelsForegroundContainer();
    this.reelsContainer = new ReelsContainer((reelSet as ReelSet).layout, startPosition);
    this.comboCollectorContainer = new ComboCollectorContainer();
    this.wildCollectorContainer = new WildCollectorContainer();
    this.multiplierContainer = new MultiplierContainer();
    this.bonusIndicatorContainer = new BonusIndicatorContainer();
    this.bonusGameContainer = new BonusGameContainer();
    this.miniPayTableContainer = new MiniPayTableContainer(slotData.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(this.reelsContainer.getCurrentSpinResult());
    this.gameView = this.initGameView(slotData);
    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();
    this.initPixiLayers();
    this.application.stage.addChild(
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
    );
  }

  private initPopupContainer(): Container {
    const container = new Container();
    container.visible = true; //TODO: why this is set to true? It should be false
    container.name = 'buyFeaturePopup';
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    const bonusInstructions = new BonusInstructionsPopup();
    const bonusEnd = new BonusEndPopup();
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE, buyFeaturePopup);
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, buyFeaturePopupConfirm);
    PopupController.the.registerPopup(PopupTypes.BONUS_INSTRUCTIONS, bonusInstructions);
    PopupController.the.registerPopup(PopupTypes.BONUS_FINISH, bonusEnd);
    container.addChild(buyFeaturePopup, buyFeaturePopupConfirm, bonusInstructions, bonusEnd);
    return container;
  }

  private initPixiLayers(): void {
    const phoenixAnticipation = new PhoenixAnticipation();
    this.application.stage.addChild(
      new Background(),
      new Backdrop(EventTypes.OPEN_POPUP_BG, EventTypes.CLOSE_POPUP_BG),
      this.initSafeArea(),
      new BottomContainer(),
      new BigWinContainer(),
      new FadeArea(),
      phoenixAnticipation,
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotData: ISlotData): GameView {
    const gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(this.comboCollectorContainer),
      reelsBackgroundContainer: new ReelsBackgroundContainer(),
      reelsContainer: this.reelsContainer,
      comboCollectorContainer: this.comboCollectorContainer,
      wildCollectorContainer: this.wildCollectorContainer,
      multiplierContainer: this.multiplierContainer,
      bonusIndicatorContainer: this.bonusIndicatorContainer,
      bonusGameContainer: this.bonusGameContainer,
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
      reelsForegroundContainer: this.reelsForegroundContainer,
    });
    gameView.interactive = true;
    gameView.on('mousedown', () => eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION));
    gameView.on('touchstart', () => eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION));

    if (isBuyFeatureEnabled(slotData.clientSettings.features)) {
      gameView.mainContainer.addChild(new BuyFeatureBtn());
    }
    gameView.addChild(this.initPopupContainer());
    gameView.mainContainer.addChild(this.reelsForegroundContainer);
    return gameView;
  }

  public onBrokenGame(bonus: UserBonus): void {
    eventManager.emit(EventTypes.BROKEN_GAME, bonus);
    eventManager.emit(EventTypes.GAME_READY);
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      if (!setBrokenGame()) eventManager.emit(EventTypes.GAME_READY);
    });
    eventManager.addListener(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE, this.setCurrentResultMiniPayTable.bind(this));
    eventManager.addListener(EventTypes.START_CASCADE_FEATURE, this.startCascadeFeature.bind(this));
    eventManager.addListener(EventTypes.NEXT_CASCADE, this.nextCascade.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, SlotMachine.handleError);
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private static handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(cascade: CascadeAnimation | undefined): void {
    (this.reelsContainer.reels[REELS_AMOUNT - 1] as Reel).cascadeAnimation
      ?.getWaiting()
      .removeOnComplete(this.throwTimeoutError);

    cascade?.getWaiting().removeOnComplete(this.throwTimeoutError);

    this.bonusGameWait?.kill();
  }

  public spinSpinAnimation(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    const spinAnimation = this.getSpinAnimation();
    eventManager.emit(EventTypes.START_SPIN_ANIMATION);
    spinAnimation.start();
  }

  private getReelAnimations(): CascadeAnimation[] {
    const tweens: CascadeAnimation[] = [];

    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i as number] as Reel;
      const cascadeAnimation: CascadeAnimation = reel.createCascadeAnimation();
      cascadeAnimation.animationName = 'baseGameReel_' + i;
      tweens.push(cascadeAnimation);
    }

    return tweens;
  }

  private getSpinAnimation(): AnimationGroup {
    const animationGroup = new AnimationGroup();

    let reelAnimations: CascadeAnimation[];

    if (Logic.the.controller.gameMode === GameMode.BONUS_GAME) {
      // TODO: return 5 reel animations, move collect event, rename function
      reelAnimations = this.bonusGameContainer.spin();
    } else {
      reelAnimations = this.getReelAnimations();
    }

    for (let i = 0; i < reelAnimations.length; i++) {
      const cascadeAnimation: CascadeAnimation = reelAnimations[i] as CascadeAnimation;

      if (i === reelAnimations.length - 1) {
        cascadeAnimation.getWaiting().addOnChange(() => {
          if (setBetResult() && !Logic.the.isReadyForStop) {
            Logic.the.isReadyForStop = true;
            this.removeErrorHandler(cascadeAnimation);
            const betResult = getBetResult(setBetResult());

            const reelStop = betResult.bet.result.reelPositions.toString();
            console.log('Reels: ', reelStop);

            const cascadeColumnsServer = getCascadeColumns({
              reelPositions: betResult.bet.result.reelPositions,
              layout: betResult.bet.reelSet.layout,
              cascades: betResult.bet.data.features.cascade,
            });

            let event = EventTypes.SETUP_REEL_POSITIONS;
            if (Logic.the.controller.gameMode === GameMode.BONUS_GAME) {
              event = EventTypes.SETUP_BONUS_REEL_POSITIONS;
            }
            if (betResult.bet.data.features.cascadeColumns) {
              eventManager.emit(event, betResult.bet.data.features.cascadeColumns, Logic.the.isStoppedBeforeResult);
            } else {
              eventManager.emit(event, cascadeColumnsServer, Logic.the.isStoppedBeforeResult);
            }
          }
        });
        if (Logic.the.controller.gameMode !== GameMode.BONUS_GAME) {
          cascadeAnimation.getWaiting().addOnComplete(this.throwTimeoutError);
        } else {
          this.bonusGameWait = gsap.delayedCall(BONUS_GAME_WAIT_DELAY, () => {
            this.throwTimeoutError();
          });
        }
      }
      (this.reelsContainer.reels[i as number] as Reel).isPlaySoundOnStop = true;
      animationGroup.addAnimation(cascadeAnimation);
    }

    return animationGroup;
  }

  private startCascadeFeature(): void {
    const rowBetResult = setBetResult();
    const betResult = getBetResult(rowBetResult);
    const spinResult = this.reelsContainer.getCurrentSpinResult();

    const [cascade] = betResult.bet.data.features.cascade;
    if ((cascade as Cascade).isRandomWilds) {
      eventManager.emit(EventTypes.START_RANDOM_WILDS_ANIMATION, cascade, 1);
      return;
    }
    eventManager.emit(EventTypes.START_WIN_ANIMATION, spinResult, cascade, 0);
    const { winAmounts } = cascade as Cascade;
    const endCount = winAmounts.reduce((x, y) => x + y, 0);
    if (endCount > 0) {
      eventManager.emit(EventTypes.START_COUNT_UP, 0, endCount, 0);
    }
  }

  private nextCascade(id: number, isBonus?: boolean): void {
    const betResult = getBetResult(setBetResult());
    const { cascade } = betResult.bet.data.features;
    const chain = new AnimationChain({});
    if (Logic.the.controller.gameMode === GameMode.FREE_SPINS) {
      chain.appendAnimation(Tween.createDelayAnimation(1000));
    }

    let winPositions = (cascade[id - 1] as Cascade).winPositions;
    if (isBonus) {
      winPositions = [];
    }
    const resetAnimation = this.reelsContainer.createResetReelsAnimation(
      winPositions,
      (cascade[id - 1] as Cascade).extraWildPositions as number[],
    );
    if (!(cascade[id - 1] as Cascade).isRandomWilds) {
      const starId = cascade.filter((elem, index) => index < id && !elem.isRandomWilds).length - 1;
      if (Logic.the.controller.gameMode === GameMode.BASE_GAME && starId < 8) {
        eventManager.emit(EventTypes.OPEN_STAR, starId);
      }
      if (Logic.the.controller.gameMode === GameMode.FREE_SPINS) {
        eventManager.emit(EventTypes.OPEN_MULTIPLIER_STAR, starId);
      }
    }
    resetAnimation.addOnComplete(() => {
      eventManager.emit(EventTypes.UPDATE_SLOT_BLICKS);

      const spinResult = this.reelsContainer.getCurrentSpinResult();
      if (id >= cascade.length) {
        eventManager.emit(EventTypes.END_CASCADE_FEATURE);
        return;
      }
      if ((cascade[id as number] as Cascade).isRandomWilds) {
        eventManager.emit(EventTypes.START_RANDOM_WILDS_ANIMATION, cascade[id as number], id + 1);
        return;
      }
      const { winAmounts } = cascade[id as number] as Cascade;
      const prevWin = cascade.reduce((sum, current, index) => {
        return index >= id ? sum : sum + current.winAmounts.reduce((x, y) => x + y, 0);
      }, 0);
      eventManager.emit(EventTypes.START_WIN_ANIMATION, spinResult, cascade[id as number], id);
      const currentWin = winAmounts.reduce((x, y) => x + y, 0);
      if (currentWin > 0) {
        eventManager.emit(EventTypes.START_COUNT_UP, prevWin, prevWin + currentWin, id);
      }
    });
    chain.appendAnimation(resetAnimation);
    chain.start();
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x as number]!.slots[y as number]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public setCurrentResultMiniPayTable(): void {
    this.miniPayTableContainer.setSpinResult(this.reelsContainer.getCurrentSpinResult());
  }
}

export default SlotMachine;

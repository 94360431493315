export const FREE_SPINS_TITLE_POSITION_X = 414;
export const FREE_SPINS_TITLE_POSITION_Y = 30;
export const FREE_SPINS_TITLE_WIDTH = 0;
export const FREE_SPINS_TITLE_HEIGHT = 0;

export const FREE_SPIN_ANIMATION_SCALE = 1.2;
export const FREE_SPIN_ANIMATION_DELAY = 500;
export const FREE_SPIN_ANIMATION_LOOP = false;
export const FREE_SPIN_TITLE_TEXT_WIDTH = 400;
export const FREE_SPIN_TITLE_TEXT_HEIGHT = 55;
export const FREE_SPIN_SPINS_TEXT_WIDTH = 120;

export const FREE_SPINS_TEXT_X = 25;
export const FREE_SPINS_TEXT_Y = 318;
export const FREE_SPIN_TEXT_FRAME_WIDTH_AD = 640;
export const FREE_SPIN_TEXT_FRAME_WIDTH_A = 600;
export const FREE_SPIN_TEXT_FRAME_X = 75;
export const FREE_SPIN_TEXT_FRAME_Y = -15;
export const FREE_SPIN_ICON_X = -7;
export const FREE_SPIN_ICON_Y = -33;
export const FREE_SPIN_INIT_WIDTH_AD = 614;
export const FREE_SPIN_INIT_WIDTH_A = 590;

export const TILE_FLIP_LOOP_COUNT = 3;

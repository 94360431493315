import { TextStyle } from 'pixi.js';

import { Variables } from '../../config';

export const modeButtonTextStyle = new TextStyle({
  align: 'center',
  fill: '#F2B764',
  fontFamily: 'Myriad-Pro-Semibold',
  fontSize: 64,
  lineHeight: 60,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 340,
});

export const buyFeatureButtonTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAlpha: 0.2,
  dropShadowAngle: 0.6,
  dropShadowBlur: 2,
  dropShadowDistance: 4,
  fill: ['#CA9A55', '#D3A564', '#A98047'],
  fillGradientStops: [0.1, 0.5, 1],
  fontFamily: 'Myriad-Pro-Semibold',
  letterSpacing: -2,
  fontSize: 40,
  lineHeight: 32,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 3,
  whiteSpace: 'normal',
  wordWrapWidth: 340,
});

export const buyFeatureButtonDisabledTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAlpha: 0.2,
  dropShadowAngle: 0.6,
  dropShadowBlur: 2,
  dropShadowDistance: 4,
  fill: ['#806c53'],
  fillGradientStops: [0.1, 0.5, 1],
  fontFamily: 'Myriad-Pro-Semibold',
  letterSpacing: -2,
  fontSize: 40,
  lineHeight: 40,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 3,
  whiteSpace: 'normal',
  wordWrapWidth: 340,
});

export const titleBaseTextStyle = new TextStyle({
  align: 'center',
  fill: '#F2B764',
  fontFamily: 'Myriad-Pro-Semibold',
  fillGradientType: 1,
  fontSize: 72,
  lineHeight: 90,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  // stroke: '#DD1A7A',
  // strokeThickness: 10,
  lineJoin: 'round',
  whiteSpace: 'normal',
  wordWrapWidth: 500,
  trim: true,
});

export const betValueStyle = new TextStyle({
  align: 'center',
  fill: 'white',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 26,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const totalCostTextStyle = new TextStyle({
  align: 'center',
  fill: '#F2B764',
  fillGradientType: 1,
  fontFamily: 'Myriad-Pro-Semibold',
  fontSize: 54,
  miterLimit: 5,
  padding: 5,
  lineJoin: 'round',
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const totalCostValueTextStyle = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fillGradientType: 1,
  fontFamily: 'Myriad-Pro-Semibold',
  fontSize: 64,
  miterLimit: 5,
  padding: 5,
  // stroke: '#DD1A7A',
  // strokeThickness: 10,
  lineJoin: 'round',
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  trim: true,
});

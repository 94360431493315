import gsap from 'gsap';
import { DropShadowFilter } from 'pixi-filters';
import { BitmapText, Container, Graphics, Sprite, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes, GameMode, type ReelsPattern } from '../../global.d';
import { Logic } from '../../logic';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class ComboCollectorSlot extends ViewContainer {
  public defaultX = 45;
  public reserved = false;
  private combo: ReelsPattern | undefined;
  private symbol = new Sprite(Texture.EMPTY);
  private countText = new BitmapText('0', {
    fontName: 'prizeCounterSpriteSheet-export',
    fontSize: 120,
    letterSpacing: -14,
  });
  private outline = new Graphics();
  private countTextContainer = new Container();

  private combosWonCount = 0;
  private wildCollectors = ['C', 'D', 'E', 'F'];
  private isWildCollector = false;
  private defaultScale = [1, 1];

  private winGlowEffect = new Sprite();

  constructor() {
    super();

    this.x = this.defaultX;

    this.alpha = 0;

    this.addGraphics();
  }

  public get symbolName(): string {
    return this.combo?.type || '';
  }

  public get count(): number {
    return this.combo?.count || 0;
  }

  private addGraphics() {
    this.symbol.anchor.set(0.5);
    this.symbol.scale.set(0.82);

    this.countTextContainer.y = 5;
    this.countText.anchor.set(0, 0.5);
    this.countText.x = -85;
    this.countText.y = 35;

    this.outline.lineStyle(5, 0xd0c285, 0.5);
    const width = 105;
    const height = 80;
    this.outline.drawRect(0, -height / 2 + 55, width, height);
    this.outline.x = -width / 2 - 40;
    this.outline.width = 60;
    this.setCountText();

    const dropShadowFilter = new DropShadowFilter({ distance: 10, rotation: 135 });

    this.countText.filters = [dropShadowFilter];

    const winGlowTexture = utils.TextureCache['multiplier_cell_glow_active.png'];
    this.winGlowEffect = new Sprite(winGlowTexture);
    this.winGlowEffect.anchor.set(0.5);
    this.winGlowEffect.alpha = 0;

    this.countTextContainer.addChild(this.countText, this.outline);
    this.addChild(this.symbol, this.countTextContainer, this.winGlowEffect);
  }

  public setSymbol(pattern: ReelsPattern, isNew = true) {
    this.scale.set(...this.defaultScale);
    this.x = this.defaultX;
    this.alpha = 1;

    this.combo = pattern;
    const texture = Texture.from(MAPPED_SYMBOLS[this.symbolName as SlotId].default);
    if (texture) {
      this.symbol.texture = texture;
    } else {
      console.error('Unknown symbol: "', this.symbolName, '"');
    }

    if (this.wildCollectors.includes(this.symbolName)) {
      this.isWildCollector = true;
    }

    this.setCountText();

    this.combosWonCount = 1;

    if (!isNew) return;

    if (this.isWildCollector) {
      Logic.the.controller.gameMode !== GameMode.BONUS_GAME &&
        eventManager.emit(EventTypes.WILD_COLLECTOR, this.symbolName);
    }

    this.winEffect();
  }

  private setCountText() {
    if (this.combo) {
      const text = this.count.toString();
      this.countText.text = text;
      if (text.length === 1) {
        this.outline.width = 60;
      } else if (text.length === 2) {
        this.outline.width = 105;
      } else if (text.length === 3) {
        this.outline.width = 158;
      }
    }
  }

  private winEffect() {
    const blinkDuration = 0.2;
    const timeline = gsap.timeline({
      repeat: 1,
      onStart: () => {
        AudioApi.play({ type: ISongs.SFX_UI_MenuOpen, stopPrev: true });
      },
    });
    timeline
      .to(this.winGlowEffect, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' })
      .to(this.winGlowEffect, { alpha: 0, duration: blinkDuration, ease: 'bounceOut' });
  }

  public increment(count: number) {
    if (this.combo) {
      this.combo.count += count;
      this.setCountText();
      this.combosWonCount++;

      this.winEffect();

      if (this.isWildCollector) {
        Logic.the.controller.gameMode !== GameMode.BONUS_GAME &&
          eventManager.emit(EventTypes.WILD_COLLECTOR, this.symbolName);
      }
    }
  }

  public hideAnimation() {
    const duration = 0.4;
    gsap.to(this.scale, {
      x: this.scale.x * 0.3,
      y: this.scale.y * 0.3,
      duration: duration,
    });
    gsap.to(this, {
      x: 0,
      alpha: 0,
      duration: duration,
      onComplete: () => {
        this.reset();
      },
    });
  }
  public reset() {
    this.x = this.defaultX;
    this.combo = undefined;
    this.countText.text = '0';
    this.symbol.texture = Texture.EMPTY;
    this.alpha = 0;
    this.combosWonCount = 0;
    this.isWildCollector = false;
    this.reserved = false;
  }

  public rotateSlots(orientation: 'vertical' | 'horizontal') {
    if (orientation === 'vertical') {
      this.rotation = 0;
      this.defaultScale = [1, 1];
      this.countTextContainer.y = 0;
    } else {
      this.rotation = -Math.PI / 2;
      this.defaultScale = [-1, 1];
      this.countTextContainer.y = 5;
    }

    this.scale.set(...this.defaultScale);
  }
}

export default ComboCollectorSlot;

import _ from 'lodash';

import type { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BGM_BG_Base_Loop = 'BGM_BG_Base_Loop', // "steampunk_industry_sound_BG"
  BGM_BG_Melo_Loop = 'BGM_BG_Melo_Loop', // "steampunk_mainTheme03_Main_song_FlatBGmusic"
  BGM_Bonus_Loop = 'BGM_Bonus_Loop',
  // BGM_FS_Loop = 'BGM_FS_Loop',
  // BGM_RM_Loop = 'BGM_RM_Loop',
  //BigWin_End = 'BigWin_End',
  //BigWin_Loop = 'BigWin_Loop',
  BuyButton = 'BuyButton',
  //Cascade_Symbol_Explosion = 'Cascade_Symbol_Explosion',
  //FS_EntrancePopup = 'FS_EntrancePopup',
  //FS_ExitPopup = 'FS_ExitPopup',
  //FeatureWilds = 'FeatureWilds',
  //HighWin = 'HighWin',
  //MediumWin = 'MediumWin',
  //Multiplier_Popup = 'Multiplier_Popup',
  //Progressbar_Fill = 'Progressbar_Fill',
  //Progressbar_Off = 'Progressbar_Off',
  //Progressbar_Win = 'Progressbar_Win',
  //RM_EntrancePopup = 'RM_EntrancePopup',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End', // "SFX_SM_CountUp_End"
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop', // "SFX_SM_CountUp_Loop"
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin', // "SFX_UI_AutoSpin"
  SFX_UI_BetChange = 'SFX_UI_BetChange', // "SFX_UI_BetChange"
  SFX_UI_Close = 'SFX_UI_Close', // "SFX_UI_Close"
  SFX_UI_General = 'SFX_UI_General', // "SFX_UI_General"
  SFX_UI_Hover = 'SFX_UI_Hover', // "SFX_UI_Hover"
  SFX_UI_MaxBet = 'SFX_UI_MaxBet', // "SFX_UI_MaxBet"
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen', // "SFX_UI_MenuOpen"
  SFX_UI_SpinStart = 'SFX_UI_SpinStart', // "SFX_UI_SpinStart"
  SFX_UI_SpinStop = 'SFX_UI_SpinStop', // "SFX_UI_SpinStop"
  SFX_WIN_FeatureTrigger = 'SFX_WIN_FeatureTrigger', // "SFX_WIN_FeatureTrigger"
  SFX_Phoenix = 'SFX_Phoenix', //"010_38_Yocho_Phoenix"
  //SmallWin = 'SmallWin',
  //WildLanding = 'WildLanding',
  Win_Big = 'Win_Big',
  Win_Epic = 'Win_Epic',
  Win_Great = 'Win_Great',
  Win_Mega = 'Win_Mega',
  Win_Finish = 'Win_Finish',
  // new sounds
  SFX_Collapsing_Failing = 'SFX_Collapsing_Failing',
  SFX_Collector_Door_Open = 'SFX_Collector_Door_Open',
  SFX_Collector_Door_Close = 'SFX_Collector_Door_Close',
  SFX_Extra_Collector_Open = 'SFX_Extra_Collector_Open',
  SFX_Extra_Collector_Close = 'SFX_Extra_Collector_Close',
  SFX_Open_Extra_Row = 'SFX_Open_Extra_Row',
  SFX_Close_Extra_Row = 'SFX_Close_Extra_Row',
  SFX_Bonus_Spins_Counter = 'SFX_Bonus_Spins_Counter',
  SFX_Steam_01 = 'SFX_Steam_01',
  SFX_Steam_02 = 'SFX_Steam_02',
  SFX_Steam_03 = 'SFX_Steam_03',
  SFX_Steam_04 = 'SFX_Steam_04',
  SFX_Steam_Big_Slow_03 = 'SFX_Steam_Big_Slow_03',
  SFX_Block_Combine_In_Pattern = 'SFX_Block_Combine_In_Pattern',
  SFX_Block_Land_In_Collector = 'SFX_Block_Land_In_Collector',
  SFX_Symbols_Fly = 'SFX_Symbols_Fly',
  SFX_Bomb_Explode = 'SFX_Bomb_Explode',
  SFX_Bomb_Alarm = 'SFX_Bomb_Alarm',
  SFX_Bonus_Reel_Spinning_Loop = 'SFX_Bonus_Reel_Spinning_Loop',
  SFX_Bonus_Reel_Expand = 'SFX_Bonus_Reel_Expand',
  SFX_Bonus_Spin_End = 'SFX_Bonus_Spin_End',
  SFX_Bonus_Spin_Start = 'SFX_Bonus_Spin_Start',
  SFX_Custom_click = 'SFX_Custom_click',
  SFX_Coins_combine = 'SFX_Coins_combine',
  SFX_Extra_spin_match_and_collect = 'SFX_Extra_spin_match_and_collect',
  SFX_Match_Bonus = 'SFX_Match_Bonus',
  SFX_Popup_open_or_info_page_slide = 'SFX_Popup_open_or_info_page_slide',
  SFX_Missile_Alarm = 'SFX_Missile_Alarm',
  SFX_Synthetic_thunder_short = 'SFX_Synthetic_thunder_short',
}

export const audioSprite: TAudioSprite = {
  [ISongs.SFX_Phoenix]: [344000, 4033.33333333336],
  [ISongs.BGM_BG_Base_Loop]: [126000, 21183.67346938777, true],
  [ISongs.BGM_BG_Melo_Loop]: [149000, 144000, true],
  [ISongs.BGM_Bonus_Loop]: [33000, 40000, true],
  //[ISongs.BGM_FS_Loop]: [0, 0],
  //[ISongs.BGM_RM_Loop]: [0, 0], // [0, 0, true],
  // [ISongs.BigWin_End]: [0, 0],
  //[ISongs.BigWin_Loop]: [0, 12000, true],
  [ISongs.BuyButton]: [3000, 929.7505668934241],
  // [ISongs.Cascade_Symbol_Explosion]: [0, 0],
  // [ISongs.FS_EntrancePopup]: [0, 0],
  // [ISongs.FS_ExitPopup]: [0, 0],
  // [ISongs.FeatureWilds]: [0, 0],
  // [ISongs.HighWin]: [0, 0],
  // [ISongs.MediumWin]: [0, 0],
  // [ISongs.Multiplier_Popup]: [0, 0],
  // [ISongs.Progressbar_Fill]: [0, 0],
  // [ISongs.Progressbar_Off]: [0, 0],
  // [ISongs.Progressbar_Win]: [0, 0],
  // [ISongs.RM_EntrancePopup]: [0, 0],
  [ISongs.SFX_SM_CountUp_End]: [350000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [352000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [354000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [356000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [358000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [360000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [362000, 151.76870748300075],
  [ISongs.SFX_UI_MaxBet]: [364000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [366000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [368000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [370000, 151.02040816327644],
  [ISongs.SFX_WIN_FeatureTrigger]: [372000, 1033.3333333333314],
  // [ISongs.SmallWin]: [0, 0],
  // [ISongs.WildLanding]: [0, 0],
  [ISongs.Win_Big]: [0, 12000, true],
  [ISongs.Win_Epic]: [94000, 12000, true],
  [ISongs.Win_Great]: [113000, 12000, true],
  [ISongs.Win_Mega]: [299000, 12000, true],
  [ISongs.Win_Finish]: [339000, 4000],
  [ISongs.SFX_Collapsing_Failing]: [86000, 578.0045351473859],
  [ISongs.SFX_Collector_Door_Open]: [91000, 1091.8367346938708],
  [ISongs.SFX_Collector_Door_Close]: [88000, 1200.0000000000027],
  [ISongs.SFX_Extra_Collector_Open]: [107000, 1833.3333333333285],
  [ISongs.SFX_Extra_Collector_Close]: [76000, 2321.3832199546546],
  [ISongs.SFX_Open_Extra_Row]: [312000, 2823.061224489777],
  [ISongs.SFX_Close_Extra_Row]: [80000, 1903.5147392290241],
  [ISongs.SFX_Bonus_Spins_Counter]: [318000, 439.1156462585286],
  [ISongs.SFX_Steam_01]: [320000, 948.7528344671432],
  [ISongs.SFX_Steam_02]: [322000, 654.6485260770964],
  [ISongs.SFX_Steam_03]: [324000, 1171.9274376417275],
  [ISongs.SFX_Steam_04]: [327000, 1048.9795918367406],
  [ISongs.SFX_Steam_Big_Slow_03]: [330000, 4000],
  [ISongs.SFX_Block_Combine_In_Pattern]: [13000, 1103.1292517006807],
  [ISongs.SFX_Block_Land_In_Collector]: [16000, 929.750566893425],
  [ISongs.SFX_Symbols_Fly]: [335000, 2199.9999999999886],
  [ISongs.SFX_Bomb_Explode]: [18000, 2000],
  [ISongs.SFX_Bomb_Alarm]: [21000, 490.6122448979602, true],
  [ISongs.SFX_Bonus_Reel_Spinning_Loop]: [23000, 314.10430839002146, true],
  [ISongs.SFX_Bonus_Reel_Expand]: [25000, 3000],
  [ISongs.SFX_Bonus_Spin_End]: [29000, 951.3605442176854],
  [ISongs.SFX_Bonus_Spin_Start]: [31000, 415.48752834466995],
  [ISongs.SFX_Custom_click]: [74000, 369.77324263038724],
  [ISongs.SFX_Coins_combine]: [83000, 1700.0000000000027],
  [ISongs.SFX_Extra_spin_match_and_collect]: [110000, 1846.6893424036216],
  [ISongs.SFX_Match_Bonus]: [294000, 3791.791383219959],
  [ISongs.SFX_Popup_open_or_info_page_slide]: [316000, 949.9999999999886],
  [ISongs.SFX_Missile_Alarm]: [375000, 497.0975056689326],
  [ISongs.SFX_Synthetic_thunder_short]: [377000, 4029.7732426303696],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Base_Loop]: 0.2,
  [ISongs.BGM_BG_Melo_Loop]: 0.12,
  [ISongs.BGM_Bonus_Loop]: 0.15,
  //[ISongs.BGM_FS_Loop]: 0.2,
  //[ISongs.BGM_RM_Loop]: 0.2,
  [ISongs.BuyButton]: 0.2,
  // [ISongs.Cascade_Symbol_Explosion]: 0.2,
  // [ISongs.FS_EntrancePopup]: 0.2,
  //[ISongs.BigWin_Loop]: 0.2,
  // [ISongs.BigWin_End]: 0.3,
  // [ISongs.FS_ExitPopup]: 0.3,
  // [ISongs.FeatureWilds]: 0.3,
  // [ISongs.HighWin]: 0.3,
  // [ISongs.MediumWin]: 0.3,
  // [ISongs.SmallWin]: 0.3,
  // [ISongs.WildLanding]: 0.8,
  // [ISongs.Multiplier_Popup]: 0.4,
  // [ISongs.Progressbar_Fill]: 0.4,
  // [ISongs.Progressbar_Off]: 0,
  // [ISongs.Progressbar_Win]: 0.3,
  // [ISongs.RM_EntrancePopup]: 0.3,
  [ISongs.Win_Big]: 0.2,
  [ISongs.Win_Mega]: 0.3,
  [ISongs.Win_Great]: 0.3,
  [ISongs.Win_Epic]: 0.4,
  [ISongs.Win_Finish]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.6,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.2,
  [ISongs.SFX_UI_SpinStop]: 0.2,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.05,
  [ISongs.SFX_Phoenix]: 0.5,
  [ISongs.SFX_Collapsing_Failing]: 1,
  [ISongs.SFX_Collector_Door_Open]: 1,
  [ISongs.SFX_Collector_Door_Close]: 5,
  [ISongs.SFX_Extra_Collector_Open]: 1,
  [ISongs.SFX_Extra_Collector_Close]: 1,
  [ISongs.SFX_Open_Extra_Row]: 1,
  [ISongs.SFX_Close_Extra_Row]: 1,
  [ISongs.SFX_Bonus_Spins_Counter]: 1,
  [ISongs.SFX_Steam_01]: 1,
  [ISongs.SFX_Steam_02]: 1,
  [ISongs.SFX_Steam_03]: 0.1,
  [ISongs.SFX_Steam_04]: 0.1,
  [ISongs.SFX_Steam_Big_Slow_03]: 0.1,
  [ISongs.SFX_Block_Combine_In_Pattern]: 0.2,
  [ISongs.SFX_Block_Land_In_Collector]: 0.6,
  [ISongs.SFX_Symbols_Fly]: 0.2,
  [ISongs.SFX_Bomb_Explode]: 1,
  [ISongs.SFX_Bomb_Alarm]: 0.2,
  [ISongs.SFX_Bonus_Reel_Spinning_Loop]: 1,
  [ISongs.SFX_Bonus_Reel_Expand]: 1,
  [ISongs.SFX_Bonus_Spin_End]: 0.4,
  [ISongs.SFX_Bonus_Spin_Start]: 0.5,
  [ISongs.SFX_Custom_click]: 1,
  [ISongs.SFX_Coins_combine]: 0.5,
  [ISongs.SFX_Extra_spin_match_and_collect]: 0.4,
  [ISongs.SFX_Match_Bonus]: 0.4,
  [ISongs.SFX_Popup_open_or_info_page_slide]: 1,
  [ISongs.SFX_Missile_Alarm]: 0.2,
  [ISongs.SFX_Synthetic_thunder_short]: 1,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { EventTypes, GameMode, GraphQLErrorsType, ISettledBet } from '../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentIsTurboSpin,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTurboSpin,
  setLastRegularWinAmount,
  setPhoenixAnticipation,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import type { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import { placeBetGql } from '../../gql/mutation';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getProgressGql,
  isStoppedGql,
  stressfulGql,
} from '../../gql/query';
import { Logic } from '../../logic';
import { eventManager } from '../../slotMachine/config';
import type { LineSet } from '../../slotMachine/d';
import { canPressSpin, checkPhoenixAnticipation, showCurrency } from '../../utils';

import type { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSets } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const lineSet = lineSets[0] as LineSet;
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const balanceAmount = userBalance?.balance.amount || 0;

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError(error) {
      eventManager.emit('placeBetCompleted');

      if (error.graphQLErrors.some((err) => err.extensions?.['type'] === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        Logic.the.handleInsufficientFunds();
      }
    },

    async onCompleted({ placeBet }) {
      eventManager.emit('placeBetCompleted');
      const bonusGameRoundsLeft = placeBet.bet.data.features.gameRoundStore.reelState.bonusState?.spinsLeft;
      const bonusLinesRoundsLeft = placeBet.bet.data.features.gameRoundStore.reelState.spinsLeftAtNumActiveRows;
      let roundsLeft = bonusGameRoundsLeft ?? bonusLinesRoundsLeft ?? 0;
      roundsLeft = Math.max(roundsLeft, 0);
      setCurrentBonus({
        ...setCurrentBonus(),
        roundsLeft: roundsLeft,
      });

      setCurrentIsTurboSpin(setIsTurboSpin());
      const showPhoenixAnticipation = checkPhoenixAnticipation(placeBet);
      if (showPhoenixAnticipation) {
        setPhoenixAnticipation(true);
        eventManager.emit(EventTypes.PHOENIX_ANTICIPATION_START);
        eventManager.once(EventTypes.PHOENIX_ANTICIPATION_END, () => {
          Logic.the.controller.setResult(placeBet);
          setUserLastBetResult(placeBet.bet);
          setPhoenixAnticipation(false);
        });
      } else {
        Logic.the.controller.setResult(placeBet);
        setUserLastBetResult(placeBet.bet);
      }
      if (Logic.the.isStopped()) {
        Logic.the.quickStop();
      }
    },
  });

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsPopup(false);
    setIsOpenAutoplayPopup(false);
    setIsOpenInfoPopup(false);
    setIsOpenHistoryPopup(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      clearTimeout(timeout);
      if (Logic.the.isReadyToStop()) {
        Logic.the.quickStop();
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
        return;
      }
      if (Logic.the.isReadyToSkip()) {
        Logic.the.skipWinAnimation();
        return;
      }
      if (Logic.the.isReadyToSpin()) {
        resetPopupsStateToClosed();
        setCurrentIsTurboSpin(isTurboSpin);
        setWinAmount(0);
        setLastRegularWinAmount(0);
        Logic.the.spin();

        if (Logic.the.controller.gameMode !== GameMode.BONUS_GAME) {
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
          );
        }

        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });

        const placeBetInput: IPlaceBetInput = {
          slotId,
          coinAmount: setCoinAmount(),
          coinValue: setCoinValue(),
          lineSetId: lineSet.id,
        };

        // TODO: debug
        const currentBonus = setCurrentBonus();
        console.log(currentBonus);
        if (currentBonus.id && currentBonus.isActive) {
          placeBetInput.userBonusId = currentBonus.id;
        }
        fnGet({
          variables: {
            input: placeBetInput,
          },
        });
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.play({ type: ISongs.SFX_UI_SpinStart, stopImmediately: [ISongs.SFX_UI_Close] });
      }
    },
    [dataBet?.betAmount, fnGet, lineSet, lineSet, slotId],
  );
  useEffect(() => {
    // const freeSpin = () => {
    //   setCurrentIsTurboSpin(setIsTurboSpin());
    //   clearTimeout(timeout);
    //   Logic.the.spin();
    //   client.writeQuery({
    //     query: isStoppedGql,
    //     data: {
    //       isSlotStopped: false,
    //     },
    //   });
    //   fnGet({
    //     variables: {
    //       input: {
    //         slotId,
    //         coinAmount: setCoinAmount(),
    //         coinValue: setCoinValue(),
    //         lineSetId: lineSet.id,
    //         userBonusId: setCurrentBonus().id,
    //       },
    //     },
    //   });
    //   setIsSpinInProgress(true);
    //   setIsSlotBusy(true);
    //   AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
    // };
    // eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    return () => {
      //   eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;
      if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (
      (Logic.the.controller.gameMode === GameMode.BASE_GAME ||
        Logic.the.controller.gameMode === GameMode.BUY_FEATURE) &&
      setIsFreeSpinsWin()
    ) {
      return;
    }
    onSpin(isTurboSpin);
  }, [isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();
        eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);

        if (
          !canPressSpin({
            gameMode: Logic.the.controller.gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            bonusCurrentRound: setCurrentBonus().currentRound,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isPopupOpened: false, //setIsPopupOpened(),
            isAutoPlay: false,
          })
        ) {
          return;
        }

        if (isAutoSpins) {
          Logic.the.skipWinAnimation();
          if (Logic.the.isReadyToStop()) {
            Logic.the.quickStop();
            client.writeQuery({
              query: isStoppedGql,
              data: {
                isSlotStopped: true,
              },
            });
          }
          return;
        }

        onSpin(isTurboSpin);
      }
    },
    [
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          if (!stressful?.stressful.show) {
            checkAutoSpinSettings();
          }
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [
    isAutoSpins,
    isFreeSpinsWin,
    checkAutoSpinSettings,
    dataSlotStopped?.isSlotStopped,
    isSlotBusy,
    stressful?.stressful,
  ]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return <></>;
};

export default Spin;

import gsap from 'gsap';
import { Point, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS, SlotId } from '../../config';
import { CollectorTargetTypes, EventTypes, type ReelsPattern } from '../../global.d';
import { ParticlesAnimation } from '../animations/particles';
import type ComboCollectorContainer from '../comboCollector/comboCollectorContainer';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';
import CoinRainAnimation from '../effects/coinRainAnimation';

import BonusSlot from './bonusSlot';

type collectTarget = {
  name: CollectorTargetTypes;
  getGlobalPosition: (position: Point) => Point;
};

export default class BonusWinAnimationContainer extends ViewContainer {
  private collector: ComboCollectorContainer;
  private targets: collectTarget[];

  private coinRainAnimation: CoinRainAnimation;

  constructor(collector: ComboCollectorContainer, targets: collectTarget[]) {
    super();

    this.collector = collector;
    this.targets = targets;

    this.coinRainAnimation = new CoinRainAnimation();
    this.addChild(this.coinRainAnimation);
    //this.coinRainAnimation.startSpawningCoins();
    this.addEvents();
  }

  private addEvents() {
    eventManager.addListener(EventTypes.START_COUNT_UP, () => {
      this.coinRainAnimation.startSpawningCoins();
    });
    eventManager.addListener(EventTypes.HIDE_COUNT_UP, () => {
      this.coinRainAnimation.stopSpawningCoins();
    });
  }

  public collectSlotsAnimation(slotsCollections: Partial<Record<SlotId, BonusSlot[]>>) {
    const collectTimeLine = gsap.timeline();
    const slots: BonusSlot[] = [];
    for (const i in slotsCollections) {
      const collection = slotsCollections[i as SlotId] as BonusSlot[];

      if (collection.length < 3) {
        console.error(`Error: Win formed with ${collection.length} symbols`);
      }

      let isExtraSpin = false;
      let isMultiplier = false;

      let toPos = new Point(0, 0);
      const slotId = (collection[0] as BonusSlot).slotId;
      if (slotId === SlotId.SC6) {
        const target = this.targets.find((t) => t.name === CollectorTargetTypes.bonusTurnsCounter);
        if (target) {
          toPos = this.toLocal(target.getGlobalPosition(new Point(0, 0)));
        }
        isExtraSpin = true;
      } else if (slotId === SlotId.SC5) {
        const target = this.targets.find((t) => t.name === CollectorTargetTypes.bonusMultiplier);
        if (target) {
          toPos = this.toLocal(target.getGlobalPosition(new Point(0, 0)));
        }
        isMultiplier = true;
      } else {
        toPos = this.toLocal(this.collector.predictGlobalPosition(slotId, true));
      }

      const slotGroup: BonusSlot[] = [];
      const collectSlotGroup: BonusSlot[] = [];
      for (const slot of collection) {
        const winSlot = new BonusSlot(0, slot.slotId);
        winSlot.name = 'winSlot';
        const pos = this.toLocal(slot.toGlobal(new Point(0, 0)));
        winSlot.position.set(pos.x, pos.y);
        winSlot.scale.set(slot.scale.x);
        winSlot.alpha = 0;
        this.addChild(winSlot);
        !isMultiplier && !isExtraSpin && this.collectQueueAnimation([slot], collectTimeLine, [winSlot], toPos);
        slots.push(winSlot);
        collectSlotGroup.push(winSlot);
        slotGroup.push(slot);
      }

      (isMultiplier || isExtraSpin) &&
        this.collectQueueAnimation(
          slotGroup,
          gsap.timeline({
            onComplete: () => {
              if (isExtraSpin) {
                eventManager.emit(EventTypes.BONUS_GAME_EXTRA_SPIN);
              }
              if (isMultiplier) {
                eventManager.emit(EventTypes.BONUS_GAME_MULTIPLIER);
              }
            },
          }),
          collectSlotGroup,
          toPos,
        );
    }

    return collectTimeLine;
  }

  private collectQueueAnimation(
    slots: BonusSlot[],
    collectTimeLine: gsap.core.Timeline,
    winSlots: BonusSlot[],
    toPos: Point,
  ) {
    // Assume a every batch is of the same type
    const slotId = (slots[0] as BonusSlot).slotId;
    const particle = utils.TextureCache[MAPPED_SYMBOLS[slotId].default] as Texture;
    const burstAnimations: ParticlesAnimation[] = [];
    for (let i = 0; i < winSlots.length; i++) {
      burstAnimations.push(new ParticlesAnimation('trail', [particle]));
    }
    this.addChild(...burstAnimations);

    collectTimeLine.to(winSlots, {
      x: toPos.x + 50,
      y: toPos.y,
      duration: 0.25,
      onStart: () => {
        //burstAnimation.emitter.autoUpdate = true;
        AudioApi.play({ type: ISongs.SFX_Block_Combine_In_Pattern });
        for (let i = 0; i < winSlots.length; i++) {
          const particle = burstAnimations[i];
          if (particle) {
            particle.emitter.autoUpdate = true;
          }
          const winSlot = winSlots[i] as BonusSlot;
          winSlot.alpha = 1;
          const slot = slots[i] as BonusSlot;
          slot.filters = [slot.collectedFilter];
          slot.alpha = 0.3;
          slot.pauseAnimations();
        }
      },
      onUpdate: () => {
        for (let i = 0; i < winSlots.length; i++) {
          AudioApi.play({ type: ISongs.SFX_Symbols_Fly });
          const winSlot = winSlots[i] as BonusSlot;
          const particle = burstAnimations[i];
          if (winSlot.parent && particle) {
            particle.emitter.updateOwnerPos(winSlot.x - 20, winSlot.y);
          }
        }
      },
      onComplete: () => {
        if (slotId !== SlotId.SC6 && slotId !== SlotId.SC5) {
          const combo: ReelsPattern = { type: slotId, count: 1, wild: [] };
          eventManager.emit(EventTypes.BONUS_GAME_COLLECT, combo);
        }

        for (let i = 0; i < winSlots.length; i++) {
          const winSlot = winSlots[i];
          const particle = burstAnimations[i];

          if (winSlot) {
            winSlot.destroy();
          }

          if (particle) {
            particle.emitter.autoUpdate = false;
            particle.destroy();
          }
        }
      },
    });
  }
}

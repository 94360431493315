import gsap from 'gsap';
import { BitmapText, Container, Loader, Sprite, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setBetResult } from '../../gql/cache';
import { Logic } from '../../logic';
import { getBetResult } from '../../utils';
import SpriteAnimation from '../animations/sprite';
import { ViewContainer } from '../components/ViewContainer';
import { MULTIPLIER, eventManager } from '../config';
import { TILE_FLIP_LOOP_COUNT } from '../gameView/config';

class MultiplierContainer extends ViewContainer {
  protected multiplier = 1;
  protected multiplierFontSize = 130;

  protected multiplierText = new BitmapText('x1_', { fontName: 'prizeCounterSpriteSheet-export' });
  protected winGlowEffect = new Sprite();
  protected holder = new Sprite();
  protected textHolder = new Container();

  protected tileFlipAnimation: SpriteAnimation;
  protected onesTileFlipAnimation: SpriteAnimation;

  protected applyMultiplierAnimation: gsap.core.Timeline;

  constructor() {
    super();

    this.tileFlipAnimation = this.initNumberFlipAnim();
    this.onesTileFlipAnimation = this.initNumberFlipAnim();
    this.onesTileFlipAnimation.spriteAnimation.x = 110;
    this.addGraphics();

    const blinkDuration = 0.2;
    this.applyMultiplierAnimation = gsap.timeline({ repeat: -1 });
    this.applyMultiplierAnimation
      .to(this.winGlowEffect, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' })
      .to(this.winGlowEffect, { alpha: 0, duration: blinkDuration, ease: 'bounceOut' });
    this.applyMultiplierAnimation.pause();

    this.addEvents();
  }

  protected override resize(_width: number, _height: number): void {
    super.resize(_width, _height);
    // default landscape
    this.x = -10;
    this.y = 510;
    this.rotation = 0;
    this.scale.set(0.95);

    this.holder.alpha = 0;

    this.winGlowEffect.x = 50;
    this.winGlowEffect.y = -50;
    this.winGlowEffect.scale.set(1);

    this.textHolder.x = 0;

    const isPortrait = _height > _width;

    if (isPortrait) {
      this.x = 90;
      this.y = 420;
      this.rotation = -Math.PI / 2;
      const scale = 0.95;
      this.scale.set(-scale, scale);

      this.holder.alpha = 1;

      this.winGlowEffect.x = 70;
      this.winGlowEffect.y = -50;
      this.winGlowEffect.scale.set(1.3, 1);

      this.textHolder.x = 30;
    }
  }

  protected addEvents() {
    // TODO: use 1 event
    eventManager.on(EventTypes.MULTIPLIER_TURN, () => {
      // TODO: stop spins of the main game and remove this condition
      if (Logic.the.controller.gameMode !== GameMode.BONUS_GAME) {
        this.increment();
      }
    });
    eventManager.on(EventTypes.COLLECT_MULTIPLIER, () => {
      // TODO: stop spins of the main game and remove this condition
      if (Logic.the.controller.gameMode !== GameMode.BONUS_GAME) {
        this.increment();
      }
    });
    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => {
      // TODO: stop spins of the main game and remove this condition
      if (Logic.the.controller.gameMode !== GameMode.BONUS_GAME) {
        this.setMultiplier(1);
      }
    });

    eventManager.on(EventTypes.UPDATE_MULTIPLIER, () => {
      this.setMultiplier(MULTIPLIER.current);
    });

    eventManager.on(EventTypes.START_MULTIPLIER_ANIMATION, () => {
      const betResult = getBetResult(setBetResult());
      const multiplier = betResult.bet.data.features.winMultiplier;
      this.setMultiplier(multiplier);
      this.applyMultiplier();
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        this.endApplyMultiplier();
      });
    });

    const loops = TILE_FLIP_LOOP_COUNT;
    let currentLoopOnes = 0;
    this.onesTileFlipAnimation.spriteAnimation.onLoop = () => {
      if (currentLoopOnes >= loops) {
        this.onesTileFlipAnimation.spriteAnimation.visible = false;
        this.onesTileFlipAnimation.spriteAnimation.stop();
        currentLoopOnes = 0;
      }

      currentLoopOnes++;
    };

    let currentLoopTens = 0;
    this.tileFlipAnimation.spriteAnimation.onLoop = () => {
      if (currentLoopTens >= loops) {
        this.tileFlipAnimation.spriteAnimation.visible = false;
        this.tileFlipAnimation.spriteAnimation.stop();
        currentLoopTens = 0;
      }

      currentLoopTens++;
    };
  }

  protected initNumberFlipAnim(): SpriteAnimation {
    const flipScale = 0.67;
    const tileFlipTensSheet = Loader.shared.resources['game']!.spritesheet!;
    const tileFlipTensTexture = tileFlipTensSheet.animations['prize_num_flip'] as Texture[];
    const tileFlipAnimation = new SpriteAnimation({ isLoop: true }, tileFlipTensTexture);
    tileFlipAnimation.spriteAnimation.anchor.set(0.5, 0.5);
    tileFlipAnimation.spriteAnimation.position.set(55, -45);
    tileFlipAnimation.spriteAnimation.scale.set(flipScale);
    tileFlipAnimation.spriteAnimation.animationSpeed = 0.2;
    tileFlipAnimation.spriteAnimation.visible = false;

    return tileFlipAnimation;
  }
  private addGraphics() {
    const bgTexture = utils.TextureCache['bonus_mutiplier_cutted.png'];
    this.holder.texture = bgTexture as Texture;
    this.holder.anchor.set(0.5);
    this.holder.position.set(110, -90);

    this.multiplierText.fontSize = this.multiplierFontSize;
    this.multiplierText.letterSpacing = -6;
    this.multiplierText.anchor.set(-1, 1);
    this.multiplierText.position.set(-200, -2);

    const winGlowTexture = utils.TextureCache['maingame_multiplier_glow.png'];
    this.winGlowEffect = new Sprite(winGlowTexture);
    this.winGlowEffect.anchor.set(0.5);
    this.winGlowEffect.alpha = 0;

    this.textHolder.addChild(
      this.multiplierText,
      this.tileFlipAnimation.spriteAnimation,
      this.onesTileFlipAnimation.spriteAnimation,
    );
    this.addChild(this.holder, this.textHolder, this.winGlowEffect);
  }

  private increment() {
    const blinkDuration = 0.2;
    const timeline = gsap.timeline({ repeat: 3 });
    timeline
      .to(this.winGlowEffect, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' })
      .to(this.winGlowEffect, { alpha: 0, duration: blinkDuration, ease: 'bounceOut' });

    this.setMultiplier(this.multiplier + 1);
  }

  private decrement() {
    if (this.multiplier > 1) {
      this.setMultiplier(this.multiplier - 1);
    }
  }

  protected setMultiplier(multiplier: number) {
    if (multiplier >= 1) {
      if (multiplier > this.multiplier) {
        const blinkDuration = 0.2;
        const timeline = gsap.timeline({ repeat: 3 });
        timeline
          .to(this.winGlowEffect, { alpha: 1, duration: blinkDuration, ease: 'bounceIn' })
          .to(this.winGlowEffect, { alpha: 0, duration: blinkDuration, ease: 'bounceOut' });
      }
      this.multiplier = multiplier;
      const padding = multiplier < 10 ? '_' : '';
      const text = `x${this.multiplier}${padding}`;
      if (this.multiplierText.text !== text) {
        AudioApi.play({ type: ISongs.SFX_Bonus_Spin_End });
      }
      if (this.multiplierText.text[1] !== text[1]) {
        this.tileFlipAnimation.spriteAnimation.visible = true;
        this.tileFlipAnimation.spriteAnimation.play();
      }
      if (this.multiplierText.text[2] !== text[2]) {
        this.onesTileFlipAnimation.spriteAnimation.visible = true;
        this.onesTileFlipAnimation.spriteAnimation.play();
      }
      this.multiplierText.text = text;
      MULTIPLIER.current = this.multiplier;
    }
  }

  protected applyMultiplier() {
    this.applyMultiplierAnimation.restart();
  }

  protected endApplyMultiplier() {
    this.applyMultiplierAnimation.pause();
    this.winGlowEffect.alpha = 0;
  }
}

export default MultiplierContainer;

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: string; freespin?: ResourceTypes }> = {
  [SlotId.WL]: { default: ResourceTypes.wL }, // WILD
  [SlotId.A]: { default: ResourceTypes.a }, // A
  [SlotId.K]: { default: ResourceTypes.k }, // K
  [SlotId.Q]: { default: ResourceTypes.q }, // Q
  [SlotId.J]: { default: ResourceTypes.j }, // J
  [SlotId.T]: { default: ResourceTypes.t }, // 10
  [SlotId.C]: { default: ResourceTypes.c }, // SPHERE
  [SlotId.D]: { default: ResourceTypes.d }, // PYRAMID
  [SlotId.E]: { default: ResourceTypes.e }, // CUBE
  [SlotId.F]: { default: ResourceTypes.f }, // CYLINDER
  [SlotId.SC1]: { default: ResourceTypes.sc1 }, // BONUS
  [SlotId.MS1]: { default: ResourceTypes.sc1 }, // BONUS
  [SlotId.SC2]: { default: ResourceTypes.sc2 }, // BOMB
  [SlotId.SC3]: { default: ResourceTypes.sc3 }, // IW UP
  [SlotId.SC4]: { default: ResourceTypes.sc4 }, // IW DOWN
  [SlotId.SC5]: { default: ResourceTypes.sc5 }, // MULTIPLIER
  [SlotId.SC6]: { default: ResourceTypes.sc6 }, // ?!? EXTRA SPIN
  [SlotId.coin]: { default: ResourceTypes.coin }, // IW FILL
  [SlotId.empty01]: { default: ResourceTypes.empty01 }, // empty
  [SlotId.empty02]: { default: ResourceTypes.empty02 }, // empty
  [SlotId.empty03]: { default: ResourceTypes.empty03 }, // empty
  [SlotId.empty04]: { default: ResourceTypes.empty04 }, // empty
  [SlotId.empty05]: { default: ResourceTypes.empty05 }, // empty
  [SlotId.empty06]: { default: ResourceTypes.empty06 }, // empty
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

// export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
//   [key in SlotId]: {
//     type: SymbolAnimationType;
//     src?: string;
//     animation?: string;
//   };
// } = {
//   [SlotId.A]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'a_symbol',
//     animation: 'win',
//   },
//   [SlotId.B]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'b_symbol',
//     animation: 'win',
//   },
//   [SlotId.C]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'c_symbol',
//     animation: 'hp_3_win',
//   },
//   [SlotId.D]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'd_symbol',
//     animation: 'hp_4_win',
//   },
//   [SlotId.E]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'e_symbol',
//     animation: 'hp_5_win',
//   },
//   [SlotId.F]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'f_symbol',
//     animation: 'win',
//   },
//   [SlotId.G]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'g_symbol',
//     animation: 'win',
//   },
//   [SlotId.H]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'h_symbol',
//     animation: 'lp_3_win',
//   },
//   [SlotId.I]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'i_symbol',
//     animation: 'lp_4_win',
//   },
//   [SlotId.J]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'j_symbol',
//     animation: 'win',
//   },
//   [SlotId.WL]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'wild',
//     animation: 'wild_win',
//   },
// };

// export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
//   [key in SlotId]: {
//     type: SymbolAnimationType;
//     src?: string;
//     animation?: string;
//   };
// } = {
//   [SlotId.A]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'a_symbol',
//     animation: 'land',
//   },
//   [SlotId.B]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'b_symbol',
//     animation: 'land',
//   },
//   [SlotId.C]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'c_symbol',
//     animation: 'hp_3_land',
//   },
//   [SlotId.D]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'd_symbol',
//     animation: 'hp_4_land',
//   },
//   [SlotId.E]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'e_symbol',
//     animation: 'hp_5_land',
//   },
//   [SlotId.F]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'f_symbol',
//     animation: 'land',
//   },
//   [SlotId.G]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'g_symbol',
//     animation: 'land',
//   },
//   [SlotId.H]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'h_symbol',
//     animation: 'lp_3_land',
//   },
//   [SlotId.I]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'i_symbol',
//     animation: 'lp_4_land',
//   },
//   [SlotId.J]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'j_symbol',
//     animation: 'land',
//   },
//   [SlotId.WL]: {
//     type: SymbolAnimationType.SPINE,
//     src: 'wild',
//     animation: 'wild_land',
//   },
// };

export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.a, src: Resources.getSource(ResourceTypes.a) },
  { name: ResourceTypes.k, src: Resources.getSource(ResourceTypes.k) },
  { name: ResourceTypes.q, src: Resources.getSource(ResourceTypes.q) },
  { name: ResourceTypes.j, src: Resources.getSource(ResourceTypes.j) },
  { name: ResourceTypes.t, src: Resources.getSource(ResourceTypes.t) },
  { name: ResourceTypes.c, src: Resources.getSource(ResourceTypes.c) },
  { name: ResourceTypes.d, src: Resources.getSource(ResourceTypes.d) },
  { name: ResourceTypes.e, src: Resources.getSource(ResourceTypes.e) },
  { name: ResourceTypes.f, src: Resources.getSource(ResourceTypes.f) },
  { name: ResourceTypes.wL, src: Resources.getSource(ResourceTypes.wL) },
  { name: ResourceTypes.sc1, src: Resources.getSource(ResourceTypes.sc1) },
  { name: ResourceTypes.sc2, src: Resources.getSource(ResourceTypes.sc2) },
  { name: ResourceTypes.sc3, src: Resources.getSource(ResourceTypes.sc3) },
  { name: ResourceTypes.sc4, src: Resources.getSource(ResourceTypes.sc4) },
  { name: ResourceTypes.sc5, src: Resources.getSource(ResourceTypes.sc5) },
  { name: ResourceTypes.sc6, src: Resources.getSource(ResourceTypes.sc6) },
  { name: ResourceTypes.coin, src: Resources.getSource(ResourceTypes.coin) },
  { name: ResourceTypes.empty01, src: Resources.getSource(ResourceTypes.empty01) },
  { name: ResourceTypes.empty02, src: Resources.getSource(ResourceTypes.empty02) },
  { name: ResourceTypes.empty03, src: Resources.getSource(ResourceTypes.empty03) },
  { name: ResourceTypes.empty04, src: Resources.getSource(ResourceTypes.empty04) },
  { name: ResourceTypes.empty05, src: Resources.getSource(ResourceTypes.empty05) },
  { name: ResourceTypes.empty06, src: Resources.getSource(ResourceTypes.empty06) },
];

export const LOADER_TEXTURES = [
  {
    name: 'coinsAnimation',
    src: '/animations/coins/coins.json',
  },
  {
    name: 'ui',
    src: '/images/ui/ui.json',
  },
  {
    name: 'symbols',
    src: '/images/ui/symbols.json',
  },
  {
    name: 'gates',
    src: '/images/ui/gates.json',
  },
  {
    name: 'game',
    src: '/images/game.json',
  },
  {
    name: 'game-elements',
    src: '/images/game-elements.json',
  },
  {
    name: 'bonus-game',
    src: '/images//ui/bonus-game.json',
  },
  {
    name: 'unused',
    src: '/images/unused.json',
  },
  {
    name: 'dialogs',
    src: '/images/dialogs.json',
  },
  {
    name: 'prizeCounterFont',
    src: '/images/prizeCounterSpriteSheet-export.fnt',
  },
  {
    name: 'collectorDoorsMultiplier',
    src: '/images/collector-doors-multiplier-font-export.fnt',
  },
  {
    name: 'prizeCounterFrame',
    src: '/images/ui/prizeCounterFrame.json',
  },
  {
    name: 'patterns',
    src: '/images/ui/patterns.json',
  },
  {
    name: 'dynamic-animations',
    src: '/animations/dynamic-animations.json',
  },
  {
    name: 'logo_animations',
    src: '/animations/logo_animations.json',
  },
  {
    name: 'bonus-wings',
    src: '/images/spines/bonus-wings.json',
  },
  {
    name: 'steampunk_steam',
    src: '/images/spines/steampunk_steam.json',
  },
  {
    name: 'win_message',
    src: '/images/spines/win_message.json',
  },
  {
    name: 'win_message_effects',
    src: '/images/optimized/win_message_effects.json',
  },
  {
    name: 'anticipation',
    src: '/images/spines/anticipation.json',
  },
  {
    name: 'steampunk_bomb_explosion',
    src: '/images/spines/steampunk_bomb_explosion.json',
  },
  {
    name: ResourceTypes.bg,
    src: Resources.getSource(ResourceTypes.bg),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.buyFeature01MaskForCta,
    src: Resources.getSource(ResourceTypes.buyFeature01MaskForCta),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.introReel4,
    src: Resources.getSource(ResourceTypes.introReel4),
  },
  {
    name: ResourceTypes.introReel5,
    src: Resources.getSource(ResourceTypes.introReel5),
  },
  {
    name: ResourceTypes.bonusTutorial01,
    src: Resources.getSource(ResourceTypes.bonusTutorial01),
  },
  {
    name: ResourceTypes.bonusTutorial02,
    src: Resources.getSource(ResourceTypes.bonusTutorial02),
  },
  {
    name: ResourceTypes.bonusTutorial03,
    src: Resources.getSource(ResourceTypes.bonusTutorial03),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: 'phoenixMovie',
    src: '/movie/phoenixAnticipation.mp4',
  },
];

import { isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import type { TPlay } from '@phoenix7dev/audio-api/dist/d';

import { ISongs, SlotId } from '../config';
import { FeatureState, GameMode, ISettledBet, baseReelSetIds, bonusesId, buyFeatureBonusesId } from '../global.d';
import { setBetAmount, setIsDuringBigWinLoop } from '../gql/cache';
import Tween from '../slotMachine/animations/tween';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
} from '../slotMachine/config';

import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  return buyFeatureBonusesId[featureState as FeatureState] as string;
};

export const findGameModeById = (id: string): GameMode | null => {
  for (const mode in GameMode) {
    // Filter out numeric keys from enum iteration
    if (isNaN(Number(mode))) {
      const numericMode = GameMode[mode as keyof typeof GameMode];
      if (bonusesId[numericMode] === id) {
        return numericMode;
      }
    }
  }
  return null;
};
//buyFeatureBonusesId

export const findBuyFeatureBonusesById = (id: string): FeatureState | null => {
  for (const mode in FeatureState) {
    // Filter out numeric keys from enum iteration
    if (isNaN(Number(mode))) {
      const numericMode = FeatureState[mode as keyof typeof FeatureState];
      if (buyFeatureBonusesId[numericMode] === id) {
        return numericMode;
      }
    }
  }
  return null;
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS || mode === GameMode.RAGE_MODE;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};
export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const isBaseReelSet = (reelSetId: string): boolean => {
  return baseReelSetIds.includes(reelSetId);
};

export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * 50) / 100;
};

export const normalizePosition = (length: number, position: number): number => {
  return (length + position) % length;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isPopupOpened,
  isAutoPlay,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound?: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened: boolean;
  isAutoPlay: boolean;
}): boolean => {
  if ((gameMode === GameMode.BASE_GAME || isBuyFeatureMode(gameMode)) && isFreeSpinsWin) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }
  if (isAutoPlay) {
    return false;
  }

  return true;
};

export const delayedAction = (delay: number, completeCallback: () => void, startCallback?: () => void): void => {
  const delayAnim = Tween.createDelayAnimation(delay);
  if (startCallback) {
    delayAnim.addOnStart(startCallback);
  }
  delayAnim.addOnComplete(completeCallback);
  delayAnim.start();
};

export const isRageModeWin = (result: ISettledBet): boolean => {
  return result.bet.data.features.multiplier === 10;
};

export const getBGMSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.BGM_BG_Melo_Loop;
    case GameMode.BONUS_GAME:
      return ISongs.BGM_Bonus_Loop;
    case GameMode.ONE_BONUS_LINE:
      return ISongs.BGM_BG_Melo_Loop;
    case GameMode.ALL_BONUS_LINES:
      return ISongs.BGM_BG_Melo_Loop;
    default:
      return ISongs.BGM_BG_Base_Loop;
  }
};

export const handleChangeRestriction = (mode: GameMode): void => {
  AudioApi.unSuspend();
  AudioApi.processRestriction({
    restricted: false,
  });
  const list: TPlay[] = [];
  if (setIsDuringBigWinLoop()) {
    // list.push({ type: ISongs.BigWin_Loop });
  }
  switch (mode) {
    case GameMode.BASE_GAME:
      list.push({ type: ISongs.BGM_BG_Base_Loop });
      break;
    case GameMode.BONUS_GAME:
      list.push({ type: ISongs.BGM_Bonus_Loop, stopImmediately: [ISongs.BGM_BG_Melo_Loop] });
      break;
    // case GameMode.BUY_FEATURE:
    //   //list.push({ type: ISongs.BGM_RM_Loop, stopImmediately: [ISongs.BGM_BG_Base_Loop] });
    //   break;
    default:
      list.push({ type: ISongs.BGM_BG_Base_Loop });
      break;
  }
  AudioApi.playlist({ list });
};

export const getSlotOrderBySlotId = (slotId: SlotId): number => {
  switch (slotId) {
    case SlotId.WL:
      return 11;
    case SlotId.A:
      return 16;
    case SlotId.K:
      return 14;
    case SlotId.Q:
      return 13;
    case SlotId.J:
      return 12;
    case SlotId.T:
      return 11;
    case SlotId.C:
      return 10;
    case SlotId.D:
      return 9;
    case SlotId.E:
      return 8;
    case SlotId.F:
      return 7;
    case SlotId.SC1:
      return 6;
    case SlotId.SC2:
      return 5;
    case SlotId.SC3:
      return 4;
    case SlotId.SC4:
      return 3;
    case SlotId.SC5:
      return 2;
    case SlotId.SC6:
      return 1;
    default:
      return 0;
  }
};

export const getRandomEnumValue = function <T extends Record<string, string | number>>(anEnum: T): T[keyof T] {
  const enumValues = Object.values(anEnum) as T[keyof T][];
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  // Assert that the selected value will not be undefined
  return enumValues[randomIndex] as T[keyof T];
};

// TODO: set argument type as non empty array
export const getRandomArrayElement = function <T>(array: T[]): T | undefined {
  if (array.length === 0) {
    console.error();
    return undefined;
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex] as T;
};

export const shuffleArray = function <T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // Explicit type assertion as T
    const temp: T = array[i] as T; // Safely assume non-undefined because of controlled indices
    array[i] = array[j] as T;
    array[j] = temp;
  }
  return array;
};

export const cascadeEase = (x: number): number => {
  const c1 = 1.70158;
  const c2 = c1 * 1;

  return x < 0.5 ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2 : Math.pow(x, 2);
};

export function easeInSine(x: number): number {
  return 1 - Math.cos((x * Math.PI) / 2);
}

export function easeInOutSine(x: number): number {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

export function easeOutSine(x: number): number {
  return Math.sin((x * Math.PI) / 2);
}

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth >= 768 && _deviceWidth < 1000;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isMobile.any && isLandscape && _deviceWidth < 950;
};

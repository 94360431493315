import i18n from 'i18next';
import _ from 'lodash';
import { Container, Graphics } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { Colors, type SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCoinAmount, setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import { TextField } from '../components/TextField';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_BORDER_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  eventManager,
  miniPayTableStyle,
} from '../config';
import type { Combos, Icon, IconCombo } from '../d';
import type Gates from '../gates/gates';

// Here we will have other special symbols (Collect, Scatter, Money, Wild)
type SpecialSymbolType = 'WL' | 'SC1' | 'SC2' | 'SC3' | 'SC4' | 'SC5' | 'SC6' | 'MS1';

const slotIndexPerLineIndex: { [key: number]: number[] } = {
  0: [20, 21, 22, 23, 24],
  1: [15, 16, 17, 18, 19],
  2: [10, 11, 12, 13, 14],
  3: [5, 6, 7, 8, 9],
  4: [0, 1, 2, 3, 4],
};

class MiniPayTable extends Container {
  private id: number;

  private currency = '';

  private betAmount: number;

  public iconId: SlotId;

  public combos: Combos;

  public rect!: Graphics;

  private wrap!: Container;

  public multipliers!: TextField;

  public additionalText!: TextField;

  private visibleLines: number[];

  public specialSymbolDetails: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [key in SpecialSymbolType]: string;
  } = {
    WL: 'infoPayTableWildText',
    SC1: 'infoPayTableBonusText',
    SC2: 'infoPayTableNukeText',
    SC3: 'infoPayTableCoinText',
    SC4: 'infoPayTableCoinText',
    SC5: 'infoPayTableMultiplierText',
    SC6: 'infoPayTableExtraSpinText',
    MS1: 'infoPayTableMysteryText',
  };

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.iconId = icon.id;
    this.visible = false;
    this.visibleLines = [1, 2, 3];
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    this.betAmount = setBetAmount();
    this.currency = setCurrency();
    this.initRect();
    this.addChild(this.rect);
    this.initWrap();
    this.addChild(this.wrap);
    this.initMultipliers();
    this.wrap.addChild(this.multipliers.getText());
    this.initAdditionalText();
    this.wrap.addChild(this.additionalText.getText());
    this.setPayTableData(icon.id, combos);

    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => {
      this.showPayTable(i);
    });
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, () => (this.visible = false));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventManager.addListener(EventTypes.HANDLE_CHANGE_BET_AMOUNT, () => this.handleChangeBetAmount());
    eventManager.addListener(EventTypes.END_TOGGLE_GATE, this.setActiveIds.bind(this));
  }

  setActiveIds(gates: Gates) {
    const upperGateLineIndex = 4;
    const lowerGateLineIndex = 0;

    if (gates.upperGate.currentState === 'open' && !this.visibleLines.includes(upperGateLineIndex)) {
      this.visibleLines.unshift(upperGateLineIndex);
    }

    if (gates.upperGate.currentState === 'close' && this.visibleLines.includes(upperGateLineIndex)) {
      this.visibleLines = this.visibleLines.filter((index) => index !== upperGateLineIndex);
    }

    if (gates.lowerGate.currentState === 'open' && !this.visibleLines.includes(lowerGateLineIndex)) {
      this.visibleLines.push(lowerGateLineIndex);
    }

    if (gates.lowerGate.currentState === 'close' && this.visibleLines.includes(lowerGateLineIndex)) {
      this.visibleLines = this.visibleLines.filter((index) => index !== lowerGateLineIndex);
    }
  }

  initRect(): void {
    this.rect = new Graphics();
    this.rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
    this.rect.lineStyle(2, PAY_TABLE_BORDER_COLOR);
    this.rect.alpha = 0.6;
    this.rect.drawRoundedRect(0, 0, PAY_TABLE_WIDTH, PAY_TABLE_HEIGHT, 0);
  }

  initWrap(): void {
    this.wrap = new Container();
  }

  setPayTableData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;

    this.updatePaytableData();
  }

  initMultipliers(): void {
    this.multipliers = new TextField('', PAY_TABLE_WIDTH - 20, PAY_TABLE_HEIGHT - 20, {}, true, {
      default: {
        ...miniPayTableStyle,
        align: 'left',
        lineHeight: 50,
        fontSize: 28,
      },
      span: {
        fill: Colors.GAME_COLOR,
        lineHeight: 50,
      },
    });
    this.multipliers.text.anchor.set(0.5, 0);
  }

  private initAdditionalText(): void {
    this.additionalText = new TextField('', PAY_TABLE_WIDTH - 20, 70, {}, true, {
      default: {
        ...miniPayTableStyle,
        align: 'center',
        lineHeight: 30,
        fontSize: 28,
      },
      span: {
        fill: Colors.GAME_COLOR,
        lineHeight: 30,
        dropShadowColor: PAY_TABLE_BACKGROUND_COLOR,
        dropShadow: true,
        dropShadowDistance: 10,
        dropShadowBlur: 1,
      },
    });
    this.additionalText.text.anchor.set(0.5, 0.5);
  }

  private calcMultiplier(multiplier: number): number {
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    let activeIds: number[] = [];
    for (const lineID of this.visibleLines) {
      const currentIds = slotIndexPerLineIndex[lineID] as number[];
      activeIds = [...activeIds, ...currentIds];
    }

    if (!activeIds.includes(uniqueId)) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }

  private getCombos(): string {
    const combos =
      this.combos?.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${curr.pattern} <span>${formatNumber({
            currency: this.currency,
            value: this.calcMultiplier(curr.multiplier),
            showCurrency: showCurrency(this.currency),
          })}</span>\n`,
        '',
      ) || '';

    return combos.trimEnd();
  }

  private handleChangeBetAmount(): void {
    this.updatePaytableData();
  }

  private updatePaytableData(): void {
    this.multipliers.setText(this.getCombos());
    const specialSymbolText = this.getSpecialSymbolInfo();
    if (specialSymbolText) {
      this.additionalText.text.visible = true;
      this.additionalText.setText(i18n.t(specialSymbolText));
      this.additionalText.text.y = this.multipliers.text.height;
    } else {
      this.additionalText.text.visible = false;
      this.additionalText.setText('');
    }

    this.wrap.x = PAY_TABLE_WIDTH / 2;
    this.wrap.y = PAY_TABLE_HEIGHT / 2 - this.wrap.height / 2;
  }

  private getSpecialSymbolInfo(): string {
    const specialSymbolDetails = this.specialSymbolDetails[this.iconId as SpecialSymbolType];
    return specialSymbolDetails || '';
  }
}

export default MiniPayTable;

import { GameMode } from '../../global.d';

//import { setCurrentBonus } from '../../gql/cache';
import { BonusModeController } from './BonusModeController';

export class OneBonusLineController extends BonusModeController {
  public override gameMode: GameMode = GameMode.ONE_BONUS_LINE;

  public static override the = new OneBonusLineController();

  // public override enterController(prevGameMode: GameMode, _props?: EnterProps | undefined): void {
  //   super.enterController(prevGameMode, _props);

  //   setCurrentBonus({
  //     ...setCurrentBonus(),
  //     gameMode: GameMode.ONE_BONUS_LINE,
  //     rounds: 10,
  //     currentRound: 0,
  //     isActive: true,
  //   });
  // }
}

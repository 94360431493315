import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { getRandomArrayElement } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';

export default class BonusWings extends ViewContainer {
  private maxWingSpeedDelta = 0.2;
  private curetFlapAnimation = '';
  private forceNextAnimation = '';
  public enableDelay = true;

  public wingLeftSpine: Spine;
  public wingRightSpine: Spine;
  private flapDelay: gsap.core.Tween | undefined;

  constructor() {
    super();

    const wingsScale = 1.1;
    this.wingLeftSpine = new Spine(Loader.shared.resources['bonus-wings']!.spineData!);
    this.wingLeftSpine.state.setAnimation(0, 'Flap', false);
    this.wingLeftSpine.scale.set(wingsScale);
    this.wingLeftSpine.state.timeScale = 0;

    this.wingRightSpine = new Spine(Loader.shared.resources['bonus-wings']!.spineData!);
    this.wingRightSpine.state.setAnimation(0, 'Flap', false);
    this.wingRightSpine.scale.set(-wingsScale, wingsScale);
    this.wingRightSpine.state.timeScale = 0;

    this.addChild(this.wingLeftSpine, this.wingRightSpine);

    this.initSpineMixes();

    this.addEvents();
  }

  private addEvents() {
    const idleAnimation = 'Idle';
    this.wingLeftSpine.state.addListener({
      complete: (_entry) => {
        const leftTimeScale = 0.9 + this.maxWingSpeedDelta * Math.random();
        // start idle before next flap
        this.wingLeftSpine.state.setAnimation(0, idleAnimation, false);
        this.wingLeftSpine.state.timeScale = leftTimeScale;
        //this.wingRightSpine.angle = 5;

        if (this.curetFlapAnimation === idleAnimation) return;

        // if not in idle setup next flap
        let randomDelay = 5 + Math.random() * 10;
        const wingAnimations = ['Flap', 'Small Flap', 'Soar'];
        let nextAnimations = wingAnimations.filter((a) => a !== this.curetFlapAnimation);

        if (!this.enableDelay) {
          // Flap constantly
          randomDelay = 0.7;
          if (this.forceNextAnimation !== '') {
            nextAnimations = [this.forceNextAnimation];
          }
        }

        this.curetFlapAnimation = idleAnimation;

        this.flapDelay = gsap.delayedCall(randomDelay, () => {
          const animation = getRandomArrayElement(nextAnimations) as string;
          this.flapWings(animation);
        });
      },
    });

    this.wingRightSpine.state.addListener({
      complete: (_entry) => {
        const rightTimeScale = 0.9 + this.maxWingSpeedDelta * Math.random();
        this.wingRightSpine.state.setAnimation(0, idleAnimation, false);
        this.wingRightSpine.state.timeScale = rightTimeScale;
      },
    });
  }

  private initSpineMixes() {
    // Get all available animations
    const animations = this.wingLeftSpine.spineData.animations.map((animation) => animation.name);

    // Set up mix transitions between all animations
    const mixDuration = 0.2;
    for (let i = 0; i < animations.length; i++) {
      for (let j = 0; j < animations.length; j++) {
        const firstAnimName = animations[i] as string;
        const secondAnimName = animations[j] as string;
        this.wingLeftSpine.stateData.setMix(firstAnimName, secondAnimName, mixDuration);
        this.wingRightSpine.stateData.setMix(firstAnimName, secondAnimName, mixDuration);
      }
    }
  }

  public flapWings(animation = 'Flap') {
    this.flapDelay?.kill();
    const leftTimeScale = 0.9 + this.maxWingSpeedDelta * Math.random();
    this.wingLeftSpine.state.setAnimation(0, animation, false);
    this.wingLeftSpine.state.timeScale = leftTimeScale;

    const rightTimeScale = 0.9 + this.maxWingSpeedDelta * Math.random();
    this.wingRightSpine.state.setAnimation(0, animation, false);
    this.wingRightSpine.state.timeScale = rightTimeScale;

    this.curetFlapAnimation = animation;
  }

  public flapConstantly(animation: string) {
    this.enableDelay = false;
    this.forceNextAnimation = animation;
    this.flapWings(animation);
  }

  public pause() {
    this.wingLeftSpine.state.timeScale = 0;
    this.wingRightSpine.state.timeScale = 0;
  }

  public resume() {
    this.wingLeftSpine.state.timeScale = 1;
    this.wingRightSpine.state.timeScale = 1;
  }
}

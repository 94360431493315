import { BitmapText, Container, Loader, Sprite, Texture, utils } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import { ParticlesAnimation } from '../animations/particles';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { MAXIMUM_FRACTION_DIGITS, MINIMUM_FRACTION_DIGITS, eventManager } from '../config';

class WinCountUpMessage extends ViewContainer {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winTitle: BitmapText;
  private messageFrameBack: SpriteAnimation;
  private frameOpen: boolean;
  private messageFrame: Container;
  private frameParts: { midPart: Sprite; rightPart: Sprite; leftPart: Sprite };
  private leftPartX: number;
  private rightPartX: number;
  private messageFrameBackBack: SpriteAnimation;
  burstAnimation: ParticlesAnimation;

  constructor() {
    super();
    this.frameOpen = false;
    this.name = 'WinCountUpMessage';
    const slotSheet = Loader.shared.resources['prizeCounterFrame']!.spritesheet!;
    const texture = slotSheet.animations['prize_counter_plate'] as Texture[];
    this.messageFrameBack = new SpriteAnimation({ isLoop: false }, texture);
    this.messageFrameBack.spriteAnimation.animationSpeed = 0.3;
    this.messageFrameBack.spriteAnimation.position.set(-264, 373);
    this.messageFrameBack.spriteAnimation.visible = false;
    this.messageFrameBack.addOnComplete(() => {
      (this.winCountUpAnimation as Animation).start();
      this.messageFrame.visible = true;
      this.messageFrameBack.spriteAnimation.visible = false;
      this.messageFrameBackBack.spriteAnimation.gotoAndStop(0);
      this.messageFrameBackBack.spriteAnimation.visible = true;
    });
    this.addChild(this.messageFrameBack.spriteAnimation);

    const textureBack = [...texture].reverse();
    this.messageFrameBackBack = new SpriteAnimation({ isLoop: false }, textureBack);
    this.messageFrameBackBack.spriteAnimation.animationSpeed = 0.3;
    this.messageFrameBackBack.spriteAnimation.position.set(-264, 373);
    this.messageFrameBackBack.spriteAnimation.visible = false;
    this.messageFrameBackBack.addOnComplete(() => {
      this.messageFrameBackBack.spriteAnimation.visible = false;
    });

    this.addChild(this.messageFrameBackBack.spriteAnimation);

    this.messageFrame = new Container();
    this.messageFrame.visible = false;
    this.messageFrame.position.set(50, 335);
    const midPart = new Sprite(Texture.from('prize_counter_window_centerbg_resize.png'));
    midPart.anchor.set(0.5, 0.5);
    midPart.position.set(-49, 137);
    this.messageFrame.addChild(midPart);

    const leftPart = new Sprite(Texture.from('prize_counter_window_left.png'));
    leftPart.anchor.set(0.5, 0.5);
    leftPart.position.set(-120, 137);
    this.leftPartX = -120;
    this.messageFrame.addChild(leftPart);

    const rightPart = new Sprite(Texture.from('prize_counter_window_right.png'));
    rightPart.anchor.set(0.5, 0.5);
    rightPart.position.set(35, 137);
    this.rightPartX = 35;

    this.messageFrame.addChild(rightPart);

    this.messageFrame.visible = false;

    this.frameParts = { midPart, rightPart, leftPart };

    this.addChild(this.messageFrame);

    const particle1 = utils.TextureCache['particles_0.png'] as Texture;
    const particle2 = utils.TextureCache['particles_1.png'] as Texture;
    const particle3 = utils.TextureCache['particles_2.png'] as Texture;
    this.burstAnimation = new ParticlesAnimation('squareBurst', [particle1, particle2, particle3]);
    this.messageFrame.addChild(this.burstAnimation);

    this.winTitle = new BitmapText(
      this.winValue.toLocaleString('en-EN', {
        minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
        maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
      }),
      {
        fontName: 'prizeCounterSpriteSheet-export',
        fontSize: 170,
        letterSpacing: -12,
      },
    );

    this.winTitle.position.set(-40, 105);
    this.winTitle.visible = false;

    this.winTitle.anchor.set(0.5, 0.5);
    this.messageFrame.addChild(this.winTitle);

    eventManager.addListener(EventTypes.START_COUNT_UP, this.startCountUp.bind(this));
    eventManager.addListener(EventTypes.HIDE_COUNT_UP, this.hideCountUp.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.winValue = 0.0;
    });
  }

  private hideCountUp(durationTime?: number): void {
    const duration = durationTime === undefined ? 1000 : 0;
    const hideCountUpMassage = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.messageFrame,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration,
    });
    hideCountUpMassage.addOnComplete(() => {
      this.burstAnimation.emitter.autoUpdate = false;

      this.messageFrameBackBack.spriteAnimation.gotoAndPlay(0);
      this.messageFrame.visible = false;
      this.messageFrame.alpha = 1;
    });
    hideCountUpMassage.addOnSkip(() => {});
    hideCountUpMassage.start();
    this.frameOpen = false;
  }

  private startCountUp(start: number, end: number, id: number): void {
    this.burstAnimation.emitter.autoUpdate = true;
    this.burstAnimation.emitter.emit = true;

    const normalizedStart = normalizeCoins(start);
    const normalizedEnd = normalizeCoins(end);
    const countUpAnimation = new AnimationChain();
    const baseWinAnimation = this.createBaseWinAnimation(normalizedStart, normalizedEnd, id);
    baseWinAnimation.addOnStart(() => {
      this.winTitle.visible = true;
      //   this.messageFrameBack.spriteAnimation.visible = true;
      AudioApi.play({ type: ISongs.SFX_SM_CountUp_Loop, stopPrev: true });
    });
    baseWinAnimation.addOnComplete(() => {
      this.burstAnimation.emitter.emit = false;

      eventManager.emit(EventTypes.COUNT_UP_END);
      AudioApi.stop({ type: ISongs.SFX_SM_CountUp_Loop });
      AudioApi.play({ type: ISongs.SFX_SM_CountUp_End });
      this.winCountUpAnimation = null;
    });
    baseWinAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.COUNT_UP_END);
      this.setWinValue(normalizedEnd);
      this.winCountUpAnimation = null;
    });
    countUpAnimation.appendAnimation(baseWinAnimation);

    this.winCountUpAnimation = countUpAnimation;
    if (!this.frameOpen) {
      this.frameOpen = true;
      this.messageFrameBack.spriteAnimation.visible = true;
      this.messageFrameBack.spriteAnimation.gotoAndPlay(0);
    } else {
      this.winCountUpAnimation.start();
    }
  }

  private createBaseWinAnimation(start: number, end: number, id: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    const duration = 1000;
    // const propertyBeginValueX = Math.min(1.4 + 0.1 * id, BASE_WIN_TITLE_SCALE);
    // const propertyBeginValueY = Math.min(1.4 + 0.1 * id, BASE_WIN_TITLE_SCALE);
    // const targetX = Math.min(1.4 + 0.1 * (id + 1), BASE_WIN_TITLE_SCALE);
    // const targetY = Math.min(1.4 + 0.1 * (id + 1), BASE_WIN_TITLE_SCALE);
    const countUpAnimation = new Tween({
      propertyBeginValue: start,
      target: end,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: (winValue) => {
        this.setWinValue(winValue);
        this.setFrameWidth();
      },
      duration,
    });
    // const scaleXAnimation = new Tween({
    //   object: this.winTitle.scale,
    //   propertyBeginValue: propertyBeginValueX,
    //   target: targetX,
    //   property: TweenProperties.X,
    //   duration,
    // });
    // const scaleYAnimation = new Tween({
    //   object: this.winTitle.scale,
    //   propertyBeginValue: propertyBeginValueY,
    //   target: targetY,
    //   property: TweenProperties.Y,
    //   duration,
    // });
    if (id !== -1) {
      //   baseWinAnimation.addAnimation(scaleXAnimation);
      //   baseWinAnimation.addAnimation(scaleYAnimation);
    }
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private setFrameWidth(): void {
    const maxScale = 1.3;
    const prizeWidth = Math.ceil(this.winTitle.textWidth) + 10;
    const baseWidth = 207;
    if (prizeWidth > baseWidth) {
      const diff = (prizeWidth - baseWidth) / 2 + 10;
      this.frameParts.leftPart.x = this.leftPartX - diff;
      this.frameParts.rightPart.x = this.rightPartX + diff;
      this.frameParts.midPart.scale.set(1 + prizeWidth / 13, 1);
      let burstScale = 1 + (prizeWidth - 150) / 1000;
      if (burstScale < maxScale) {
        burstScale = maxScale;
      }

      this.burstAnimation.scale.set(burstScale, maxScale);
      this.burstAnimation.position.set(-50 - 150 * burstScale, 20);
    }
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winTitle.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
  }
}

export default WinCountUpMessage;

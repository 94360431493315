import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import {
  gameRules3,
  gameRules4,
  infoBonusStarDesc,
  infoBuyBonusFeature,
  infoInstantWinCoins,
  infoMultiplierDesc,
  infoNukeDesc,
  infoSteamBonusExtraSpin,
  infoWildDesc,
} from '../../config/gameRules';
import { setSlotConfig } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { isBuyFeatureEnabled } from '../../utils';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const Wild: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['payLines']}>
      <div className={styles['img']}>
        <h1 className={styles['subtitle']}>{t('infoPayTable_wL')}</h1>
        <img draggable="false" alt="sc" src={Resources.getSource('wL')} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoWildDesc.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};
const BonusStar: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['payLines']}>
      <div className={styles['img']}>
        <h1 className={styles['subtitle']}>{t('infoPayTable_sc1')}</h1>
        <img draggable="false" alt="sc1" src={Resources.getSource('sc1')} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoBonusStarDesc.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};
const Multiplier: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['payLines']}>
      <div className={styles['img']}>
        <h1 className={styles['subtitle']}>{t('infoPayTable_sc5')}</h1>
        <img draggable="false" alt="sc5" src={Resources.getSource('sc5')} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoMultiplierDesc.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};
const Nuke: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['payLines']}>
      <div className={styles['img']}>
        <h1 className={styles['subtitle']}>{t('infoPayTable_sc2')}</h1>
        <img draggable="false" alt="sc2" src={Resources.getSource('sc2')} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoNukeDesc.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};
const SteamBonusExtraSpin: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['payLines']}>
      <div className={styles['img']}>
        <h1 className={styles['subtitle']}>{t('infoPayTable_sc6')}</h1>
        <img draggable="false" alt="sc6" src={Resources.getSource('sc6')} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoSteamBonusExtraSpin.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};
const InstantWinCoins: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['payLines']}>
      <div className={styles['img']}>
        <h1 className={styles['subtitle']}>{t('infoPayTable_coinsInf')}</h1>
        <img draggable="false" alt="coinsInfo" src={Resources.getSource('coins')} style={{ width: '60%' }} />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoInstantWinCoins.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};
// const CascadeFeature: React.VFC = () => {
//   const { t } = useTranslation();
//   return (
//     <section className={styles['feature']}>
//       <h1 className={styles['subtitle']}>{t('infoCascadeFeatureTitle')}</h1>
//       <div className={styles['row']}>
//         <div className={styles['col']}>
//           {infoCascadeFeatureDescs1.map((keyName) => (
//             <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
//           ))}
//         </div>
//         <div className={styles['col']}>
//           {infoCascadeFeatureDescs2.map((keyName) => (
//             <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

const BonusGame: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoBonusGameTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {gameRules3
            .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
            .map((v) => (
              <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
            ))}
        </div>
        <div className={styles['col']}>
          {gameRules4
            .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
            .map((v) => (
              <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
            ))}
        </div>
      </div>
    </section>
  );
};

// const RandomWildsFeature: React.VFC = () => {
//   const { t } = useTranslation();
//   return (
//     <section className={styles['feature']}>
//       <h1 className={styles['subtitle']}>{t('infoRandomWildsFeatureTitle')}</h1>
//       <div className={styles['row']}>
//         <div className={styles['col']}>
//           <p className={styles['p']} dangerouslySetInnerHTML={{ __html: t('infoRandomWildsFeatureDescs_1') }} />
//         </div>
//         <div className={styles['col']}>
//           <p className={styles['p']} dangerouslySetInnerHTML={{ __html: t('infoRandomWildsFeatureDescs_2') }} />
//         </div>
//       </div>
//     </section>
//   );
// };

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles['payLines']}>
      <h1 className={styles['subtitle']}>{t('infoFeatureBuyTitle')}</h1>
      <div className={styles['img']}>
        <img
          className={styles['buy_feature__img']}
          alt="buyFeatureBtn"
          draggable="false"
          src={Resources.getSource('buybonus')}
        />
        {/*<p className={styles['buy_feature__text']} dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />*/}
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoBuyBonusFeature.map((keyName) => (
            <p className={styles['p']} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
        {/*<div className={styles['col']}>*/}
        {/*  {infoTBD.map((keyName) => (*/}
        {/*    <p className={styles['p']} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { t } = useTranslation();
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoTabFeatures')}</h1>
      <Wild />
      <InstantWinCoins />
      <Multiplier />
      <Nuke />
      <BonusStar />
      <SteamBonusExtraSpin />
      {/*<CascadeFeature />*/}
      {isBuyFeatureEnabled ? <BonusGame /> : ''}
      {/*<BonusGame />*/}
      {/*<RandomWildsFeature />*/}
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;

import { ResourceTypes } from '../resources.d';

export const introContents = [
  { img: ResourceTypes.introReel1, txtKey: 'introTitle' },
  { img: ResourceTypes.introReel2, txtKey: 'introTitle2' },
  { img: ResourceTypes.introReel3, txtKey: 'introTitle3' },
  { img: ResourceTypes.introReel4, txtKey: 'introTitle4' },
  { img: ResourceTypes.introReel5, txtKey: 'introTitle5' },
];

export const bonusContent = [
  { img: ResourceTypes.bonusTutorial01, txtKey: 'bonusIntroText1' },
  { img: ResourceTypes.bonusTutorial02, txtKey: 'bonusIntroText2' },
  { img: ResourceTypes.bonusTutorial03, txtKey: 'bonusIntroText3' },
];

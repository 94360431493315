import { Sprite, Texture, filters, utils } from 'pixi.js';

import type { SlotId } from '../../config';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import Slot from '../reels/slot';

export default class BonusSlot extends Slot {
  private winHighlight = new Sprite(Texture.EMPTY);

  public collectedFilter = new filters.ColorMatrixFilter();

  constructor(id: number, slotId: SlotId) {
    super(id, slotId);

    this.addGraphics();
  }

  public winAnimation() {
    const tween = new Tween({
      object: this.winHighlight,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: 500,
    });

    return tween;
  }

  private addGraphics() {
    this.winHighlight.texture = utils.TextureCache['hightlight.png'] || Texture.EMPTY;
    this.winHighlight.anchor.set(0.5);
    this.winHighlight.alpha = 0;
    this.addChild(this.winHighlight);
    this.collectedFilter.blackAndWhite(true);
  }
}

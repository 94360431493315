import { SlotId } from '../config';
import {
  BonusTurnData,
  BonusTurnInputData,
  BonusWinPosition,
  type Cascade,
  EventTypes,
  GameMode,
  type ReelSet,
  UserBonus,
  bonusesId,
} from '../global.d';
import { setIsErrorMessage, setSlotConfig, setUserLastBetResult } from '../gql/cache';
import { Logic } from '../logic';
import { SLOTS_PER_REEL_AMOUNT, eventManager } from '../slotMachine/config';
import type { Icon } from '../slotMachine/d';

import { normalizePosition } from './helper';

const normalize = (coord: number, layout: string[]) => {
  return coord < 0 ? layout.length - (Math.abs(coord) % layout.length) : coord % layout.length;
};

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const cols = 5;
  const rows = 5;
  return [...(Array(cols * rows) as Icon[])].map((_, index) => {
    const row = Math.floor(index / cols);
    const column = index % cols;
    const layout = reelSet.layout[column as number];

    const initialCoord = reelPositions[column as number];
    const coord = (initialCoord as number) + row;

    return (
      icons.find((icon) => icon.id === reelSet.layout[column as number]![normalize(coord, layout as string[])]) ||
      icons[0]
    );
  }) as Icon[];
};

export const getCascadeColumns = ({
  reelPositions,
  layout,
  cascades,
}: {
  reelPositions: number[];
  layout: SlotId[][];
  cascades: Cascade[];
}): Icon[][] => {
  const { icons } = setSlotConfig();
  const moreSlots: SlotId[][] = [[], [], [], [], []];
  cascades
    .filter((cascade) => !cascade.isRandomWilds)
    .forEach((cascade) => {
      cascade.cascadeFall.reverse().forEach((cascadeFalls, _index) => {
        cascadeFalls.forEach((slot, idx) => {
          if (slot !== '') {
            moreSlots[idx as number]!.push(slot);
          }
        });
      });
    });
  const res = reelPositions.map((position, index) => {
    const start = position + 4;
    const slots: Icon[] = [];
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const position = normalizePosition(layout[index as number]!.length, start - i);
      slots.push(icons.find((icon) => icon.id === layout[index as number]![position as number])!);
    }
    for (let i = 0; i < moreSlots[index as number]!.length; i++) {
      slots.push(icons.find((icon) => icon.id === moreSlots[index as number]![i as number])!);
    }

    return slots.reverse();
  });

  return res;
};

export const getBonusTurn = (data: BonusTurnInputData) => {
  if (data.activeRowsPerReel.length === 0) {
    console.warn('Error: No reel layout data');
  }

  const cascadeColumnsServer = getCascadeColumns({
    reelPositions: data.reelPositions,
    layout: data.layout,
    cascades: [],
  });

  console.log(cascadeColumnsServer);

  const turn: BonusTurnData = { winPositions: [] as BonusWinPosition[], reels: [] as SlotId[][] };
  const extraSpinBonus = data.bonuses.find(() => (b: UserBonus) => b.bonusId === bonusesId[GameMode.BONUS_GAME]);
  const multipliers: BonusWinPosition[] = [];
  let extraSpinCount = 0;
  for (const i in cascadeColumnsServer) {
    const col = cascadeColumnsServer[i] as Icon[];
    turn.reels[i] = [];
    const reel = turn.reels[i] || [];
    for (let j = 0; j < (data.activeRowsPerReel[i] ?? col.length); j++) {
      const slot = col[j];
      if (!slot) continue;

      // check if position is scatter win
      if (slot && slot.id === SlotId.SC6) {
        extraSpinCount++;
        if (extraSpinBonus) {
          const winPos = { position: [+i, reel.length], bonus: 'spin' };
          turn.winPositions.push(winPos);
        }
      }
      if (slot && slot.id === SlotId.SC5) {
        multipliers.push({ position: [+i, reel.length], bonus: 'multiplier' });
      }

      turn.reels[i]?.push(slot?.id as SlotId);
    }
  }

  if (extraSpinBonus && extraSpinCount < 3) {
    console.warn('Error: Not enough extra spins on reel to win bonus!');
  }
  if (data.isMultiplier && multipliers.length < 3) {
    console.warn('Error: Not enough multipliers on reel to win bonus!');
  }

  if (data.isMultiplier && multipliers.length >= 3) {
    turn.winPositions.unshift(...multipliers);
  }

  for (const payline of data.payLines) {
    for (const index of payline.winPositions) {
      const reelSize = data.activeRowsPerReel[index % 5] ?? 0;
      const slotIndex = Math.floor(index / 5);
      if (slotIndex >= reelSize) {
        console.warn(`Unexpected win at reel ${index % 5}, index ${slotIndex}. Reel size: ${reelSize}`);
      }
      const winPos = { position: [index % 5, Math.floor(index / 5)] };
      turn.winPositions.push(winPos);
    }
  }

  return turn;
};

export const fallBackReelPosition = () => {
  setIsErrorMessage(true);
  const slotData = setSlotConfig();
  let reelSet;
  if (setUserLastBetResult().id) {
    if (setUserLastBetResult().reelSet) {
      reelSet = setUserLastBetResult().reelSet;
    } else {
      reelSet = slotData.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!;
    }
  } else {
    reelSet = slotData.reels[0];
  }

  const startPosition = setUserLastBetResult().id
    ? setUserLastBetResult().result.reelPositions
    : slotData.settings.startPosition;
  eventManager.emit(
    EventTypes.SETUP_REEL_POSITIONS,
    getCascadeColumns({
      reelPositions: startPosition,
      layout: reelSet?.layout!,
      cascades: [],
      //   reelsCount: SLOTS_PER_REEL_AMOUNT,
    }),
    Logic.the.isStoppedBeforeResult,
  );

  eventManager.emit(EventTypes.END_WAITING_ANIMATION);
  eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
};

import gsap from 'gsap';
import { Loader, Point, Texture } from 'pixi.js';

import SpriteAnimation from '../animations/sprite';
import { ViewContainer } from '../components/ViewContainer';

interface CoinRainSettings {
  interval?: number;
  minScale?: number;
  maxScale?: number;
}

// TODO: make coin fall square on landscape
export default class CoinRainAnimation extends ViewContainer {
  private defaultWidth = 3000;
  // widescreen portrait
  private defaultHeight = 6500;
  private effectWidth = 1920;
  private effectHeight = 1920;

  private lastCoinTime = 0;

  private texture: Texture[];
  //private coins: SpriteAnimation[];
  private spawnInterval: number | null;
  private settings: CoinRainSettings;

  private boundSpawnTickerCallback: () => void;

  constructor() {
    super();

    this.x = -this.defaultWidth / 2;
    const textureSheet = Loader.shared.resources['dynamic-animations']!.spritesheet!;
    this.texture = textureSheet.animations['coin'] as Texture[];
    //this.coins = [];
    this.spawnInterval = null;

    this.settings = {
      interval: 100, // Default spawn interval in milliseconds
      minScale: 0.7, // Default minimum scale
      maxScale: 1.0, // Default maximum scale
    };

    this.boundSpawnTickerCallback = this.spawnTickerCallback.bind(this);
  }

  addEvents() {
    //eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
  }

  private createCoin(minScale: number, maxScale: number): void {
    const coin = new SpriteAnimation({ isLoop: true }, this.texture);
    coin.spriteAnimation.anchor.set(0.5, 0.5);
    const timeScale = 0.5 + Math.random() * (0.7 - 0.5);
    coin.spriteAnimation.animationSpeed = timeScale;
    coin.spriteAnimation.play();

    // Set initial position at the top with a random horizontal position
    coin.spriteAnimation.x = Math.random() * this.defaultWidth;
    coin.spriteAnimation.y = 0; //-coin.spriteAnimation.height;

    // Set random scale between minScale and maxScale
    const scale = minScale + Math.random() * (maxScale - minScale);
    coin.spriteAnimation.scale.set(scale);

    // Randomly reverse the coin in X and/or Y direction
    coin.spriteAnimation.scale.x *= Math.random() < 0.5 ? 1 : -1;
    coin.spriteAnimation.scale.y *= Math.random() < 0.5 ? 1 : -1;

    coin.spriteAnimation.rotation = Math.random() * Math.PI * 2;

    //this.coins.push(coin);
    this.addChild(coin.spriteAnimation);

    gsap.to(coin.spriteAnimation, {
      y: this.defaultHeight,
      duration: 4,
      ease: 'none',
      onComplete: () => {
        this.removeChild(coin.spriteAnimation);
      },
    });
  }

  public startSpawningCoins(settings: CoinRainSettings = {}): void {
    this.settings.interval = settings.interval || 100;
    this.settings.minScale = settings.minScale || 1;
    this.settings.maxScale = settings.maxScale || 1;
    //const intervalScale = this.effectWidth / this.defaultWidth;

    gsap.ticker.add(this.boundSpawnTickerCallback);
    //this.spawnInterval = window.setInterval(() => this.createCoin(minScale, maxScale), interval);
  }

  private spawnTickerCallback() {
    const currentTime = gsap.ticker.time * 1000; // Convert to milliseconds
    if (currentTime - this.lastCoinTime >= (this.settings.interval as number)) {
      this.createCoin(this.settings.minScale as number, this.settings.maxScale as number);
      this.lastCoinTime = currentTime;
    }
  }

  public stopSpawningCoins(): void {
    gsap.ticker.remove(this.boundSpawnTickerCallback);
  }

  protected override resize(_width: number, _height: number): void {
    super.resize(_width, _height);
    this.effectWidth = _width;
    this.effectHeight = _height;

    // wait for parent elements to position
    setTimeout(() => {
      if (this.parent) {
        const pos = new Point(0, -100);
        const localPos = this.parent.toLocal(pos);
        //this.x = localPos.x;
        this.y = localPos.y;
      }
    });
  }
}

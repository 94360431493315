import { Container, Graphics, Loader, Sprite, Texture, utils } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { Logic } from '../../logic';
import SpriteAnimation from '../animations/sprite';
import type BonusGameContainer from '../bonusGameContainer/bonusGameContainer';
import type BonusIndicatorContainer from '../bonusIndicator/bonusIndicatorContainer';
import type ComboCollectorContainer from '../comboCollector/comboCollectorContainer';
import { ViewContainer } from '../components/ViewContainer';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import type { IGameContainer } from '../d';
import SteampunkAnticipation from '../effects/steampunkAnticipation';
import Gates from '../gates/gates';
import type WildCollectorContainer from '../wildCollector/wildCollectorContainer';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';
import type WinCountUpMessage from '../winAnimations/winCountUpMessage';

import { FreeSpinsLeft } from './freeSpinsLeft';

class GameView extends ViewContainer {
  public mainContainer: Sprite;

  public winSlotsContainer: Container;

  public miniPayTableContainer: Container;

  public reelsBackgroundContainer: Container;

  public reelsContainer: Container;

  public comboCollectorContainer: ComboCollectorContainer;

  public wildCollectorContainer: WildCollectorContainer;

  public bonusIndicatorContainer: BonusIndicatorContainer;

  public bonusGameContainer: BonusGameContainer;

  public slotsContainer: Container;

  public overSlotsContainer: Container;

  public winCountUpMessage: WinCountUpMessage;

  public coinsAnimationContainer: Container;

  public gameLogo: SpriteAnimation;

  public freeSpinsLeft: FreeSpinsLeft;

  public maskArea: Graphics;

  public gatesContainer: Container;
  private wildsCelebration = new SteampunkAnticipation();

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.mainContainer = new Sprite(Texture.EMPTY);
    this.mainContainer.name = 'mainContainer';
    this.mainContainer.anchor.set(0.5);
    this.mainContainer.pivot.set(0.5, 50);
    this.addChild(this.mainContainer);
    this.slotsContainer = new Container();
    this.slotsContainer.interactive = false;
    this.slotsContainer.interactiveChildren = false;
    this.slotsContainer.name = 'slotsContainer';
    this.slotsContainer.x = -SLOTS_CONTAINER_WIDTH / 2;
    this.slotsContainer.y = -SLOTS_CONTAINER_HEIGHT / 2;
    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.maskArea.x = -SLOTS_CONTAINER_WIDTH / 2 + SLOTS_CONTAINER_WIDTH / 2;
    this.maskArea.y = 0;
    this.overSlotsContainer = new Container();
    this.overSlotsContainer.name = 'overSlotsContainer';
    this.freeSpinsLeft = new FreeSpinsLeft();
    this.gameLogo = this.initGameLogo();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.coinsAnimationContainer.interactive = false;
    this.coinsAnimationContainer.interactiveChildren = false;
    this.gatesContainer = new Gates();
    this.gatesContainer.interactive = false;
    this.gatesContainer.interactiveChildren = false;
    this.coinsAnimationContainer.x = this.slotsContainer.x;
    this.coinsAnimationContainer.y = this.slotsContainer.y;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.x = this.slotsContainer.x;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsBackgroundContainer.x = 10;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.comboCollectorContainer = props.comboCollectorContainer;
    this.wildCollectorContainer = props.wildCollectorContainer;
    this.bonusIndicatorContainer = props.bonusIndicatorContainer;
    this.bonusGameContainer = props.bonusGameContainer;
    this.slotsContainer.mask = this.maskArea;
    props.reelsForegroundContainer.x = this.slotsContainer.x;
    props.reelsForegroundContainer.y = this.slotsContainer.y;

    const orientationContainer = new Container();
    orientationContainer.name = 'comboCollectorOrientationContainer';
    const animationsContainer = new Container();
    orientationContainer.addChild(animationsContainer);
    this.mainContainer.addChild(orientationContainer);
    animationsContainer.name = 'comboCollectorAnimationsContainer';
    animationsContainer.addChild(this.comboCollectorContainer);
    this.mainContainer.addChild(this.reelsBackgroundContainer);
    this.mainContainer.addChild(this.slotsContainer);
    this.mainContainer.addChild(this.gatesContainer);
    this.mainContainer.addChild(this.wildCollectorContainer);
    this.mainContainer.addChild(this.gameLogo.spriteAnimation);
    this.mainContainer.addChild(this.winSlotsContainer);
    this.mainContainer.addChild(this.winCountUpMessage);
    this.mainContainer.addChild(this.bonusIndicatorContainer);
    this.mainContainer.addChild(this.miniPayTableContainer);
    this.mainContainer.addChild(this.coinsAnimationContainer);
    this.mainContainer.addChild(this.wildsCelebration);

    this.addChild(this.bonusGameContainer);

    this.addEvents();
  }

  private addEvents() {
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));

    // Hide main game during bonus game
    eventManager.addListener(EventTypes.SHOW_FULL_SCREEN_DIALOG, () => {
      if (Logic.the.controller.gameMode === GameMode.BONUS_GAME) {
        this.mainContainer.visible = false;
      } else {
        this.mainContainer.visible = true;
      }
    });

    eventManager.addListener(EventTypes.HIDE_FULL_SCREEN_DIALOG, () => {
      if (Logic.the.controller.gameMode === GameMode.BONUS_GAME) {
        this.mainContainer.visible = false;
      } else {
        this.mainContainer.visible = true;
      }
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.BONUS_GAME:
        this.handleBonusGame();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    this.mainContainer.addChild(this.winCountUpMessage);
    this.winCountUpMessage.y = 0;

    this.gameLogo.spriteAnimation.play();
  }

  private handleBonusGame(): void {
    const underWinContainerIndex = this.bonusGameContainer.children.length - 2;
    this.bonusGameContainer.addChildAt(this.winCountUpMessage, underWinContainerIndex);
    this.winCountUpMessage.y = 100;

    this.gameLogo.spriteAnimation.stop();
  }

  private initGameLogo(): SpriteAnimation {
    const logoSheet = Loader.shared.resources['logo_animations']!.spritesheet!;
    const logoTexture = logoSheet.animations['gamelogo'] as Texture[];
    const logoAnimation = new SpriteAnimation({ isLoop: true }, logoTexture);
    logoAnimation.spriteAnimation.anchor.set(0.5);
    logoAnimation.spriteAnimation.pivot.set(10);
    logoAnimation.spriteAnimation.position.set(0, -650);
    logoAnimation.start();

    logoAnimation.spriteAnimation.animationSpeed = 0.3;
    logoAnimation.spriteAnimation.name = 'gameLogo';

    return logoAnimation;
  }

  private initDesktopReelsFrame(): Sprite {
    const frame = new Sprite(utils.TextureCache['grid_bg.png']);
    frame.y = 0;
    frame.x = 0;
    frame.anchor.set(0.5);
    frame.name = 'frameStatic';
    return frame;
  }

  protected override resize(_width: number, _height: number): void {
    const isPortrait = _height > _width;

    const scaleBase = isPortrait
      ? { width: 1350, height: 2600, x: 0, y: -220 }
      : { width: 1700, height: 1200, x: -90, y: 30 };

    const scaleX = _width / scaleBase.width;
    const scaleY = _height / scaleBase.height;
    const scale = Math.min(scaleX, scaleY);

    this.scale.set(scale);
    this.mainContainer.scale.set(isPortrait ? 1 : 0.75);
    this.mainContainer.y = scaleBase.y;
    this.mainContainer.x = scaleBase.x;
    this.mainContainer.pivot.set(isPortrait ? -60 : 0, isPortrait ? 0 : 30);
    this.gameLogo.spriteAnimation.position.set(isPortrait ? 0 : -520, isPortrait ? -810 : -615);
    this.gameLogo.spriteAnimation.scale.set(isPortrait ? 1 : 0.75);
  }

  private resizeGameContainer(_width: number, _height: number, _deviceWidth: number, _deviceHeight: number): void {
    this.y = 0;
    this.x = _width / 2;
  }
}

export default GameView;

import { Spine } from 'pixi-spine';
import { Container, Loader, Sprite, Text, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setBetAmount, setCurrency, setIsDuringBigWinLoop } from '../../gql/cache';
import { Logic } from '../../logic';
import { getBGMSoundByGameMode, getWinStage, normalizeCoins, showCurrency } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import {
  APPLICATION_FPS,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  BigWinTextValueTextStyle,
  BigWinTextValueTextStyle2,
  EPIC_WIN_COUNT_UP_MULTIPLIER,
  GREAT_WIN_AMOUNT_LIMIT,
  GREAT_WIN_COUNT_UP_MULTIPLIER,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  WinStages,
  eventManager,
} from '../config';

export class BigWinContainer extends Container {
  protected background: Sprite;

  private spine: Spine;

  private spineEffects: Spine;

  private winValue = new Text();

  private winValue2 = new Text();

  private bigWinCountUpAnimation: Animation | null = null;

  private isLandscape = true;

  constructor() {
    super();
    this.spine = new Spine(Loader.shared.resources['win_message']!.spineData!);
    this.spine.visible = false;

    this.spineEffects = new Spine(Loader.shared.resources['win_message_effects']!.spineData!);
    this.spineEffects.visible = false;
    this.spineEffects.scale.set(0.5);

    this.background = this.initBackground();
    this.addChild(this.background);
    this.addChild(this.spine);
    this.winValue.addChild(this.winValue2);
    this.spine.addChild(this.winValue);
    this.addChild(this.spineEffects);
    this.zIndex = 2;
    eventManager.addListener(EventTypes.START_BIG_WIN_PRESENTATION, this.startBigWinPresentation.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    sprite.alpha = 0.7;
    sprite.visible = false;
    return sprite;
  }

  private startBigWin(): void {
    this.spine.visible = true;
    this.spineEffects.visible = true;
    const inAnimation = '01_bigwin_intro';
    const loopAnimation = '02_bigwin_idle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);

    this.spineEffects.state.setAnimation(0, 'animation', true);
  }

  private endBigWin(): void {
    const outAnimation = '12_bigwin_exit';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private startMegaWin(): void {
    const inAnimation = '03_bigwin_transition_megawin';
    const loopAnimation = '04_megawin_idle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);

    //this.spineEffects.state.addAnimation(0, 'animation', false, 0);
  }

  private endMegaWin(): void {
    const outAnimation = '11_megawin_exit';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private startGreatWin(): void {
    const inAnimation = '05_megawin_transition_greatwin';
    const loopAnimation = '06_greatwin_idle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);
  }

  private endGreatWin(): void {
    const outAnimation = '10_greatwin_exit';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private startEpicWin(): void {
    const inAnimation = '07_greatwin_transition_epicwin';
    const loopAnimation = '08_epicwin_idle';
    this.spine.state.setAnimation(0, inAnimation, false);
    this.spine.state.addAnimation(0, loopAnimation, true, 0);
  }

  private endEpicWin(): void {
    const outAnimation = '09_epicwin_exit';
    this.spine.state.setAnimation(0, outAnimation, false);
  }

  private skipWinCountUpAnimation() {
    this.bigWinCountUpAnimation?.skip();
  }

  public startBigWinPresentation(winAmount: number): void {
    this.setWinValue(0);
    this.winValue.visible = true;
    const betAmount = normalizeCoins(setBetAmount());
    const normalizedWinAmount = normalizeCoins(winAmount);
    const stage = getWinStage(winAmount);
    setIsDuringBigWinLoop(true);

    //// DEBUG
    const isBaseGame = Logic.the.controller.gameMode === GameMode.BASE_GAME;
    const isOneLine = Logic.the.controller.gameMode === GameMode.ONE_BONUS_LINE;
    // const isBonus = Logic.the.controller.gameMode === GameMode.BONUS_GAME;
    if (isBaseGame && stage >= WinStages.MegaWin) {
      console.log('BASE GAME mega win');
    }
    if ((isBaseGame || isOneLine) && stage >= WinStages.GreatWin) {
      console.log('BASE GAME great win');
    }
    if (stage >= WinStages.GreatWin) {
      console.log('Great win');
    }
    if (stage >= WinStages.EpicWin) {
      console.log('Epic win');
    }

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    animationChain.addOnStart(() => {
      this.background.visible = true;
      eventManager.emit(EventTypes.SHOW_COINS);
    });
    animationChain.addOnSkip(() => {
      this.bigWinCountUpAnimation = null;
      eventManager.emit(EventTypes.HIDE_COINS);
    });
    animationChain.addOnComplete(() => {
      this.bigWinCountUpAnimation = null;
      eventManager.emit(EventTypes.HIDE_COINS);
    });
    if (stage >= WinStages.BigWin) {
      const bigWinAnimationGroup = new AnimationGroup();
      const bigWinAnimation = this.createBigWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.BigWin);

      const bigWinBgmChain = new AnimationChain();
      bigWinBgmChain.addOnStart(() => {
        //AudioApi.fadeOut(3000, getBGMSoundByGameMode(Logic.the.controller.gameMode));
        AudioApi.fadeOut(3000, ISongs.BGM_BG_Base_Loop);
        AudioApi.play({ type: getBGMSoundByGameMode(Logic.the.controller.gameMode) });
        // AudioApi.fadeOut(1000, ISongs.RM_EntrancePopup);
        // AudioApi.fadeOut(1000, ISongs.FS_EntrancePopup);
      });

      bigWinAnimationGroup.addAnimation(bigWinAnimation);
      bigWinAnimationGroup.addAnimation(bigWinBgmChain);
      animationChain.appendAnimation(bigWinAnimationGroup);
    }
    if (stage >= WinStages.MegaWin) {
      const megaWinAnimation = this.createMegaWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.MegaWin);
      animationChain.appendAnimation(megaWinAnimation);
    }
    if (stage >= WinStages.GreatWin) {
      const greatWinAnimation = this.createGreatWinAnimation(
        normalizedWinAmount,
        betAmount,
        stage === WinStages.GreatWin,
      );
      animationChain.appendAnimation(greatWinAnimation);
    }
    if (stage >= WinStages.EpicWin) {
      const epicWinAnimation = this.createEpicWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.EpicWin);
      animationChain.appendAnimation(epicWinAnimation);
    }

    const stayAnimation = Tween.createDelayAnimation(8000);
    animationChain.appendAnimation(stayAnimation);

    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winValue,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration: mappedAudioSprites[ISongs.Win_Finish].duration,
    });

    fadeOutAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
      AudioApi.stop([{ type: ISongs.Win_Mega }, { type: ISongs.Win_Great }, { type: ISongs.Win_Epic }]);
      AudioApi.play({ type: ISongs.Win_Finish });
      //AudioApi.fadeOut(3000, ISongs.Win_Big);
      if (stage >= WinStages.BigWin) {
        if (stage === WinStages.BigWin) this.endBigWin();
        if (stage === WinStages.MegaWin) this.endMegaWin();
        if (stage === WinStages.GreatWin) this.endGreatWin();
        if (stage === WinStages.EpicWin) this.endEpicWin();
        this.background.visible = false;
        setIsDuringBigWinLoop(false);

        //AudioApi.fadeOut(500, ISongs.Win_Big);
        //AudioApi.stop({ type: ISongs.BigWin_Loop });
        //AudioApi.stop([{ type: ISongs.Win_Mega }, { type: ISongs.Win_Great }, { type: ISongs.Win_Epic }]);
        //AudioApi.fadeOut(3000, ISongs);
      }
    });
    fadeOutAnimation.addOnSkip(() => {
      this.spine.state.setEmptyAnimation(0, 0);
      eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
    });
    fadeOutAnimation.addOnComplete(() => {
      this.spine.state.setEmptyAnimation(0, 0);
    });
    animationChain.appendAnimation(fadeOutAnimation);
    animationChain.addOnStart(() => {
      this.winValue.alpha = 1;
      this.winValue.visible = true;
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.END_BIG_WIN_PRESENTATION);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      this.clean();
    });
    animationChain.addOnSkip(() => {
      this.clean();
      eventManager.emit(EventTypes.END_BIG_WIN_PRESENTATION);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    this.bigWinCountUpAnimation = animationChain;
    animationChain.start();
  }

  private createBigWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration =
      (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startBigWin();
      AudioApi.play({ type: ISongs.Win_Big });
      //AudioApi.play({ type: ISongs.BigWin_Loop, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endBigWin();
        AudioApi.stop({ type: ISongs.Win_Big });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endBigWin();
        //AudioApi.fadeOut(3000, ISongs.Win_Big);
        AudioApi.stop({ type: ISongs.Win_Big });
      }
    });
    return countUpAnimation;
  }

  private createMegaWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration =
      (Math.min(win / bet, MEGA_WIN_AMOUNT_LIMIT) / (MEGA_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * BIG_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startMegaWin();
      AudioApi.play({ type: ISongs.Win_Mega, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endMegaWin();
        AudioApi.stop({ type: ISongs.Win_Mega });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endMegaWin();
        AudioApi.fadeOut(500, ISongs.Win_Mega);
        AudioApi.stop({ type: ISongs.Win_Mega });
      }
    });
    return countUpAnimation;
  }

  private createGreatWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration =
      (Math.min(win / bet, GREAT_WIN_AMOUNT_LIMIT) / (GREAT_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * MEGA_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startGreatWin();
      this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT));
      AudioApi.play({ type: ISongs.Win_Great, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endGreatWin();
        AudioApi.stop({ type: ISongs.Win_Great });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endGreatWin();
        AudioApi.fadeOut(3000, ISongs.Win_Great);
        AudioApi.stop({ type: ISongs.Win_Great });
      }
    });
    return countUpAnimation;
  }

  private createEpicWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration = (win / bet / (EPIC_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * GREAT_WIN_AMOUNT_LIMIT,
      target: win,
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startEpicWin();
      AudioApi.play({ type: ISongs.Win_Epic });
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(win);
      if (!isLastStage) {
        this.endEpicWin();
        AudioApi.fadeOut(3000, ISongs.Win_Epic);
        AudioApi.stop({ type: ISongs.Win_Epic });
      }
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(win);
      if (!isLastStage) {
        this.endEpicWin();
        AudioApi.stop({ type: ISongs.Win_Epic });
      }
    });

    return countUpAnimation;
  }

  private clean(): void {
    setIsDuringBigWinLoop(false);
    //AudioApi.stop({ type: ISongs.BigWin_Loop });
    //AudioApi.stop({ type: ISongs.BigWin_End });
    AudioApi.stop({ type: ISongs.Win_Big });
    AudioApi.stop({ type: ISongs.Win_Mega });
    AudioApi.stop({ type: ISongs.Win_Great });
    AudioApi.stop({ type: ISongs.Win_Epic });
    AudioApi.fadeIn(3000, getBGMSoundByGameMode(Logic.the.controller.gameMode));
    AudioApi.play({ type: getBGMSoundByGameMode(Logic.the.controller.gameMode) });
    this.spine.state.addEmptyAnimation(0, 0, 0);
    this.spine.visible = false;
    this.spineEffects.state.addEmptyAnimation(0, 0, 0);
    this.spineEffects.visible = false;
    this.winValue.visible = false;
    this.setWinValue(0);
    this.bigWinCountUpAnimation = null;
  }

  public setWinValue(winValue: number): void {
    this.winValue.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winValue.style = BigWinTextValueTextStyle;
    this.winValue.anchor.set(0.5, 0.5);
    this.winValue2.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winValue2.style = BigWinTextValueTextStyle2;
    this.winValue2.anchor.set(0.5, 0.5);
  }

  private resize(width: number, height: number) {
    this.background.width = width;
    this.background.height = height;
    this.isLandscape = width >= height;
    this.position.set(width / 2, height / 2);
    const factor = this.isLandscape ? height / 1280 : width / 1000;

    this.spine.scale.set(factor);
    this.spineEffects.scale.set(factor * 0.75);
  }
}

import { TextStyle } from 'pixi.js';

export const spinsLeftTitleStyle = new TextStyle({
  dropShadow: true,
  dropShadowAlpha: 0.2,
  dropShadowAngle: 0.6,
  dropShadowBlur: 4,
  dropShadowDistance: 1,
  fill: ['#CA9A55', '#D3A564', '#A98047'],
  fillGradientStops: [0.1, 0.5, 1],
  fontFamily: 'Myriad-Pro-Regular',
  letterSpacing: -1,
  fontSize: 62,
});

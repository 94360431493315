import gsap from 'gsap';
import { Sprite, utils } from 'pixi.js';

import { EventTypes, GameMode, ReelsPattern } from '../../global.d';
import ComboCollectorContainer from '../comboCollector/comboCollectorContainer';
import ComboCollectorSlot from '../comboCollector/comboCollectorSlot';
import { eventManager } from '../config';

export default class BonusComboCollector extends ComboCollectorContainer {
  // private multiplier = 2;
  // private multiplierText = new Text('x2');
  //protected override scaleFactor = 0.5;

  constructor() {
    super();
  }

  protected override addEvents() {
    eventManager.on(EventTypes.START_SPIN_ANIMATION, () => {
      this.reset();
    });

    eventManager.on(EventTypes.BONUS_GAME_COLLECT, (data) => {
      const pattern = data as ReelsPattern;
      this.addCombo(pattern);
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BONUS_GAME:
        this.reset();
        break;
      default:
        break;
    }
  }

  protected override addGraphics() {
    const frameTexture = utils.TextureCache['maingame_collector_vertical_resource.png'];
    const frame = new Sprite(frameTexture);
    frame.x = 0;
    frame.y = 20;
    frame.anchor.set(0.5);

    const extraFrameTexture = utils.TextureCache['extra_collector_big.png'];
    const extraFrame = new Sprite(extraFrameTexture);
    extraFrame.x = 200;
    //extraFrame.y = 50;
    extraFrame.anchor.set(0.5);
    extraFrame.visible = false;
    this.extraSlotsContainer = extraFrame;

    let distance = 175;
    const startY = -435;
    for (let i = 0; i < this.slotsCount; i++) {
      const slot = new ComboCollectorSlot();
      slot.y = startY + distance * i;

      this.defaultSlots.push(slot);
    }

    distance = 177;
    // skip 1 slot
    for (let i = 1; i <= this.extraSlotsCount; i++) {
      const slot = new ComboCollectorSlot();
      slot.x += 15;
      slot.y = startY + distance * i - 137;

      this.extraSlots.push(slot);
    }

    this.addChild(extraFrame, frame, ...this.defaultSlots);
    extraFrame.addChild(...this.extraSlots);
    extraFrame.scale.set(0.78);

    this.x = 100;
    this.y = 600;
    this.rotation = Math.PI / 2; // Rotate by 90 degrees in radians
    const scale = 0.8;
    this.scale.set(scale, -scale);

    this.collectorSlots = [...this.defaultSlots, ...this.extraSlots];

    this.collectorSlots.forEach((s) => s.rotateSlots('horizontal'));
  }

  protected override reset() {
    for (const slot of this.collectorSlots) {
      slot.hideAnimation();
    }

    if (this.collectedTypesCount >= this.slotsCount) {
      this.hideExtraSlots();
    }

    this.collectedTypesCount = 0;

    this.reservedSlots = [];
  }

  protected override showExtraSlots(): gsap.core.Timeline {
    const chain = gsap.timeline();

    chain.fromTo(this.extraSlotsContainer, { visible: true, x: 0 }, { x: 220, duration: 0.5, ease: 'bounce.out' });

    return chain;
  }

  protected override hideExtraSlots(): gsap.core.Timeline {
    const chain = gsap.timeline();
    chain.to(this.extraSlotsContainer, {
      x: 0,
      duration: 0.5,
      onComplete: () => {
        this.extraSlotsContainer.visible = false;
      },
    });

    return chain;
  }

  protected override resize(_width: number, _height: number): void {}
}

import { TEXT_GRADIENT, TextStyle } from 'pixi.js';

import { Colors, Variables } from '../../config';

// const sample = new TextStyle({
//   align: 'center', // string;
//   breakWords: true, // ##false, // boolean;
//   dropShadow: true, // ##false, // boolean;
//   dropShadowAlpha: 0.75, // ##1, // number;
//   dropShadowAngle: 0.785, // ##Math.PI / 6, // number;
//   dropShadowBlur: 10, // ##0, // number;
//   dropShadowColor: 0x000000, // ##'black', // string | number;
//   dropShadowDistance: 4, // number;
//   fill: [0xffc000, 0xff0000], // ##'black', // string | string[] | number | number[] | CanvasGradient | CanvasPattern;
//   fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL, // number;
//   // fillGradientStops: [0,1], //number[];
//   fontFamily;
//   fontSize: 80, // number | string;
//   fontStyle: 'normal', // string;
//   fontVariant: 'normal', // string;
//   fontWeight: 'bold', // ## 'normal', // string;
//   leading: 0, // number;
//   letterSpacing: 0, // number;
//   // lineHeight: 0, //number;
//   lineJoin: 'round', // ##'miter', // string;
//   miterLimit: 10, // number;
//   padding: 4, // ##0, // number;
//   stroke: '#ffffff', // ##'black', // string | number;
//   strokeThickness: 6, // ##0, // number;
//   trim: false, // boolean;
//   textBaseline: 'alphabetic', // string;
//   whiteSpace: 'pre', // string;
//   wordWrap: false, // boolean;
//   wordWrapWidth: 1330, // number;
// });

export const multiplierTitleTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  dropShadowAngle: 1.2,
  fill: ['#9f6518', '#ffeb19', '#FBC41C', '#F5FF3C', '#f08418'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontSize: 70,
  fontWeight: 'bolder',
  stroke: '#000000',
  strokeThickness: 6,
});

const winMessage = new TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 140,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new TextStyle({
  align: 'center',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 60,
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#fff',
  strokeThickness: 22,
  whiteSpace: 'normal',
});

const winCounterStyle2 = new TextStyle({
  align: 'center',
  fill: ['#fff27c', '#ffe388', '#ff9900', '#ffee54'],
  fillGradientStops: [0, 0.45, 0.55, 0.75],
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 60,
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 14,
  whiteSpace: 'normal',
});

export const miniPayTableStyle = {
  fontSize: 28,
  lineHeight: 50,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
};
export const miniPayTableAdditionalTextStyle = new TextStyle({
  fontSize: 28,
  lineHeight: 30,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
  wordWrap: true,
  wordWrapWidth: 220,
  breakWords: true,
  align: 'center',
});

export const bottomContainerTextStyle = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

export const bigWinStyles = new TextStyle(winMessage);
export const megaWinStyles = new TextStyle(winMessage);
export const greatWinStyles = new TextStyle(winMessage);
export const epicWinStyles = new TextStyle(winMessage);
export const winValueStyles = new TextStyle(winCounterStyle);
export const winValueStyles2 = new TextStyle(winCounterStyle2);

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_FIT_TO_WIDTH = 160;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 2;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;

export const BigWinTextValueTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  align: 'center',
  fontSize: 140,
  lineHeight: 40,
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#fff',
  strokeThickness: 22,
  whiteSpace: 'normal',
});
export const BigWinTextValueTextStyle2 = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  align: 'center',
  fontSize: 140,
  lineHeight: 40,
  fill: ['#fff27c', '#ffe388', '#ff9900', '#ffee54'],
  fillGradientStops: [0, 0.45, 0.55, 0.75],
  fontWeight: '700',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 14,
  whiteSpace: 'normal',
});

export const buyFeatureTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 30,
  lineHeight: 32,
  lineJoin: 'round',
  fill: ['#FFFDC7'],
  stroke: '#DD1A79',
  strokeThickness: 8,
});
export const buyFeatureHoverTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 32,
  lineHeight: 34,
  lineJoin: 'round',
  fill: ['#FFFFFF'],
  stroke: '#DD1A79',
  strokeThickness: 8,
});

export const buyFeatureClickTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 28,
  lineHeight: 30,
  lineJoin: 'round',
  fill: ['#FFCC48'],
  stroke: '#DD1A79',
  strokeThickness: 8,
});

export const buyFeatureDisableTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 30,
  lineHeight: 30,
  lineJoin: 'round',
  fill: ['#FFCC48'],
  stroke: '#DD1A79',
  strokeThickness: 8,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };

export const autoPlayCounterTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 25,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
});

export const clockTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const gameNameTextStyle = new TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const soundToastStyles = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

export const restoredGameTextStyle = new TextStyle({
  fontSize: 80,
  fontFamily: 'Myriad-Pro-Semibold',
  whiteSpace: 'normal',
  fontWeight: '500',
  align: 'center',
  fill: 0xfdbf3f,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  strokeThickness: 10,
});

export const comboCollectorMultiplierTextStyle = new TextStyle({
  dropShadow: true,
  dropShadowAlpha: 0.5,
  dropShadowBlur: 4,
  dropShadowColor: 0x383f31,
  dropShadowDistance: 2,
  fill: [0xca9c55, 0xb3884a, 0xb58645],
  fillGradientStops: [0.51],
  fontFamily: 'Arial Black',
  fontSize: 62,
  letterSpacing: -4,
  lineJoin: 'round',
  miterLimit: 13,
  padding: 2,
  stroke: 0x493920,
  strokeThickness: 2,
  trim: true,
  align: 'center',
});
